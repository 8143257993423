import {isEmpty, isEqual} from "lodash";
import {Gender, TargetGroup} from "../../../services/interfaces";
import Input from "../../common/Input";
import {Select} from "antd";
import Button from "../../common/Button";
import React, {useMemo, useState} from "react";
import {createTargetGroupHandler, updateTargetGroupHandler} from "../../../handlers/targetGroupHandlers";
import TargetGroupAmountRanges from "./TargetGroupAmountRanges";
import {TARGET_GROUP_DEFAULT_STATE} from "../../../redux/merchant";
import TargetGroupRangesSelect from "./TargetGroupRangesSelect";
import {useSelector} from "react-redux";
import {getDateRange} from "../../../redux/selectors/merchantSelector";
import moment from "moment";

interface Props {
    targetGroups: TargetGroup[]
    editedTargetGroup: TargetGroup
    setEditedTargetGroup: React.Dispatch<React.SetStateAction<TargetGroup>>
    setShowList: React.Dispatch<React.SetStateAction<boolean>>
    editCurrent: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const TargetGroupForm = ({
                             targetGroups,
                             editedTargetGroup,
                             setEditedTargetGroup,
                             setShowList,
                             editCurrent,
                             setIsOpen,
                         }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState(false);
    const dateRange = useSelector(getDateRange);

    const originalTargetGroup = useMemo(() => {
        return targetGroups.find(group => group.id === editedTargetGroup.id);
    }, [targetGroups, editedTargetGroup]);

    const isEdited = useMemo(() => {
        if (originalTargetGroup)
            return !isEqual(originalTargetGroup, editedTargetGroup);
        else return true
    }, [originalTargetGroup, editedTargetGroup]);

    const isDisabled = loading || !isEdited || !editedTargetGroup.name;
    const isAdded = !editedTargetGroup.id;

    const onCancel = () => {
        if (isEmpty(targetGroups) || editCurrent) {
            setIsOpen(false)
        } else {
            setEditedTargetGroup(TARGET_GROUP_DEFAULT_STATE);
            setShowList(true);
        }
        setError(false)
    };


    const onSave = () => {
        const callback = () => {
            setLoading(false);
            setShowList(true);
            setEditedTargetGroup(TARGET_GROUP_DEFAULT_STATE);
            if (editCurrent)
                setIsOpen(false)
        };

        const errorCallback = () => {
            setLoading(false);
            setError(true)
        };

        setLoading(true);
        originalTargetGroup && editedTargetGroup.id ?
            updateTargetGroupHandler(originalTargetGroup, editedTargetGroup, callback, errorCallback) :
            createTargetGroupHandler(editedTargetGroup, callback, errorCallback);
    };


    return (
        <div className="target-group-form">
            <div className="modal-body">
                <Input<TargetGroup, "name"> name="name" value={editedTargetGroup} label="Nazwa"
                                            placeholder="Grupa docelowa..." onChange={setEditedTargetGroup}/>
                <div className="range-container">
                    <TargetGroupRangesSelect rangeKey='age_ranges' title='Wiek' placeholder="Wybierz wiek..."
                                             targetGroup={editedTargetGroup}
                                             setTargetGroup={setEditedTargetGroup}/>
                </div>
                <div className="select-wrapper">
                    <p className="label">Płeć</p>
                    <Select
                        mode="multiple"
                        value={editedTargetGroup.gender}
                        onChange={(value) => setEditedTargetGroup({...editedTargetGroup, gender: value})}
                        allowClear={true}
                        placeholder="Wybierz płeć..."
                        showSearch={false}
                        options={[
                            {value: "male" as Gender, label: 'Mężczyzna'},
                            {value: "female" as Gender, label: 'Kobieta'},
                            {value: "unknown" as Gender, label: 'Inna'},
                        ]}
                        className="dropdown-select"
                    />
                </div>
                <div className="range-container">
                    <TargetGroupRangesSelect rangeKey='transactions_ranges' title='Liczba transakcji w sklepie'
                                             placeholder="Wybierz liczbę transakcji..."
                                             targetGroup={editedTargetGroup}
                                             setTargetGroup={setEditedTargetGroup}/>
                </div>
                <TargetGroupAmountRanges editedTargetGroup={editedTargetGroup}
                                         setEditedTargetGroup={setEditedTargetGroup}/>
                <div className="range-container">
                    <div className="date-section">
                        <p className="label">Data transakcji</p>
                        <p className="label blue">{moment(dateRange.startDate).format("DD.MM.YYYY")} - {moment(dateRange.endDate).format("DD.MM.YYYY")}</p>
                    </div>
                </div>
            </div>
            <div className="modal-footer with-error">
                {error && <div className="error-message">Wystąpił błąd podczas zapisywania zmian grupy docelowej</div>}
                <div className="buttons">
                    <Button label="Anuluj"
                            onClick={onCancel}
                            type="Default"
                    />
                    <Button label={isAdded ? "Utwórz grupę" : "Zapisz zmiany"}
                            disabled={isDisabled}
                            loading={loading}
                            onClick={onSave}
                    />
                </div>
            </div>
        </div>
    )
};

export default TargetGroupForm