import {OrderType} from "../../../../services/interfaces";
import React, {useEffect, useState} from "react";
import Table from "../../../../components/common/Table";
import {AdminAppUserSummary} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {
    getAdminUsersWithoutTransactionsTableCount,
    getAdminUsersWithoutTransactionsTableList,
    getAdminUsersWithoutTransactionsTableMaxPage
} from "../../../redux/selectors/adminUsersSelectors";
import {
    downloadUsersWithoutTransactionsTableHandler,
    getUsersWithoutTransactionsTableHandler
} from "../../../handlers/adminUsersHandlers";
import AdminUsersTableFiltersModal from "./AdminUsersTableFiltersModal";
import AdminUsersTableRow from "./AdminUsersTableRow";
import {USERS_TABLE_HEADERS} from "./AdminUsersTable";
import AppUserDetailsModal from "../../../../components/modals/AppUserDetailsModal";


const AdminUsersWithoutTransactionsTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const data: AdminAppUserSummary[] = useSelector(getAdminUsersWithoutTransactionsTableList);
    const maxPage: number = useSelector(getAdminUsersWithoutTransactionsTableMaxPage);
    const count: number = useSelector(getAdminUsersWithoutTransactionsTableCount);

    useEffect(() => {
        getUsersWithoutTransactionsTableHandler({
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize,
        })
    }, [page, query, sort, order, filterString, pageSize]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);


    return (
        <>
            <Table page={page}
                   setPage={setPage}
                   pageSize={pageSize}
                   setPageSize={setPageSize}
                   query={query}
                   setQuery={setQuery}
                   sort={sort}
                   setSort={setSort}
                   order={order}
                   setOrder={setOrder}
                   count={count}
                   filters={filterString}
                   setFilters={setFilterString}
                   maxPage={maxPage}
                   headers={USERS_TABLE_HEADERS}
                   renderRows={() => data.map((user, index) =>
                       <AdminUsersTableRow user={user}
                                           filters={filterString}
                                           key={`UserRow${index}`}
                                           onClick={() => setSelectedId(user.id)}
                       />
                   )}
                   downloadHandler={downloadUsersWithoutTransactionsTableHandler}
                   Modal={AdminUsersTableFiltersModal}/>
            <AppUserDetailsModal userId={selectedId} admin
                                 onClose={() => setSelectedId(null)}/>
        </>
    )
};


export default AdminUsersWithoutTransactionsTable;