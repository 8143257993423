import {icons} from "../../../services/images";
import Button from "../../common/Button";
import ActionModal from "../../modals/ActionModal";
import React, {ChangeEvent, useMemo, useRef, useState} from "react";
import {Category, CategoryFilters, CategoryType} from "../../../services/interfaces";
import ImportCategoriesModal from "./ImportCategoriesModal";
import CategoryFiltersModal from "./CategoryFiltersModal";
import {
    downloadCategoriesHandler,
    getCategoriesHandler,
    importCategoryExcelHandler
} from "../../../handlers/bookingHandlers";
import {useDispatch} from "react-redux";
import {setResponseNotificationAction, setResponseNotificationErrorAction} from "../../../redux/navigation";


interface Props {
    filters: CategoryFilters
    setFilters: (filters: CategoryFilters) => void
    categoryType: CategoryType,
    addCategory: () => void
    addItem: () => void
    categories: Category[]
}

const ControlsRow = ({filters, setFilters, categoryType, addCategory, addItem, categories}: Props) => {

    const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isFilterModalOpen, setFiltersModalOpen] = useState(false);
    const actionRef = useRef<(HTMLDivElement | null)>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024;
        try {
            event.target.value = '';  // Reset
            // Validate if file exists
            if (!file) {
                dispatch(setResponseNotificationAction({status: "error", message: "no_file"}));
                return;
            }

            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                dispatch(setResponseNotificationAction({status: "error", message: "file_too_large"}));
                return;
            }

            // Validate file type (assuming you want Excel files)
            const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validTypes.includes(file.type)) {
                dispatch(setResponseNotificationAction({status: "error", message: "wrong_file_type"}));
                return;
            }

            const reader = new FileReader();

            // Handle file reading errors
            reader.onerror = (error) => {
                dispatch(setResponseNotificationErrorAction());
            };


            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const fileBase64 = reader.result.split(",")[1];
                    importCategoryExcelHandler(categoryType, fileBase64, () => getCategoriesHandler(categoryType));
                } else {
                    dispatch(setResponseNotificationErrorAction());
                }
            };

            reader.readAsDataURL(file);


        } catch (error) {
            dispatch(setResponseNotificationErrorAction());
        }
    };


    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const DEFAULT_ACTIONS = useMemo(() => [
        {
            title: 'Eksport',
            onClick: () => {
                downloadCategoriesHandler(categoryType);
                setIsActionModalOpen(false);
            },
            className: ''
        },
        {
            title: 'Import z pliku Excel',
            onClick: () => {
                fileInputRef.current && fileInputRef.current.click();
                setIsActionModalOpen(false);
            },
            className: ''
        },
        {
            title: 'Import z innego punktu',
            onClick: () => {
                setIsImportModalOpen(true);
                setIsActionModalOpen(false);
            },
            className: ''
        },
    ], [categoryType]);

    const actions = useMemo(() => {
        if (categoryType === 'product') return DEFAULT_ACTIONS;
        return [{
            title: 'Zarządzaj Harmonogramem',
            onClick: () => console.log(), // TODO
            className: ''
        }, ...DEFAULT_ACTIONS];
    }, [categoryType, DEFAULT_ACTIONS]);

    return (
        <div className="controls-row">
            <input type="file" onChange={handleFileChange} ref={fileInputRef} hidden
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            <div className="filters-row">
                <div className="searchbar">
                    <img src={icons.search} alt="Search"/>
                    <input type="text" placeholder="Szukaj..." className="searchbar-input"
                           maxLength={50}
                           value={filters.query}
                           onChange={event => setFilters({...filters, query: event.target.value})}/>
                </div>
                <div className="filters-button" onClick={() => setFiltersModalOpen(true)}>
                    <img src={icons.filterGrey} alt="Filters"/>
                    <p className="label-small">Filtry</p>
                </div>
            </div>
            <div className="buttons-row">
                <div ref={actionRef}>
                    <Button label="Więcej opcji" onClick={toggleActionModal} icon={icons.cogIcon} type={'Default'}/>
                    <ActionModal isOpen={isActionModalOpen}
                                 onClose={toggleActionModal}
                                 actions={actions}
                                 x={actionModalPosition.x} y={actionModalPosition.y}/>
                </div>
                <Button label="Dodaj kategorię" onClick={addCategory} icon={icons.addBlueWhiteIcon}
                        type={'Default'}/>
                <Button label={`Dodaj ${categoryType === 'product' ? 'produkt' : 'usługę'}`} onClick={addItem}
                        icon={icons.addWhiteIcon}/>
            </div>
            <ImportCategoriesModal isOpen={isImportModalOpen} onClose={() => setIsImportModalOpen(false)}
                                   categoryType={categoryType}/>
            <CategoryFiltersModal open={isFilterModalOpen} setOpen={setFiltersModalOpen} allCategories={categories}
                                  setFilters={setFilters} filters={filters}/>
        </div>
    )

};

export default ControlsRow;