import React, {useState} from 'react';

import Step1 from '../modals/stepper/Step1';
import Step2 from '../modals/stepper/Step2';
import Step3 from '../modals/stepper/Step3';
import Step4 from '../modals/stepper/Step4';
import FormStepIndicator from "../modals/stepper/FormStepIndicator";
import {OfferDays, OfferLocation, OfferType, TargetGroup} from "../../services/interfaces";
import {TARGET_GROUP_DEFAULT_STATE} from "../../redux/merchant";
import {DEFAULT_OFFER_LOCATION} from "./MapModal";
import {message} from "antd";
import OfferSuccessModal from "./OfferSuccessModal";

export interface OfferData {
  step1: {
    clients: string;
    targetGroup: TargetGroup;
    localization: OfferLocation;
  },
  step2: {
    name: string;
    type: OfferType | undefined;
    merchantPoints: number[];
    startDate: Date | null;
    endDate: Date | null;
    daysOffer: OfferDays[];
    usage: number | undefined;
    description: string;
    directAddressing: boolean;
    directAddressingStyle: string,
    sendPushNotification: boolean;
  },
  step3: {
    imagePath: string;
    filmPath: string;
    filmTitle: string;
    filmFile: File|null;
  }
}

export interface FormErrors {
  step1?: {
    clients?: string;
    targetGroup?: string;
  },
  step2?: {
    name?: string;
    type?: string;
    merchantPoints?: string;
    date?: string;
    daysOffer?: string;
    usage?: string;
  },
}

export const DEFAULT_OFFER_DATA = {
  step1: {
    clients: "current",
    targetGroup: TARGET_GROUP_DEFAULT_STATE,
    localization: DEFAULT_OFFER_LOCATION,
  },
  step2: {
    name: "",
    type: undefined,
    merchantPoints: [],
    startDate: null,
    endDate: null,
    daysOffer: [],
    usage: undefined,
    description: "",
    directAddressing: false,
    directAddressingStyle: "Hej Adam,...",
    sendPushNotification: false,
  },
  step3: {
    imagePath: "",
    filmPath: "",
    filmTitle: "",
    filmFile: null,
  }
}

const Stepper: React.FC<{ closeModal: () => void }> = ({closeModal}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<OfferData>(DEFAULT_OFFER_DATA);
  const [errors, setErrors] = useState({});
  const [estimatedCost, setEstimatedCost] = useState<number>(0);
  const [numberOfCustomers, setNumberOfCustomers] = useState<number>(0);

  const validateStep1 = () => {
    const newErrors: FormErrors = {};
    if (formData.step1.clients === "") {
      newErrors.step1 = {...newErrors.step1, clients: 'Pole klientów jest wymagane'};
    }
    if (formData.step1.targetGroup.name === "") {
      newErrors.step1 = {...newErrors.step1, targetGroup: 'Grupa docelowa jest wymagana'};
    }
    return newErrors;
  };


  const validateStep2 = () => {
    const newErrors: FormErrors = {};
    if (formData.step2.name === "") {
      newErrors.step2 = {...newErrors.step2, name: 'Nazwa jest wymagana'};
    }
    if (!formData.step2.type) {
      newErrors.step2 = {...newErrors.step2, type: 'Typ promocji jest wymagany'};
    }
    if (Object.keys(formData.step2.merchantPoints).length === 0) {
      newErrors.step2 = {...newErrors.step2, merchantPoints: 'Wybranie punktów jest wymagane'};
    }
    if (!formData.step2.startDate || !formData.step2.endDate) {
      newErrors.step2 = {...newErrors.step2, date: 'Czas trwania promocji jest wymagany'};
    }
    if (!formData.step2.usage) {
      newErrors.step2 = {...newErrors.step2, usage: 'Podanie liczby dostępnych użyć jest wymagane'};
    }
    if (formData.step2.usage && formData.step2.usage <= 0) {
      newErrors.step2 = {...newErrors.step2, usage: 'Liczba dostępnych użyć musi być większa'};
    }
    return newErrors;
  };


  const nextStep = () => {
    let stepErrors: FormErrors = {};
    if (step === 1) {
      stepErrors = validateStep1();
    } else if (step === 2) {
      stepErrors = validateStep2();
    }

    if (Object.keys(stepErrors).length > 0) {
      setErrors(stepErrors);
      const errorMessages = Object.values(stepErrors).flatMap(Object.values);
      message.error(errorMessages[0]);
    } else {
      setErrors({});
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep(step - 1);

  const handleChange = (step: keyof OfferData, field: string, value: any) => {
    setFormData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: value,
      },
    }));
  };

  const handleChangeSubField = (step: keyof OfferData, field: string, subField: string, value: any) => {
    setFormData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: {
          ...((prevData[step] as any)[field] || {}),
          [subField]: value,
        },
      },
    }));
  };


  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1
            formData={formData.step1}
            handleChangeSubField={(field: string, subField: string, value: any) => handleChangeSubField('step1', field, subField, value)}
            handleChange={(field: string, value: any) => handleChange('step1', field, value)}
            setNumberOfCustomers={((value: number) => setNumberOfCustomers(value))}
            setEstimateCost={(value: number) => setEstimatedCost(value)}
            nextStep={nextStep}
            prevStep={closeModal}
            errors={errors}/>;
      case 2:
        return <Step2
            formData={formData.step2}
            handleChange={(field: string, value: any) => handleChange('step2', field, value)}
            nextStep={nextStep}
            prevStep={prevStep}
            numberOfCustomers={numberOfCustomers}
            estimateCost={estimatedCost}
            errors={errors}/>;
      case 3:
        return <Step3
            formData={formData.step3}
            handleChange={(field: string, value: any) => handleChange('step3', field, value)}
            prevStep={prevStep}
            nextStep={nextStep}
            numberOfCustomers={numberOfCustomers}
            estimateCost={estimatedCost}
            errors={errors}/>;
      case 4:
        return <Step4
            formData={formData}
            handleChange={(field: string, value: any) => handleChange('step3', field, value)}
            prevStep={prevStep}
            nextStep={nextStep}
            setStep={(value: number) => setStep(value)}
            numberOfCustomers={numberOfCustomers}
            estimateCost={estimatedCost}
            errors={errors}/>;
      case 5:
        return <OfferSuccessModal
                  onClose={closeModal}
                  formData={formData}
        />
      default:
        return null;
    }
  };

  return (
      <div className="modal-body">
        <FormStepIndicator step={step}/>
        {
          renderStep()
        }
      </div>
  );
};

export default Stepper;