import React from "react";
import Card from "../common/Card";
import {ServiceType} from "../../services/interfaces";

interface Props {
    logo: string;
    backgroundImage: string;
    backgroundColor: number | null;
    address?: string;
    addressColor: number;
    serviceTypes: ServiceType[];
}

export const CardPreview = ({logo, backgroundImage, backgroundColor, address, addressColor, serviceTypes}: Props) => {
    return (
        <div className="card-preview">
            <h2>Podgląd karty</h2>
            <Card logo={logo} backgroundImage={backgroundImage} backgroundColor={backgroundColor} address={address}
                  addressColor={addressColor} serviceTypes={serviceTypes}/>
        </div>
    )
};

export default CardPreview;