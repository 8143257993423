import {icons} from "../../../services/images";
import React from "react";
import {Tooltip} from "react-tooltip";

interface StepIndicatorProps {
    step: number,
}

const FormStepIndicator = ({step}: StepIndicatorProps) => {
    return (
        <div className="stepper-wrapper">
            <div className="stepper-item">
                <img src={step === 1 ? icons.step1Icon : icons.prevStepIcon} alt="step 1"/>
                <span className="text activated">Grupa docelowa</span>
                <Tooltip id="TargetGroupTooltip" className="tooltip" noArrow={true}>
                        <p className="tooltip-body">Wybierz z listy gotową lub stwórz własną grupę,
                            do której chcesz zaadresować promocję np. kobiety 20-40 z miasta Goleniów.</p>
                </Tooltip>
                <img src={icons.infoIcon} className="info" alt="info" data-tooltip-id="TargetGroupTooltip" data-tooltip-place="top-end"/>
            </div>
            <div className="stepper-line">
                <img src={step > 1 ? icons.blueLineIcon : icons.greyLineIcon} alt="line"/>
            </div>
            <div className="stepper-item">
                <img src={step === 2 ? icons.step2Icon : (step < 2 ? icons.step2GreyIcon : icons.prevStepIcon)}
                     alt="step 2"/>
                <span className={step >= 2 ? "text activated" : "text"}>Szczegóły promocji</span>
                <Tooltip id="PromotionDetailsTooltip" className="tooltip" noArrow={true}>
                        <p className="tooltip-body">Szczegóły promocji.</p>
                </Tooltip>
                <img src={icons.infoIcon} className="info" alt="info" data-tooltip-id="PromotionDetailsTooltip" data-tooltip-place="top"/>
            </div>
            <div className="stepper-line">
                <img src={step > 2 ? icons.blueLineIcon : icons.greyLineIcon} alt={"line"}/>
            </div>
            <div className="stepper-item" style={{marginLeft: 40, marginRight: 40}}>
                <img src={step === 3 ? icons.step3Icon : (step < 3 ? icons.step3GreyIcon : icons.prevStepIcon)}
                     alt={"step 3"}/>
                <span className={step >= 3 ? "text activated" : "text"}>Media</span>
            </div>
            <div className="stepper-line">
                <img src={step > 3 ? icons.blueLineIcon : icons.greyLineIcon} alt={"line"}/>
            </div>
            <div className={"stepper-item"}>
                <img src={step === 4 ? icons.step4Icon : (step < 4 ? icons.step4GreyIcon : icons.prevStepIcon)}
                     alt={"step 4"}/>
                <span className={step >= 4 ? "text activated" : "text"}>Podsumowanie</span>
            </div>
        </div>
    );
}

export default FormStepIndicator;