import AdminDashboard from "../components/dashboard/AdminDashboard";
import {useEffect} from "react";
import {ChartInterval, DateRange} from "../../services/interfaces";
import {useSelector} from "react-redux";
import {
    getAdminChartInterval,
    getAdminDateRange,
    getAdminGlobalFilters,
    getAdminPreviousPeriod
} from "../redux/selectors/adminViewSettingsSelectors";
import {getAdminDashboardDataHandler} from "../handlers/dashboardHandlers";

const DashboardContainer = () => {
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const chartInterval: ChartInterval = useSelector(getAdminChartInterval);
    const previousPeriod = useSelector(getAdminPreviousPeriod);
    const globalFilters = useSelector(getAdminGlobalFilters);

    useEffect(() => {
        getAdminDashboardDataHandler(dateRange, previousPeriod, chartInterval, globalFilters)
    }, [dateRange, chartInterval, previousPeriod, globalFilters]);

    return <AdminDashboard/>
};

export default DashboardContainer;