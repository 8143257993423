import React, {useState} from "react";
import {icons} from "../../services/images";
import {isNaN} from "lodash";
import {Tooltip} from "react-tooltip";


const Input = <T extends object, K extends keyof T>({
                                                        value,
                                                        onChange,
                                                        name,
                                                        placeholder,
                                                        icon,
                                                        label,
                                                        tooltipTitle,
                                                        tooltipBody,
                                                        max,
                                                        min = 0,
                                                        decimalPlaces = 0,
                                                        type = "text",
                                                        showError = false,
                                                        errorMessage = {},
                                                        validate = () => null,
                                                        isDisabled = false,
                                                        isRequired = false,
                                                        isLoading = false,
                                                        isDisabledDuringLoading = false,
                                                        maxLength,
                                                        displayedValue,
                                                        appendix
                                                    }: Props<T, K>) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        const updatedValue = type === 'number' ? parseNumberInput(value) : value;

        if (updatedValue !== undefined) {
            onChange((prevState) => ({...prevState, [name]: updatedValue}));

            if (showError && !validate(updatedValue)) {
                setError(false);
            }
        }
    };

    const parseNumberInput = (input: string): number | undefined => {
        const parsedValue = parseFloat(input);
        if (isNaN(parsedValue)) {
            return undefined;
        }
        const formattedValue = parseFloat(parsedValue.toFixed(decimalPlaces));
        if (max) {
            if (formattedValue >= min && formattedValue <= max)
                return formattedValue;
            else if (formattedValue < min)
                return min;
            else if (formattedValue > max)
                return max;
        } else {
            if (parsedValue >= min)
                return parsedValue;
            else
                return undefined;
        }
    };
    const [error, setError] = useState<string | boolean | null>(false);

    return (
        <div className={`input-wrapper ${error ? 'has-error' : ''}`}>
            {label &&
                <div className="label-wrapper">
                    <label>
                        {label}
                        {isRequired && <span className="required-mark"> *</span>}
                    </label>
                    {tooltipTitle && tooltipBody &&
                        <>
                            <Tooltip id={tooltipTitle} className="tooltip" noArrow={true}>
                                <p className="tooltip-title">{tooltipTitle}</p>
                                <p className="tooltip-body">{tooltipBody}</p>
                            </Tooltip>
                            <img src={icons.infoIcon} alt="info" data-tooltip-id={tooltipTitle}
                                 data-tooltip-place="top-end"/>
                        </>
                    }
                </div>
            }
            {icon &&
                <span className="icon-wrapper">
                    <img className={`icon ${name as string}`} src={icon} alt={name as string}/>
                </span>
            }
            {isLoading && <span className="input-loading"/>}
            <input
                className="form-control"
                type={type}
                placeholder={placeholder}
                onChange={handleChange}
                value={displayedValue ?? value[name] as string}
                disabled={isDisabled || (isDisabledDuringLoading && isLoading)}
                onBlur={() => showError &&
                    setError((value[name] as string).length !== 0 && validate(value[name]))
                }
                maxLength={maxLength ? maxLength : undefined}
            />
            {appendix && <span style={{marginLeft: -25}}>{appendix}</span>}
            {error && typeof error == "string" && errorMessage &&
                <p className="error-message">{errorMessage[error]}</p>
            }
        </div>
    )
};

interface Props<T extends object, K extends keyof T> {
    appendix?: string,
    decimalPlaces?: number;
    displayedValue?: string
    errorMessage?: { [key: string]: string };
    icon?: string;
    isDisabled?: boolean;
    isDisabledDuringLoading?: boolean;
    isLoading?: boolean;
    isRequired?: boolean;
    label?: string;
    max?: number;
    maxLength?: number;
    min?: number;
    name: K;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    placeholder?: string;
    showError?: boolean;
    tooltipBody?: string;
    tooltipTitle?: string;
    type?: React.HTMLInputTypeAttribute;
    validate?: (value: any) => null | string;
    value: T;
}

export default Input;