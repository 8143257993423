import {icons} from "../../../services/images";
import {TransactionStatus, TransactionStatusState} from "../../../services/interfaces";
import React from "react";


interface Props {
    status: TransactionStatusState
    setStatus: React.Dispatch<React.SetStateAction<TransactionStatusState>>
}


const HistoryCashbackStatus = ({status, setStatus}: Props) => {

    interface CashbackStatusesInterface {
        value: TransactionStatus
        icon: string
        color: string
        label: string
    }

    const areAllStatusActive = Object.values(status).every((value) => value);

    const cashbackStatuses: CashbackStatusesInterface[] = [
        {value: 'wallet', icon: icons.coinGreenIcon, color: '#00F6A1', label: 'Portfel'},
        {value: 'receivables', icon: icons.coinBluePrimaryIcon, color: '#213DFC', label: 'W toku'},
        {value: 'withdrawn', icon: icons.coinGrayIcon, color: '#989898', label: 'Wypłacone'},
        {value: 'rejected', icon: icons.coinRedIcon, color: '#D13735', label: 'Odrzucone'},
    ];

    const toggleStatus = (status: TransactionStatus) => {
        setStatus((prevState: TransactionStatusState) => ({
            ...prevState,
            [status]: !prevState[status],
        }));
    };

    return (
        <div className="section">
            <div className="header">
                <h6>Status cashbacku</h6>
                <span onClick={() =>
                    setStatus(() => {
                            if (areAllStatusActive) {
                                return {
                                    wallet: false,
                                    receivables: false,
                                    withdrawn: false,
                                    rejected: false,
                                }
                            } else {
                                return {
                                    wallet: true,
                                    receivables: true,
                                    withdrawn: true,
                                    rejected: true,
                                }
                            }
                        }
                    )}>
                    {areAllStatusActive ? 'Odznacz wszystko' : 'Zaznacz wszystko'}
                </span>
            </div>
            <div className="status">
                {cashbackStatuses.map((o, index) =>
                    <button onClick={() => toggleStatus(o.value)} className={`${status[o.value] && 'active'}`} key={`CashbackStatus${index}`}>
                        <img src={o.icon} alt="icon"/>
                        <span style={{color: o.color}}>{o.label}</span>
                    </button>
                )}
            </div>
        </div>
    )
};

export default HistoryCashbackStatus;