import {icons} from "../../services/images";

interface Props {
    value?: number
}

const CupStateTile = ({value}: Props) => {
    return (
        <div className="block block-1-4 icon-block">
            <div className="icon-section">
                <img src={icons.cupUsedIcon} alt="cup-use-icon" className="cup-icon"/>
            </div>
            <div className="value-section">
                <h4>Kubki na stanie</h4>
                <div className="value-wrapper">
                    <span className="standard-value">{value || 0}</span>
                </div>
            </div>
        </div>
    )
};

export default CupStateTile;

