import axios from "axios";
import {store} from "../redux/store";
import {logoutAction} from "../redux/auth";
import {clearAuthItems} from "../services/storage/secureStorage";
import {each, isArray} from "lodash";

interface ErrorResponse {
    error: string;
    status?: number
    detail?: any
}

export const handleError = (error: any) => {
    if (axios.isAxiosError(error)) {
        if (error.code === "ERR_NETWORK") {
            return {
                error: error.message, status: 500
            } as ErrorResponse
        }
        const errorResponse = error.response as { data?: any; status: number };
        if ([401, 403].includes(errorResponse.status)) {
            store.dispatch(logoutAction());
            clearAuthItems();
        }

        let detail = '';
        if ([400, 422].includes(errorResponse.status) && isArray(errorResponse.data.detail)) {
            if (each(errorResponse.data.detail, d => 'row' in d && 'column' in d)) {
                detail = errorResponse.data.detail
            } else {
                errorResponse.data.detail.forEach((element: any) => {
                    detail += element.msg + '. ';
                });
            }
        } else {
            detail = errorResponse.data.detail;
        }

        return {
            error: error.message, detail: detail, status: errorResponse.status
        } as ErrorResponse;
    } else {
        return {error: String(error)} as ErrorResponse
    }

};
