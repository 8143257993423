import Button from "./Button";
import {images} from "../../services/images";

interface Props {
    title: string;
    body: string;
    onContinue: () => void;
}

const SuccessScreen = ({title, body, onContinue}: Props) => {
    return (
        <div className="onboarding">
            <div className="onboarding-content">
                <img className="logo" src={images.payProfitCRMLogoBlack} alt="PayProfitCRM"/>
                <div className="badge badge-success"/>
                <h2>{title}</h2>
                <p className="note">{body}</p>
                <Button label="Kontynuuj" onClick={onContinue}/>
            </div>
        </div>
    )
};

export default SuccessScreen;