import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";

interface Props {
    title: string;
    value?: number
    prevValue?: number
}

const DashboardMiddleTile = ({title, value, prevValue}: Props) => {
    return (
        <div className="block block-1-3">
            <div className="value-section">
                <h4>{title}</h4>
                <div className="value-wrapper">
                    <span className="standard-value">{value || 0}</span>
                    <ChangePill value={value} prevValue={prevValue}/>
                    <TimeRangeLabel/>
                </div>
            </div>
        </div>
    )
};

export default DashboardMiddleTile;

