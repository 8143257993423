import {combineReducers} from "@reduxjs/toolkit";
import adminDashboardReducer from './adminDashboard';
import adminFinanceReducer from './adminFinance';
import adminViewSettingsReducer from './adminViewSettings';
import adminMerchantsReducer from './adminMerchants';
import adminUsersReducer from './adminUsers';


const adminRootReducer = combineReducers({
    dashboard: adminDashboardReducer,
    finance: adminFinanceReducer,
    merchants: adminMerchantsReducer,
    users: adminUsersReducer,
    viewSettings: adminViewSettingsReducer,
});

export default adminRootReducer