import moment from "moment/moment";
import {DatePreset, DateRange} from "./interfaces";


export const DEFAULT_START_DATE = moment().subtract(29, 'days').format('YYYY-MM-DD');
export const DEFAULT_END_DATE = moment().format('YYYY-MM-DD');


const getPresetRanges = {
    week: () => [moment().startOf('week'), moment()],
    month: () => [moment().startOf('month'), moment()],
    quarter: () => [moment().startOf('quarter'), moment()],
    halfYear: () => [moment().subtract(6, 'months'), moment()],
    year: () => [moment().startOf('year'), moment()],
    last7Days: () => [moment().subtract(6, 'days'), moment()],
    last30Days: () => [moment().subtract(29, 'days'), moment()],
    last90Days: () => [moment().subtract(89, 'days'), moment()],
    last365Days: () => [moment().subtract(364, 'days'), moment()]
};

export const DEFAULT_PRESET: DatePreset = {
    datePickerLabel: "Ostatnie 30 dni",
    label: "Ostatnie 30 dni",
    getRangeValue: getPresetRanges.last30Days
};

export const DEFAULT_PRESETS: DatePreset[] = [
    {datePickerLabel: "Tydzień", label: "Tydzień", getRangeValue: getPresetRanges.week},
    {datePickerLabel: "Miesiąc", label: "Miesiąc", getRangeValue: getPresetRanges.month},
    {datePickerLabel: "Kwartał", label: "Kwartał", getRangeValue: getPresetRanges.quarter},
    {datePickerLabel: "Pół roku", label: "Półrocze", getRangeValue: getPresetRanges.halfYear},
    {datePickerLabel: "Rok", label: "Rok", getRangeValue: getPresetRanges.year},
    {datePickerLabel: "Ostatnie 7 dni", label: "Ostatnie 7 dni", getRangeValue: getPresetRanges.last7Days},
    {datePickerLabel: "Ostatnie 30 dni", label: "Ostatnie 30 dni", getRangeValue: getPresetRanges.last30Days},
    {datePickerLabel: "Ostatnie 90 dni", label: "Ostatnie 90 dni", getRangeValue: getPresetRanges.last90Days},
    {datePickerLabel: "Ostatnie 365 dni", label: "Ostatnie 365 dni", getRangeValue: getPresetRanges.last365Days}
];


export const getDateRangeDays = (dateRange: DateRange) => moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days') + 1;

export const toLocalISOString = (date: Date) => {
    const pad = (n: number) => n.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const dateGroups = ['today', 'yesterday', 'last7Days', 'last30Days', 'olderThanMonth'];

export const getDateGroupTitle = (group: string) => {
    switch (group) {
        case 'today':
            return 'Dzisiaj';
        case 'yesterday':
            return 'Wczoraj';
        case 'last7Days':
            return 'Ostatnie 7 dni';
        case 'last30Days':
            return 'Ostatnie 30 dni';
        default:
            return 'Starsze niż 30 dni'
    }
};

export const categorizeByDate = <T>(data: T[], dateKey: keyof T) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const groups: { [key: string]: T[] } = {
        today: [],
        yesterday: [],
        last7Days: [],
        last30Days: [],
        olderThanMonth: []
    };

    data.forEach(item => {
        const itemDate = new Date(item[dateKey] as unknown as string);
        itemDate.setHours(0, 0, 0, 0);

        if (itemDate >= today) {
            groups.today.push(item);
        } else if (itemDate >= yesterday) {
            groups.yesterday.push(item);
        } else if (itemDate >= last7Days) {
            groups.last7Days.push(item);
        } else if (itemDate >= last30Days) {
            groups.last30Days.push(item);
        } else {
            groups.olderThanMonth.push(item);
        }
    });

    return groups;
};

export const displayConvertedDate = (date: string) => {
    const dateObj = new Date(date);
    const today = new Date();
    const isToday = dateObj.getDate() === today.getDate() &&
        dateObj.getMonth() === today.getMonth() &&
        dateObj.getFullYear() === today.getFullYear();

    if (isToday) {
        return `Dzisiaj | ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    } else {
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} | ${hours}:${minutes}`;
    }
};


export const formatDateToDuration = (date: Date): number => date.getHours() * 60 + date.getMinutes();

export const formatDurationToDate = (totalMinutes: number): Date => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return new Date(0, 0, 0, hours, minutes);
};

export const formatDurationToString = (totalMinutes: number): string => {
    let formattedDuration = '';
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours > 0) {
        formattedDuration += `${hours}godz `;
    }

    if (minutes > 0) {
        formattedDuration += `${minutes}min`;
    }

    return formattedDuration.trim();
};