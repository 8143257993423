import {images} from "../../services/images";
import {Link} from "react-router-dom";


interface Props {
    title: string
    image: string
    body: string
    hint?: string
    hintLink?: string
    imgSrc?: string
}

const OnboardingSidebar = ({title, image, body, hint, hintLink, imgSrc}: Props) =>
    <div className="onboarding-sidebar">
        <img className="brand-logo" alt="PayProfit CRM" src={imgSrc ||images.payProfitCRMLogoWhite}/>
        <img className="onboarding-image" src={image} alt="onboarding"/>
        <div>
            <h1>{title}</h1>
            <p>{body}</p>
            {hint && hintLink && <Link to={hintLink}>{hint}<span className="arrow"></span></Link>}
        </div>
    </div>;

export default OnboardingSidebar;