import {ServiceTypeName, ServiceTypeTile} from "../interfaces";


export const serviceTypeNames = ['CULTURE', 'HEALTH', 'GASTRONOMY', 'SPORT', 'TOURISM', 'HOME', 'BEAUTY', 'EDUCATION', 'SHOP', 'CHILD', 'CAR', 'ANIMAL', 'PROPERTY', 'ELECTRONIC', 'FASHION'];


export const serviceTypeTiles: ServiceTypeTile[] = [
    {name: 'CULTURE', iconWidth: 37, iconHeight: 25, label: 'Kultura i rozrywka'},
    {name: 'HEALTH', iconWidth: 31, iconHeight: 26, label: 'Zdrowie'},
    {name: 'GASTRONOMY', iconWidth: 28, iconHeight: 29, label: 'Gastronomia'},
    {name: 'SPORT', iconWidth: 29, iconHeight: 29, label: 'Sport'},
    {name: 'TOURISM', iconWidth: 31, iconHeight: 29, label: 'Turystyka i Wypoczynek'},
    {name: 'HOME', iconWidth: 35, iconHeight: 30, label: 'Dom i ogród'},
    {name: 'BEAUTY', iconWidth: 24, iconHeight: 32, label: 'Beauty'},
    {name: 'EDUCATION', iconWidth: 35, iconHeight: 29, label: 'Edukacja'},
    {name: 'SHOP', iconWidth: 32, iconHeight: 32, label: 'Supermarket'},
    {name: 'CHILD', iconWidth: 29, iconHeight: 29, label: 'Dziecko'},
    {name: 'CAR', iconWidth: 48, iconHeight: 20, label: 'Motoryzacja'},
    {name: 'ANIMAL', iconWidth: 29, iconHeight: 29, label: 'Dla zwierząt'},
    {name: 'PROPERTY', iconWidth: 29, iconHeight: 32, label: 'Nieruchomości'},
    {name: 'ELECTRONIC', iconWidth: 42, iconHeight: 24, label: 'Eletronika'},
    {name: 'FASHION', iconWidth: 27, iconHeight: 30, label: 'Moda'},
];


export const getServiceTypeTile = (name: ServiceTypeName): ServiceTypeTile | undefined => serviceTypeTiles.find(s => s.name === name);


const translations: { [key: string]: string } = {
  'CULTURE': 'Kultura i rozrywka',
  'HEALTH': 'Zdrowie',
  'GASTRONOMY': 'Gastronomia',
  'SPORT': 'Sport',
  'TOURISM': 'Turystyka i Wypoczynek',
  'HOME': 'Dom i ogród',
  'BEAUTY': 'Beauty',
  'EDUCATION': 'Edukacja',
  'SHOP': 'Supermarket',
  'CHILD': 'Dziecko',
  'CAR': 'Motoryzacja',
  'ANIMAL': 'Dla zwierząt',
  'PROPERTY': 'Nieruchomości',
  'ELECTRONIC': 'Eletronika',
  'FASHION': 'Moda'
};

export const translateServiceType = (englishName: string): string => {
  return translations[englishName.toUpperCase()] || englishName;
};