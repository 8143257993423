import Modal from 'react-modal';
import React, {useMemo} from "react";
import AdminContactUserMessage from "./AdminContactUserMessage";
import AdminContactMenu from "./AdminContactMenu";
import {SetContactPageActionType} from "../../../../services/interfaces";
import {icons} from "../../../../services/images";
import AdminContactMerchantMessage from "./AdminContactMerchantMessage";
import {useDispatch, useSelector} from "react-redux";
import {getModalOpen} from "../../../../redux/selectors/navigationSelectors";
import {getContactPage} from "../../../../redux/selectors/chatSelectors";
import {setContactPageAction} from "../../../../redux/chat";
import {modalCloseAction} from "../../../../redux/navigation";
import {setAdminAppUserDetailsAction} from "../../../redux/adminUsers";


const AdminContactModal = () => {
    const isOpen = useSelector(getModalOpen('contact'));
    const contactPage = useSelector(getContactPage);

    const dispatch = useDispatch();
    const title = useMemo(() => {
        switch (contactPage) {
            case 'user_message':
                return 'Wyślij wiadomość do użytkowników PayProfit';
            case 'merchant_message':
                return 'Wyślij wiadomość do sieci';
            default:
                return 'Kontakt';
        }
    }, [contactPage]);

    const BackButton = useMemo(() => () => {
        const Button = ({actionType}: { actionType: SetContactPageActionType }) =>
            (
                <div className="back-button" onClick={() => dispatch(setContactPageAction(actionType))}>
                    <img src={icons.backIcon} alt=""/>
                </div>
            );

        switch (contactPage) {
            case 'user_message':
                return <Button actionType={null}/>;
            case 'merchant_message':
                return <Button actionType={null}/>;
            default:
                return <></>
        }
    }, [contactPage]);


    const Page = useMemo(() => () => {

        switch (contactPage) {
            case 'user_message':
                return <AdminContactUserMessage onClose={closeModal}/>;
            case 'merchant_message':
                return <AdminContactMerchantMessage onClose={closeModal}/>;
            default:
                return <AdminContactMenu/>
        }
    }, [contactPage]);

    const closeModal = () => {
        dispatch(modalCloseAction('contact'));
        dispatch(setContactPageAction(null));
        dispatch(setAdminAppUserDetailsAction(null));
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            className={`modal-content contact ${contactPage === 'user_message' ? 'user-message' : ''}`}
            ariaHideApp={false}
        >
            <div className={`modal-header ${!contactPage ? 'menu-header' : ''}`}>
                {!contactPage && <div/>}
                <div className="modal-title">
                    <BackButton/>
                    <h5>{title}</h5>
                </div>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <Page/>
            </div>
        </Modal>
    )
};

export default AdminContactModal;