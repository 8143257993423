import {ChartInterval, DateRange, MerchantPoint, PreviousPeriod} from "../../services/interfaces";
import {find} from "lodash";
import {AdminGlobalFilters} from "./interfaces";

export const getMainServiceType = (merchantPoint: MerchantPoint) => find(merchantPoint.service_types, s => s.is_main);


export const prepareURLParamForAdminChartData = (dateRange: DateRange,
                                                 previousPeriod: PreviousPeriod,
                                                 chartInterval: ChartInterval,
                                                 globalFilters: AdminGlobalFilters): string => {
    const urlParam = new URLSearchParams();
    const filters = '' +
        '&merchant_points_ids=' + globalFilters.merchantPoints.join(',') +
        '&service_types=' + globalFilters.serviceTypes.join(',');
    urlParam.append('filters', filters);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);

    return urlParam.toString()
};