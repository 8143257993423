import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppUserDetails, ChartData, PieChartData, StatisticsData, User} from "../../services/interfaces";
import {AdminAppUserSummary, AdminUsersCount, UsersTableResponse} from "../services/interfaces";

export interface AdminUsersState {
    statisticsData: StatisticsData
    statisticsDetails: {
        current: PieChartData[] | ChartData[]
        previous: PieChartData[] | ChartData[]
    },
    count: AdminUsersCount,
    table: {
        users: AdminAppUserSummary[],
        maxPage: number,
        usersCount: number,
        pageSize: number
    }
    withoutTransactionsTable: {
        users: AdminAppUserSummary[],
        maxPage: number,
        usersCount: number,
        pageSize: number
    }
    appUserDetails: AppUserDetails | null,
    all: User[]
}

const DEFAULT_STATE: AdminUsersState = {
    statisticsData: {
        current: {
            aggregate_data: [],
            aggregate_sum: 0,
            income_sum: 0,
            transactions_count: 0,
            shared_offers: 0,
            cashback_withdrawn: 0,
        },
        previous: {
            aggregate_data: [],
            aggregate_sum: 0,
            income_sum: 0,
            transactions_count: 0,
            shared_offers: 0,
            cashback_withdrawn: 0,
        },
        amount: [],
        transactions: [],
        gender: [],
        age: [],
        average_amount: [],
        new_clients: [],
        returning_clients: [],
        active_clients: []
    },
    statisticsDetails: {
        current: [],
        previous: [],
    },
    count: {
        current: {
            with_bank_account: 0,
            without_bank_account: 0,
            bank_accounts: 0
        },
        previous: {
            with_bank_account: 0,
            without_bank_account: 0,
            bank_accounts: 0
        }
    },
    table: {
        users: [],
        maxPage: 0,
        pageSize: 0,
        usersCount: 0
    },
    withoutTransactionsTable: {
        users: [],
        maxPage: 0,
        pageSize: 0,
        usersCount: 0
    },
    appUserDetails: null,
    all: []
};

const adminUsersSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminUsers',
    reducers: {
        setStatisticsData: (state, action: PayloadAction<StatisticsData>) => {
            return {
                ...state,
                statisticsData: action.payload
            }
        },
        setStatisticsDetailsData: (state, action: PayloadAction<{
            previous: PieChartData[] | ChartData[],
            current: PieChartData[] | ChartData[]
        }>) => {
            return {
                ...state,
                statisticsDetails: {
                    ...state.statisticsDetails,
                    current: action.payload.current,
                    previous: action.payload.previous,
                }
            }
        },
        setCount: (state, action: PayloadAction<AdminUsersCount>) => {
            return {
                ...state,
                count: action.payload
            }
        },
        setUsersTable: (state, action: PayloadAction<UsersTableResponse>) => {
            return {
                ...state,
                table: {
                    users: action.payload.users,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    usersCount: action.payload.users_count
                },
            }
        },
        setUsersWithoutTransactionsTable: (state, action: PayloadAction<UsersTableResponse>) => {
            return {
                ...state,
                withoutTransactionsTable: {
                    users: action.payload.users,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    usersCount: action.payload.users_count
                },
            }
        },
        setAppUserDetails: (state, action: PayloadAction<AppUserDetails | null>) => {
            return {
                ...state,
                appUserDetails: action.payload
            }
        },
        setAllAppUsers: (state, action: PayloadAction<User[]>) => {
            return {
                ...state,
                all: action.payload
            }
        },
    }
});

export default adminUsersSlice.reducer

export const setAdminUsersStatisticsDataAction = adminUsersSlice.actions.setStatisticsData;
export const setAdminUsersStatisticsDetailsDataAction = adminUsersSlice.actions.setStatisticsDetailsData;
export const setAdminUsersCountAction = adminUsersSlice.actions.setCount;
export const setAdminUsersTableAction = adminUsersSlice.actions.setUsersTable;
export const setAdminUsersWithoutTransactionsTable = adminUsersSlice.actions.setUsersWithoutTransactionsTable;
export const setAdminAppUserDetailsAction = adminUsersSlice.actions.setAppUserDetails;
export const setAdminAllAppUsers = adminUsersSlice.actions.setAllAppUsers;
