import {RootState} from "../../../redux/selectors/rootSelectors";
import {createSelector, Selector} from "reselect";
import {ChartInterval, DatePreset, DateRange, PreviousPeriod} from "../../../services/interfaces";
import {
    AdminDashboardMapFilterOptions,
    AdminGlobalFilters,
    AdminMerchantSimple,
    AdminMerchantTransactionsFilterOptions, BankIdentification
} from "../../services/interfaces";

export const getAdminViewSettingsState = (state: RootState) => state.admin.viewSettings;


export const getAdminDateRange: Selector<RootState, DateRange> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.dateRange
);

export const getAdminDatePreset: Selector<RootState, DatePreset | null> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.datePreset
);

export const getAdminChartInterval: Selector<RootState, ChartInterval> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.chartInterval
);

export const getAdminFirstTransactionDate: Selector<RootState, string | null> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.firstTransactionDate
);

export const getAdminPreviousPeriod: Selector<RootState, PreviousPeriod> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.previousPeriod
);

export const getAdminBarChartSelected: Selector<RootState, boolean> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.barChartSelected
);

export const getAdminGlobalFilters: Selector<RootState, AdminGlobalFilters> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.globalFilters
);

export const getAdminMerchants: Selector<RootState, AdminMerchantSimple[]> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.filterOptions.merchants
);

export const getAdminDashboardMapFilterOptions: Selector<RootState, AdminDashboardMapFilterOptions | null> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.filterOptions.dashboardMap
);

export const getAdminMerchantTransactionsFilterOptions: Selector<RootState, AdminMerchantTransactionsFilterOptions | null> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.filterOptions.merchantTransactions
);

export const getAdminBanksFilterOptions: Selector<RootState, BankIdentification[]> = createSelector(
    getAdminViewSettingsState,
    (adminViewSettingsState) => adminViewSettingsState.filterOptions.banks
);