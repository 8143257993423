import {PreviousPeriod} from "../../services/interfaces";
import {Select} from "antd";


interface Props {
    previousPeriod: PreviousPeriod;
    onChange: (value: PreviousPeriod) => void;
}


const PreviousPeriodSelect = ({previousPeriod, onChange}: Props) => {
    return (
        <div className="previous-period-select">
            <span className="label-2">Poprzedni okres: </span>
            <Select
                value={previousPeriod}
                onChange={onChange}
                options={[
                    {value: "period" as PreviousPeriod, label: 'Poprzedni Okres'},
                    {value: "year" as PreviousPeriod, label: 'Poprzedni Rok'},
                    {value: "hide" as PreviousPeriod, label: 'Nie wyświetlaj'},
                ]}
            />
        </div>
    )
};

export default PreviousPeriodSelect;