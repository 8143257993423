import React from 'react';

import {APIProvider, Map} from '@vis.gl/react-google-maps';
import {GOOGLE_MAP_KEY} from "../../../../config/config-prod";
import ClusteredItemMarkers from "./ClusteredItemMarkers";
import {BaseMapItem, MapViewProps} from "./types";


const containerStyle = {

};


const MapView = <T extends BaseMapItem>({
                                            items,
                                            MarkerComponent,
                                            InfoWindowContent,
                                            defaultCenter = {lat: 52.2321, lng: 20.9883},
                                            defaultZoom =10
                                        }: MapViewProps<T>) => {

    return (
        <APIProvider apiKey={GOOGLE_MAP_KEY}>
            <Map
                mapId={GOOGLE_MAP_KEY}
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                gestureHandling={'greedy'}
                style={containerStyle}
                className="map-view"
                disableDefaultUI>
                {items && <ClusteredItemMarkers items={items}
                                                MarkerComponent={MarkerComponent}
                                                InfoWindowContent={InfoWindowContent}
                />}
            </Map>
        </APIProvider>
    );
};

export default MapView;
