import {Blocker, useBlocker, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {icons} from "../../services/images";
import {CONFIGURE_MERCHANT_PAGE, HOME_PAGE, MERCHANT_POINT_EDIT_PAGE} from "../../services/dictionaries/paths";
import {useSelector} from "react-redux";
import {
    getCurrentMerchantPoint,
    getIsMerchantSelected,
    getMerchant,
    getMerchantPoints
} from "../../redux/selectors/merchantSelector";
import {isEmpty} from "lodash";
import ImportMerchantPointDataModal from "../merchant_point/ImportMerchantPointDataModal";
import {isLogo} from "../../services/imageUtils";
import {getMerchantHandler, getMerchantPointHandler} from "../../handlers/merchantHandler";
import {MerchantPointSimple} from "../../services/interfaces";
import UnsavedChangesModal from "../modals/UnsavedChangesModal";
import {getShouldBlockNavigation} from "../../redux/selectors/navigationSelectors";


const MerchantPointDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isImportDataModalOpen, setIsImportDataModalOpen] = useState(false);
    const merchantPoints = useSelector(getMerchantPoints);
    const currentMerchantPoint = useSelector(getCurrentMerchantPoint);
    const merchant = useSelector(getMerchant);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const shouldBlockNavigation = useSelector(getShouldBlockNavigation);
    const location = useLocation();
    const listRef = useRef(null);
    const navigate = useNavigate();
    const isConfigurePageActive = location.pathname.includes(CONFIGURE_MERCHANT_PAGE) || location.pathname.startsWith('/merchant');
    const [blockerProceedAction, setBlockerProceedAction] = useState<(() => void) | null>(null);
    const isDraft: boolean = !merchant || (merchant && merchant.nip === null);

    let blocker: Blocker = useBlocker(
        ({currentLocation, nextLocation}) =>
            shouldBlockNavigation && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            // @ts-ignore
            if (listRef.current && !listRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleList = () => {
        merchant && !isDraft ?
            setIsOpen(!isOpen) :
            navigate(CONFIGURE_MERCHANT_PAGE)
    };

    const getTitle = () => {
        const isMerchantPointAdded = !merchant || (!currentMerchantPoint.id && isConfigurePageActive);
        if (isMerchantPointAdded)
            return "Wypełnij dane";
        if (isMerchantSelected && merchant)
            return "Cała sieć " + (merchant.name ?? '');
        if (currentMerchantPoint.id && !isMerchantSelected)
            return currentMerchantPoint.name;
    };

    const handleOnMerchantClick = () => {
        if (isConfigurePageActive) {
            navigate(HOME_PAGE);
            if (!shouldBlockNavigation) {
                getMerchantHandler(true, true);
            } else {
                setBlockerProceedAction(() => () => getMerchantHandler(true, true))
            }
        } else {
            getMerchantHandler(true, true);
        }
    };

    const handleOnMerchantPointClick = (merchantPoint: MerchantPointSimple) => {
        if (isConfigurePageActive) {
            navigate(HOME_PAGE);
            if (!shouldBlockNavigation) {
                getMerchantPointHandler(merchantPoint.id);
            } else {
                setBlockerProceedAction(() => () => getMerchantPointHandler(merchantPoint.id))
            }

        } else {
            getMerchantPointHandler(merchantPoint.id);
        }
    };

    const handleOnMerchantPointEditClick = (merchantPoint: MerchantPointSimple) =>
        merchantPoint.id && navigate(MERCHANT_POINT_EDIT_PAGE(merchantPoint.id));


    return (
        <div className={`merchant-dropdown ${isDraft ? 'disabled' : ''}`} ref={listRef}>
            <div
                className={`header ${isOpen ? 'open' : ''} ${isConfigurePageActive ? 'active' : ''}`}
                onClick={toggleList}>
                <div className="merchant-details">
                    <div className="logo" style={{
                        backgroundImage: `url(${isLogo(currentMerchantPoint.logo_image, currentMerchantPoint.service_types)})`,
                    }}/>
                    <span className="title">
                        {getTitle()}
                    </span>
                </div>
                {!merchant ?
                    <span className="chevron right"/> :
                    <div className="chevrons">
                        <span className="chevron up"/>
                        <span className="chevron down"/>
                    </div>
                }
            </div>
            <ul className={`merchant-list ${isOpen ? 'open' : ''}`}>
                <li className="merchant-list-item add"
                    onClick={() => merchant && setIsImportDataModalOpen(true)}>
                    <span>
                        Dodaj nowy punkt
                    </span>
                    <img src={icons.addIcon} alt=""/>
                </li>
                {merchant && !isEmpty(merchantPoints) &&
                    <>
                        <li className="merchant-list-item merchant" onClick={handleOnMerchantClick}>
                            <span>Cała sieć {merchant.name}</span>
                        </li>

                        {merchantPoints.map((point) => (
                            <li key={`MerchantPoint${point.id}`}
                                className="merchant-list-item"
                            >
                                <span onClick={() => handleOnMerchantPointClick(point)}>{point.address}</span>
                                <div onClick={() => handleOnMerchantPointEditClick(point)}>
                                    <img src={icons.penActiveIcon} alt="" className="edit"/>
                                </div>
                            </li>
                        ))}
                    </>
                }
            </ul>
            {merchant &&
                <ImportMerchantPointDataModal isOpen={isImportDataModalOpen}
                                              setIsOpen={setIsImportDataModalOpen}
                                              merchant={merchant}
                />
            }
            <UnsavedChangesModal
                blocker={blocker}
                onProceed={blockerProceedAction}
            />
        </div>
    )
};

export default MerchantPointDropdown;