import {createSelector, Selector} from "reselect";
import {RootState} from "./rootSelectors";
import {MerchantUserDetails} from "../../services/interfaces";


export const getManagementState = (state: RootState) => state.management;

export const getUserList: Selector<RootState, MerchantUserDetails[]> = createSelector(
    getManagementState,
    (management) => management.userList
);