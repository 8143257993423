import request, {GET} from "../../http/request";
import {handleError} from "../../http/handleError";
import {AdminDashboardCashbackBalance, AdminDashboardData} from "../services/interfaces";


export const getAdminDashboard = async (urlParam: string): Promise<{
    previous: AdminDashboardData,
    current: AdminDashboardData,
    cashback_balance: AdminDashboardCashbackBalance
}> => {
    try {
        const {data} = await request(GET, `dashboard/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getAdminDashboardMerchantPoints = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `dashboard/merchant_points?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};
