import React, {useEffect, useState} from "react";
import {User} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getChatAvailableUsers} from "../../../redux/selectors/chatSelectors";
import {icons} from "../../../services/images";
import {setChatDetailsAction, setContactPageAction} from "../../../redux/chat";
import {getChatAvailableUsersHandler} from "../../../handlers/chatHandlers";
import {getAppUserName} from "../../../services/helpers";


const ContactNewChat = () => {
    const [query, setQuery] = useState<string>('');
    const users = useSelector(getChatAvailableUsers);
    const dispatch = useDispatch();
    const filteredUsers: User[] = users.filter((user) =>
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        (user.name && !user.name.toLowerCase().includes(query.toLowerCase()))
    );
    useEffect(() => {
        getChatAvailableUsersHandler()
    }, []);
    return (
        <div className="new-chat">
            <div className="searchbar">
                <img src={icons.search} alt="Search"/>
                <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                       value={query}
                       onChange={event => setQuery(event.target.value)}/>
            </div>
            <div className="chats">
                {filteredUsers.map(user =>
                    <div className="user-overview"
                         key={`UserOverview${user.id}`}
                         onClick={() => {
                             dispatch(setContactPageAction('chat'));
                             dispatch(setChatDetailsAction({chat: {user: user}, messages: []}))
                         }}>
                        <div className="user-profile-icon">
                            <img src={icons.userActive} alt=""/>
                        </div>
                        <span className="user-name">{getAppUserName(user)}</span>
                    </div>
                )}
            </div>
        </div>
    )
};

export default ContactNewChat