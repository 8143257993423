import {store} from "../redux/store";
import {resetCurrentTargetGroupAction, setCurrentTargetGroupAction, TARGET_GROUP_RANGE_KEYS} from "../redux/merchant";
import {setStatisticsFilterOrderAction} from "../redux/statistics";
import {
    ChartData,
    Gender,
    PieChartData,
    RangeBase,
    StatisticsPieChartKey,
    TargetGroup,
    TargetGroupRangeKey
} from "./interfaces";
import {forEach, isEmpty, some} from "lodash";
import {GenderEnum, TargetGroupRangeKeyEnum} from "./dictionaries/enums";


export const resetCurrentTargetGroupAndStatisticsFilterOrder = () => {
    store.dispatch(resetCurrentTargetGroupAction());
    store.dispatch(setStatisticsFilterOrderAction([]))
};

export const setCurrentTargetGroupAndStatisticsFilterOrder = (targetGroup: TargetGroup) => {
    store.dispatch(setCurrentTargetGroupAction(targetGroup));
    let filterOrder: TargetGroupRangeKey[] = [];
    TARGET_GROUP_RANGE_KEYS.forEach(key => {
        const ranges = targetGroup[key] as RangeBase[];
        const areRangesSelected = some(ranges, 'selected');
        if (areRangesSelected)
            filterOrder.push(key)
    });
    store.dispatch(setStatisticsFilterOrderAction(filterOrder))
};


export const updateFilterOrder = (currentOrder: StatisticsPieChartKey[], key: StatisticsPieChartKey, areSomeRangesSelected: boolean): StatisticsPieChartKey[] => {
    if (currentOrder.includes(key) && areSomeRangesSelected) {
        return currentOrder.filter(k => k !== key || areSomeRangesSelected);
    } else if (areSomeRangesSelected) {
        return [...currentOrder, key];
    } else {
        return currentOrder.filter(k => k !== key);
    }
};

export const getRangeLabel = (range: RangeBase, rangeKey: TargetGroupRangeKey) => {
    if (rangeKey === "age_ranges" && !range.min && !range.max)
        return "Brak wieku";
    else return `${range.min} ${range.max ? ('- ' + range.max) : '>'}`
};

export const isChartDataArray = (data: PieChartData[] | ChartData[]): data is ChartData[] => data.length > 0 && 'date' in data[0];


export const getSelectedFiltersFromTargetGroup = (targetGroup: TargetGroup) => {
    const filters: string[] = [];
    if (!isEmpty(targetGroup.gender)) {
        let genderText = 'Płeć: ';
        forEach(targetGroup.gender, (gender: Gender, index) => {
            if (index > 0)
                genderText += ', ';
            genderText += GenderEnum[gender]
        });
        filters.push(genderText)
    }

    TARGET_GROUP_RANGE_KEYS.forEach(key => {
        const ranges = targetGroup[key] as RangeBase[];
        if (some(ranges, 'selected')) {
            let rangeText = `${TargetGroupRangeKeyEnum[key]}: `;
            let rangeLabels: string[] = [];
            forEach(ranges, (range: RangeBase, index) => {
                if (range.selected)
                    rangeLabels.push(getRangeLabel(range, key))
            });
            rangeText += rangeLabels.join(", ");
            filters.push(rangeText)
        }
    });

    return filters
};