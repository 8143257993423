import {parseQueryString} from "../../../../services/utils";
import {AdminAppUserSummary} from "../../../services/interfaces";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import {GenderEnum} from "../../../../services/dictionaries/enums";

interface Props {
    user: AdminAppUserSummary,
    filters: string,
    onClick: () => void
}

const AdminUsersTableRow = ({user, filters, onClick}:  Props) => {
    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    let gender = 'Inna';
    if (user.gender in GenderEnum) {
        gender = GenderEnum[user.gender];
    }
    return (
        <tr>
            <td className="clickable-cell" >
                <span onClick={onClick}>#{user.id}</span>
            </td>
            {!excludedColumns.includes('transactions_count') &&
                <td className="cell-digit">{formatNumber(user.transactions_count, WHOLE_NUMBER_FORMAT)}</td>}
            {!excludedColumns.includes('cup_actions_count') &&
                <td className="cell-digit">{formatNumber(user.cup_actions_count, WHOLE_NUMBER_FORMAT)}</td>}
            {!excludedColumns.includes('cashback') &&
                <td className="cell-digit">{formatNumber(user.cashback)}</td>}
            {!excludedColumns.includes('gender') && <td>{gender}</td>}
            {!excludedColumns.includes('age') && <td>{user.age ? user.age : "--"}</td>}
            {!excludedColumns.includes('email') && <td>{user.email}</td>}
            {!excludedColumns.includes('bank') && <td>{user.bank ? user.bank : "--"}</td>}
        </tr>
    )
};

export default AdminUsersTableRow;