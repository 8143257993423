import Modal from "react-modal";
import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import {FilterModalProps} from "../../../../services/interfaces";
import {getAdminDashboardMapFilterOptionsHandler} from "../../../handlers/filterOptionsHandlers";
import {getAdminDashboardMapFilterOptions} from "../../../redux/selectors/adminViewSettingsSelectors";
import {AdminDashboardMapFilterOptions, PartnerTypeState} from "../../../services/interfaces";
import {icons} from "../../../../services/images";
import HistorySlider from "../../../../components/modals/filters/HistorySlider";
import Button from "../../../../components/common/Button";
import AdminPartnerTypeFilter, {DEFAULT_PARTNER_TYPE_STATE} from "../../common/AdminPartnerTypeFilter";


const AdminDashboardMapFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: AdminDashboardMapFilterOptions | null = useSelector(getAdminDashboardMapFilterOptions);
    const [cupActionCountSlider, setCupActionCountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.cup_action.min_count, filterOptions.cup_action.max_count] : null);
    const [cashbackSlider, setCashbackSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.transactions.min_cashback, filterOptions.transactions.max_cashback] : null);
    const [feeSlider, setFeeSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.transactions.min_payprofit_fee, filterOptions.transactions.max_payprofit_fee] : null);
    const [partnerType, setPartnerType] = useState<PartnerTypeState>(DEFAULT_PARTNER_TYPE_STATE);


    useEffect(() => {
        getAdminDashboardMapFilterOptionsHandler()
    }, []);

    useEffect(() => {
        if (filterOptions && (cashbackSlider === null || feeSlider === null || cupActionCountSlider === null)) {
            setCashbackSlider([filterOptions.transactions.min_cashback, filterOptions.transactions.max_cashback]);
            setFeeSlider([filterOptions.transactions.min_payprofit_fee, filterOptions.transactions.max_payprofit_fee]);
            setCupActionCountSlider([filterOptions.cup_action.min_count, filterOptions.cup_action.max_count])

        }
    }, [filterOptions]);


    const clearFilters = () => {
        setPartnerType(DEFAULT_PARTNER_TYPE_STATE);
        setCupActionCountSlider([filterOptions?.cup_action.min_count || 0, filterOptions?.cup_action.max_count || 100]);
        setCashbackSlider([filterOptions?.transactions.min_cashback || 0, filterOptions?.transactions.max_cashback || 100]);
        setFeeSlider([filterOptions?.transactions.min_payprofit_fee || 0, filterOptions?.transactions.max_payprofit_fee || 100]);
    };

    const applyFilters = () => {
        if (!cupActionCountSlider || !cashbackSlider || !feeSlider) {
            return;
        }
        const partnerTypeString = Object.keys(partnerType)
            .filter(key => partnerType[key as keyof PartnerTypeState])
            .join(',');
        const urlFilters = '' +
            '&partner_types=' + partnerTypeString +
            '&cup_action_count=' + cupActionCountSlider.join(',') +
            '&cashback=' + cashbackSlider.join(',') +
            '&fee=' + feeSlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <AdminPartnerTypeFilter partnerType={partnerType} setPartnerType={setPartnerType}/>
                <HistorySlider
                    label="Liczba akcji kubkowych"
                    min={filterOptions?.cup_action.min_count || 0}
                    max={filterOptions?.cup_action.max_count || 100}
                    value={cupActionCountSlider}
                    onChange={setCupActionCountSlider}
                    wholeNumber
                />
                <HistorySlider
                    label="Kwota cashbacku"
                    min={filterOptions?.transactions.min_cashback || 0}
                    max={filterOptions?.transactions.max_cashback || 100}
                    value={cashbackSlider}
                    onChange={setCashbackSlider}
                />
                <HistorySlider
                    label="Opłaty Payprofit"
                    min={filterOptions?.transactions.min_payprofit_fee || 0}
                    max={filterOptions?.transactions.max_payprofit_fee || 100}
                    value={feeSlider}
                    onChange={setFeeSlider}
                />
            </div>
            <div className="modal-footer">
                {filterOptions &&
                    <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default AdminDashboardMapFiltersModal;