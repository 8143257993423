import {Slider} from "antd";
import React from "react";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY, formatNumber, WHOLE_NUMBER_FORMAT} from "../../../services/numbers";

interface Props {
    label: string;
    min: number;
    max: number;
    onChange: React.Dispatch<React.SetStateAction<[number, number] | null>>;
    value: [number, number] | null;
    wholeNumber?: boolean
}

const HistorySlider = ({label, min, max, onChange, value, wholeNumber = false}: Props) => {
    const step = (max - min > 100 || wholeNumber) ? 1 : 0.01;
    const format = wholeNumber ? WHOLE_NUMBER_FORMAT : DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY;
    const handleChange = (range: any) => {
        onChange(range);
    };

    return (
        <div className="section">
            <div className="header">
                <h6>{label}</h6>
                <span onClick={() => onChange([min, max])}>Wyczyść</span>
            </div>
            <div className="border-values">
                <span>Min. {formatNumber(min, format)}</span>
                <span>Max. {formatNumber(max, format)}</span>
            </div>
            <Slider
                min={min}
                max={max}
                onChange={handleChange}
                value={value === null ? undefined : value}
                step={step}
                tooltip={{formatter: (value) => formatNumber(value, format)}}
                range
            />
        </div>
    )
};

export default HistorySlider;