import React, {useCallback, useEffect, useState} from 'react';
import Button from "../../common/Button";
import {Select, SelectProps, Tag} from "antd";
import HistoryDatePicker from "../filters/HistoryDatePicker";
import Switch from "../../common/Switch";
import {MerchantPointSimple, OfferDays, OfferType} from "../../../services/interfaces";
import {arrayIncludesAll, getAddressWithoutCountry, showCustomersNumber} from "../../../services/utils";
import {getMerchantPoints} from "../../../redux/selectors/merchantSelector";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {FormErrors} from "../Stepper";
import {formatNumber} from "../../../services/numbers";

interface StepProps {
    formData: {
        name: string;
        type: OfferType | undefined;
        merchantPoints: number[];
        startDate: Date | null;
        endDate: Date | null;
        daysOffer: OfferDays[];
        usage: number | undefined;
        description: string;
        directAddressing: boolean;
        directAddressingStyle: string,
        sendPushNotification: boolean;
    };
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
    errors: FormErrors;
}

type TagRender = SelectProps['tagRender'];

const Option = Select.Option;

export const tagRender: TagRender = (props) => {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={"#0071FC"}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className="tag"
        >
            {label}
        </Tag>
    );
};

const WorkWeek = [
    {value: "MONDAY" as OfferDays, label: 'W poniedziałek'},
    {value: "TUESDAY" as OfferDays, label: 'W wtorek'},
    {value: "WEDNESDAY" as OfferDays, label: 'W środę'},
    {value: "THURSDAY" as OfferDays, label: 'W czwartek'},
    {value: "FRIDAY" as OfferDays, label: 'W piątek'},
];

const Weekend = [
    {value: "SATURDAY" as OfferDays, label: 'W sobotę'},
    {value: "SUNDAY" as OfferDays, label: 'W niedzielę'}
];

const Step2: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        nextStep,
                                        prevStep,
                                        numberOfCustomers,
                                        estimateCost,
                                        errors
                                    }) => {
    const merchantPoints = useSelector(getMerchantPoints);
    const merchantPointIDs = merchantPoints.map(point => point.id)

    const [step2Errors, setStep2Errors] = useState<FormErrors>({});
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const validateStep2 = useCallback(() => {
        const errors: FormErrors = {};
        if (formData.name === "") {
            errors.step2 = {...errors.step2, name: 'Nazwa jest wymagana'};
        }
        if (!formData.type) {
            errors.step2 = {...errors.step2, type: 'Typ promocji jest wymagany'};
        }
        if (Object.keys(formData.merchantPoints).length === 0) {
            errors.step2 = {...errors.step2, merchantPoints: 'Wybranie punktów jest wymagane'};
        }
        if (!formData.startDate || !formData.endDate) {
            errors.step2 = {...errors.step2, date: 'Czas trwania promocji jest wymagany'};
        }
        if (!formData.usage) {
            errors.step2 = {...errors.step2, usage: 'Podanie liczby dostępnych użyć jest wymagane'};
        }
        if (formData.usage && formData.usage <= 0) {
            errors.step2 = {...errors.step2, usage: 'Liczba dostępnych użyć musi być większa'};
        }
        setStep2Errors(errors);
        return Object.keys(errors).length === 0;
    }, [formData]);

    useEffect(() => {
        if (isSubmitted) {
            setIsNextDisabled(!validateStep2());
        }
    }, [formData, isSubmitted, validateStep2]);


    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label label-margin">Nazwa promocji<span className="required-mark"> *</span></p>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={formData.name}
                               onChange={e => handleChange("name", e.target.value)}
                               placeholder="Nadaj nazwę swojej promocji..."
                               maxLength={100}
                        />
                    </div>
                </div>
                {step2Errors.step2?.name &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2.name}</div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label">Rodzaj promocji<span className="required-mark"> *</span></p>
                        <Select
                            value={formData.type ? formData.type : undefined}
                            onChange={(value) => handleChange("type", value)}
                            allowClear={true}
                            placeholder="Wybierz rodzaj promocji..."
                            showSearch={false}
                            options={[
                                {value: "gratis" as OfferType, label: 'Gratis'},
                                {value: "contest" as OfferType, label: 'Konkurs'},
                                {value: "discount" as OfferType, label: 'Rabat'},
                                {value: "event" as OfferType, label: 'Wydarzenie'},
                                {value: "news" as OfferType, label: 'Nowości'},
                                {value: "extra_cashback" as OfferType, label: 'Dodatkowy cashback'},
                            ]}
                            className="dropdown-select"
                        />
                    </div>
                </div>
                {step2Errors.step2?.type &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2?.type}</div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label">Wybierz punkty biorące udział w promocji
                            <span className="required-mark"> *</span>
                        </p>
                        <Select
                            value={formData.merchantPoints}
                            onChange={(value) => {
                                handleChange("merchantPoints", value)
                                if (value.includes(-1)) {
                                    handleChange("merchantPoints", [-1]);
                                }
                                if (arrayIncludesAll(value, merchantPointIDs)) {
                                    handleChange("merchantPoints", [-1]);
                                }
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Wybierz punkty..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                        >
                            <Option value={-1}>Wszystkie punkty</Option>
                            {merchantPoints.map((merchantPoint: MerchantPointSimple) => (
                                <Option
                                    value={merchantPoint.id}
                                    disabled={formData.merchantPoints.includes(-1)}
                                >
                                    {getAddressWithoutCountry(merchantPoint)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                {step2Errors.step2?.merchantPoints &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2?.merchantPoints}</div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Czas trwania
                            <span className="required-mark"> *</span>
                        </p>
                        <HistoryDatePicker
                            fromToday
                            noTitle
                            withIcon
                            fromDate={formData.startDate}
                            setFromDate={(value) => handleChange("startDate", value)}
                            toDate={formData.endDate}
                            setToDate={(value) => {
                                handleChange("endDate", value)
                                if (isEmpty(formData.daysOffer)) {
                                    handleChange("daysOffer", "WEEK")
                                }
                            }}
                        />
                    </div>
                </div>
                {step2Errors.step2?.date &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2?.date}</div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label">Dni obowiązywania promocji</p>
                        <Select
                            value={formData.daysOffer}
                            onChange={(value) => {
                                handleChange("daysOffer", value)
                                if (arrayIncludesAll(value, ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"])) {
                                    handleChange("daysOffer", ["WORK_WEEK"]);
                                }
                                if (arrayIncludesAll(value, ["WORK_WEEK", "MONDAY"]) ||
                                    arrayIncludesAll(value, ["WORK_WEEK", "TUESDAY"]) ||
                                    arrayIncludesAll(value, ["WORK_WEEK", "WEDNESDAY"]) ||
                                    arrayIncludesAll(value, ["WORK_WEEK", "THURSDAY"]) ||
                                    arrayIncludesAll(value, ["WORK_WEEK", "FRIDAY"])
                                ) {
                                    handleChange("daysOffer", ["WORK_WEEK"]);
                                }
                                if (arrayIncludesAll(value, ["WORK_WEEK", "SATURDAY", "SUNDAY"])) {
                                    handleChange("daysOffer", ["WEEK"]);
                                }
                                if (arrayIncludesAll(value, ["WEEK", "WORK_WEEK"]) ||
                                    arrayIncludesAll(value, ["WEEK", "SATURDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "SUNDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "MONDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "TUESDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "WEDNESDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "THURSDAY"]) ||
                                    arrayIncludesAll(value, ["WEEK", "FRIDAY"])
                                ) {
                                    handleChange("daysOffer", ["WEEK"]);
                                }
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Ustal w jakie dni ma obowiązywać promocja..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                        >
                            <Option value={"WEEK"} disabled={false}>Cały tydzień</Option>
                            <Option value={"WORK_WEEK"}
                                    disabled={
                                        formData.daysOffer &&
                                        formData.daysOffer.includes("WEEK")
                                    }>W dni robocze</Option>
                            {WorkWeek.map((option) => (
                                <Option
                                    value={option.value}
                                    disabled={
                                        (formData.daysOffer.includes("WORK_WEEK"))
                                        || (formData.daysOffer.includes("WEEK"))
                                    }
                                >
                                    {option.label}
                                </Option>

                            ))}
                            {Weekend.map((option) => (
                                <Option value={option.value}
                                        disabled={
                                            formData.daysOffer &&
                                            formData.daysOffer.includes("WEEK")
                                        }>{option.label}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="section">
                    <p className="label label-margin">Liczba dostępnych użyć
                        <span className="required-mark"> *</span>
                    </p>
                    <div className="input">
                        <input type="number"
                               className="input-text"
                               value={formData.usage}
                               onChange={(e) => handleChange("usage", e.target.value)}
                               placeholder="Ustal ile razy klient może skorzystać z promocji..."
                        />
                    </div>
                </div>
                {step2Errors.step2?.usage &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2?.usage}</div>
                    </div>
                }
                <div className="section">
                    <p className="label label-margin">Opis promocji</p>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={formData.description}
                               onChange={(e) => handleChange("description", e.target.value)}
                               placeholder="Opis..."
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="one-line select-wrapper">
                        <p className="label">Użyj bezpośrednich zwrotów do klienta</p>
                        <Switch checked={formData.directAddressing}
                                setChecked={(value) => {
                                    handleChange("directAddressing", value);
                                }}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", marginTop: 5}}>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.directAddressingStyle === "Hej Adam,..."}
                                disabled={!formData.directAddressing}
                                value="Hej Adam,..."
                                onChange={e => handleChange("directAddressingStyle", e.target.value)}
                            />
                            <p className="label">"Hej Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.directAddressingStyle === "Cześć Adam,..."}
                                disabled={!formData.directAddressing}
                                value="Cześć Adam,..."
                                onChange={e => handleChange("directAddressingStyle", e.target.value)}
                            />
                            <p className="label">"Cześć Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.directAddressingStyle === "Adam,..."}
                                disabled={!formData.directAddressing}
                                value="Adam,..."
                                onChange={e => handleChange("directAddressingStyle", e.target.value)}
                            /><p className="label">"Adam,..."</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper one-line">
                        <p className="label">Wyślij powiadomienia push do użytkowników</p>
                        <Switch checked={formData.sendPushNotification}
                                setChecked={(value) => {
                                    handleChange("sendPushNotification", value);
                                }}/>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label" style={{fontWeight: 700}}>{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span className="label" style={{fontWeight: 700}}>{formatNumber(estimateCost)}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            disabled={isNextDisabled}
                            onClick={() => {
                                if (!isSubmitted) {
                                    setIsSubmitted(true);
                                }
                                setIsNextDisabled(!validateStep2)
                                nextStep()
                            }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step2;