import {icons} from "../../services/images";
import React, {useMemo, useRef, useState} from "react";
import ActionModal from "../modals/ActionModal";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentTargetGroup, getTargetGroups} from "../../redux/selectors/merchantSelector";
import {find, isEmpty, isEqual, range, sum, sumBy} from "lodash";
import {RangeBase, TargetGroup} from "../../services/interfaces";
import {TARGET_GROUP_DEFAULT_STATE, TARGET_GROUP_RANGE_KEYS} from "../../redux/merchant";
import TargetGroupModal from "../modals/target_group/TargetGroupModal";
import {updateTargetGroupHandler} from "../../handlers/targetGroupHandlers";
import {
    getSelectedFiltersFromTargetGroup,
    resetCurrentTargetGroupAndStatisticsFilterOrder,
    setCurrentTargetGroupAndStatisticsFilterOrder
} from "../../services/statisticsHelpers";
import {Tooltip} from "react-tooltip";
import {getStatisticsTableMode} from "../../redux/selectors/statisticsSelectors";
import {setStatisticsTableModeAction} from "../../redux/statistics";


const TargetGroupFloatingButtons = () => {
    const [isTargetGroupModalOpen, setIsTargetGroupModalOpen] = useState(false);
    const [showModalList, setShowModalList] = useState<boolean>(false);
    const [editData, setEditData] = useState<TargetGroup>(TARGET_GROUP_DEFAULT_STATE);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const cogRef = useRef<(HTMLDivElement | null)>(null);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const targetGroups = useSelector(getTargetGroups);
    const tableMode = useSelector(getStatisticsTableMode);
    const dispatch = useDispatch()

    const changesCount = useMemo(() => {
        const compareGroups = (group1: TargetGroup, group2: TargetGroup): number => {
            let changes = 0;

            // Compare non-range fields
            changes += sum([
                group1.name !== group2.name,
                group1.start_date !== group2.start_date,
                group1.end_date !== group2.end_date
            ].map(Number));

            // Compare gender
            changes += sumBy(
                range(Math.max(group1.gender.length, group2.gender.length)),
                i => Number(!isEqual(group1.gender[i], group2.gender[i]))
            );            // Compare


            // Compare range fields
            TARGET_GROUP_RANGE_KEYS.forEach(key => {
                const ranges1 = group1[key] as RangeBase[];
                const ranges2 = group2[key] as RangeBase[];

                if (ranges1.length === ranges2.length) {
                    for (let i = 0; i < ranges1.length; i++) {
                        const range1 = ranges1[i];
                        const range2 = ranges2[i];

                        let rangeChanges = 0;

                        // Check if min or max changed
                        if (range1.min !== range2.min || range1.max !== range2.max) {
                            rangeChanges++;
                        }

                        // Check if selected changed
                        if (range1.selected !== range2.selected) {
                            rangeChanges++;
                        }

                        changes += Math.min(rangeChanges, 2);
                    }
                }
            });

            return changes;
        };

        const foundGroup = find(targetGroups, group => group.id === currentTargetGroup.id);

        if (foundGroup) {
            return compareGroups(foundGroup, currentTargetGroup);
        } else {
            return compareGroups(TARGET_GROUP_DEFAULT_STATE, currentTargetGroup);
        }
    }, [currentTargetGroup, targetGroups]);

    const changesText = useMemo(() => {
        if (changesCount === 0) return 'zmian';
        if (changesCount === 1) return 'zmiana';
        return 'zmiany';
    }, [changesCount]);

    const selectedFilters: string[] = useMemo(() => {
        return getSelectedFiltersFromTargetGroup(currentTargetGroup)
    }, [currentTargetGroup]);

    const originalTargetGroup = useMemo(() => {
        return targetGroups.find(group => group.id === currentTargetGroup.id);
    }, [targetGroups, currentTargetGroup]);

    const actionOptions = useMemo(() => {

        const actions = [{
            title: 'Jako nową grupę docelową z wybranych filtrów',
            onClick: () => {
                setEditData({...currentTargetGroup, id: undefined, name: ''});
                setShowModalList(false);
                setIsTargetGroupModalOpen(true);
                setIsActionModalOpen(false);
            },
            className: ''
        }];
        if (currentTargetGroup.id && originalTargetGroup) {
            actions.push({
                title: 'Jako obecną grupę docelową',
                onClick: () => updateTargetGroupHandler(
                    originalTargetGroup,
                    currentTargetGroup,
                    () => setIsActionModalOpen(false),
                    () => setIsActionModalOpen(false)),
                className: ''
            },)
        }
        return actions
    }, [originalTargetGroup, currentTargetGroup]);

    const toggleActionModal = () => {
        if (cogRef.current) {
            const {right, top} = cogRef.current.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: top - 5,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const onClearChanges = () => {
        if (currentTargetGroup.id && originalTargetGroup) {
            setCurrentTargetGroupAndStatisticsFilterOrder(originalTargetGroup)
        } else
            resetCurrentTargetGroupAndStatisticsFilterOrder();
    };

    return (
        <div className="target-group-floating-buttons">
            {tableMode ?
                <div className="target-group-changes-counter">
                    <span className="label">Tryb ręczny aktywny</span>
                    <div className="remove">
                        <img src={icons.removeWhiteIcon} alt="" onClick={() => dispatch(setStatisticsTableModeAction(false))}/>
                    </div>
                </div>:
                <>
                    {!isEmpty(selectedFilters) &&
                        <div className="target-group-selected-filters">
                            <Tooltip id="TargetGroupSelectedFiltersTooltip" className="tooltip" noArrow={true}>
                                <p className="tooltip-title">Wybrane Filtry</p>
                                {selectedFilters.map((filter, index) =>
                                    <p className="tooltip-body" key={`TargetGroupSelectedFiltersTooltipKey${index}`}>
                                        {filter}</p>
                                )}
                            </Tooltip>
                            <img src={icons.infoIcon} alt="info" data-tooltip-id="TargetGroupSelectedFiltersTooltip"
                                 data-tooltip-place="top-end"/>
                        </div>}
                    {changesCount > 0 &&
                        <>
                            <div className="target-group-changes-counter">
                                <span className="label">{changesCount} {changesText}</span>
                                <div className="remove">
                                    <img src={icons.removeWhiteIcon} alt="" onClick={onClearChanges}/>
                                </div>
                            </div>
                            <div className="target-group-management" onClick={toggleActionModal} ref={cogRef}>
                                <span>Zapisz</span>
                            </div>

                            <ActionModal isOpen={isActionModalOpen}
                                         onClose={() => setIsActionModalOpen(false)}
                                         actions={actionOptions}
                                         direction={"up"}
                                         x={actionModalPosition.x} y={actionModalPosition.y}/>
                            <TargetGroupModal isOpen={isTargetGroupModalOpen}
                                              setIsOpen={setIsTargetGroupModalOpen}
                                              showList={showModalList}
                                              setShowList={setShowModalList}
                                              editCurrent={true}
                                              editData={editData}
                            />
                        </>
                    }
                </>
            }
        </div>
    );
};


export default TargetGroupFloatingButtons;