import {
    Aggregate,
    PieChartData,
    PreparedPieChartData,
    RangeBase,
    TargetGroupRangeKey
} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo, useState} from "react";
import {getStatisticsFilterOrder, getStatisticsTableMode} from "../../../redux/selectors/statisticsSelectors";
import {preparePieChartData} from "../../../services/chartUtils";
import {every, isEmpty, some, sumBy} from "lodash";
import {updateFilterOrder} from "../../../services/statisticsHelpers";
import {selectTargetGroupRangeAction, setCurrentTargetGroupAction} from "../../../redux/merchant";
import {setStatisticsFilterOrderAction} from "../../../redux/statistics";
import NoChart from "../../common/chart/NoChart";
import PieChartGraph from "./PieChartGraph";
import PieChartLegend from "./PieChartLegend";
import {getCurrentTargetGroup} from "../../../redux/selectors/merchantSelector";

interface Props {
    data: PieChartData[];
    prevData?: PieChartData[];
    targetGroupRanges: RangeBase[];
    targetGroupRangeKey?: TargetGroupRangeKey;
    aggregate: Aggregate
}

const RangePieChartContainer = ({data, prevData, targetGroupRanges, targetGroupRangeKey, aggregate}: Props) => {
    const [hoveredEntry, setHoveredEntry] = useState<PreparedPieChartData | null>(null);
    const dispatch = useDispatch();
    const filterOrder = useSelector(getStatisticsFilterOrder);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const tableMode = useSelector(getStatisticsTableMode);


    const isRangeSelected = useCallback((rangeId: string | number): boolean => {
        const allSelected = every(targetGroupRanges, r => !r.selected);
        return allSelected || !!targetGroupRanges.find(r => r.id === rangeId)?.selected;
    }, [targetGroupRanges]);

    const chartData = useMemo<PreparedPieChartData[]>(
        () => preparePieChartData(data, isRangeSelected, prevData),
        [data, isRangeSelected, prevData]
    );

    const legendData = useMemo<PreparedPieChartData[]>(
        () => data.map((entry, index) => ({
            ...entry,
            checked: isRangeSelected(entry.id),
            prevValue: undefined // prevValue is not needed in the legend
        })),
        [data, isRangeSelected]
    );

    const filteredData = useMemo(() =>
            some(data, entry => entry.filtered_value)
                ? data.map(entry => ({...entry, value: entry.filtered_value}))
                : data,
        [data]
    );

    const selectedSum = useMemo(
        () => sumBy(filteredData, entry => isRangeSelected(entry.id) && entry.value ? entry.value : 0),
        [filteredData, isRangeSelected]
    );

    const sum = useMemo(() => sumBy(data, 'value'), [data]);

    const handleSelect = (entry: PreparedPieChartData) => {
        if (!isSelectable(entry) || !targetGroupRangeKey) return;
        let newFilterOrder = [...filterOrder];
        if (targetGroupRangeKey === "amount_ranges" && some(currentTargetGroup.amount_sum_ranges, 'selected')) {
            let newTargetGroup = {...currentTargetGroup};
            newTargetGroup.amount_sum_ranges = newTargetGroup.amount_sum_ranges.map(range => ({
                ...range,
                selected: false
            }));
            dispatch(setCurrentTargetGroupAction(newTargetGroup));
            newFilterOrder = updateFilterOrder(filterOrder, "amount_sum_ranges", false);
            dispatch(setStatisticsFilterOrderAction(newFilterOrder));
        }
        if (targetGroupRangeKey === "amount_sum_ranges" && some(currentTargetGroup.amount_ranges, 'selected')) {
            let newTargetGroup = {...currentTargetGroup};
            newTargetGroup.amount_ranges = newTargetGroup.amount_ranges.map(range => ({
                ...range,
                selected: false
            }));
            dispatch(setCurrentTargetGroupAction(newTargetGroup));
            newFilterOrder = updateFilterOrder(filterOrder, "amount_ranges", false);
        }
        dispatch(selectTargetGroupRangeAction({
            targetGroupRangeKey,
            id: entry.id
        }));

        const newRanges = targetGroupRanges.map(r => r.id === entry.id ? {...r, selected: !r.selected} : r);
        const areSomeRangesSelected = some(newRanges, 'selected');
        newFilterOrder = updateFilterOrder(newFilterOrder, targetGroupRangeKey, areSomeRangesSelected);

        dispatch(setStatisticsFilterOrderAction(newFilterOrder));
    };

    const isSelectable = (entry: PreparedPieChartData) => !(!!prevData || (entry.value === 0 && !entry.checked) || tableMode);
    const isHovered = (id: number | string) => hoveredEntry?.id === id;

    if (isEmpty(chartData)) {
        return <NoChart/>;
    }

    return (
        <div className="pie-chart-section">
            <PieChartGraph
                chartData={chartData}
                prevData={prevData}
                selectedSum={selectedSum}
                aggregate={aggregate}
                handleSelect={!prevData ? handleSelect : undefined}
                setHoveredEntry={!prevData ? setHoveredEntry : undefined}
                isEntryHovered={!prevData ? isHovered : undefined}
                isEntrySelectable={isSelectable}
            />
            <PieChartLegend
                legendData={legendData}
                sum={sum}
                handleSelect={handleSelect}
                setHoveredEntry={setHoveredEntry}
                isHovered={isHovered}
                isSelectable={isSelectable}
            />
        </div>
    );
};

export default RangePieChartContainer;