import AdminMerchantPointServiceButton from "../common/AdminMerchantPointServiceButton";
import ChartIntervalSelect from "../../../components/common/chart/ChartIntervalSelect";
import {
    setAdminChartIntervalAction, setAdminDatePresetAction,
    setAdminDateRangeAction,
    setAdminPreviousPeriodAction
} from "../../redux/adminViewSettings";
import PreviousPeriodSelect from "../../../components/common/PreviousPeriodSelect";
import DateRangePicker from "../../../components/common/DateRangePicker";
import {icons} from "../../../services/images";
import AdminControlsModal from "../modals/AdminControlsModal";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ChartInterval, DatePreset, DateRange, PreviousPeriod, StatisticsData} from "../../../services/interfaces";
import {
    getAdminBarChartSelected,
    getAdminChartInterval, getAdminDatePreset,
    getAdminDateRange,
    getAdminPreviousPeriod
} from "../../redux/selectors/adminViewSettingsSelectors";
import {setStatisticsAggregateAction} from "../../../redux/statistics";
import AggregateSelect from "../../../components/statistics/AggregateSelect";
import StatisticsAggregateChartTile from "../../../components/statistics/StatisticsAggregateChartTile";
import {setBarChartSelectedAction} from "../../../redux/merchant";
import StatisticsChartTile from "../../../components/statistics/StatisticsChartTile";
import {getAdminUsersCount, getAdminUsersStatisticsData} from "../../redux/selectors/adminUsersSelectors";
import {getStatisticsAggregate} from "../../../redux/selectors/statisticsSelectors";
import AdminUsersTile from "./AdminUsersTile";
import AdminUsersTable from "./table/AdminUsersTable";
import AdminUsersWithoutTransactionsTable from "./table/AdminUsersWithoutTransactionsTable";


const AdminUsers = () => {
    const [isControlsModalOpen, setIsControlsModalOpen] = useState(false);
    const statisticsData: StatisticsData = useSelector(getAdminUsersStatisticsData);
    const currentData = statisticsData.current;
    const previousData = statisticsData.previous;
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const datePreset: DatePreset | null = useSelector(getAdminDatePreset);
    const chartInterval: ChartInterval = useSelector(getAdminChartInterval);
    const previousPeriod = useSelector(getAdminPreviousPeriod);
    const barChartSelected = useSelector(getAdminBarChartSelected);
    const aggregate = useSelector(getStatisticsAggregate);
    const usersBasicData = useSelector(getAdminUsersCount);

    const chartTileProps: {
        statisticsData: StatisticsData
        previousPeriod: PreviousPeriod
        chartInterval: ChartInterval
        barChartSelected: boolean
        dateRange: DateRange
        datePreset: DatePreset | null
        admin: boolean
    } = {
        statisticsData, previousPeriod, chartInterval, barChartSelected, dateRange, datePreset, admin: true
    };

    const dispatch = useDispatch();
    return (
        <div className="dashboard-content with-sticky-bar admin">
            <div className="controls-sticky-bar">
                <AdminMerchantPointServiceButton/>
                <div className="selectors-with-labels">
                    <AggregateSelect aggregate={aggregate}
                                     setAggregate={(aggregate) => dispatch(setStatisticsAggregateAction(aggregate))}/>
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={(interval) => dispatch(setAdminChartIntervalAction(interval))}/>
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setAdminPreviousPeriodAction(period))}/>
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange}
                                         setDateRange={(dateRange: DateRange) => dispatch(setAdminDateRangeAction(dateRange))}
                                         datePreset={datePreset}
                                         setDatePreset={(datePreset) => dispatch(setAdminDatePresetAction(datePreset))}
                                         admin={true}
                        />
                    </div>
                </div>
            </div>
            <div className="controls-mobile-button" onClick={() => setIsControlsModalOpen(true)}>
                <img src={icons.cogIcon} alt=""/>
            </div>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Lista użytkowników - Payprofit</h2>
                </div>
            </div>
            <div className="block-wrapper">
                <AdminUsersTile title="Użytkownicy z podpiętym kontem bankowym"
                                value={usersBasicData.current.with_bank_account}
                                prevValue={usersBasicData.previous.with_bank_account}/>
                <AdminUsersTile title="Użytkownicy bez podpiętego konta bankowego"
                                value={usersBasicData.current.without_bank_account}
                                prevValue={usersBasicData.previous.without_bank_account}/>
                <AdminUsersTile title="Liczba podpiętych kont bankowych" value={usersBasicData.current.bank_accounts}
                                prevValue={usersBasicData.previous.bank_accounts}/>
            </div>
            <StatisticsAggregateChartTile aggregate={aggregate}
                                          chartData={currentData.aggregate_data}
                                          prevChartData={previousData.aggregate_data}
                                          value={currentData.aggregate_sum} prevValue={previousData.aggregate_sum}
                                          chartInterval={chartInterval}
                                          barChartSelected={barChartSelected}
                                          setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
            />
            <div className="block-wrapper statistics-chart-block-wrapper">
                <StatisticsChartTile dataKey="amount" {...chartTileProps}/>
                <StatisticsChartTile dataKey="transactions" {...chartTileProps}/>
                <StatisticsChartTile dataKey="gender" {...chartTileProps}/>
            </div>
            <div className="block-wrapper statistics-chart-block-wrapper">
                <StatisticsChartTile dataKey="age" {...chartTileProps}/>
                <StatisticsChartTile dataKey="dynamics" {...chartTileProps}/>
                <StatisticsChartTile dataKey="average" {...chartTileProps}/>
            </div>
            <h3>Lista użytkowników - Z transakcjami</h3>
            <div className="block-wrapper">
                <AdminUsersTable/>
            </div>
            <h3>Lista użytkowników - Bez transakcji/Bez konta bankowego</h3>
            <div className="block-wrapper">
                <AdminUsersWithoutTransactionsTable/>
            </div>
            <AdminControlsModal isOpen={isControlsModalOpen} setIsOpen={setIsControlsModalOpen}/>
        </div>
    )
};

export default AdminUsers;