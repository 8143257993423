import React, {useEffect} from "react";
import {getBankIcon} from "../../services/images";
import {bankListHandler} from "../../handlers/bankHandlers";
import {BankIdentification} from "../../services/interfaces";
import {useSelector} from "react-redux";
import {getBankList} from "../../redux/selectors/bankSelectors";


const numColumns = 3;

interface Props {
    onBankSelect: (bank: number) => void;
    error: boolean;
    disabled: boolean;
}

const BankGrid = ({onBankSelect, error, disabled}: Props) => {

    useEffect(() => {
        bankListHandler()
    }, []);

    const bankList: BankIdentification[] = useSelector(getBankList);

    const data = bankList.map((bank: BankIdentification) => ({
        id: bank.id,
        code: bank.bank_code,
        icon: getBankIcon(bank.path_icon)
    }));


    const renderRow = (rowItems: { id: number, icon: string, code: number }[], rowKey: number) => (
        <div key={`row${rowKey}`} className="bank-grid-row">
            {rowItems.map((item) =>
                <div key={item.id}
                     onClick={() => {
                         if (!disabled)
                             onBankSelect(item.code);
                     }}
                     className={`bank-grid-icon ${disabled ? 'disabled' : ''}`}
                >
                    <img src={item.icon} alt={`bankIcon${item.id}`}/>
                </div>
            )}
        </div>
    );

    const renderGrid = () => {
        const grid = [];
        for (let i: number = 0; i < data.length; i += numColumns) {
            const rowItems: { id: number, icon: string, code: number }[] = data.slice(i, i + numColumns);
            grid.push(renderRow(rowItems, i / 3));
        }
        return grid;
    };

    return (
        <div className="bank-grid">
            {error && <div className="bank-grid-error">Wystąpił błąd podczas dodawania banku</div>}
            {renderGrid()}
        </div>
    )
};


export default BankGrid;