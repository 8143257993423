import {cookieGet, cookieRemove, cookieSave, storageActionTypes} from "./basicStorage";

export enum secureStorageKeys {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    EXP_DATE = 'expDate',
    ADMIN = 'admin'
}

export const authStorageManager = (action: storageActionTypes, storageKey: secureStorageKeys, storageValue?: string): string | Promise<string | null> => {
    if (action === storageActionTypes.GET)
        return cookieGet(storageKey) as string;

    if (action === storageActionTypes.SET && storageValue)
        cookieSave(storageKey, storageValue);

    if (action === storageActionTypes.DELETE)
        cookieRemove(storageKey);

    return Promise.resolve(null);
};
export const storeAuthItems = (accessToken: string, refreshToken: string, expire: number, admin: boolean = false): void => {
    authStorageManager(storageActionTypes.SET, secureStorageKeys.ACCESS_TOKEN, accessToken);
    authStorageManager(storageActionTypes.SET, secureStorageKeys.REFRESH_TOKEN, refreshToken);
    authStorageManager(storageActionTypes.SET, secureStorageKeys.EXP_DATE, String(expire))
    authStorageManager(storageActionTypes.SET, secureStorageKeys.ADMIN, admin ? 'true' : 'false');
};
export const clearAuthItems = (): void => {
    authStorageManager(storageActionTypes.DELETE, secureStorageKeys.ACCESS_TOKEN);
    authStorageManager(storageActionTypes.DELETE, secureStorageKeys.REFRESH_TOKEN);
    authStorageManager(storageActionTypes.DELETE, secureStorageKeys.EXP_DATE);
    authStorageManager(storageActionTypes.DELETE, secureStorageKeys.ADMIN);
};