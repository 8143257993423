import {TooltipProps} from "recharts";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {
    DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY,
    formatNumber,
    WHOLE_NUMBER_FORMAT,
    WHOLE_NUMBER_FORMAT_PERCENTAGE
} from "../../services/numbers";
import {useMemo} from "react";

interface ExtendedTooltipProps extends TooltipProps<ValueType, NameType> {
    chartType: string
    tooltipFormat?: (value: number) => string;
}

const ChartCustomTooltip = ({
                                active,
                                payload,
                                label,
                                chartType,
                                tooltipFormat,
                            }: ExtendedTooltipProps) => {
    const valueFormat = useMemo(() => {
        if (tooltipFormat) {
            return tooltipFormat
        }
        if (chartType === 'currency')
            return (value: number) => formatNumber(value, DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY);
        if (chartType === 'percent')
             return (value: number) => formatNumber(value, WHOLE_NUMBER_FORMAT_PERCENTAGE, 100);
        return (value: number) => formatNumber(value, WHOLE_NUMBER_FORMAT);

    }, [chartType, tooltipFormat]);

    if (active && payload && payload[0] && payload[1] && payload[1].payload.prevName) {
        return (
            <div className="chart-tooltip">
                <div className="label">
                    {payload[1].color && <span className="dot" style={{backgroundColor: payload[1].color}}/>}
                    <span>{`${label}: ${valueFormat(payload[1].value as number)}`}</span>
                </div>
                <p className="label">
                    <span className="dot" style={{backgroundColor: payload?.[0].color}}/>
                    <span>{`${payload?.[0].payload.prevName}: ${valueFormat(payload[0].value as number)}`}</span>
                </p>
            </div>
        );
    }
    if (active && payload && payload[0]) {
        return (
            <div className="chart-tooltip">
                <div className="label">
                    {payload[0].color && <span className="dot" style={{backgroundColor: payload[0].color}}/>}
                    <span>{`${label}: ${valueFormat(payload[0].value as number)}`}</span>
                </div>
            </div>
        );
    }

    return null;
};

export default ChartCustomTooltip;