import HistoryTableRow from "./HistoryTableRow";
import {useSelector} from "react-redux";
import {
    getHistoryTransactionsCount,
    getHistoryTransactionsList,
    getHistoryTransactionsMaxPage
} from "../../../redux/selectors/merchantSelector";
import React, {useEffect} from "react";
import {OrderType, TableHeader, Transaction, TransactionHistorySort} from "../../../services/interfaces";
import {downloadTransactionTableHandler} from "../../../handlers/transactionHandlers";
import TransactionHistoryFiltersModal from "../../modals/filters/TransactionHistoryFiltersModal";
import Table from "../../common/Table";


interface Props {
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    query: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
    sort: string
    setSort: React.Dispatch<React.SetStateAction<string>>
    order: OrderType
    setOrder: React.Dispatch<React.SetStateAction<OrderType>>
    filters: string
    setFilters: React.Dispatch<React.SetStateAction<string>>
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
}

const HistoryTable = (props: Props) => {

    const data: Transaction[] = useSelector(getHistoryTransactionsList);
    const maxPage: number = useSelector(getHistoryTransactionsMaxPage);
    const count: number = useSelector(getHistoryTransactionsCount);
    const headers: TableHeader<TransactionHistorySort>[] = [
        {name: 'ID transakcji', sort: 'id', digit: false},
        {name: 'ID klienta', sort: 'client_id', digit: false},
        {name: 'Data', sort: 'trade_date', digit: false},
        {name: 'Adres punktu', sort: 'name_address_value_1', digit: false},
        {name: 'Status Cashbacku', sort: 'status', digit: false},
        {name: 'Kwota transakcji', sort: 'amount', digit: true},
        {name: 'Cashback', sort: 'cashback_accrued', digit: true},
        {name: 'Opłaty', sort: 'total_fees', digit: true},
        {name: 'Suma opłat', sort: 'total', digit: true},
        {name: 'Płeć', sort: 'gender', digit: true},
        {name: 'Wiek', sort: 'age', digit: true}
    ];

    useEffect(() => {
        props.setPage(1)
    }, [maxPage]);

    return (
        <Table {...props} maxPage={maxPage} count={count}
               renderRows={() => data.map((transaction, index) => <HistoryTableRow transaction={transaction}
                                                                                   filters={props.filters}
                                                                                   key={`TransactionRow${index}`}/>)}
               downloadHandler={downloadTransactionTableHandler} headers={headers}
               Modal={TransactionHistoryFiltersModal}/>
    )
};


export default HistoryTable;