import ChartIntervalSelect from "./ChartIntervalSelect";
import ChartSwitch from "./ChartSwitch";
import DateIntervalBarChart from "./DateIntervalBarChart";
import DateIntervalLineChart from "./DateIntervalLineChart";
import {ChartData, ChartInterval, ChartType, ChartValueKey, PreviousPeriod} from "../../../services/interfaces";
import {isEmpty} from "lodash";
import ChartLegend from "./ChartLegend";
import NoChart from "./NoChart";

interface Props {
    chartData: ChartData[]
    prevChartData?: ChartData[]
    chartType: ChartType
    chartInterval: ChartInterval
    setChartInterval?: (chartInterval: ChartInterval) => void
    barChartSelected: boolean
    setBarChartSelected: (barChartSelected: boolean) => void
    previousPeriod?: PreviousPeriod
    valueKey?: ChartValueKey
    height?: number
    tooltipFormat?: (value: number) => string
}

const DateIntervalComposedChart = ({
                                       chartData,
                                       prevChartData,
                                       chartType,
                                       chartInterval,
                                       setChartInterval,
                                       barChartSelected,
                                       setBarChartSelected,
                                       previousPeriod,
                                       valueKey = "value",
                                       height = 230,
                                       tooltipFormat,
                                   }: Props) => {

    return (
        <div className="chart-section">
            <div className="chart-selectors">
                {prevChartData && valueKey !== 'change' && previousPeriod !== 'hide' && <ChartLegend/>}
                {setChartInterval &&
                    <ChartIntervalSelect chartInterval={chartInterval} onIntervalChange={setChartInterval}/>
                }
                <ChartSwitch barChartSelected={barChartSelected} onChartChange={setBarChartSelected}/>
            </div>
            {!isEmpty(chartData) ?
                barChartSelected ?
                    <DateIntervalBarChart chartData={chartData} prevChartData={prevChartData}
                                          interval={chartInterval} chartType={chartType}
                                          valueKey={valueKey} height={height} previousPeriod={previousPeriod}
                                          tooltipFormat={tooltipFormat}
                    /> :
                    <DateIntervalLineChart chartData={chartData} prevChartData={prevChartData}
                                           interval={chartInterval} chartType={chartType}
                                           valueKey={valueKey} height={height} previousPeriod={previousPeriod}
                                           tooltipFormat={tooltipFormat}
                    />
                :
                <NoChart/>
            }
            <div className="chart-section-bottom">
                {prevChartData && valueKey !== 'change' && <ChartLegend/>}
            </div>
        </div>
    )
};

export default DateIntervalComposedChart;