import {sumBy} from "lodash";
import {useMemo} from "react";
import {formatNumber} from "../../../services/numbers";
import {BALANCE_PIE_CHART_COLORS} from "../../../services/chartUtils";
import {PreparedPieChartData} from "../../../services/interfaces";
import AdminPieChartWithLabels from "./AdminPieChartWithLabels";
import {useSelector} from "react-redux";
import {getAdminDashboardCashbackBalance} from "../../redux/selectors/adminDashboardSelectors";
import {AdminDashboardCashbackBalance} from "../../services/interfaces";


const AdminCashbackBalanceTile = () => {

    const balance: AdminDashboardCashbackBalance = useSelector(getAdminDashboardCashbackBalance);
    const balanceData: PreparedPieChartData[] = useMemo(() => {
        const getName = (key: string): string => {
            switch (key) {
                case 'cashback':
                    return "Na Cashback:";
                case 'partner_cashback':
                    return "Na Cashback partnerski:";
                case 'forest':
                    return "Na lasy:";
                case 'payprofit_fee':
                    return "Przychody:";
                default:
                    return key;
            }
        };

        return Object.entries(balance).map(([key, value], index) => ({
            id: index,
            name: getName(key),
            value: value,
            filtered_value: null,
            checked: false
        }));

    }, [balance]);

    const balanceSum = useMemo(() => sumBy(balanceData, 'value'), [balanceData]);


    return (
        <div className="block block-1-2 balance-chart-container">
            <div className="balance-chart-legend">
                <h5>Wpływy Payprofit</h5>
                <div className="balance-chart-legend-row">
                    <p className="label">Wpływy:</p>
                    <p className="label">{formatNumber(balanceSum)}</p>
                </div>
                {balanceData.map((d, index) =>
                    <div className="balance-chart-legend-row" key={`BalanceChartLegendRow${index}`}>
                        <div className="label-with-dot">
                            <div className="dot" style={{backgroundColor: BALANCE_PIE_CHART_COLORS[index]}}/>
                            <p>{d.name}</p>
                        </div>
                        <p className="label">{formatNumber(d.value)}</p>
                    </div>
                )}
            </div>
            <AdminPieChartWithLabels chartData={balanceData} sum={balanceSum}/>
        </div>
    )
};

export default AdminCashbackBalanceTile;

