import {range} from "lodash";


interface Props {
    color: number | null;
    setColor: (color: number) => void;
    colorRange: number
}
const ColorPicker = ({color, setColor, colorRange}: Props) => {

    return (
        <div className="color-pick-wrapper">
            {range(1, colorRange + 1).map(index =>
                <div
                    key={`ColorPicker${index}`}
                    onClick={() => setColor(index)}
                    className={`color-pick color-${index} ${color && color === index ? 'selected' : ''}`}/>
            )}
        </div>
    )
};

export default ColorPicker;