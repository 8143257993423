import {
    getUserList,
    removeStandardUser,
    resendActivationCode,
    sendInvitation,
    verifyInvitationToken
} from "../http/management";
import {setUserList} from "../redux/management";
import {store} from "../redux/store";
import React from "react";
import {ActivateCredentials} from "../services/interfaces";


export const getUserListHandler = () => {
    getUserList().then(response => {
        store.dispatch(setUserList(response.data));
    }).catch(error => {
        console.log(error);
    })
};

export const sendInvitationHandler = (email: string, callback: () => void, lockButtonCallback: React.Dispatch<React.SetStateAction<boolean>>) => {
    lockButtonCallback(true);
    sendInvitation(email).then(response => {
        callback();
    }).catch(error => {
        console.log(error);
        lockButtonCallback(false);
    })
};

export const verifyInvitationTokenHandler = (token: string, callbackStatus: React.Dispatch<React.SetStateAction<string | null>>) => {
    verifyInvitationToken(token, null).then(response => {
        callbackStatus(response.data);
    }).catch(error => {
        callbackStatus(error.detail || 'unknown_error')
    })
};

export const activateAccountHandler = (data: ActivateCredentials, token: string, callback: React.Dispatch<React.SetStateAction<Object | string | null>>, buttonCallback: React.Dispatch<React.SetStateAction<boolean>>) => {
    buttonCallback(true);
    verifyInvitationToken(token, data).then(response => {
        if (response.status === 201) {
            callback('registered');
        } else {
            callback(response.data);
        }
        buttonCallback(false);
    }).catch(error => {
        callback(error.detail || 'unknown_error');
        buttonCallback(false);
    })
};

export const removeStandardUserHandler = (id: number, callback: () => void) => {
    removeStandardUser(id).then(response => {
        callback();
    }).catch(error => {
        console.log(error);
    })
};

export const resendActivationCodeHandler = (id: number, callback: () => void) => {
    resendActivationCode(id).then(response => {
        callback();
    }).catch(error => {
        console.log(error);
    })
};