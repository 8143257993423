import Button from "./Button";
import {images} from "../../services/images";

interface Props {
    body: string;
    onTryAgain: () => void;
}

const ErrorScreen = ({body, onTryAgain}: Props) => {
    return (
        <div className="onboarding">
            <div className="onboarding-content">
                <img src={images.payProfitCRMLogoBlack} alt="PayProfitCRM"/>
                <div className="badge badge-error"/>
                <h2>Upss... coś poszło nie tak</h2>
                <p className="note">{body}</p>
                <Button label="Spróbuj jeszcze raz" onClick={onTryAgain}/>
                <Button label="Skontaktuj się z nami"
                        onClick={() => console.log("Skontaktuj się z nami")} //TODO add redirect to contact with us
                        type="Default"/>

            </div>
        </div>
    )
};

export default ErrorScreen;