import request, {GET, POST} from "./request";
import {
    AuthResponse,
    LoginCredentials,
    SignUpCredentials,
} from "../services/interfaces";
import {handleError} from "./handleError";
import {storageActionTypes} from "../services/storage/basicStorage";
import {authStorageManager, secureStorageKeys} from "../services/storage/secureStorage";

export const signUp = async ({email, password, fullName, phoneNumber}: SignUpCredentials) => {
    try {
        const {data, status}: { data: AuthResponse, status: number } = await request(POST, 'auth/create', {
            email: email,
            password: password,
            name: fullName,
            phone: phoneNumber,
        });
        return {data, status}
    } catch (error) {
        throw handleError(error);
    }
};

export const logIn = async ({email, password}: LoginCredentials, admin?: boolean) => {
    try {
        const {data, status}: { data: AuthResponse, status: number } = await request(POST, 'auth/login', {
            email: email,
            password: password,
        }, {}, admin);
        return {data, status};
    } catch (error) {
        throw handleError(error);
    }
};

export const logOut = async (admin?: boolean) => {
    try {
        const refreshToken = await authStorageManager(storageActionTypes.GET, secureStorageKeys.REFRESH_TOKEN);
        await request(POST, 'auth/logout', {refresh_token: refreshToken}, {}, admin);
    } catch (error) {
        throw handleError(error);
    }
};

export const getUserDetails = async (admin?: boolean) => {
    try {
        const {data, status}: { data: any, status: number } = await request(GET, 'auth/me', {}, {}, admin);
        return {data, status}
    } catch (error) {
        throw handleError(error);
    }
};

export const sendVerificationCode = async (code: number) => {
    try {
        const {status}: { status: number } = await request(POST, 'auth/verify_phone', {code});
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const resendVerificationCode = async () => {
    try {
        const {status}: { status: number } = await request(POST, 'auth/resend_verification_code');
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const changePassword = async ({oldPassword, newPassword}: { oldPassword: string, newPassword: string }, admin?: boolean) => {
    try {
        const {status}: { status: number } = await request(POST, 'auth/change_password', {
            old_password: oldPassword,
            new_password: newPassword
        }, {}, admin);
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const sendResetPasswordEmail = async (email: string, admin?: boolean) => {
    try {
        const {status}: { status: number } = await request(POST, 'auth/reset_password', {email}, {}, admin);
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const resetPassword = async (token: string, password: string | null, admin?: boolean) => {
    try {
        const {status, data}: {
            data: { detail: string },
            status: number
        } = await request(POST, 'auth/reset_password/verify', {password, token}, {}, admin);
        return {status, data}
    } catch (error) {
        throw handleError(error)
    }
};