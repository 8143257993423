import Statistics from "../components/statistics/Statistics";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {
    getStatisticsActiveClientInterval,
    getStatisticsAggregate, getStatisticsCurrentTargetGroup,
    getStatisticsFilterOrder, getStatisticsSelectedUserIds, getStatisticsTableMode,
} from "../redux/selectors/statisticsSelectors";
import {getStatisticsHandler,} from "../handlers/statisticsHandlers";
import {
    getChartInterval,
    getCurrentMerchantPoint,
    getCurrentTargetGroup, getDateRange,
    getIsMerchantSelected,
    getMerchant, getPreviousPeriod
} from "../redux/selectors/merchantSelector";
import {getTargetGroupsHandler} from "../handlers/targetGroupHandlers";
import {DateRange} from "../services/interfaces";


const StatisticsContainer = () => {
    const aggregate = useSelector(getStatisticsAggregate);
    const filterOrder = useSelector(getStatisticsFilterOrder);
    const activeClientInterval = useSelector(getStatisticsActiveClientInterval);
    const previousPeriod = useSelector(getPreviousPeriod);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const chartInterval = useSelector(getChartInterval);
    const dateRange: DateRange = useSelector(getDateRange);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const statisticsTargetGroup = useSelector(getStatisticsCurrentTargetGroup);
    const selectedUserIds = useSelector(getStatisticsSelectedUserIds);

    useEffect(() => {
        getStatisticsHandler(aggregate, statisticsTargetGroup, filterOrder, activeClientInterval, previousPeriod, chartInterval, dateRange, selectedUserIds);
    }, [aggregate, filterOrder, activeClientInterval, merchant, merchantPoint, isMerchantSelected, previousPeriod, chartInterval, dateRange, currentTargetGroup, selectedUserIds]);

    useEffect(() => {
        getTargetGroupsHandler();
    }, []);

    return <Statistics/>
};

export default StatisticsContainer;