import {useEffect, useMemo, useState} from "react";
import {
    DateRange,
    GetTableProps,
    OrderType,
    StatisticsUsersSort,
    StatisticsUserSummary,
    TableHeader
} from "../../../services/interfaces";
import {
    getCurrentMerchantPoint,
    getCurrentTargetGroup,
    getDateRange,
    getIsMerchantSelected,
    getMerchant
} from "../../../redux/selectors/merchantSelector";
import {useDispatch, useSelector} from "react-redux";
import StatisticsTableRow from "./StatisticsTableRow";
import {downloadStatisticsTableHandler, getStatisticsTableHandler} from "../../../handlers/statisticsHandlers";
import Table from "../../common/Table";
import {
    getStatisticsAllUserIds,
    getStatisticsCurrentTargetGroup,
    getStatisticsSelectedUserIds,
    getStatisticsTableCount,
    getStatisticsTableList,
    getStatisticsTableMaxPage,
    getStatisticsTableMode
} from "../../../redux/selectors/statisticsSelectors";
import StatisticsTableFiltersModal from "./StatisticsTableFiltersModal";
import AppUserDetailsModal from "../../modals/AppUserDetailsModal";
import {selectAllStatisticsUsersAction} from "../../../redux/statistics";
import {isEqual} from "lodash";


const StatisticsTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [userDetailsId, setUserDetailsId] = useState<number | null>(null);

    const data: StatisticsUserSummary[] = useSelector(getStatisticsTableList);
    const maxPage: number = useSelector(getStatisticsTableMaxPage);
    const count: number = useSelector(getStatisticsTableCount);
    const dateRange: DateRange = useSelector(getDateRange);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const statisticsTargetGroup = useSelector(getStatisticsCurrentTargetGroup);
    const tableMode = useSelector(getStatisticsTableMode);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const selectedUserIds = useSelector(getStatisticsAllUserIds);
    const allUserIds = useSelector(getStatisticsSelectedUserIds);
    const areAllUsersSelected: boolean = useMemo(() => {
        return isEqual(selectedUserIds, allUserIds)
    }, [selectedUserIds, allUserIds])


    const dispatch = useDispatch();

    const headers: TableHeader<StatisticsUsersSort>[] = [
        {name: 'ID klienta', sort: 'id', digit: false},
        {name: 'Imię i Nazwisko', sort: 'name', digit: false},
        {name: 'Adres e-mail', sort: 'email', digit: false},
        {name: 'Wiek', sort: 'age', digit: false},
        {name: 'Płeć', sort: 'gender', digit: false},
        {name: 'Łącznie wydana kwota', sort: 'amount', digit: true},
    ];


    useEffect(() => {
        const getTableProps: GetTableProps = {
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize
        };
        getStatisticsTableHandler(getTableProps, statisticsTargetGroup, dateRange)
    }, [page, query, sort, order, filterString, pageSize, dateRange, merchant, merchantPoint, isMerchantSelected, currentTargetGroup, tableMode]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);


    return (
        <>
            <Table page={page}
                   setPage={setPage}
                   pageSize={pageSize}
                   setPageSize={setPageSize}
                   query={query}
                   setQuery={setQuery}
                   sort={sort}
                   setSort={setSort}
                   order={order}
                   setOrder={setOrder}
                   count={count}
                   filters={filterString}
                   setFilters={setFilterString}
                   maxPage={maxPage}
                   headers={headers}
                   renderRows={() => data.map((user, index) =>
                       <StatisticsTableRow user={user}
                                           filters={filterString}
                                           key={`UserRow${index}`}
                                           onClick={() => setUserDetailsId(user.id)}
                       />
                   )}
                   downloadHandler={downloadStatisticsTableHandler}
                   Modal={StatisticsTableFiltersModal}
                   onAllCheckboxClick={tableMode ?
                       () => dispatch(selectAllStatisticsUsersAction()) :
                       undefined
                   }
                   areAllSelected={areAllUsersSelected}
            />
            <AppUserDetailsModal
                onClose={() => setUserDetailsId(null)}
                userId={userDetailsId}
            />
        </>
    )
};


export default StatisticsTable;