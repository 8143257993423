import {icons} from "../../../services/images";
import {logOutHandler} from "../../../handlers/authHandlers";
import React from "react";
import {SettingsPage} from "../../../services/interfaces";


interface Props {
    setSettingsPage: React.Dispatch<React.SetStateAction<SettingsPage | null>>;
}

const AdminSettingsMenu = ({setSettingsPage}: Props) => {

    return (
        <ul className="list-bars">
            <li onClick={() => setSettingsPage("password")}>
                <span className="img-wrapper"><img src={icons.lockActive}/></span>
                <span>Twoje hasło</span>
            </li>
            <li onClick={() => logOutHandler(true)}>
                <span className="img-wrapper"><img src={icons.logoutActive}/></span>
                <span>Wyloguj się</span>
            </li>
        </ul>
    )

};

export default AdminSettingsMenu;
