import {DateRange, DownloadTableProps, OrderType} from "../../services/interfaces";
import {prepareB64FileToXLSX, prepareURLParamForDownloadTable, prepareURLParamForTable} from "../../services/utils";
import {saveAs} from "file-saver";
import {
    downloadMerchantsTable,
    getMerchants,
    getMerchantsPieChartData,
    sendMessageToMerchants
} from "../http/adminMerchants";
import {store} from "../../redux/store";
import {setAdminMerchantsPieChartAction, setAdminMerchantsTableAction} from "../redux/adminMerchants";
import {getAdminDateRange} from "../redux/selectors/adminViewSettingsSelectors";
import {Dispatch, SetStateAction} from "react";


export const downloadMerchantsTableHandler = (props: DownloadTableProps) => {
    const urlParam = prepareURLParamForDownloadTable(props);
    const dateRange = getAdminDateRange(store.getState());
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    downloadMerchantsTable(urlParam.toString()).then((response) => {
        const blob = prepareB64FileToXLSX(response.data.file);
        saveAs(blob, `merchants-${new Date().toISOString()}.xlsx`)
    }).catch(error => {
        console.error(error);
    })
};


export const getMerchantsTableHandler = ({page, query, sort, order, filters, pageSize, dateRange}: {
    page: number,
    query: string,
    sort: string,
    order: OrderType,
    filters: string,
    pageSize: number,
    dateRange: DateRange
}) => {
    const urlParam = prepareURLParamForTable({page, query, sort, order, filters, pageSize});
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    getMerchants(urlParam.toString()).then((data) => {
        store.dispatch(setAdminMerchantsTableAction(data))
    }).catch(error => {
        console.error(error);
    })
};

export const getMerchantsPieChartDataHandler = (dateRange: DateRange) => {
    getMerchantsPieChartData(dateRange).then((data) => {
        store.dispatch(setAdminMerchantsPieChartAction(data))
    }).catch(error => {
        console.error(error);
    })
};

export const sendMessageToMerchantsHandler = ({title, content, merchantIds, successCallback, errorCallback, setLoading}:
                                                  {
                                                      title: string,
                                                      content: string,
                                                      merchantIds: number[],
                                                      successCallback: () => void,
                                                      errorCallback: () => void,
                                                      setLoading: Dispatch<SetStateAction<boolean>>
                                                  }
) => {
    setLoading(true);
    sendMessageToMerchants(title, content, merchantIds).then(() => {
        setLoading(false);
        successCallback();
    }).catch(error => {
        errorCallback();
        setLoading(false);
        console.error(error);
    })
};