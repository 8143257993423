import {
    Aggregate,
    ChartInterval,
    DateRange,
    DownloadTableProps,
    GetTableProps,
    OrderType,
    PreviousPeriod,
    StatisticsData,
    StatisticsDataKey,
    StatisticsPieChartKey,
    TargetGroup
} from "../../services/interfaces";
import {store} from "../../redux/store";
import {setScreenLoadingAction} from "../../redux/navigation";
import {AdminGlobalFilters, AdminMessageToUser, AdminUsersCount} from "../services/interfaces";
import {
    downloadUsersTable,
    downloadUsersWithoutTransactionsTable,
    getAllAppUsers,
    getAppUserDetails,
    getUsersCount,
    getUsersStatistics,
    getUsersStatisticsDetail,
    getUsersTable,
    getUsersWithoutTransactionsTable,
    sendMessageToUsers
} from "../http/adminUsers";
import {
    setAdminAllAppUsers,
    setAdminAppUserDetailsAction,
    setAdminUsersCountAction,
    setAdminUsersStatisticsDataAction,
    setAdminUsersStatisticsDetailsDataAction,
    setAdminUsersTableAction,
    setAdminUsersWithoutTransactionsTable,
} from "../redux/adminUsers";
import {getAdminDateRange, getAdminGlobalFilters} from "../redux/selectors/adminViewSettingsSelectors";
import {isEmpty} from "lodash";
import {prepareB64FileToXLSX, prepareURLParamForDownloadTable, prepareURLParamForTable} from "../../services/utils";
import {saveAs} from "file-saver";
import {getCurrentTargetGroup} from "../../redux/selectors/merchantSelector";
import {Dispatch, SetStateAction} from "react";

const prepareUsersTableParams = (filters: string,
                                 globalFilters: AdminGlobalFilters,
                                 targetGroup: TargetGroup) => {

    const urlParam = new URLSearchParams();
    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('target_group_json', targetGroupJson);
    let newFilters = filters;
    if (!isEmpty(globalFilters.merchantPoints))
        newFilters += '&merchant_points_ids=' + globalFilters.merchantPoints.join(',');
    if (!isEmpty(globalFilters.serviceTypes))
        newFilters += '&service_types=' + globalFilters.serviceTypes.join(',');
    if (newFilters !== '')
        urlParam.append('filters', newFilters);

    return urlParam;
};


export const getUsersTableHandler = ({
                                         page,
                                         query,
                                         sort,
                                         order,
                                         filters,
                                         pageSize,
                                         dateRange,
                                         targetGroup,
                                         globalFilters
                                     }: {
    page: number,
    query: string,
    sort: string,
    order: OrderType,
    filters: string,
    pageSize: number
    dateRange: DateRange,
    targetGroup: TargetGroup,
    globalFilters: AdminGlobalFilters
}) => {
    const urlParam = prepareUsersTableParams(filters, globalFilters, targetGroup);
    urlParam.append('page', page.toString());
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);

    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    if (pageSize !== 10)
        urlParam.append('page_size', pageSize.toString());

    getUsersTable(urlParam.toString()).then((data) => {
        store.dispatch(setAdminUsersTableAction(data));
    }).catch(error => {
        console.error(error);
    });
};

export const downloadUsersTableHandler = ({query, sort, order, filters}: DownloadTableProps) => {
    const dateRange = getAdminDateRange(store.getState());
    const globalFilters = getAdminGlobalFilters(store.getState());
    const targetGroup = getCurrentTargetGroup(store.getState());
    const urlParam = prepareUsersTableParams(filters, globalFilters, targetGroup);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    downloadUsersTable(urlParam.toString()).then((response) => {
        const blob = prepareB64FileToXLSX(response.data.file);
        saveAs(blob, `users-${new Date().toISOString()}.xlsx`)
    }).catch(error => {
        console.error(error);
    })
};

export const getUsersWithoutTransactionsTableHandler = (props: GetTableProps) => {
    const urlParam = prepareURLParamForTable(props);
    getUsersWithoutTransactionsTable(urlParam.toString()).then((data) => {
        store.dispatch(setAdminUsersWithoutTransactionsTable(data));
    }).catch(error => {
        console.error(error);
    });
};

export const downloadUsersWithoutTransactionsTableHandler = (props: DownloadTableProps) => {
    const urlParam = prepareURLParamForDownloadTable(props);
    downloadUsersWithoutTransactionsTable(urlParam.toString()).then((response) => {
        const blob = prepareB64FileToXLSX(response.data.file);
        saveAs(blob, `users-without-transactions${new Date().toISOString()}.xlsx`)
    }).catch(error => {
        console.error(error);
    })
};


export const getUsersStatisticsHandler = (aggregate: Aggregate,
                                          targetGroup: TargetGroup,
                                          filterOrder: StatisticsPieChartKey[],
                                          previousPeriod: PreviousPeriod,
                                          chartInterval: ChartInterval,
                                          dateRange: DateRange,
                                          globalFilters: AdminGlobalFilters) => {
    store.dispatch(setScreenLoadingAction(true));

    const urlParam = new URLSearchParams();
    urlParam.append('aggregate', aggregate);
    urlParam.append('filter_order', filterOrder.join(','));
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('target_group_json', targetGroupJson);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const filters = '' +
        '&merchant_points_ids=' + globalFilters.merchantPoints.join(',') +
        '&service_types=' + globalFilters.serviceTypes.join(',');
    urlParam.append('filters', filters);


    getUsersStatistics(urlParam.toString()).then((data: StatisticsData) => {
        store.dispatch(setAdminUsersStatisticsDataAction(data));
        store.dispatch(setScreenLoadingAction(false));
    }).catch(error => {
        store.dispatch(setScreenLoadingAction(false));
        console.error(error);
    });
};


export const getUsersStatisticsDetailHandler = (targetGroup: TargetGroup,
                                                aggregate: Aggregate,
                                                dataKey: StatisticsDataKey,
                                                dateRange: DateRange,
                                                previousPeriod: PreviousPeriod,
                                                chartInterval: ChartInterval) => {
    const urlParam = new URLSearchParams();

    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('detail_key', dataKey);
    urlParam.append('aggregate', aggregate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    urlParam.append('target_group_json', targetGroupJson);

    const globalFilters = getAdminGlobalFilters(store.getState());
    const filters = '' +
        '&merchant_points_ids=' + globalFilters.merchantPoints.join(',') +
        '&service_types=' + globalFilters.serviceTypes.join(',');
    urlParam.append('filters', filters);
    getUsersStatisticsDetail(urlParam.toString()).then((data) => {
        store.dispatch(setAdminUsersStatisticsDetailsDataAction(data));
    }).catch(error => {
        console.error(error);
    });
};


export const getUsersCountHandler = (dateRange: DateRange) => {

    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);

    getUsersCount(urlParam.toString()).then((data: AdminUsersCount) => {
        store.dispatch(setAdminUsersCountAction(data));
    }).catch(error => {
        console.error(error);
    });

};

export const getAppUserDetailsHandler = (userId: number) => {
    getAppUserDetails(userId).then((data) => {
        store.dispatch(setAdminAppUserDetailsAction(data));
    }).catch(error => {
        console.error(error);
    });
};


export const getAllAppUsersHandler = () => {
    getAllAppUsers().then((data) => {
        store.dispatch(setAdminAllAppUsers(data));
    }).catch(error => {
        console.error(error);
    });
};

export const sendMessageToUsersHandler = (
    data: AdminMessageToUser,
    successCallback: () => void,
    errorCallback: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>,
) => {
    setLoading(true);
    sendMessageToUsers(data).then(() => {
        successCallback();
        setLoading(false);
    }).catch(error => {
        errorCallback();
        setLoading(false);
        console.error(error);
    })
};