import React from "react";
import {icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import {setContactPageAction} from "../../../redux/chat";
import {getUnreadChatsCount} from "../../../redux/selectors/chatSelectors";

const ContactMenu = () => {
    const dispatch = useDispatch();
    const unreadChatsCount = useSelector(getUnreadChatsCount);
    return (
        <ul className="list-bars">
            <li onClick={() => dispatch(setContactPageAction("chat_list"))}>
                <span className="img-wrapper"><img src={icons.userActive} alt=""/></span>
                <span>Chat z klientami</span>
                {unreadChatsCount && unreadChatsCount > 0 ?
                    <span className="badge-attention">{unreadChatsCount}</span> : <span/>
                }
            </li>
            <li onClick={() => dispatch(setContactPageAction("admin"))}>
                <span className="img-wrapper"><img src={icons.messagesIcon} alt=""/>
                </span>
                <span>Wyślij wiadomość do administratorów PayProfit</span>

            </li>
        </ul>
    )
};

export default ContactMenu