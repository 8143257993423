import {useSelector} from "react-redux";
import {getBookingEditMode} from "../redux/selectors/bookingSelector";
import BookingEdit from "../components/booking/edit/BookingEdit";
import BookingDashboard from "../components/booking/dashboard/BookingDashboard";


const BookingContainer = () => {
    const editMode = useSelector(getBookingEditMode);
    return (
        editMode ?
            <BookingEdit/> :
            <BookingDashboard/>
    )
};

export default BookingContainer;