import request, {GET, POST, PUT} from "./request";
import {handleError} from "./handleError";
import {CupAction, CupDashboardResponse, CupTableResponse} from "../services/interfaces";


export const getMerchantCupTable = async (urlParams: string): Promise<CupTableResponse> => {
    try {
        const {data} = await request(GET, `cup/?${urlParams}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointCupTable = async (merchantPointId: number, urlParams: string): Promise<CupTableResponse> => {
    try {
        const {data} = await request(GET, `cup/${merchantPointId}?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadMerchantCupActions = async (urlParam: string) => {
    try {
        return await request(GET, `cup/download/?${urlParam}`)
    } catch (error) {
        throw handleError(error);
    }
};

export const downloadMerchantPointCupActions = async (merchantPointId: number, urlParam: string) => {
    try {
        return await request(GET, `cup/${merchantPointId}/download/?${urlParam}`)
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantCupDashboard = async (urlParam: string): Promise<CupDashboardResponse> => {
    try {
        const {data} = await request(GET, `cup/dashboard/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointCupDashboard = async (merchantPointId: number, urlParam: string): Promise<CupDashboardResponse> => {
    try {
        const {data} = await request(GET, `cup/${merchantPointId}/dashboard/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantCupActionsFilterOptions = async () => {
    try {
        const {data} = await request(GET, 'cup/filter_options/');
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointCupActionsFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `cup/${merchantPointId}/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const manageMerchantPointCupCount = async (merchantPointId: number, numberOfCups: number, add: boolean) => {
    try {
        const {data} = await request(POST, `cup/${merchantPointId}/manage/`, {number_of_cups: numberOfCups, add});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const setCupActionError = async (cupAction: CupAction) => {
    try {
        const {data} = await request(PUT, `cup/error/${cupAction.id}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};