import {Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {ChartData, ChartInterval, PreviousPeriod} from "../../../services/interfaces";
import ChartCustomTooltip from "../ChartCustomTooltip";
import {AXIS_COLOR, CURRENT_COLOR, getPrevDataKey, prepareChartData, PREV_COLOR} from "../../../services/chartUtils";
import {CurveType} from "recharts/types/shape/Curve";
import {useMemo} from "react";

interface Props {
    chartData: ChartData[]
    prevChartData?: ChartData[]
    interval: ChartInterval,
    chartType: 'currency' | 'absolute' | 'percent'
    previousPeriod?: PreviousPeriod
    valueKey?: 'value' | 'change' | 'average'
    height?: number
    tooltipFormat?: (value: number) => string;
}


const DateIntervalLineChart = ({
                                   chartData,
                                   prevChartData,
                                   interval,
                                   chartType,
                                   previousPeriod,
                                   valueKey = 'value',
                                   height = 230,
                                   tooltipFormat,
                               }: Props) => {
    const data = prepareChartData({chartData, prevChartData, interval, previousPeriod});

    const lineProps: {
        type: CurveType,
        strokeWidth: number,
        dot: boolean
    } = {
        strokeWidth: 3,
        dot: false,
        type: "monotone",
    };
    const prevDataKey = useMemo(() =>
        getPrevDataKey(valueKey, prevChartData), [valueKey, prevChartData]);

    return (
        <ResponsiveContainer width="100%" height={height}>
            <LineChart
                data={data}
                margin={{left: -20, top: 0, bottom: height < 230 ? -10 : 0, right: 0}}
            >
                <XAxis dataKey="name" fontSize="12" stroke={AXIS_COLOR} tickMargin={5}/>
                {valueKey === "change" &&
                    <ReferenceLine y={0} stroke="#ccc"/>
                }
                <YAxis fontSize="12" stroke={AXIS_COLOR} padding={{top: 5}} tickMargin={5}
                       allowDecimals={chartType !== 'absolute'}/>
                {prevDataKey &&
                    <Line dataKey={prevDataKey}
                          stroke={PREV_COLOR}
                          {...lineProps}/>
                }
                <Line dataKey={valueKey}
                      stroke={CURRENT_COLOR}
                      {...lineProps}/>
                <Tooltip content={<ChartCustomTooltip chartType={chartType} tooltipFormat={tooltipFormat}/>}/>
            </LineChart>
        </ResponsiveContainer>
    )
};

export default DateIntervalLineChart;