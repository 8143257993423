import {createSlice} from "@reduxjs/toolkit";
import {BankIdentification} from "../services/interfaces";


interface BankState {
    bankList: BankIdentification[];
}

const DEFAULT_STATE: BankState = {
    bankList: [],
};

const bankSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'bank',
    reducers: {
        setBankList: (state, action) => {
            return {...state, bankList: action.payload}
        },
    }
});

export default bankSlice.reducer;

export const setBankList = bankSlice.actions.setBankList;