import React, {useState} from "react";
import AdminMessageSuccess from "./AdminMessageSuccess";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import TextArea from "../../../../components/common/TextArea";
import {Select} from "antd";
import {useSelector} from "react-redux";
import {getAdminMerchants} from "../../../redux/selectors/adminViewSettingsSelectors";
import {isEmpty} from "lodash";
import {sendMessageToMerchantsHandler} from "../../../handlers/adminMerchantsHandlers";
import {AdminMerchantSimple} from "../../../services/interfaces";


interface Props {
    onClose: () => void
}

const AdminContactMerchantMessage = ({onClose}: Props) => {
    const [data, setData] = useState({title: '', content: ''});
    const [merchantIds, setMerchantIds] = useState<number[]>([]);
    const [successScreen, setSuccessScreen] = React.useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const merchants: AdminMerchantSimple[] = useSelector(getAdminMerchants);

    const successCallback = () => setSuccessScreen(true);
    const errorCallback = () => setError('send_message_error');

    return (
        successScreen ?
            <AdminMessageSuccess onClose={onClose}/> :
            <>
                <div className="admin-message">
                    <div className="input-wrapper section">
                        <div className="label-wrapper">
                            <label>Wybierz sieci</label>
                        </div>
                        <Select
                            mode="multiple"
                            value={merchantIds}
                            onChange={(value) => setMerchantIds(value)}
                            allowClear={true}
                            placeholder="Zaznacz sieci do których chcesz wysłać wiadomość..."
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={merchants.map(merchant => ({
                                value: merchant.id,
                                label: merchant.name
                            }))}
                            className="dropdown-select merchant"
                        />
                    </div>
                    <Input name='title' onChange={setData} value={data} label="Temat"/>
                    <TextArea name='content' onChange={setData} value={data} label="Treść wiadomości"/>
                    {error && <ErrorComponent error={error}/>}
                    <Button label="Wyślij wiadomość"
                            disabled={!data.title || !data.content || isEmpty(merchantIds) || loading}
                            onClick={() => sendMessageToMerchantsHandler({
                                title: data.title,
                                content: data.content,
                                merchantIds,
                                successCallback,
                                errorCallback,
                                setLoading
                            })}
                            loading={loading}
                            fullWidth={true}
                    />
                </div>
            </>
    )
};

export default AdminContactMerchantMessage