import {
    AppUserDetails,
    Category, CategoryFilters,
    Chat,
    CupActionFilterOptions,
    HistoryFilterOptions,
    Merchant,
    MerchantPoint,
    MultiSelectOption,
    OpeningHours,
    OrderFilterOptions,
    Product,
    ReservationFilterOptions,
    Service,
    User
} from "./interfaces";
import {every, sumBy} from "lodash";
import {useMemo} from "react";


export const isOpeningHoursFilled = (openingHours: OpeningHours[]) => every(openingHours, dayOpeningHours =>
    (dayOpeningHours.open_time && dayOpeningHours.close_time) || dayOpeningHours.is_closed);


export const getHeaderTitle = (isMerchantSelected: boolean, merchant: Merchant, merchantPoint: MerchantPoint) =>
    (isMerchantSelected ? "Cała sieć " : "") + (isMerchantSelected ? merchant.name : merchantPoint.name);


export const getFiltersClientOptions = (filterOptions: CupActionFilterOptions | HistoryFilterOptions | ReservationFilterOptions | OrderFilterOptions | null) =>
    filterOptions?.clients.map(client => {
        return {value: client.toString(), children: client.toString()}
    });

export const getFiltersPointsOptions = (filterOptions: CupActionFilterOptions | HistoryFilterOptions | null) => {
    const pointsOptions: MultiSelectOption[] = [];
    if (filterOptions) {
        Object.keys(filterOptions?.merchant_points).forEach(key => {
            pointsOptions.push({
                value: filterOptions?.merchant_points[parseInt(key, 10)].toString(),
                children: key.toString()
            })
        });
    }
    return pointsOptions
};


export const getMerchantCupCount = (merchant: Merchant) =>
    sumBy(merchant.merchant_points, 'cup_count');


export const getAppUserName = (user: AppUserDetails | User) => `${user.email} ${user.name ? `(${user.name})` : ''}`;


export const getIsChatUnread = (chat: Chat) => chat.last_message?.sender_type === 'user' && !chat.last_message.read_timestamp;


export const getFiltersEmployeesOptions = (filterOptions: ReservationFilterOptions | null) => {
    const employeeOptions: MultiSelectOption[] = [];
    if (filterOptions) {
        Object.keys(filterOptions?.employees).forEach(key => {
            employeeOptions.push({
                value: filterOptions?.employees[parseInt(key, 10)].toString(),
                children: key.toString()
            })
        });
    }
    return employeeOptions
};

export const getCategoryName = (category: Category) => category.name === 'Uncategorized' ? 'Nieprzypisane' : category.name;


// Helper functions
const isPriceInRange = (price: number, range: [number, number] | null): boolean => {
    if (!range) return true;
    const [min, max] = range;
    return price >= min && price <= max;
};

const matchesQuery = (item: Service | Product, query: string): boolean => {
    return item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase());
};

// Type guards
export const isService = (item: any): item is Service => 'duration' in item;

export const isProduct = (item: any): item is Product => 'quantity_in_stock' in item && 'unit' in item;

export const useFilteredCategories = (
    categories: Category[],
    filters: CategoryFilters
): Category[] => {
    return useMemo(() => {
        return (categories as Category[]).map(category => {
            if (category.items.length === 0) return category;

            const isServiceCategory = isService(category.items[0]);

            if (isServiceCategory) {
                // Handle Services
                const items = category.items as Service[];
                const filteredItems = items.filter((item: Service) => {
                    const matchesPrice = isPriceInRange(item.price, filters.priceSlider);
                    const matchesSearchQuery = filters.query ?
                        matchesQuery(item, filters.query) :
                        true;

                    return matchesPrice && matchesSearchQuery;
                });
                return {...category, items: filteredItems};
            } else {
                // Handle Products
                const items = category.items as Product[];
                const filteredItems = items.filter((item: Product) => {
                    const matchesPrice = isPriceInRange(item.price, filters.priceSlider);
                    const matchesSearchQuery = filters.query ?
                        matchesQuery(item, filters.query) :
                        true;

                    return matchesPrice && matchesSearchQuery;
                });
                return {...category, items: filteredItems};
            }
        }).filter(category => {
            const isVisible = !filters.hiddenCategories.includes(category.id);
            // const hasItems = category.items.length > 0;
            return isVisible;
        });
    }, [categories, filters]);
};
