import BankGrid from "../BankGrid";
import {
    configureBankHandler, getConfigureAccountNumbersHandler,
    skipTerminalVerificationHandler
} from "../../../handlers/bankHandlers";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BankIdentification, DraftTerminalResponse, QueryParams} from "../../../services/interfaces";
import _ from "lodash";
import {separateAccountNumber} from "../../../services/utils";
import {getBankList} from "../../../redux/selectors/bankSelectors";
import {useSelector} from "react-redux";
import {getBankIcon} from "../../../services/images";
import Button from "../../common/Button";
import {getUserDetailsHandler} from "../../../handlers/authHandlers";
import {HOME_PAGE} from "../../../services/dictionaries/paths";


interface Props {
    queryParams: QueryParams
    addBankAccountCallback: (response: DraftTerminalResponse) => void
}

const ConfigureTerminalBank = ({queryParams, addBankAccountCallback}: Props) => {
    const [bankRedirect, setBankRedirect] = useState(false);
    const [configureErrorHandler, setConfigureErrorHandler] = useState(false);
    const [accountNumbers, setAccountNumbers] = useState<string[]>([]);
    const [bankId, setBankId] = useState<number | null>(null);
    const [merchantPointId, setMerchantPointId] = useState<number | null>(null);
    const [skipButtonClicked, setSkipButtonClicked] = useState<boolean>(false);

    const bankList: BankIdentification[] = useSelector(getBankList);
    const selectedBank = bankList.find((bank: BankIdentification) => bank.id === bankId);

    const navigate = useNavigate();

    const configureErrorCallback = () => {
        setConfigureErrorHandler(true);
        setBankRedirect(false);
    };

    const skipTerminalVerificationCallback = () => {
        setSkipButtonClicked(false);
        getUserDetailsHandler();
    };

    useEffect(() => {
        if (!_.isEmpty(queryParams) && queryParams.status === 'success' && queryParams.type === 'choices') {
            getConfigureAccountNumbersHandler(setAccountNumbers, setBankId)
        } else if (!_.isEmpty(queryParams && queryParams.status === 'failed')) {
            setConfigureErrorHandler(true);
        } else if (!_.isEmpty(queryParams) && queryParams.hasOwnProperty('mp')) {
            setMerchantPointId(+queryParams.mp);
        }
    }, [queryParams]);

    return (
        <>
            <h2>Podłącz konto bankowe</h2>
            <p className="note">
                Wybierz konto bankowe, które zostanie użyte w celu weryfikacji Twojego terminala płatniczego.
            </p>
            {bankRedirect ?
                <div className="loader"/> :
                (_.isEmpty(accountNumbers) ?
                        <BankGrid
                            onBankSelect={(bankCode) => {
                                configureBankHandler(bankCode, configureErrorCallback, null, merchantPointId);
                                setBankRedirect(true);
                            }}
                            disabled={skipButtonClicked}
                            error={configureErrorHandler}
                        /> :
                        <div className="account-numbers">
                            <ul>
                                {selectedBank && accountNumbers.map((accountNumber, index) => (
                                    <li key={index}
                                        onClick={() => {
                                            configureBankHandler(selectedBank.bank_code, configureErrorCallback, accountNumber, +queryParams.merchantPointId);
                                            setBankRedirect(true);
                                        }}
                                    >
                                        <img src={selectedBank ? getBankIcon(selectedBank.path_icon) : ''}
                                             alt={selectedBank?.bank_name}
                                        />
                                        {separateAccountNumber(accountNumber)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                )}
            <div className="accept-rules-container">
                <span className="accept-rules-text">Przechodząc dalej akceptuję &nbsp;
                    {/*TODO change to redirect terms of use*/}
                    <Link to="/">Warunki korzystania z usług</Link>
                </span>
            </div>
            {!bankRedirect && _.isEmpty(accountNumbers) &&
                <Button
                    label={merchantPointId ? 'Wróć do ekranu głównego' : 'Pomiń dodawanie terminala'}
                    onClick={() => {
                        setSkipButtonClicked(true);
                        if (merchantPointId) {
                            navigate(HOME_PAGE);
                        } else {
                            skipTerminalVerificationHandler(skipTerminalVerificationCallback);

                        }
                    }}
                    type="Default"
                    loading={skipButtonClicked}
                    disabled={skipButtonClicked}
                />
            }
        </>
    )
};

export default ConfigureTerminalBank;