import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {AdminUserDetails, MerchantUserDetails} from "../../services/interfaces";

export const getAuthState = (state: RootState) => state.auth;

export const getIsAuthenticated: Selector<RootState, boolean | null> = createSelector(
    getAuthState,
    (account) => account.isAuthenticated
);
export const getAccountErrorStatus: Selector<RootState, string | null> = createSelector(
    getAuthState,
    (account) => account.error
);
export const getUserDetails: Selector<RootState, MerchantUserDetails | null> = createSelector(
    getAuthState,
    (account) => account.userDetails
);

export const getAdminUserDetails: Selector<RootState, AdminUserDetails | null> = createSelector(
    getAuthState,
    (account) => account.adminUserDetails
);


export const getIsAdminPanel: Selector<RootState, boolean | null> = createSelector(
    getAuthState,
    (account) => account.isAdminPanel
);