import React, {ChangeEvent, MutableRefObject, useCallback, useRef, useState} from "react";
import {getCroppedImg, isBase64Image} from "../../services/imageUtils";
import {CroppedAreaPixels} from "../../services/interfaces";
import Modal from "react-modal";
import Cropper from 'react-easy-crop'
import Button from "../common/Button";
import {cloudFrontUrl, icons} from "../../services/images";


type ImageType = 'logo-image' | 'background-image' | 'offer-image';

interface Props {
    width: number;
    height: number;
    type: ImageType;
    image: string;
    setImage: (image: string) => void;
    label: string;
    setBackgroundColor?: (color: number | null) => void;
    selectedColor?: number | null;
    withIcon?: boolean;
}


const AddImage = ({width, height, type, image, setImage, label, setBackgroundColor, selectedColor, withIcon}: Props) => {

    const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer | null>(null);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedAreaPixels | null>(null);
    const [trimLoading, setTrimLoading] = useState<boolean>(false);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [fileLargeError, setFileLargeError] = useState<boolean>(false);
    const fileRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

    const selectImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setImageLoading(true);
        const file = event.target.files && event.target.files[0];
        const maxSizeInBytes = 2e6; // 2MB in bytes
        if (file === undefined)
            setImageLoading(false);
        else if (file && file.size <= maxSizeInBytes) {
            setFileLargeError(false);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setUploadedImage(reader.result);
        } else {
            setFileLargeError(true);
            setImageLoading(false);
        }
    };

    const applyCroppedImage = useCallback(async () => {
        try {
            if (typeof uploadedImage === 'string' && croppedAreaPixels) {
                setTrimLoading(true);
                const image: string | null = await getCroppedImg(uploadedImage, croppedAreaPixels);
                if (image !== null) {
                    setTimeout(() => {
                        setImage(image);
                        setBackgroundColor && setBackgroundColor(null);
                        setUploadedImage(null);
                        setTrimLoading(false);
                    }, 500);
                } else {
                    console.error("Error cropping image");
                    setTrimLoading(false);
                }
            }
        } catch (e) {
            console.error(e)
        }
    }, [setBackgroundColor, setImage, uploadedImage, croppedAreaPixels]);


    const onCropComplete = useCallback((_croppedArea: CroppedAreaPixels, croppedAreaPixels: CroppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
        setImageLoading(false);
    }, []);

    const toggleImageModal = () => setUploadedImage(null);

    const [zoom, setZoom] = useState(1);

    const onImageRemove = () => {
        setImage('');
        setBackgroundColor && setBackgroundColor(selectedColor ? selectedColor : 1)
    };

    const canDeselectColor = !!selectedColor && image && setBackgroundColor;

    return (
        <>
            {withIcon ?
                <>
                    <label htmlFor={type}>
                        <img className="add-icon" src={icons.addBackgroundImageIcon} alt={"dodaj tło"}/>
                    </label>
                    <input
                        ref={fileRef}
                        multiple={false}
                        id={type}
                        onClick={(e) => {
                            if (canDeselectColor) {
                                setBackgroundColor(null);
                                e.preventDefault();
                            }
                        }}
                        onChange={selectImageHandler}
                        accept="image/png, image/jpeg, image/jpg"
                        type="file"
                        hidden
                    />
                </>
                :
                <div className="file-input-inner-wrapper">
                    <label>{label}</label>
                    <div className="remove-wrapper">
                        <label
                            htmlFor={type}
                            className={`input-image ${type} ${!selectedColor && image ? 'selected' : ''} ${image ? 'selectable' : ''}`}
                            style={{
                                backgroundImage: image ? `url(${isBase64Image(image) ? image : cloudFrontUrl + image})` : '',
                            }}
                        />
                        <input
                            ref={fileRef}
                            multiple={false}
                            id={type}
                            onClick={(e) => {
                                if (canDeselectColor) {
                                    setBackgroundColor(null);
                                    e.preventDefault();
                                }
                            }}
                            onChange={selectImageHandler}
                            accept="image/png, image/jpeg, image/jpg"
                            type="file"
                            hidden
                        />
                        {image &&
                            <div className="remove" onClick={onImageRemove}>
                                <img src={icons.closeIcon} alt=""/>
                            </div>
                        }
                    </div>
                    <p className="note">Zalecane wymiary: {width}x{height} Rozszerzenia: JPG, JPEG, PNG
                        Wielkość obrazków nie może przekroczyć 2 MB</p>
                    {fileLargeError && <p className="error-message">Wybrany plik jest za duży - max 2MB</p>}
                </div>
            }
            {uploadedImage && typeof uploadedImage === 'string' &&
                <Modal
                    onRequestClose={toggleImageModal}
                    isOpen={!!uploadedImage}
                    overlayClassName="modal-wrapper"
                    ariaHideApp={false}
                    className={`modal-content image-modal ${type}`}
                >
                    <div className="modal-header">
                        <h5>Przytnij zdjecie</h5>
                        <div className="modal-close" onClick={toggleImageModal}>
                            <img src={icons.closeIcon} alt=""/>
                        </div>
                    </div>
                    <div className="modal-body">
                        <Cropper
                            image={uploadedImage}
                            crop={crop}
                            aspect={width / height}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            objectFit="vertical-cover"
                            zoom={zoom}
                            onZoomChange={(changedZoom: number) => setZoom(changedZoom)}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button
                            label="Anuluj"
                            type="Default"
                            onClick={() => setUploadedImage(null)}
                            disabled={trimLoading}
                        />
                        <Button
                            onClick={applyCroppedImage}
                            disabled={trimLoading}
                            loading={trimLoading}
                            label="Dodaj zdjęcie"
                        />
                    </div>
                </Modal>
            }
        </>
    );
};

export default AddImage;