import HistoryTable from "./table";
import DateRangePicker from "../common/DateRangePicker";
import {useDispatch, useSelector} from "react-redux";
import {
    getBarChartSelected,
    getChartInterval,
    getCurrentMerchantPoint,
    getCurrentTargetGroup, getDatePreset,
    getDateRange,
    getHistoryTransactionsDashboard,
    getIsMerchantSelected,
    getMerchant,
    getPreviousPeriod
} from "../../redux/selectors/merchantSelector";
import {
    setBarChartSelectedAction,
    setChartIntervalAction, setDatePresetAction,
    setDateRangeAction,
    setPreviousPeriodAction
} from "../../redux/merchant";
import React, {useEffect, useState} from "react";
import ChangePill from "../common/ChangePill";
import {
    getMerchantTransactionsDashboardHandler,
    getMerchantTransactionsHandler
} from "../../handlers/transactionHandlers";
import DateIntervalComposedChart from "../common/chart/DateIntervalComposedChart";
import {ChartInterval, DatePreset, DateRange, OrderType} from "../../services/interfaces";
import {getHeaderTitle} from "../../services/helpers";
import TimeRangeLabel from "../common/TimeRangeLabel";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import {transactionTooltipFormat} from "../../services/chartUtils";
import TargetGroupButton from "../common/TargetGroupButton";
import {getTargetGroupsHandler} from "../../handlers/targetGroupHandlers";
import {icons} from "../../services/images";
import ControlsModal from "../modals/ControlsModal";
import ChartIntervalSelect from "../common/chart/ChartIntervalSelect";

const History = () => {
    const [isControlsModalOpen, setIsControlsModalOpen] = useState<boolean>(false);

    const dateRange: DateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const chartInterval: ChartInterval = useSelector(getChartInterval);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const previousPeriod = useSelector(getPreviousPeriod);
    const barChartSelected = useSelector(getBarChartSelected);
    const targetGroup = useSelector(getCurrentTargetGroup);

    const dispatch = useDispatch();

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');

    useEffect(() => {
        getMerchantTransactionsDashboardHandler(dateRange, previousPeriod, chartInterval, targetGroup)
    }, [dateRange, isMerchantSelected, merchant, merchantPoint, previousPeriod, chartInterval, targetGroup]);

    useEffect(() => {
        getMerchantTransactionsHandler({page, query, sort, order, filters: filterString, pageSize})
    }, [page, query, sort, order, isMerchantSelected, merchant, merchantPoint, filterString, pageSize]);

    useEffect(() => {
        getTargetGroupsHandler();
    }, []);

    const dashboardData = useSelector(getHistoryTransactionsDashboard);

    if (!merchant)
        return <div/>;

    return (
        <div className="dashboard-content with-sticky-bar">
            <div className="controls-sticky-bar">
                <div>
                    <TargetGroupButton showTargetGroupChanges/>
                </div>
                <div className="selectors-with-labels">
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={(interval) => dispatch(setChartIntervalAction(interval))}/>
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setPreviousPeriodAction(period))}/>
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange}
                                         setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                         datePreset={datePreset}
                                         setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                        />
                    </div>
                </div>
            </div>
            <div className="controls-mobile-button" onClick={() => setIsControlsModalOpen(true)}>
                <img src={icons.cogIcon} alt=""/>
            </div>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Historia transakcji - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
            </div>
            <div className="block-wrapper chart-block-wrapper">
                <div className="block chart-block big-chart-block">
                    <div className="value-section">
                        <h3>Liczba transakcji</h3>
                        <div className="value-wrapper">
                            <span className="standard-value">{dashboardData.transactionsCount}</span>
                            <ChangePill value={dashboardData.transactionsCount}
                                        prevValue={dashboardData.transactionsPreviousCount}
                            />
                            <TimeRangeLabel/>
                        </div>
                    </div>
                    <DateIntervalComposedChart chartData={dashboardData.transactions}
                                               prevChartData={dashboardData.transactionsPrevious}
                                               chartType="absolute"
                                               chartInterval={chartInterval}
                                               barChartSelected={barChartSelected}
                                               setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
                                               previousPeriod={previousPeriod}
                                               tooltipFormat={transactionTooltipFormat}
                    />
                </div>
            </div>
            <div className="block-wrapper">
                <HistoryTable
                    page={page}
                    setPage={setPage}
                    query={query}
                    setQuery={setQuery}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    filters={filterString}
                    setFilters={setFilterString}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
            <ControlsModal isOpen={isControlsModalOpen} setIsOpen={setIsControlsModalOpen}/>
        </div>
    )
};

export default History;