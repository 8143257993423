import Modal from "react-modal";
import {icons} from "../../../services/images";
import Button from "../../../components/common/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAdminGlobalFilters} from "../../redux/selectors/adminViewSettingsSelectors";
import {setAdminGlobalFiltersAction} from "../../redux/adminViewSettings";
import {ServiceTypeName} from "../../../services/interfaces";
import AdminMerchantPointFilter from "../common/AdminMerchantPointFilter";
import AdminServiceTypeFilter from "../common/AdminServiceTypeFilter";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminMerchantPointServiceModal = ({isOpen, setIsOpen}: Props) => {
    const [serviceTypes, setServiceTypes] = useState<ServiceTypeName[]>([]);
    const [merchantPointIds, setMerchantPointIds] = useState<number[]>([]);
    const globalFilters = useSelector(getAdminGlobalFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        setServiceTypes(globalFilters.serviceTypes);
        setMerchantPointIds(globalFilters.merchantPoints);
    }, [globalFilters, isOpen]);

    const toggleModal = () => {
        setIsOpen(false);
    };

    const applyFilters = () => {
        dispatch(setAdminGlobalFiltersAction({
            serviceTypes: serviceTypes,
            merchantPoints: merchantPointIds
        }));
        setIsOpen(false);
    };

    const resetFilters = () => {
        setMerchantPointIds([]);
        setServiceTypes([])
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content admin-service-type-modal filters-modal"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Wybierz sklepy/branże</h5>
                <div className="modal-close" onClick={resetFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <AdminServiceTypeFilter serviceTypes={serviceTypes} setServiceTypes={setServiceTypes}/>
                <AdminMerchantPointFilter merchantPointIds={merchantPointIds}
                                          setMerchantPointsIds={setMerchantPointIds}/>
            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry"
                        onClick={applyFilters}
                        fullWidth={true}
                />
            </div>
        </Modal>
    )
};

export default AdminMerchantPointServiceModal