import React, {useEffect, useRef, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import {
    ActiveClientInterval,
    Aggregate,
    ChartData,
    ChartInterval,
    ChartType,
    ChartValueKey,
    DatePreset,
    DateRange,
    PieChartData,
    PreviousPeriod,
    StatisticsDataKey,
    TargetGroupRangeKey
} from "../../services/interfaces";
import DateRangePicker from "../common/DateRangePicker";
import {useSelector} from "react-redux";
import {
    getStatisticsCurrentTargetGroup,
    getStatisticsDetailsCurrentData,
    getStatisticsDetailsPreviousData
} from "../../redux/selectors/statisticsSelectors";
import Button from "../common/Button";
import DateIntervalComposedChart from "../common/chart/DateIntervalComposedChart";
import {getMerchantStatisticsDetailHandler} from "../../handlers/statisticsHandlers";
import html2canvas from "html2canvas";
import {saveAs} from "file-saver";
import AggregateSelect from "./AggregateSelect";
import RangePieChartContainer from "./chart/RangePieChartContainer";
import GenderPieChartContainer from "./chart/GenderPieChartContainer";
import NoChart from "../common/chart/NoChart";
import {TARGET_GROUP_RANGE_KEYS} from "../../redux/merchant";
import {store} from "../../redux/store";
import {setStatisticsDetailsDataAction} from "../../redux/statistics";
import {isChartDataArray} from "../../services/statisticsHelpers";
import ActiveClientIntervalSelect from "./ActiveClientIntervalSelect";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import ChartIntervalSelect from "../common/chart/ChartIntervalSelect";
import {clientsTooltipFormat, transactionTooltipFormat} from "../../services/chartUtils";
import {DOUBLE_DECIMAL_NUMBER_FORMAT} from "../../services/numbers";
import {getUsersStatisticsDetailHandler} from "../../admin/handlers/adminUsersHandlers";
import {
    getAdminUsersStatisticsDetailsCurrentData,
    getAdminUsersStatisticsDetailsPreviousData
} from "../../admin/redux/selectors/adminUsersSelectors";
import {setAdminUsersStatisticsDetailsDataAction} from "../../admin/redux/adminUsers";
import {getCurrentTargetGroup} from "../../redux/selectors/merchantSelector";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    dataKey: StatisticsDataKey;
    parentAggregate: Aggregate;
    parentDateRange: DateRange;
    parentDatePreset: DatePreset | null;
    parentChartInterval: ChartInterval;
    parentActiveClientInterval?: ActiveClientInterval;
    parentPreviousPeriod: PreviousPeriod;
    parentBarChartSelected: boolean;
    admin?: boolean;
}


const ChartDetailsModal = ({
                               isOpen,
                               setIsOpen,
                               title,
                               dataKey,
                               parentAggregate,
                               parentDateRange,
                               parentDatePreset,
                               parentChartInterval,
                               parentActiveClientInterval,
                               parentPreviousPeriod,
                               admin,
                           }: Props) => {
    const [chartInterval, setChartInterval] = useState<ChartInterval | null>(null);
    const [aggregate, setAggregate] = useState<Aggregate | null>(null);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [datePreset, setDatePreset] = useState<DatePreset | null>(null);
    const [activeClientInterval, setActiveClientInterval] = useState<ActiveClientInterval | undefined>(undefined);
    const [previousPeriod, setPreviousPeriod] = useState<PreviousPeriod | null>(null);
    const [barChartSelected, setBarChartSelected] = useState<boolean>(true);

    const data = useSelector(!admin ? getStatisticsDetailsCurrentData : getAdminUsersStatisticsDetailsCurrentData);
    const prevData = useSelector(!admin ? getStatisticsDetailsPreviousData : getAdminUsersStatisticsDetailsPreviousData);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const statisticsTargetGroup = useSelector(getStatisticsCurrentTargetGroup);
    const chartRef = useRef(null);


    useEffect(() => {
        if (isOpen && chartInterval && aggregate && dateRange && previousPeriod) {
            !admin ?
                getMerchantStatisticsDetailHandler(statisticsTargetGroup, aggregate, dataKey, dateRange, previousPeriod, chartInterval, activeClientInterval) :
                getUsersStatisticsDetailHandler(currentTargetGroup, aggregate, dataKey, dateRange, previousPeriod, chartInterval)
        }
    }, [chartInterval, aggregate, dateRange, previousPeriod, activeClientInterval]);

    useEffect(() => {
        setDateRange(parentDateRange);
        setAggregate(parentAggregate);
        setDatePreset(parentDatePreset);
        parentChartInterval && setChartInterval(parentChartInterval);
        parentActiveClientInterval && setActiveClientInterval(parentActiveClientInterval);
        setPreviousPeriod(parentPreviousPeriod);
    }, []);


    if (!chartInterval || !aggregate || !dateRange || !previousPeriod)
        return <div/>


    const toggleModal = () => {
        setIsOpen(!isOpen);
        store.dispatch(
            admin ?
                setAdminUsersStatisticsDetailsDataAction({current: [], previous: []}) :
                setStatisticsDetailsDataAction({current: [], previous: []}));
    };

    const downloadChart = async () => {
        if (chartRef.current !== null) {
            const canvas = await html2canvas(chartRef.current);
            const dataURL = canvas.toDataURL('image/png');
            saveAs(dataURL, 'chart.png');
        }
    };

    const getChart = () => {

        if (dataKey === "gender")
            return <GenderPieChartContainer data={data as PieChartData[]}
                                            prevData={prevData as PieChartData[]}
                                            genderData={[]} aggregate={aggregate}/>;
        else if (TARGET_GROUP_RANGE_KEYS.includes(dataKey + "_ranges" as TargetGroupRangeKey))
            return <RangePieChartContainer
                data={data as PieChartData[]}
                prevData={prevData as PieChartData[]}
                targetGroupRanges={[]} aggregate={aggregate}/>;
        else if (isChartDataArray(data)) {
            let valueKey: ChartValueKey = "value";
            let chartType: ChartType = "absolute";
            let tooltipFormat = undefined;
            if (dataKey === "dynamics") {
                valueKey = "change";
                chartType = "percent";
            } else if (dataKey === "average") {
                valueKey = "average";
                if (aggregate === 'amount')
                    chartType = "currency";
                else if (aggregate === 'transactions')
                    tooltipFormat = (value: number) => transactionTooltipFormat(value, DOUBLE_DECIMAL_NUMBER_FORMAT);
                else if (aggregate === 'clients')
                    tooltipFormat = (value: number) => clientsTooltipFormat(value, DOUBLE_DECIMAL_NUMBER_FORMAT);
            } else {
                tooltipFormat = clientsTooltipFormat
            }
            return <DateIntervalComposedChart
                chartData={data as ChartData[]}
                prevChartData={prevData as ChartData[]}
                chartType={chartType}
                valueKey={valueKey}
                chartInterval={chartInterval}
                barChartSelected={barChartSelected}
                setBarChartSelected={setBarChartSelected}
                previousPeriod={previousPeriod}
                tooltipFormat={tooltipFormat}
            />;
        } else
            return <NoChart/>

    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content chart-details-modal"
        >
            <div className="modal-header">
                <div className="title-row">
                    <h5>{title}</h5>
                    <div className="buttons-container">
                        <Button label="Pobierz" onClick={downloadChart} icon={icons.download}/>
                        <div className="modal-close" onClick={toggleModal}>
                            <img src={icons.closeIcon} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="header-controls">
                    <AggregateSelect aggregate={aggregate} setAggregate={setAggregate}/>

                    <PreviousPeriodSelect previousPeriod={previousPeriod} onChange={setPreviousPeriod}/>
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={setChartInterval}
                    />
                    {activeClientInterval && <ActiveClientIntervalSelect activeClientInterval={activeClientInterval}
                                                                         onIntervalChange={setActiveClientInterval}/>
                    }
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} datePreset={datePreset}
                                         setDatePreset={setDatePreset}/>
                    </div>
                </div>


            </div>
            <div className="modal-body" ref={chartRef}>
                {getChart()}
            </div>
        </Modal>
    )
};

export default ChartDetailsModal