import {formatNumber} from "../../../services/numbers";
import Button from "../../common/Button";
import React, {useEffect, useState} from "react";
import {Step} from "./ConfigureTerminal";
import {ackTerminalDraftHandler, cancelVerificationHandler} from "../../../handlers/bankHandlers";

interface Props {
    terminalDraftAmount: number
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>
}

const ConfigureTerminalPayment = ({terminalDraftAmount, setCurrentStep}: Props) => {
    const [paymentButtonCountdown, setPaymentButtonCountdown] = useState<number>(10);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const ackTerminalCallback = () => {
        setCurrentStep(Step.VERIFY);
        setLoadingButton(false);
    };

    const ackTerminalCallbackError = () => {
        setLoadingButton(false);
    };

    useEffect(() => {
        if (paymentButtonCountdown <= 0) return;

        const intervalId = setInterval(() => {
            setPaymentButtonCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevCountdown - 1;
            })
        }, 1000);

        return () => clearInterval(intervalId);
    }, [paymentButtonCountdown]);

    return (
        <>
            <h1 className="blue-primary">{formatNumber(terminalDraftAmount)}</h1>
            <h2>Wykonaj płatność testową</h2>
            <p className="note">
                Wprowadź kwotę testową do terminala, a następnie dokonaj płatności kartą płatniczą.
            </p>
            <Button
                loading={loadingButton}
                disabled={paymentButtonCountdown > 0 || loadingButton}
                label={`Dokonałem płatności ${paymentButtonCountdown > 0 ? `(${paymentButtonCountdown})` : ''}`}
                onClick={() => {
                    setLoadingButton(true);
                    ackTerminalDraftHandler(ackTerminalCallback, ackTerminalCallbackError);
                }}
            />
            <Button label="Anuluj" type="Default" disabled={loadingButton} onClick={() => {
                cancelVerificationHandler(() => setCurrentStep(Step.BANK))
            }}/>
        </>
    )
};

export default ConfigureTerminalPayment;