import {OrderType, Reservation, ReservationSort, TableHeader} from "../../../services/interfaces";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    getReservationTableCount,
    getReservationTableData,
    getReservationTableMaxPage
} from "../../../redux/selectors/bookingSelector";
import {downloadReservationTableHandler, getReservationTableHandler} from "../../../handlers/bookingHandlers";
import Table from "../../common/Table";
import ReservationDetailsModal from "./ReservationDetailsModal";
import ReservationTableRow from "./ReservationTableRow";
import Button from "../../common/Button";
import {sidebarIcons} from "../../../services/images";
import ReservationFiltersModal from "./ReservationFiltersModal";


export const RESERVATION_TABLE_HEADERS: TableHeader<ReservationSort>[] = [
    {name: 'ID rezerwacji', sort: 'id', digit: false},
    {name: 'ID klienta', sort: 'client_id', digit: false},
    {name: 'Pracownik', sort: 'employee', digit: false},
    {name: 'Data wizyty', sort: 'appointment_date', digit: false},
    {name: 'Status', sort: 'status', digit: false},
    {name: 'Rodzaj usługi', sort: 'service_name', digit: false},
    {name: 'Kwota (zł)', sort: 'amount', digit: true},
];


const ReservationTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const [showCalendar, setShowCalendar] = useState(false);

    const data: Reservation[] = useSelector(getReservationTableData);
    const maxPage: number = useSelector(getReservationTableMaxPage);
    const count: number = useSelector(getReservationTableCount);

    useEffect(() => {
        getReservationTableHandler({
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize
        })
    }, [page, query, sort, order, filterString, pageSize]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);

    // TODO implement showing calendar
    return (
        <>
            <h3>Rezerwacje</h3>
            <div className="block-wrapper">
                <Table page={page}
                       setPage={setPage}
                       pageSize={pageSize}
                       setPageSize={setPageSize}
                       query={query}
                       setQuery={setQuery}
                       sort={sort}
                       setSort={setSort}
                       order={order}
                       setOrder={setOrder}
                       count={count}
                       filters={filterString}
                       setFilters={setFilterString}
                       maxPage={maxPage}
                       headers={RESERVATION_TABLE_HEADERS}
                       renderRows={() => data.map((reservation, index) =>
                           <ReservationTableRow reservation={reservation}
                                                filters={filterString}
                                                key={`ReservationRow${index}`}
                                                onClick={() => setSelectedId(reservation.id)}
                           />
                       )}
                       downloadHandler={downloadReservationTableHandler}
                       Modal={ReservationFiltersModal}
                       extraButtons={<Button onClick={() => setShowCalendar(true)} label="Pokaż kalendarz"
                                             icon={sidebarIcons.bookingActive}/>}
                       hasActionButton={true}
                />
            </div>
            <ReservationDetailsModal reservationId={selectedId} onClose={() => setSelectedId(null)}/>
        </>
    )
};

export default ReservationTable;