import {Select} from "antd";
import React, {useState} from "react";
import {formatNumber} from "../../../services/numbers";
import {differenceWith, isEmpty, orderBy, sumBy} from "lodash";
import NoChart from "../../../components/common/chart/NoChart";
import {Cell, Label, Pie, PieChart} from "recharts";
import {PIE_CHART_COLORS} from "../../../services/chartUtils";
import {icons} from "../../../services/images";


interface Props {
    title: string
    sortTitle: string
    items: SummaryItem[]
    format: string
}


export interface SummaryItem {
    logo: string,
    name: string,
    value: number,
}


const AdminSummaryTile = ({title, sortTitle, items, format}: Props) => {
    const [quantity, setQuantity] = useState(3);
    const orderedItems: SummaryItem[] = orderBy(items, 'value', 'desc');

    const topItems = orderedItems.slice(0, quantity);
    const restItems = differenceWith(items, topItems);

    let preparedItems = topItems;
    if (!isEmpty(restItems)) {
        const restItem = {
            name: "Pozostałe",
            value: sumBy(restItems, 'value'),
            logo: icons.whiteCoinIcon
        };
        preparedItems.push(restItem);
    }

    const sum = sumBy(preparedItems, 'value');

    const SIZE = {WIDTH: 184, HEIGHT: 184};
    const OUTER_RADIUS = 184 / 2;
    const INNER_RADIUS = OUTER_RADIUS * 0.63;
    const CORNER_RADIUS = 6;
    const SUM_FONT_SIZE = "18px";

    return (
        <div className="block block-1-3 summary-tile">
            <div className="summary-tile-header">
                <h5>
                    TOP
                </h5>
                <Select
                    value={quantity}
                    onChange={setQuantity}
                    options={[
                        {value: 3, label: '3'},
                        {value: 5, label: '5'},
                        {value: 7, label: '7'},
                    ]}
                />
                <h5>
                    {title}
                </h5>
                <span className="sort-title">
                    ({sortTitle})
                </span>
            </div>
            <div className="summary-tile-content">
                <div className="summary-tile-legend">
                    {preparedItems.map((item, index) => (
                        <div className="summary-tile-legend-item">
                            <div className="logo" style={{backgroundImage: `url(${item.logo})`}}/>
                            <div className="name-value-container">
                                <span className="name">{item.name}</span>
                                <div className="value">
                                    <span className="dot" style={{backgroundColor: PIE_CHART_COLORS[index]}}/>
                                    <span>
                                    {formatNumber(item.value, format)}
                                </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="summary-tile-graph">
                    {isEmpty(preparedItems) || sum === 0 ?
                        <NoChart/> :
                        <PieChart width={SIZE.WIDTH} height={SIZE.HEIGHT}>
                            <Pie
                                dataKey="value"
                                data={preparedItems}
                                cx="50%"
                                cy="50%"
                                innerRadius={INNER_RADIUS}
                                outerRadius={OUTER_RADIUS}
                                startAngle={90}
                                endAngle={450}
                                fill="#82ca9d"
                                cornerRadius={CORNER_RADIUS}
                                paddingAngle={4}
                                isAnimationActive={true}
                                labelLine={false}
                            >
                                {preparedItems.map((item, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={PIE_CHART_COLORS[index]}
                                        className="pie-chart-filling"
                                    />
                                ))}
                                <Label
                                    value={formatNumber(sum, format)}
                                    position="center"
                                    fill="black"
                                    style={{
                                        fontSize: SUM_FONT_SIZE,
                                        fontWeight: "500",
                                        fontFamily: "Roboto"
                                    }}
                                />
                            </Pie>
                        </PieChart>
                    }
                </div>
            </div>
        </div>
    )

};

export default AdminSummaryTile