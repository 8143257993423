import React from 'react';
import Button from "../../common/Button";
import {showCustomersNumber} from "../../../services/utils";
import {formatNumber} from "../../../services/numbers";
import AddImage from "../../merchant_point/AddImage";
import AddVideo from "./AddVideo";

interface StepProps {
    formData: {
        imagePath: string;
        filmPath: string;
        filmTitle: string;
        filmFile: File|null;
    };
    handleChange: (field: string, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
    errors: { password?: string };
}

const Step3: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        prevStep,
                                        errors,
                                        nextStep,
                                        numberOfCustomers,
                                        estimateCost
                                    }) => {
    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label">Zdjęcie promocji:</p>
                    <div className="add-image">
                        <AddImage
                            label=""
                            width={1200}
                            height={800}
                            type="offer-image"
                            image={formData.imagePath}
                            setImage={(image) => handleChange('imagePath', image)}
                        />
                    </div>
                </div>
                <div className="section">
                <div className="select-wrapper">
                            <p className="label label-margin">Film promocyjny:</p>
                            <div className="input" style={{marginBottom: 16}}>
                                <input type="text"
                                       className="input-text"
                                       value={formData.filmTitle}
                                       onChange={(e) => handleChange("filmTitle", e.target.value)}
                                       placeholder="Podaj nazwę filmu promocyjnego"
                                />
                            </div>
                    <div>
                        <AddVideo
                            video={formData.filmPath}
                            setVideoUrl={(video) => handleChange("filmPath", video)}
                            setVideoFile={(file) => handleChange("filmFile", file)}
                        />
                    </div>
                </div>
                </div>
                    <div className="section">
                        <div className="offer-wrapper with-border">
                            <span className="label">Przewidywany zasięg promocji: </span>
                            <span
                                className="label"
                                style={{fontWeight: 700}}>{showCustomersNumber(numberOfCustomers)}</span>
                        </div>
                        <div className="offer-wrapper">
                            <span className="label">Łączny koszt promocji: </span>
                            <span className="label" style={{fontWeight: 700}}>{formatNumber(estimateCost)}</span>
                        </div>
                        <span
                            className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                    </div>
                    <div className="modal-footer">
                        <Button label="Wstecz"
                                onClick={prevStep}
                                type="Default"/>
                        <Button label="Dalej"
                                onClick={nextStep}
                        />
                    </div>
                </div>
            </div>
            );
            };

            export default Step3;