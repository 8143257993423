import {icons} from "../../services/images";
import {useDispatch, useSelector} from "react-redux";
import {getResponseNotification} from "../../redux/selectors/navigationSelectors";
import {useEffect} from "react";
import {setResponseNotificationAction} from "../../redux/navigation";


const NotificationPopup = () => {
    const responseNotification = useSelector(getResponseNotification);
    const dispatch = useDispatch();

    useEffect(() => {
        if (responseNotification)
            setTimeout(() => {
                dispatch(setResponseNotificationAction(null))
            }, 4000)
    }, [responseNotification, dispatch]);

    return (
        responseNotification ?
            <div className="notification-popup">
                <img src={responseNotification.status === "error" ? icons.errorIcon : icons.successIcon} width={20}
                     height={20} alt=""/>
                <span className={`message ${responseNotification.status}`}>{responseNotification.message}</span>
            </div> :
            <></>

    )
};

export default NotificationPopup;