import {Merchant} from "../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getMerchantReturns} from "../../redux/selectors/merchantSelector";
import {formatNumber} from "../../services/numbers";
import {icons} from "../../services/images";
import {modalOpenAction} from "../../redux/navigation";
import {warningBoxStorageKeys, warningBoxStorageManager} from "../../services/storage/warningBoxStorage";
import {storageActionTypes} from "../../services/storage/basicStorage";
import React, {useCallback, useEffect, useState} from "react";

interface Props {
    merchant: Merchant;
}

const getReturnsMessage = (returnsAvailable: number): JSX.Element => {
    if (returnsAvailable === 1) {
        return <>Masz <span className="number">{returnsAvailable}</span> oczekujący zwrot</>;
    } else if (returnsAvailable > 1 && returnsAvailable < 5) {
        return <>Masz <span className="number">{returnsAvailable}</span> oczekujące zwroty</>;
    } else {
        return <>Masz <span className="number">${returnsAvailable}</span> oczekujących zwrotów</>;
    }
};

const warningBarContent = (value: number, type: 'deposit' | 'returns', onClick: () => {}, onClose: React.Dispatch<React.SetStateAction<boolean>>) => {
    const closeBar = (type: 'deposit' | 'returns', value: number) => {
        const storageType = type === 'deposit' ? warningBoxStorageKeys.DEPOSIT : warningBoxStorageKeys.RETURNS;
        warningBoxStorageManager(storageActionTypes.SET, storageType, value.toString());
        onClose(true);
    }

    return (
        <div className="warning-bar">
            <div className="warning-bar-content" onClick={onClick}>
                <img src={icons.redWarningIcon} alt=""/>
                {type === 'deposit' &&
                    <span className="deposit">Twoje saldo jest niewystarczające do
                    obsługi transakcji. Wpłać środki na konto. Twoje obecne zaległości to <span
                            className="deposit-amount">{formatNumber(value)}</span></span>
                }
                {type === 'returns' && <span className="return">{getReturnsMessage(value)}</span>}
            </div>
            <img className="exit" src={icons.redExitIcon} alt="" onClick={() => closeBar(type, value)}/>
        </div>
    )
}

const WarningBar = ({merchant}: Props) => {

    const returns = useSelector(getMerchantReturns);

    interface CookieValues {
        deposit: string | null;
        returns: string | null;
    }

    const [depositClosed, setDepositClosed] = useState<boolean>(false);
    const [returnsClosed, setReturnsClosed] = useState<boolean>(false);
    const [cookieValues, setCookieValues] = useState<CookieValues>({deposit: null, returns: null});
    const [loaded, setLoaded] = useState<{ deposit: boolean, returns: boolean }>({deposit: false, returns: false});
    const [insufficientFunds, setInsufficientFunds] = useState(false);
    const [returnsAvailable, setReturnsAvailable] = useState(false);

    const dispatch = useDispatch();

    const resolvePromiseOrValue = async (value: string | Promise<string | null>): Promise<string | null> => {
        return value instanceof Promise ? await value : value;
    };

    const fetchCookieValue = useCallback(async (
        cookiePromise: string | Promise<string | null>,
        key: 'deposit' | 'returns'
    ) => {
        const result = await resolvePromiseOrValue(cookiePromise);
        setCookieValues(prev => ({...prev, [key]: result}));
        setLoaded(prev => ({...prev, [key]: true}));
    }, []);

    useEffect(() => {
        fetchCookieValue(
            warningBoxStorageManager(storageActionTypes.GET, warningBoxStorageKeys.DEPOSIT),
            'deposit'
        );
        fetchCookieValue(
            warningBoxStorageManager(storageActionTypes.GET, warningBoxStorageKeys.RETURNS),
            'returns'
        );
    }, [fetchCookieValue]);

    const processDepositAndReturns = useCallback(() => {
        const {deposit: depositCookieValue, returns: returnsCookieValue} = cookieValues;

        if (depositCookieValue) {
            const depositValue = parseFloat(depositCookieValue);
            if (merchant.deposit_requirement === 0 || merchant.deposit_requirement > depositValue) {
                warningBoxStorageManager(storageActionTypes.DELETE, warningBoxStorageKeys.DEPOSIT);
            }
            setInsufficientFunds(merchant.deposit_requirement > depositValue);
        } else {
            setInsufficientFunds(merchant.deposit_requirement > 0);
        }

        if (returnsCookieValue) {
            const returnsValue = parseInt(returnsCookieValue);
            if (returns.length === 0 || returns.length > returnsValue) {
                warningBoxStorageManager(storageActionTypes.DELETE, warningBoxStorageKeys.RETURNS);
            }
            setReturnsAvailable(returns.length > returnsValue);
        } else {
            setReturnsAvailable(returns.length > 0);
        }

    }, [cookieValues, merchant.deposit_requirement, returns.length]);

    useEffect(() => {
        if (loaded.deposit && loaded.returns) {
            processDepositAndReturns();
        }
    }, [loaded, processDepositAndReturns]);

    const allLoaded = loaded.deposit && loaded.returns;


    return (
        allLoaded ?
            <div className="warning-bar-box">
                {!depositClosed && insufficientFunds && warningBarContent(merchant.deposit_requirement, 'deposit', () => dispatch(modalOpenAction('deposit')), setDepositClosed)}
                {!returnsClosed && returnsAvailable && warningBarContent(returns.length, 'returns', () => dispatch(modalOpenAction('returns')), setReturnsClosed)}
            </div> : null
    )
}

export default WarningBar;