import Modal from "react-modal";
import React, {useEffect} from "react";
import {icons} from "../../services/images";
import {getMerchantReturnMatches, getMerchantReturns} from "../../redux/selectors/merchantSelector";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../../services/numbers";
import {
    confirmReturnHandler,
    getMerchantReturnMatchesHandler,
    getMerchantReturnsHandler
} from "../../handlers/transactionHandlers";
import {Transaction, TransactionReturn} from "../../services/interfaces";
import {categorizeByDate, dateGroups, displayConvertedDate, getDateGroupTitle} from "../../services/dates";
import Button from "../common/Button";
import {getModalOpen} from "../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../redux/navigation";

interface ReturnItemProps {
    extraClassName?: string,
    index: number,
    item: Transaction | null;
    setReturnId: React.Dispatch<React.SetStateAction<number | null>> | null;
}

interface TransactionItemProps {
    index: number,
    item: TransactionReturn | null;
    selected: boolean;
    setMatchSelected: React.Dispatch<React.SetStateAction<number | null>>;
}

const ReturnItem = ({index, item, setReturnId, extraClassName}: ReturnItemProps) => {
    if (!item) return null;

    return (
        <div
            className={`returns-item ${extraClassName || ''}`}
            key={index}
            onClick={() => setReturnId && setReturnId(item.id)}
        >
            <div className="icon">
                <img src={icons.returnsBigIcon} alt=""/>
            </div>
            <div className="content">
                <span className="name">#{item.id} {item.user.email}</span>
                <span className="amount">{formatNumber(+item.amount)}</span>
                <span className="date">{displayConvertedDate(item.trade_date)}</span>
            </div>
            {extraClassName !== "non-clickable" &&
                <div className="arrow">
                    <img src={icons.arrowRightIcon} alt=""/>
                </div>
            }
        </div>
    )
}

const TransactionItem = ({index, item, selected, setMatchSelected}: TransactionItemProps) => {
    if (!item) return null;

    return (
        <div
            className={`transaction-item ${selected ? 'selected' : ''}`}
            key={index}
            onClick={() => setMatchSelected(item.id)}
        >
            <div className="icon">
                <img src={item.logo_image === "" ? icons.returnsBigIcon : item.logo_image} alt=""/>
            </div>
            <div className="content">
                <span className="name">{item.point_name}</span>
                <span className="amount">{formatNumber(+item.amount)}</span>
                <div className="footer">
                    <span className="date">{displayConvertedDate(item.trade_date)}</span>
                    <span className="cashback">
                        <img src={icons.coinBluePrimaryIcon} alt=""/>
                        + {formatNumber(item.cashback_accrued)}</span>
                </div>
            </div>
        </div>
    )
}

const ReturnsModal = () => {

    const isOpen = useSelector(getModalOpen('returns'));
    const dispatch = useDispatch();

    const [returnId, setReturnId] = React.useState<number | null>(null);
    const [matchSelected, setMatchSelected] = React.useState<number | null>(null);
    const [successScreen, setSuccessScreen] = React.useState<boolean>(false);

    const returns = useSelector(getMerchantReturns);
    const returnMatches = useSelector(getMerchantReturnMatches);

    const onClose = () => {
        dispatch(modalCloseAction('returns'));
        setReturnId(null);
        setMatchSelected(null);
        setSuccessScreen(false);
        getMerchantReturnsHandler();
    }

    const confirmReturn = (returnId: number, matchId: number) => {
        confirmReturnHandler(returnId, matchId, () => {
            setSuccessScreen(true);
        });
    };

    useEffect(() => {
        if (returnId !== null)
            getMerchantReturnMatchesHandler(returnId);
    }, [returnId]);

    const selectedReturn: Transaction | undefined = returns.find(item => item.id === returnId);

    const categorizeReturnsByDate = categorizeByDate(returnMatches, 'trade_date');

    if (successScreen) {
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={() => onClose()}
                overlayClassName="modal-wrapper"
                className="modal-content returns-modal"
            >
                <div className="modal-body">
                    <div className="success-screen">
                        <img src={icons.successIcon} alt=""/>
                        <span className="title">Pomyślnie wskazano zwrot</span>
                        <span className="desc">Transakcja została pomyślnie oznaczona jako zwrot w systemie CRM PayProfit.</span>
                        <Button label="Kontynuuj" onClick={() => {
                            onClose();
                        }}/>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            overlayClassName="modal-wrapper"
            className="modal-content returns-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => onClose()}>
                    <img src={icons.backIcon} alt=""/>
                </div>
                <h5 className="modal-title">{returnId === null ? 'Zwroty' : 'Wskaż transakcję która została zwrócona'}</h5>
                <div/>
            </div>
            <div className="modal-body">
                {returns.length === 0 && <div className="no-returns">Brak zwrotów</div>}
                {returnId === null && returns ?
                    returns.map((item, index) => (
                        <ReturnItem
                            item={item}
                            index={index}
                            setReturnId={setReturnId}
                        />
                    )) :
                    returnMatches.length > 0 && selectedReturn ? (
                        <div className="transactions">
                            <ReturnItem
                                index={returnId || -1}
                                item={selectedReturn || null}
                                setReturnId={null}
                                extraClassName="non-clickable"
                            />
                            <div className="transaction-item-list">
                                <span>Historia transakcji</span> <span>|</span>
                                <span>#{returnId} {selectedReturn.user.email}</span>
                            </div>
                            {dateGroups.map(group => {
                                    if (categorizeReturnsByDate[group].length > 0) {
                                        return (
                                            <div className="transaction-group" key={group}>
                                        <span className="title">{getDateGroupTitle(group)}</span>
                                                {categorizeReturnsByDate[group].map((item, index) =>
                                                    <TransactionItem
                                                        item={item}
                                                        index={index}
                                                        selected={item.id === matchSelected}
                                                        setMatchSelected={setMatchSelected}
                                                    />)
                                                }
                                            </div>
                                        )
                                    }
                                }
                            )}
                            <Button
                                label="Potwierdź"
                                disabled={matchSelected === null}
                                onClick={() => confirmReturn(returnId as number, matchSelected || 0)}
                                fullWidth={true}
                                extraStyle={{position: 'sticky', bottom: 10, marginTop: '1rem'}}
                            />
                        </div>
                    ) : <div className="loader returns"/>
                }
            </div>
        </Modal>
    )
}

export default ReturnsModal;