import Modal from 'react-modal';
import {SetSettingsPageActionType} from "../../../services/interfaces";
import React from "react";
import {icons} from "../../../services/images";
import AdminSettingsPassword from "./AdminSettingsPassword";
import AdminSettingsMenu from "./AdminSettingsMenu";
import {useDispatch, useSelector} from "react-redux";
import {getModalOpen} from "../../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../../redux/navigation";


const AdminSettingsModal = () => {
    const [settingsPage, setSettingsPage] = React.useState<SetSettingsPageActionType>(null);
    const isOpen = useSelector(getModalOpen('settings'));
    const dispatch = useDispatch();
    const title = settingsPage === "password" ? "Zmień hasło" : "Ustawienia";

    const closeModal = () => {
        dispatch(modalCloseAction('settings'));
        setSettingsPage(null);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            className="modal-content"

        >
            <div className="modal-header">
                <h5 className="modal-title">{title}:</h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                {settingsPage === "password" ?
                    <AdminSettingsPassword setSettingsPage={setSettingsPage}/> :
                    <AdminSettingsMenu setSettingsPage={setSettingsPage}/>
                }
            </div>
        </Modal>
    )
};

export default AdminSettingsModal;