import React from "react";
import Modal from "react-modal";

interface Props {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    body?: string
    buttonCloseLabel: string;
    popUpCloseCallback?: () => void;
}


const PopUpModal = ({
                        show,
                        setShow,
                        title,
                        body,
                        buttonCloseLabel,
                        popUpCloseCallback,
                    }: Props) => {
    return (
        <Modal
            isOpen={show}
            onRequestClose={() => setShow(false)}
            className="modal-wrapper"
            style={{overlay: {zIndex: 1000}}}
            ariaHideApp={false}
        >
            <div className="modal-content popup">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                </div>
                <div className="modal-body">
                    <p>{body}</p>
                </div>
                <div className="modal-footer">
                    <a onClick={() => {
                        popUpCloseCallback && popUpCloseCallback();
                        setShow(false);
                    }}>{buttonCloseLabel}</a>
                </div>
            </div>
        </Modal>
    )
};


export default PopUpModal;