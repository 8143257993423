import {DOUBLE_DECIMAL_NUMBER_FORMAT, formatNumber} from "../../services/numbers";
import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";
import {ChartData, ChartInterval} from "../../services/interfaces";
import DateIntervalComposedChart from "../common/chart/DateIntervalComposedChart";

interface Props {
    income?: number
    prevIncome?: number
    incomeData: ChartData[]
    prevIncomeData?: ChartData[]
    chartInterval: ChartInterval
    barChartSelected: boolean
    setBarChartSelected: (barChartSelected: boolean) => void
    title?: string
}


const DashboardIncomeTile = ({
                                 income,
                                 prevIncome,
                                 incomeData,
                                 prevIncomeData,
                                 chartInterval,
                                 barChartSelected,
                                 setBarChartSelected,
                                 title = "Przychody z zakupów PayProfit (zł)",
                             }: Props) =>
    (
        <div className="block chart-block">
            <div className="value-section">
                <h3 className="dashboard-h3">{title}</h3>
                <div className="value-wrapper">
                    <span className="standard-value">{formatNumber(income ?? 0, DOUBLE_DECIMAL_NUMBER_FORMAT)}</span>
                    <ChangePill value={income} prevValue={prevIncome}/>
                    <TimeRangeLabel/>
                </div>
            </div>
            <DateIntervalComposedChart chartData={incomeData} prevChartData={prevIncomeData} chartType="currency"
                                       chartInterval={chartInterval} height={152}
                                       barChartSelected={barChartSelected} setBarChartSelected={setBarChartSelected}
            />
        </div>
    );

export default DashboardIncomeTile;

