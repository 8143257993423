import {icons, images} from "../../services/images";
import {useState} from "react";
import {LoginCredentials} from "../../services/interfaces";
import Input from "../common/Input";
import {emailValidation} from "../../services/validators";
import ErrorComponent from "../common/ErrorComponent";
import OnboardingSidebar from "./OnboardingSidebar";
import {useSelector} from "react-redux";
import Button from "../common/Button";
import {loginHandler} from "../../handlers/authHandlers";
import {FORGOT_PASSWORD_PAGE, SIGNUP_PAGE} from "../../services/dictionaries/paths";
import {Link} from "react-router-dom";
import {getAccountErrorStatus} from "../../redux/selectors/authSelectors";

const Login = () => {
    const [data, setData] = useState<LoginCredentials>({email: '', password: ''});
    const [sendClicked, setSendClicked] = useState<boolean>(false);
    const error = useSelector(getAccountErrorStatus);
    interface LoginErrorKey {
        emailError: string
    }

    const errorMessage: Record<keyof LoginErrorKey, string> = {
        emailError: "Niepoprawny adres e-mail",
    };

    const buttonDisabled = !!emailValidation(data.email) || data.password.length < 3;

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="PayProfit dla biznesu"
                image={images.onboardingBusiness}
                body="Skutecznie zarządzaj swoim biznesem i wstrzelaj się w aktualne trendy!"
                hint="Dowiedz się więcej"
                hintLink="/" //TODO Change to valid link
            />
            <div className="onboarding-content">
                <img src={images.payProfitCRMLogoBlack} alt="PayProfitCRM" className="brand-logo"/>
                <h2>Zaloguj się</h2>
                <ErrorComponent error={error}/>
                <form>
                    <Input<LoginCredentials, 'email'>
                        value={data}
                        onChange={setData}
                        name="email"
                        type="email"
                        icon={icons.emailIcon}
                        showError
                        placeholder="E-mail"
                        validate={emailValidation}
                        errorMessage={errorMessage}
                    />
                    <Input<LoginCredentials, 'password'>
                        value={data}
                        onChange={setData}
                        name="password"
                        type="password"
                        icon={icons.passwordIcon}
                        placeholder="Hasło"
                    />
                    <Button
                        label="Zaloguj się"
                        onClick={() => loginHandler(data, setSendClicked)}
                        loading={sendClicked}
                        disabled={buttonDisabled}
                    />
                    <p className="sign-up-hint">Nie masz jeszcze konta biznesowego?<Link to={SIGNUP_PAGE}>Zarejestruj się</Link></p>
                    <p className="sign-up-hint">Nie pamiętasz hasła?<Link to={FORGOT_PASSWORD_PAGE}>Kliknij tutaj</Link></p>
                </form>
            </div>
        </div>
    )
};

export default Login;