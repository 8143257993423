import Input from "../../common/Input";
import TextArea from "../../common/TextArea";
import Button from "../../common/Button";
import React, {useEffect, useState} from "react";
import {icons} from "../../../services/images";
import {sendMessageToAdminHandler} from "../../../handlers/chatHandlers";
import ErrorComponent from "../../common/ErrorComponent";


interface Props {
    onClose: () => void
    isOpen?: boolean
    predefinedTitle?: string;
    predefinedBody?: string
}

const ContactAdminMessage = ({onClose, isOpen, predefinedTitle, predefinedBody}: Props) => {
    const [data, setData] = useState({title: '', content: ''});
    const [successScreen, setSuccessScreen] = React.useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (predefinedTitle) {
            if (predefinedBody) {
                setData({content: predefinedBody, title: predefinedTitle});
            } else {
                setData({...data, title: predefinedTitle});
            }
        } else if (predefinedBody) {
            setData({...data, content: predefinedBody});
        }

    }, [isOpen, predefinedTitle, predefinedBody]);

    const successCallback = () => setSuccessScreen(true);
    const errorCallback = () => setError('send_message_error');

    return (
        successScreen ?
            <div className="success-screen">
                <img src={icons.successIcon} alt=""/>
                <span className="title">Pomyślnie wysłano wiadomość</span>
                <span className="desc">Kopię wiadomości oraz odpowiedź dostaniesz niebawem na swój adres e-mail</span>
                <Button label="Kontynuuj" onClick={onClose}/>
            </div> :
            <div className="admin-message">
                <Input name='title' onChange={setData} value={data} label="Temat"/>
                <TextArea name='content' onChange={setData} value={data} label="Treść wiadomości"/>
                {error && <ErrorComponent error={error}/>}
                <Button label="Wyślij wiadomość"
                        disabled={!data.title || !data.content || loading}
                        onClick={() => sendMessageToAdminHandler(data, successCallback, errorCallback, setLoading)}
                        fullWidth={true}
                        loading={loading}
                />
            </div>
    )
};

export default ContactAdminMessage