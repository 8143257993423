import React, {useState} from 'react';
import Modal from 'react-modal';
import Button from "../common/Button";
import {icons} from "../../services/images";
import {Merchant, MerchantPointSimple} from "../../services/interfaces";
import {isEqual} from "lodash";
import {importMerchantPointDataHandler} from "../../handlers/merchantHandler";
import {useNavigate} from "react-router-dom";
import {MERCHANT_POINT_ADD_PAGE} from "../../services/dictionaries/paths";
import {useDispatch} from "react-redux";
import {resetMerchantPointAction} from "../../redux/merchant";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    merchant: Merchant;
}

const ImportMerchantPointDataModal = ({isOpen, setIsOpen, merchant}: Props) => {
    const [selectedMerchantPoint, setSelectedMerchantPoint] = useState<MerchantPointSimple | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleModal = () => {
        deselect();
        setIsOpen(!isOpen);
    };

    const onCancel = () => {
        toggleModal();
    };

    const isSelected = (merchantPoint: MerchantPointSimple) => isEqual(merchantPoint, selectedMerchantPoint);

    const onSelect = (merchantPoint: MerchantPointSimple) => {
        setSelectedMerchantPoint(merchantPoint)
    };

    const deselect = () => {
        setSelectedMerchantPoint(null)
    };

    const onContinueClick = () => {
        const navigateToAddPage = () => navigate(MERCHANT_POINT_ADD_PAGE);
        if (selectedMerchantPoint)
            importMerchantPointDataHandler(selectedMerchantPoint.id, navigateToAddPage);
        else {
            dispatch(resetMerchantPointAction());
            navigateToAddPage()
        }
        toggleModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content merchant-data-modal"
        >
            <div className="modal-header">
                <h5>Importuj dane z:</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ul className="modal-list">
                    {
                        merchant.merchant_points.map((point, index) => (
                            <li className="modal-list-item"
                                onClick={() => onSelect(point)}
                                key={`MerchantPointAdress${index}`}
                            >
                                <input type="radio" checked={isSelected(point)} onChange={() => null}/>
                                <span>{point.address}</span>
                            </li>
                        ))
                    }
                    <span className="horizontal-divider"/>
                    <li className="modal-list-item" onClick={deselect}>
                        <input type="radio" checked={!selectedMerchantPoint} onChange={() => null}/>
                        <span>Nie importuj danych z innego punktu</span>
                    </li>
                </ul>
            </div>
            <div className="modal-footer">
                <Button
                    label="Anuluj"
                    onClick={onCancel}
                    type="Default"
                />
                <Button label="Dalej" onClick={onContinueClick}/>
            </div>
        </Modal>
    );
};

export default ImportMerchantPointDataModal;

