import React, {useRef, useState} from 'react';
import Modal from 'react-modal';
import Button from "../common/Button";
import {icons} from "../../services/images";
import {MerchantPoint, Terminal} from "../../services/interfaces";
import ActionModal from "../modals/ActionModal";
import {getCurrentMerchantPoint, getMerchantPointTerminals} from "../../redux/selectors/merchantSelector";
import {useSelector} from "react-redux";
import {sendRemoveTerminalRequestHandler} from "../../handlers/merchantHandler";
import PopUpModal from "../modals/PopUpModal";
import {useNavigate} from "react-router-dom";
import {CONFIGURE_TERMINAL_PAGE} from "../../services/dictionaries/paths";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const TerminalManagementModal = ({isOpen, setIsOpen}: Props) => {
    const [selectedTerminal, setSelectedTerminal] = useState<Terminal | null>(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const terminalItemRefs = useRef<(HTMLLIElement | null)[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const navigation = useNavigate();
    const terminals: Terminal[] = useSelector(getMerchantPointTerminals);
    const merchantPoint: MerchantPoint = useSelector(getCurrentMerchantPoint);

    const toggleTerminalModal = () => {
        setIsOpen(!isOpen);
        setIsActionModalOpen(false);
    };

    const toggleActionModal = (terminal: Terminal, index: number) => {
        setSelectedTerminal(terminal);
        const terminalItem = terminalItemRefs.current[index];
        if (terminalItem) {
            const {right, bottom} = terminalItem.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const deleteTerminal = () => {
        sendRemoveTerminalRequestHandler(selectedTerminal!.id, () => setShow(true));
        setIsActionModalOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleTerminalModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content terminal-modal"
        >
            <div className="modal-header">
                <h5>Zarządzaj terminalami</h5>
                <div className="modal-close" onClick={toggleTerminalModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ul className="modal-list">
                    {
                        terminals.map((terminal, index) => (
                            <li className="modal-list-item"
                                key={`Terminal${index}`}
                                ref={(ref) => (terminalItemRefs.current[index] = ref)}
                            >
                                <span>{terminal.name}</span>
                                <div className="actions-button" onClick={() => toggleActionModal(terminal, index)}>
                                    <img src={icons.actionsButtonIcon}
                                         alt="actionsButtonIcon"
                                    />
                                </div>
                            </li>
                        ))
                    }
                </ul>
                <Button label="Dodaj terminal"
                        onClick={() => navigation(CONFIGURE_TERMINAL_PAGE + `?mp=${merchantPoint.id}`)} fullWidth/>
                <p className="note blue-primary">Jeżeli w punkcie sprzedaży korzystasz z innych terminali, dodaj je
                    tutaj. Bez zweryfikowania wszystkich Twoich terminali w punkcie, nie będziemy w stanie pokazać
                    Tobie pełnych statystyk sprzedażowych sklepu.</p>
            </div>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={() => setIsActionModalOpen(false)}
                         actions={[{
                             title: 'Usuń Terminal',
                             onClick: deleteTerminal,
                             className: 'delete'
                         }]}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
            <PopUpModal show={show} setShow={setShow}
                        title="Twoja prośba o usunięcie terminala została wysłana do zespołu PayProfit."
                        buttonCloseLabel="Zamknij"
            />
        </Modal>
    );
};

export default TerminalManagementModal;

