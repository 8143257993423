import {Dispatch, SetStateAction} from "react";
import {
    createChat,
    getChatAvailableUsers,
    getChatDetails,
    getChats,
    getMessages,
    pollMessages,
    sendMessage,
    sendMessageToAdmin
} from "../http/chat";
import {
    addNewMessageAction,
    prependMessagesAction,
    setChatAvailableUsersAction,
    setChatDetailsAction,
    setChatsAction,
    updateMessagesAction
} from "../redux/chat";
import {store} from "../redux/store";
import {Chat, Message, User} from "../services/interfaces";


export const sendMessageToAdminHandler = (
    data: { title: string, content: string },
    successCallback: () => void,
    errorCallback: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>,
) => {
    setLoading(true);
    sendMessageToAdmin(data.title, data.content).then(() => {
        successCallback();
        setLoading(false);
    }).catch(error => {
        errorCallback();
        setLoading(false);
        console.log(error);
    })
};

export const getChatsHandler = () => {
    getChats().then((data: Chat[]) => {
        store.dispatch(setChatsAction(data))
    }).catch(error => {
        console.log(error)
    })
};


export const getChatDetailsHandler = (userId: number) => {
    getChatDetails(userId).then((data: { chat: Chat, messages: Message[] }) => {
        store.dispatch(setChatDetailsAction(data));
    }).catch(error => {
        console.log(error)
    })
};


export const createChatHandler = (userId: number,
                                  content: string,
                                  callback: () => void,
                                  errorCallback: () => void,
) => {
    createChat(userId, content).then((data: Chat) => {
        store.dispatch(setChatDetailsAction({chat: data, messages: data.last_message ? [data.last_message] : []}));
        callback();
    }).catch(error => {
        console.log(error);
        errorCallback();
    })
};


export const sendMessageHandler = (chatId: number,
                                   content: string,
                                   callback: () => void,
                                   errorCallback: () => void,
) => {
    sendMessage(chatId, content).then((data: Message) => {
        store.dispatch(addNewMessageAction(data));
        callback();
    }).catch(error => {
        console.log(error);
        errorCallback();
    })
};


export const getChatAvailableUsersHandler = () => {
    getChatAvailableUsers().then((data: User[]) => {
        console.log(data)
        store.dispatch(setChatAvailableUsersAction(data))
    }).catch(error => {
        console.log(error)
    })
};


export const getMoreMessagesHandler = (
    chatId: number,
    page: number,
    setLoading: Dispatch<SetStateAction<boolean>>,
    successCallback: (messages: Message[]) => void
) => {
    setLoading(true);
    getMessages(chatId, page).then((data: Message[]) => {
        store.dispatch(prependMessagesAction(data));
        successCallback(data);
        setTimeout(() => {
            setLoading(false);
        }, 300)
    }).catch(error => {
        console.log(error);
        setLoading(false);
    });
};

export const pollMessagesHandler = (chatId: number, lastMessageId: number) => {
    pollMessages(chatId, lastMessageId).then((data: { messages: Message[], last_message: Message }) => {
        store.dispatch(updateMessagesAction(data));
    }).catch(error => {
        console.log(error);
    });
};