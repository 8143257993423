import {MerchantUserDetails} from "../services/interfaces";
import {createSlice} from "@reduxjs/toolkit";


interface ManagementState {
    userList: MerchantUserDetails[];
}

const DEFAULT_STATE: ManagementState = {
    userList: [],
};

const managementSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'management',
    reducers: {
        setUserList: (state, action) => {
            return {...state, userList: action.payload}
        },
    }
});

export default managementSlice.reducer;

export const setUserList = managementSlice.actions.setUserList;