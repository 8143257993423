import ChangePill from "../common/ChangePill";
import {DOUBLE_DECIMAL_NUMBER_FORMAT, formatNumber} from "../../services/numbers";

interface Props {
    title: string;
    value?: number;
    prevValue?: number;
    highlight?: boolean;
    format?: string;
}

const StatisticsTopTile = ({title, value, prevValue, highlight=false,  format = DOUBLE_DECIMAL_NUMBER_FORMAT}: Props) => {
    return (
        <div className={`block block-1-4 ${highlight ? "highlighted-block" : ""}`} style={{position: "relative", overflow: "hidden"}} >

            <div className="value-section" >
                <span className="small-block-title" style={{color : `${highlight ? "#FFF": "#000"}`}}>{title}</span>
                <div className="value-wrapper no-timerange">
                    <span className="standard-value">{formatNumber(value, format)}</span>
                    {prevValue !== undefined && <ChangePill value={value} prevValue={prevValue}/>}
                </div>
            </div>
            {highlight &&
                    <div className="highlight-decoration">
                        <div className="box-1"></div>
                        <div className="box-2"></div>
                    </div>
                }
        </div>
    )
};

export default StatisticsTopTile;