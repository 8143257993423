import {useEffect, useState} from "react";
import {RangeBase} from "../../../services/interfaces";

interface Props {
    range: RangeBase
    ranges: RangeBase[]
    changeRange: (field: 'max' | 'min', value: number | null) => void
    index: number
    hasMinError: boolean
    hasMaxError: boolean
    maxIndex?: number
}

const RangeInput = ({range, changeRange, index, ranges, hasMinError, hasMaxError, maxIndex=4}: Props) => {

    const [tempMinValue, setTempMinValue] = useState<string>('');
    const [tempMaxValue, setTempMaxValue] = useState<string>('');
    useEffect(() => {
        setTempMinValue(range.min?.toString() || '');
        setTempMaxValue(range.max?.toString() || '');
    }, [range]);


    const handleMinBlur = () => {
        const getLowerLimit = () => {
            if (index > 0) {
                const prevRangeMax = ranges[index - 1].max;
                if (prevRangeMax !== null) {
                    return prevRangeMax + 1
                }
            }
            return 0
        };

        const getUpperLimit = () => {
            const sameRangeMax = ranges[index].max;
            if (sameRangeMax)
                return sameRangeMax;
            return null;
        };

        const changeMin = (value: number | null) => changeRange('min', value);

        const upperLimit = getUpperLimit();
        const lowerLimit = getLowerLimit();

        if (tempMinValue === '') {
            changeMin(null)
        } else {
            const numValue = Number(tempMinValue);
            if (isNaN(numValue)) {
                changeMin(lowerLimit);
                setTempMinValue(lowerLimit.toString())
            } else if (numValue < lowerLimit) {
                changeMin(lowerLimit);
                setTempMinValue(lowerLimit.toString())
            } else if (upperLimit && numValue > upperLimit) {
                changeMin(upperLimit);
                setTempMinValue(upperLimit.toString())
            } else {
                changeMin(numValue);
            }
        }
    };

    const handleMaxBlur = () => {
        const getLowerLimit = (): number => {
            const sameRangeMin = ranges[index].min;
            if (sameRangeMin)
                return sameRangeMin;
            return 1;
        };

        const getUpperLimit = (): number | null => {
            const hasNextRange = (ranges.length - 1 >= index + 1);
            if (hasNextRange) {
                const nextRangeMin = ranges[index + 1].min;
                if (nextRangeMin !== null) {
                    return nextRangeMin - 1
                }
            }
            return null
        };

        const changeMax = (value: number | null) => changeRange('max', value);

        const upperLimit = getUpperLimit();
        const lowerLimit = getLowerLimit();

        if (tempMaxValue === '') {
            changeMax(null);
        } else {
            const numValue = Number(tempMaxValue);
            if (isNaN(numValue)) {
                changeMax(lowerLimit);
                setTempMaxValue(lowerLimit.toString())
            } else if (numValue < lowerLimit) {
                changeMax(lowerLimit);
                setTempMaxValue(lowerLimit.toString())
            } else if (upperLimit && numValue > upperLimit) {
                changeMax(upperLimit);
                setTempMaxValue(upperLimit.toString())
            } else {
                changeMax(numValue);
            }
        }
    };
    return (
        <>
            <div className="input-wrapper">
                <input
                    className={`form-control ${hasMinError ? 'error' : ''}`}
                    placeholder="Od"
                    value={tempMinValue}
                    onChange={(event) => setTempMinValue(event.target.value)}
                    onBlur={handleMinBlur}
                />
            </div>
            {index < maxIndex &&
                <div className="input-wrapper">
                    <input
                        className={`form-control ${hasMaxError ? 'error' : ''}`}
                        placeholder="Do"
                        value={tempMaxValue}
                        onChange={(event) => setTempMaxValue(event.target.value)}
                        onBlur={handleMaxBlur}
                    />
                </div>
            }
        </>
    )
};

export default RangeInput