import {images} from "../../services/images";
import {useState} from "react";
import {sidebarItems} from "../../services/dictionaries/sidebarItems";
import NavigationSidebarItem from "./NavigationSidebarItem";
import MerchantPointDropdown from "./MerchantPointDropdown";
import DepositCashbackBox from "./DepositCashbackBox";
import SettingsModal from "../modals/settings/SettingsModal";
import {Merchant, MerchantUserDetails} from "../../services/interfaces";
import {useSelector} from "react-redux";
import {getUserDetails} from "../../redux/selectors/authSelectors";
import {getMerchant} from "../../redux/selectors/merchantSelector";
import ReturnsModal from "../modals/ReturnsModal";
import {HOME_PAGE} from "../../services/dictionaries/paths";
import {useNavigate} from "react-router-dom";
import ContactModal from "../modals/contact/ContactModal";

const NavigationSidebar = () => {
    const [open, setOpen] = useState(false);
    const topSidebarItems = sidebarItems.filter(item => !['contact', 'settings'].includes(item.name));
    const bottomSidebarItems = sidebarItems.filter(item => ['contact', 'settings'].includes(item.name));
    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);
    const merchant: Merchant | null = useSelector(getMerchant);
    const navigate = useNavigate();

    return (
        <nav className="sidebar">
            <div className="nav-header">
                <img src={images.payProfitCRMLogoBlack} alt="logo-black" className="logo"
                     onClick={() => navigate(HOME_PAGE)}/>
                <div className="nav-mobile" onClick={() => setOpen(!open)}>
                    <div className={`nav-toggle ${open ? 'active' : ''}`}>
                        <span/>
                    </div>
                </div>
            </div>
            <div className="nav-content">
                <div className="nav-top">
                    <MerchantPointDropdown/>
                    <ul className="nav-dropdown list-nav">
                        {topSidebarItems.map(item => <NavigationSidebarItem {...item}
                                                                            key={`SidebarMenuItem${item.name}`}
                                                                            isMerchantConfigured={!!(userDetails?.merchant && merchant?.nip)}
                        />)}
                    </ul>
                </div>
                <div className="nav-dropdown nav-bottom">
                    <DepositCashbackBox merchant={merchant}/>
                    <ul className="list-nav">
                        {bottomSidebarItems.map(item => <NavigationSidebarItem {...item}
                                                                               key={`SidebarMenuItem${item.name}`}/>)}
                    </ul>
                </div>
            </div>
            <SettingsModal/>
            <ContactModal/>
            <ReturnsModal/>
        </nav>
    )
};

export default NavigationSidebar
