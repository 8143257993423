import Switch from "../../common/Switch";
import {setStatisticsTableModeAction} from "../../../redux/statistics";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getStatisticsTableMode} from "../../../redux/selectors/statisticsSelectors";
import {Tooltip} from "react-tooltip";
import {icons} from "../../../services/images";


const TableModeSwitch = () => {
    const tableMode = useSelector(getStatisticsTableMode);
        const dispatch = useDispatch();

    return (
        <div className="table-mode-switch">
            <Tooltip id="TableModeSwitchTooltip" className="tooltip" noArrow={true}>
                <p className="tooltip-title">Tryb Ręczny</p>
                <p className="tooltip-body">
                     Jeżeli chcesz wybrać określonych użytkowników, żeby zobaczyć, jak wyglądają ich zakupy lub bezpośrednio do nich skierować promocje, przełącz widok na „tryb ręczny”. W trybie ręcznym niedostępna jest opcja filtrowania i wybierania grupy docelowej za pomocą wykresów. Żądane osoby możesz wskazać w tabeli użytkowników na dole strony
                </p>
            </Tooltip>
            <div className="label-wrapper">
                <span className="label-2">Tryb ręczny</span>
                <img src={icons.infoGrayIcon} alt="info" data-tooltip-id="TableModeSwitchTooltip"
                 data-tooltip-place="top-end"/>
            </div>
            <Switch checked={tableMode} setChecked={value => dispatch(setStatisticsTableModeAction(value))}/>
        </div>
    )
};

export default TableModeSwitch;