import {
    ActiveClientInterval,
    Aggregate, AppUserDetails,
    ChartData,
    PieChartData,
    StatisticsData,
    StatisticsPieChartKey, StatisticsTableResponse, StatisticsUserSummary
} from "../services/interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {concat, isEmpty, isEqual, without} from "lodash";

export interface StatisticsState {
    data: StatisticsData
    details: {
        current: PieChartData[] | ChartData[]
        previous: PieChartData[] | ChartData[]
    },
    aggregate: Aggregate
    filterOrder: StatisticsPieChartKey[],
    activeClientInterval: ActiveClientInterval,
    table: {
        users: StatisticsUserSummary[],
        maxPage: number,
        usersCount: number,
        pageSize: number,
        allUserIds: number[]
    },
    userDetails: AppUserDetails | null,
    tableMode: boolean,
    selectedUserIds: number[]
}

const DEFAULT_STATE: StatisticsState = {
    data: {
        current: {
            aggregate_data: [],
            aggregate_sum: 0,
            income_sum: 0,
            transactions_count: 0,
            shared_offers: 0,
            cashback_withdrawn: 0,
        },
        previous: {
            aggregate_data: [],
            aggregate_sum: 0,
            income_sum: 0,
            transactions_count: 0,
            shared_offers: 0,
            cashback_withdrawn: 0,
        },
        amount: [],
        transactions: [],
        gender: [],
        age: [],
        average_amount: [],
        new_clients: [],
        returning_clients: [],
        active_clients: []
    },
    details: {
        current: [],
        previous: [],
    },
    aggregate: "amount",
    filterOrder: [],
    activeClientInterval: "month",
    table: {
        users: [],
        maxPage: 0,
        pageSize: 0,
        usersCount: 0,
        allUserIds: []
    },
    userDetails: null,
    tableMode: false,
    selectedUserIds: []
};

const statisticsSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'statistics',
    reducers: {
        setData: (state, action: PayloadAction<StatisticsData>) => {
            return {
                ...state,
                data: action.payload
            }
        },
        setDetailsData: (state, action: PayloadAction<{
            previous: PieChartData[] | ChartData[],
            current: PieChartData[] | ChartData[]
        }>) => {
            return {
                ...state,
                details: {
                    ...state.details,
                    current: action.payload.current,
                    previous: action.payload.previous,
                }
            }
        },
        setAggregate: (state, action: PayloadAction<Aggregate>) => {
            return {
                ...state,
                aggregate: action.payload
            }
        },
        setFilterOrder: (state, action: PayloadAction<StatisticsPieChartKey[]>) => {
            return {
                ...state,
                filterOrder: action.payload
            }
        },
        setActiveClientInterval: (state, action: PayloadAction<ActiveClientInterval>) => {
            return {
                ...state,
                activeClientInterval: action.payload
            }
        },
        setTable: (state, action: PayloadAction<StatisticsTableResponse>) => {
            return {
                ...state,
                table: {
                    users: action.payload.users,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    usersCount: action.payload.users_count,
                    allUserIds: action.payload.all_user_ids
                },
                selectedUserIds: state.tableMode && isEmpty(state.selectedUserIds) ?
                    action.payload.all_user_ids : state.selectedUserIds
            }
        },
        setUserDetails: (state, action: PayloadAction<AppUserDetails | null>) => {
            return {
                ...state,
                userDetails: action.payload
            }
        },
        setTableMode: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                tableMode: action.payload,
                selectedUserIds: action.payload ? state.selectedUserIds : []
            }
        },
        selectUser: (state, action: PayloadAction<number>) => {
            const userId = action.payload;
            return {
                ...state,
                selectedUserIds: state.selectedUserIds.includes(userId) ?
                    without(state.selectedUserIds, userId) :
                    concat(state.selectedUserIds, userId)
            }
        },
        selectAllUser: (state) => {
            return {
                ...state,
                selectedUserIds: isEqual(state.selectedUserIds, state.table.allUserIds) ?
                    [] : state.table.allUserIds
            }
        },
    }
});

export default statisticsSlice.reducer

export const setStatisticsDataAction = statisticsSlice.actions.setData;
export const setStatisticsDetailsDataAction = statisticsSlice.actions.setDetailsData;
export const setStatisticsAggregateAction = statisticsSlice.actions.setAggregate;
export const setStatisticsFilterOrderAction = statisticsSlice.actions.setFilterOrder;
export const setActiveClientIntervalAction = statisticsSlice.actions.setActiveClientInterval;
export const setStatisticsTableAction = statisticsSlice.actions.setTable;
export const setStatisticsUserDetailsAction = statisticsSlice.actions.setUserDetails;
export const setStatisticsTableModeAction = statisticsSlice.actions.setTableMode;
export const selectStatisticsUserAction = statisticsSlice.actions.selectUser;
export const selectAllStatisticsUsersAction = statisticsSlice.actions.selectAllUser;
