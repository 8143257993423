import React from "react";
import {cloudFrontUrl, icons} from "../../services/images";
import {isBase64Image, isLogo} from "../../services/imageUtils";
import {ServiceType} from "../../services/interfaces";

interface Props {
    logo: string;
    backgroundImage: string;
    backgroundColor: number | null;
    address?: string;
    addressColor: number;
    serviceTypes: ServiceType[];
}

export const Card = ({logo, backgroundImage, backgroundColor, address, addressColor, serviceTypes}: Props) => {
    return (
        <div className={`card color-${backgroundColor ? backgroundColor : ''}`}>
            <span className="card-overlay"/>
            <div className="top-card"
                 style={{backgroundImage: !backgroundColor ? `url(${isBase64Image(backgroundImage) ? backgroundImage : cloudFrontUrl + backgroundImage})` : ''}}>
                <span className="card-vertical-overlay"/>
                <div className="logo" style={{
                    backgroundImage: `url(${isLogo(logo, serviceTypes)})`,
                }}/>
                {address &&
                    <span className={`address color-${addressColor}`}>
                        {address.split(', ').slice(0, -1).join(', ')}
                    </span>
                }
            </div>
            <div className="bottom-card">
                <div className="card-label-container">
                    <p>ID: 1234 9847</p>
                    <p>Jan Kowalski</p>
                </div>
                <img src={icons.brandIcon} alt=""/>
            </div>
        </div>
    )
};

export default Card;