import {MERCHANT_POINT_EDIT_PAGE} from "../../services/dictionaries/paths";
import Card from "../common/Card";
import {MerchantPoint, MerchantPointSimple} from "../../services/interfaces";
import {useNavigate} from "react-router-dom";

interface Props {
    merchantPoint: MerchantPoint;
    merchantPoints: MerchantPointSimple[];
}

const DashboardCardTile = ({merchantPoint, merchantPoints}: Props) => {
    const navigate = useNavigate();
    return (
        <div className="card-block">
            <div className="card-header-wrapper">
                <h3>Karta Twojego sklepu</h3>
                <span className="link link-button"
                      onClick={() => merchantPoint.id && navigate(MERCHANT_POINT_EDIT_PAGE(merchantPoint.id))}
                >
                    Edytuj
                </span>
            </div>
            <Card logo={merchantPoint.logo_image} backgroundImage={merchantPoint.background_image}
                  backgroundColor={merchantPoint.background_color} addressColor={merchantPoint.address_color}
                  address={merchantPoints.length > 1 ? merchantPoint.address : undefined}
                  serviceTypes={merchantPoint.service_types}
            />
        </div>
    )
};

export default DashboardCardTile;

