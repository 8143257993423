import {icons, images} from "../../services/images";
import {useState} from "react";
import {LoginCredentials} from "../../services/interfaces";
import Input from "../../components/common/Input";
import {emailValidation} from "../../services/validators";
import ErrorComponent from "../../components/common/ErrorComponent";
import OnboardingSidebar from "../../components/auth/OnboardingSidebar";
import {useSelector} from "react-redux";
import Button from "../../components/common/Button";
import {loginHandler} from "../../handlers/authHandlers";
import {FORGOT_PASSWORD_PAGE} from "../../services/dictionaries/paths";
import {Link} from "react-router-dom";
import {getAccountErrorStatus} from "../../redux/selectors/authSelectors";

const AdminLogin = () => {
    const [data, setData] = useState<LoginCredentials>({email: '', password: ''});
    const [sendClicked, setSendClicked] = useState<boolean>(false);
    const error = useSelector(getAccountErrorStatus);
    interface LoginErrorKey {
        emailError: string
    }

    const errorMessage: Record<keyof LoginErrorKey, string> = {
        emailError: "Niepoprawny adres e-mail",
    };

    const buttonDisabled = !!emailValidation(data.email) || data.password.length < 3;

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="PayProfit Panel Administracyjny"
                image={images.onboardingBusiness}
                body=""
                imgSrc={images.payProfitAdminLogoWhite}
            />
            <div className="onboarding-content">
                <img src={images.payProfitAdminLogoBlack} alt="PayProfitAdmin" className="brand-logo"/>
                <h2>Zaloguj się</h2>
                <ErrorComponent error={error}/>
                <form>
                    <Input<LoginCredentials, 'email'>
                        value={data}
                        onChange={setData}
                        name="email"
                        type="email"
                        icon={icons.emailIcon}
                        showError
                        placeholder="E-mail"
                        validate={emailValidation}
                        errorMessage={errorMessage}
                    />
                    <Input<LoginCredentials, 'password'>
                        value={data}
                        onChange={setData}
                        name="password"
                        type="password"
                        icon={icons.passwordIcon}
                        placeholder="Hasło"
                    />
                    <Button
                        label="Zaloguj się"
                        onClick={() => loginHandler(data, setSendClicked, true)}
                        loading={sendClicked}
                        disabled={buttonDisabled}
                    />
                    <p className="sign-up-hint">Nie pamiętasz hasła?<Link to={`${FORGOT_PASSWORD_PAGE}?admin=true`}>Kliknij tutaj</Link></p>
                </form>
            </div>
        </div>
    )
};

export default AdminLogin;