import {InfoWindow, useMap} from '@vis.gl/react-google-maps';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {type Marker, MarkerClusterer} from '@googlemaps/markerclusterer';
import {BaseMapItem, ClusteredItemMarkersProps} from "./types";


const ClusteredItemMarkers = <T extends BaseMapItem>({
                                                         items,
                                                         MarkerComponent,
                                                         InfoWindowContent
                                                     }: ClusteredItemMarkersProps<T>) => {
    const [markers, setMarkers] = useState<{ [key: number]: Marker }>({});
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined);

    const selectedItem = useMemo(
        () =>
            items && selectedItemId
                ? items.find(item => item.id === selectedItemId)!
                : null,
        [items, selectedItemId]
    );

    const map = useMap();
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({map});
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    // this callback will effectively get passsed as ref to the markers to keep
    // tracks of markers currently on the map
    const setMarkerRef = useCallback((marker: Marker | null, key: number) => {
        setMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key]))
                return markers;

            if (marker) {
                return {...markers, [key]: marker};
            } else {
                const {[key]: _, ...newMarkers} = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setSelectedItemId(undefined);
    }, []);

    const handleMarkerClick = useCallback((item: T) => {
        setSelectedItemId(item.id);
    }, []);

    return (
        <>
            {items.map(item => (
                <MarkerComponent
                    key={item.id}
                    item={item}
                    onClick={handleMarkerClick}
                    setMarkerRef={setMarkerRef}
                />
            ))}

            {selectedItemId && selectedItem && (
                <InfoWindow
                    anchor={markers[selectedItemId]}
                    onCloseClick={handleInfoWindowClose}
                    minWidth={400}
                >
                    <InfoWindowContent {...selectedItem}/>
                </InfoWindow>
            )}
        </>
    );
};

export default ClusteredItemMarkers