import AdminDashboardMerchantPointOverview from "./AdminDashboardMerchantPointOverview";
import {icons, images} from "../../../../services/images";
import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import React from "react";
import {InfoWindowProps} from "../../common/map/types";
import {AdminDashboardMerchantPoint} from "../../../services/interfaces";


const AdminDashboardMapInfoWindow: React.FC<InfoWindowProps<AdminDashboardMerchantPoint>> = (merchantPoint) => {

    return (
        <div className="map-info-window">
            <AdminDashboardMerchantPointOverview {...merchantPoint}/>
            <div className="map-info-window-details">
                <div>
                    <img src={icons.coinBluePrimaryIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cashback_amount)}</span>
                </div>
                <div>
                    <div className="icon-container">
                        <img src={images.payProfitTransparent} alt=""/>
                    </div>
                    <span>{formatNumber(merchantPoint.payprofit_fee)}</span>
                </div>
                <div>
                    <img src={icons.cupUsedIcon} alt=""/>
                    <span>{formatNumber(merchantPoint.cup_action_count, WHOLE_NUMBER_FORMAT)} <span
                        className="description">Akcji</span></span>
                </div>
            </div>
        </div>
    )

};

export default AdminDashboardMapInfoWindow;