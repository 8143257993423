import React from "react";
import Modal from "react-modal";
import Button from "../common/Button";
import {icons} from "../../services/images";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    onConfirm: () => void;
    type?: 'warning' | 'question'
    body?: string
    confirmLabel?: string;
    onCancel?: () => void;
}

const ConfirmChangeModal = ({
                                isOpen,
                                setIsOpen,
                                title,
                                onConfirm,
                                type = 'warning',
                                body,
                                onCancel,
                                confirmLabel
                            }: Props) => {
    const toggleModal = () => {
        setIsOpen(false);
        onCancel && onCancel();
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content popup confirm-change-modal"
            ariaHideApp={false}
        >
            <div className="modal-body">
                {type === "warning" &&
                    <img src={icons.redWarningIcon} alt=""/>
                }
                {type === "question" &&
                    <img src={icons.questionMarkIcon} alt=""/>
                }
                <p className="title">{title}</p>
                {body && <p className="body">{body}</p>}
            </div>
            <div className="modal-footer">
                <Button label="Anuluj"
                        onClick={toggleModal}
                        type="Default"
                />
                <Button label={`${confirmLabel ?? 'Zatwierdź'}`}
                        onClick={() => {
                            onConfirm();
                            setIsOpen(false);
                        }}
                        type={type === 'warning' ? 'Red' : 'Primary'}
                />
            </div>
        </Modal>
    )
};


export default ConfirmChangeModal;