import {
    CategoryType,
    CreateCategoryItem,
    ImportCategoriesData,
    OrderTableResponse,
    ReservationTableResponse,
    UpdateCategoryItem
} from "../services/interfaces";
import request, {DELETE, GET, POST, PUT} from "./request";
import {handleError} from "./handleError";


export const getReservationTable = async (merchantPointId: number, urlParams: string): Promise<ReservationTableResponse> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/reservation/?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadReservations = async (merchantPointId: number, urlParams: string) => {
    try {
        return await request(GET, `booking/${merchantPointId}/reservation/download/?${urlParams}`)
    } catch (error) {
        throw handleError(error);
    }
};


export const getReservationFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/reservation/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getOrderTable = async (merchantPointId: number, urlParams: string): Promise<OrderTableResponse> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/order/?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadOrders = async (merchantPointId: number, urlParams: string) => {
    try {
        return await request(GET, `booking/${merchantPointId}/order/download/?${urlParams}`)
    } catch (error) {
        throw handleError(error);
    }
};


export const getOrderFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/order/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getCategories = async (merchantPointId: number, categoryType: CategoryType) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/${categoryType}/category/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const createCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    name: string
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/category/`, {name});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    name: string,
    categoryId: number
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/category/${categoryId}`, {name});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    categoryId: number
) => {
    try {
        const {data} = await request(DELETE, `booking/${merchantPointId}/${categoryType}/category/${categoryId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const moveCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    categoryId: number,
    direction: "up" | "down",
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/category/${categoryId}/move/`, {direction});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getCategoryOptions = async (merchantPointId: number, categoryType: CategoryType) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/${categoryType}/category/options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const importCategories = async (merchantPointId: number, categoryType: CategoryType, importData: ImportCategoriesData) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/import_categories/`, {...importData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const createCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    createData: CreateCategoryItem
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/`, {...createData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    updateData: UpdateCategoryItem,
    itemId: number,
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/${itemId}`, {...updateData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const deleteCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    itemId: number,
) => {
    try {
        const {data} = await request(DELETE, `booking/${merchantPointId}/${categoryType}/${itemId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadCategories = async (
    merchantPointId: number,
    categoryType: CategoryType
) => {
    try {
        return await request(GET, `booking/${merchantPointId}/${categoryType}/download/`)
    } catch (error) {
        throw handleError(error);
    }
};

export const importCategoryExcel = async (
    merchantPointId: number,
    categoryType: CategoryType,
    file: string
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/import/`, {file});
        return data
    } catch (error) {
        throw handleError(error);
    }
};
