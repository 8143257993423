import {Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {ChartData, ChartInterval, PreviousPeriod} from "../../../services/interfaces";
import ChartCustomTooltip from "../ChartCustomTooltip";
import {AXIS_COLOR, CURRENT_COLOR, getPrevDataKey, prepareChartData, PREV_COLOR} from "../../../services/chartUtils";
import {useMemo} from "react";

interface Props {
    chartData: ChartData[]
    prevChartData?: ChartData[]
    interval: ChartInterval
    chartType: 'currency' | 'absolute' | 'percent'
    previousPeriod?: PreviousPeriod
    valueKey?: 'value' | 'change' | 'average'
    height?: number
    tooltipFormat?: (value: number) => string;
}


const DateIntervalBarChart = ({
                                  chartData,
                                  prevChartData,
                                  interval,
                                  chartType,
                                  previousPeriod,
                                  valueKey = 'value',
                                  height = 230,
                                  tooltipFormat,
                              }: Props) => {

    const data = prepareChartData({chartData, prevChartData, interval, previousPeriod});
    const barRadius: [number, number, number, number] = [4, 4, 0, 0];
    const prevDataKey = useMemo(() =>
        getPrevDataKey(valueKey, prevChartData), [valueKey, prevChartData]);

    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart
                data={data}
                margin={{left: 0, top: 0, bottom: height < 230 ? -10 : 0, right: 0}}
            >
                <XAxis dataKey="name" fontSize="12" stroke={AXIS_COLOR} tickMargin={5}/>
                {valueKey === "change" &&
                    <ReferenceLine y={0} stroke="#ccc"/>
                }
                <YAxis fontSize="12" stroke={AXIS_COLOR} padding={{top: 5}} tickMargin={5}
                       allowDecimals={chartType !== 'absolute'}/>
                {prevDataKey &&
                    <Bar type="monotone"
                         dataKey={prevDataKey}
                         fill={PREV_COLOR}
                         radius={barRadius}/>
                }
                <Bar type="monotone"
                     dataKey={valueKey}
                     fill={CURRENT_COLOR}
                     radius={barRadius}/>
                <Tooltip content={<ChartCustomTooltip chartType={chartType} tooltipFormat={tooltipFormat}/>}
                         cursor={{opacity: 0.5}}/>
            </BarChart>
        </ResponsiveContainer>
    )
};

export default DateIntervalBarChart;