import React, {useEffect} from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {getOrderDetails} from "../../../redux/selectors/bookingSelector";
import {setOrderDetailsAction} from "../../../redux/booking";


interface Props {
    orderId: number | null,
    onClose: () => void;
}

const OrderDetailsModal = ({onClose, orderId}: Props) => {
    const dispatch = useDispatch();

    const order = useSelector(getOrderDetails);

    useEffect(() => {
        if (orderId !== null)
            console.log(orderId) // TODO Add getDetailsHandler
    }, [orderId]);

    const closeModal = () => {
        onClose();
        dispatch(setOrderDetailsAction(null)
        )
    };

    return (
        <Modal
            isOpen={!!orderId}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content booking-details-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">
                    Szczegóły zamówienia <span className="blue-primary">#{order ? order.id : ""}</span>
                </h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {order ?
                <div className="modal-body">
                </div> :
                <LoadingScreen/>
            }
        </Modal>
    )
};

export default OrderDetailsModal;