import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Chat, Message, SetContactPageActionType, User} from "../services/interfaces";

interface ChatState {
    chats: Chat[],
    messages: Message[],
    currentChat: Chat | null,
    users: User[],
    contactPage: SetContactPageActionType,
}

const DEFAULT_STATE: ChatState = {
    chats: [],
    messages: [],
    currentChat: null,
    users: [],
    contactPage: null,
};


const chatSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'chat',
    reducers: {
        setChats: (state, action: PayloadAction<Chat[]>) => {
            return {...state, chats: action.payload}
        },
        resetChat: (state) => {
            return {
                ...state,
                currentChat: DEFAULT_STATE.currentChat,
                messages: DEFAULT_STATE.messages
            }
        },
        setChatDetails: (state, action: PayloadAction<{ chat: Chat, messages: Message[] }>) => {
            return {
                ...state,
                currentChat: action.payload.chat,
                messages: action.payload.messages
            }
        },
        addNewMessage: (state, action: PayloadAction<Message>) => {
            let newMessages = [...state.messages];
            newMessages.push(action.payload);
            return {
                ...state, messages: newMessages
            }
        },
        setUsers: (state, action: PayloadAction<User[]>) => {
            return {...state, users: action.payload}
        },

        prependMessages: (state, action: PayloadAction<Message[]>) => {
            return {
                ...state,
                messages: [...action.payload, ...state.messages]
            };
        },
        updateMessages: (state, action: PayloadAction<{ messages: Message[], last_message: Message }>) => {
            const existingMessageIds = new Set(state.messages.map(m => m.id));
            const newMessages = action.payload.messages.filter(message => !existingMessageIds.has(message.id));

            if (newMessages.length > 0 && state.currentChat) {
                return {
                    ...state,
                    currentChat: {
                        ...state.currentChat,
                        last_message: action.payload.last_message
                    },
                    messages: [...state.messages, ...newMessages]
                };
            }
            return state;
        },
        setContactPage: (state, action: PayloadAction<SetContactPageActionType>) => {
            return {...state, contactPage: action.payload}
        },
    }
});

export default chatSlice.reducer;


export const setChatsAction = chatSlice.actions.setChats;
export const addNewMessageAction = chatSlice.actions.addNewMessage;
export const setChatDetailsAction = chatSlice.actions.setChatDetails;
export const setChatAvailableUsersAction = chatSlice.actions.setUsers;
export const prependMessagesAction = chatSlice.actions.prependMessages;
export const updateMessagesAction = chatSlice.actions.updateMessages;
export const setContactPageAction = chatSlice.actions.setContactPage;
export const resetChatAction = chatSlice.actions.resetChat;
