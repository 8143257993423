import {Transaction} from "../../../services/interfaces";
import {GenderEnum, TransactionStatus} from "../../../services/dictionaries/enums";
import {formatNumber} from "../../../services/numbers";
import {parseQueryString} from "../../../services/utils";


const HistoryTableRow = ({transaction, filters}: { transaction: Transaction, filters: string }) => {

    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    const formattedDate = new Date(transaction.trade_date).toLocaleDateString('pl-PL', dateOptions);

    let transactionStatus = 'Nieznany';
    if (transaction.status in TransactionStatus) {
        if (transaction.parent_id && transaction.status === 'receivables') {
            transactionStatus = 'W toku - Korekta';
        } else if (transaction.parent_id && transaction.status === 'rejected') {
            transactionStatus = 'Odrzucone - Korekta';
        } else {
            transactionStatus = TransactionStatus[transaction.status];
        }
    }

    let gender = 'Inna';
    if (transaction.user && transaction.user.gender in GenderEnum) {
        gender = GenderEnum[transaction.user.gender];
    }

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];
    const returnParent = transaction.parent_id ?
        '(Transakcja #' + transaction.parent_id + ')' :
        '';

    return (
        <tr>
            <td className="transaction-id-cell">#{transaction.id}</td>
            {!excludedColumns.includes('client_id') &&
                <td className="client-id-cell">{transaction.user ? transaction.user.id : '--'}</td>}
            {!excludedColumns.includes('trade_date') && <td>{formattedDate}</td>}
            {!excludedColumns.includes('name_address_value_1') &&
                <td>{transaction.name_address_value_1}</td>}
            {!excludedColumns.includes('status') &&
                <td className={`cashback-status-cell ${transaction.status === 'rejected' ? 'red' : (transaction.status === 'wallet' ? 'blue' : (transaction.status === 'receivables' ? 'grey' : (transaction.status === 'refund' ? '' : 'green')))}`}>
                    {transactionStatus} {returnParent}
                </td>
            }
            {!excludedColumns.includes('amount') &&
                <td className="cell-digit">{formatNumber(transaction.amount)}</td>}
            {!excludedColumns.includes('cashback_accrued') &&
                <td className="cell-digit">{formatNumber(transaction.cashback_accrued)}</td>}
            {!excludedColumns.includes('total_fees') &&
                <td className="cell-digit">{formatNumber(transaction.total_fees)}</td>}
            {!excludedColumns.includes('total') && <td className="cell-digit">{formatNumber(transaction.total)}</td>}
            {!excludedColumns.includes('gender') && <td className="cell-digit">{gender}</td>}
            {!excludedColumns.includes('age') &&
                <td className="cell-digit">{transaction.user && transaction.user.age !== 0 ? transaction.user.age : '--'}</td>}
        </tr>
    )
};

export default HistoryTableRow;