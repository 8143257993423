import React from "react";

export type ButtonFilterValue = string;

export interface ButtonFilterOptions<T extends ButtonFilterValue> {
    value: T
    icon: string
    color: string
    label: string
}

// Make StatusState generic and require all keys to be of type T
export type ButtonFilterState<T extends ButtonFilterValue> = Record<T, boolean>;

interface Props<T extends ButtonFilterValue> {
    options: ButtonFilterOptions<T>[]
    selectedOption: ButtonFilterState<T>
    setSelectedOption: React.Dispatch<React.SetStateAction<ButtonFilterState<T>>>
    title?: string
    selectAllText?: string
    deselectAllText?: string
}

const ButtonFilters = <T extends ButtonFilterValue>({
    options,
    selectedOption,
    setSelectedOption,
    title = "Status",
    selectAllText = "Zaznacz wszystko",
    deselectAllText = "Odznacz wszystko"
}: Props<T>) => {
    const areAllStatusActive = Object.values(selectedOption).every((value) => value);

    const toggleStatus = (statusValue: T) => {
        setSelectedOption((prevState) => ({
            ...prevState,
            [statusValue]: !prevState[statusValue],
        }));
    };

    const toggleAll = () => {
        setSelectedOption(() => {
            const newState: ButtonFilterState<T> = {} as ButtonFilterState<T>;
            options.forEach(s => {
                newState[s.value] = !areAllStatusActive;
            });
            return newState;
        });
    };

    return (
        <div className="section">
            <div className="header">
                <h6>{title}</h6>
                <span onClick={toggleAll}>
                    {areAllStatusActive ? deselectAllText : selectAllText}
                </span>
            </div>
            <div className="status">
                {options.map((option, index) =>
                    <button
                        onClick={() => toggleStatus(option.value)}
                        className={`${selectedOption[option.value] && 'active'}`}
                        key={`Status${index}`}
                    >
                        <img src={option.icon} alt="icon"/>
                        <span style={{color: option.color}}>{option.label}</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default ButtonFilters;