import OnboardingSidebar from "./OnboardingSidebar";
import {icons, images} from "../../services/images";
import {PhoneNumberVerify, MerchantUserDetails} from "../../services/interfaces";
import Button from "../common/Button";
import React, {useEffect, useState} from "react";
import {
    getUserDetailsHandler,
    resendVerificationCodeHandler,
    sendVerificationCodeHandler
} from "../../handlers/authHandlers";
import {getAccountErrorStatus, getUserDetails} from "../../redux/selectors/authSelectors";
import {useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import {CONFIGURE_TERMINAL_PAGE} from "../../services/dictionaries/paths";
import getErrorMessage from "../../services/dictionaries/errorDict";
import LoadingScreen from "../common/LoadingScreen";
import Input from "../common/Input";


const VerifyPhoneNumber = () => {
    const [data, setData] = useState<PhoneNumberVerify>({code: null});
    const [sendCodeClicked, setSendCodeClicked] = useState<boolean>(false);
    const [codeExpired, setCodeExpired] = useState<boolean>(false);
    const [resendMessage, setResendMessage] = useState<boolean>(false);
    const navigate = useNavigate();

    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);
    const authError = useSelector(getAccountErrorStatus);

    useEffect(() => {
        if (authError === "verification_token_expired") {
            setCodeExpired(true);
        }
    }, [authError]);
    useEffect(() => {
        !userDetails && getUserDetailsHandler();
    }, [userDetails]);

    if (!userDetails) return <LoadingScreen/>;

    if (userDetails && userDetails.verification_step !== 'phone') {
        return <Navigate to={CONFIGURE_TERMINAL_PAGE}/>
    }

    const afterSendCallback = ({success}: { success: boolean }) => {
        if (success) {
            getUserDetailsHandler();
            navigate(CONFIGURE_TERMINAL_PAGE);
        }
        setSendCodeClicked(false);
    };

    const resendCodeCallback = () => {
        setSendCodeClicked(false);
        setResendMessage(true);
    };

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="Kod weryfikacyjny"
                image={images.onboardingTelephone}
                body="Ze względów bezpieczeństwa, aby zweryfikować swoje konto musisz wpisać poniższy kod. Kod będzie działał tylko przez 15 minut i jeśli poprosisz o nowy kod, ten przestanie działać."
            />
            <div className="onboarding-content verify-telephone">
                {!codeExpired ? <>
                        <h2>Wpisz kod weryfikacyjny</h2>
                        <p className="note">Wpisz kod weryfikacyjny, który otrzymałeś na swój numer telefonu.</p>
                        <form>
                            <Input<PhoneNumberVerify, 'code'>
                                value={data}
                                onChange={setData}
                                name="code"
                                type="number"
                                icon={icons.penIcon}
                                placeholder="Tutaj wpisz kod weryfikacyjny"
                            />
                            <Button
                                label={authError ? "Spróbuj ponownie" : "Wprowadź kod"}
                                onClick={() => {
                                    setSendCodeClicked(true);
                                    if (data.code)
                                        sendVerificationCodeHandler(data.code, afterSendCallback);
                                }} //TODO Add send verification code handler
                                loading={sendCodeClicked}
                                disabled={!data.code}
                            />
                            {authError && <p className="error-message">{getErrorMessage(authError)}</p>}
                            <p className="send-again-hint">Nie dostałeś wiadomości z kodem weryfikacyjnym?</p>
                            <span className=" link"
                                  onClick={() => resendVerificationCodeHandler(resendCodeCallback)}>Wyślij kod jeszcze raz</span>
                            {resendMessage && <p className="send-again-hint">Kod został wysłany ponownie</p>}
                        </form>
                    </> :
                    <>
                        <h2>Kod weryfikacyjny wygasł</h2>
                        <span className=" link"
                              onClick={() => resendVerificationCodeHandler(resendCodeCallback)}>Wyślij kod jeszcze raz</span>
                    </>
                }
            </div>
        </div>
    )
};

export default VerifyPhoneNumber;
