import MerchantPointForm from "../components/merchant_point/MerchantPointForm";
import {useEffect} from "react";
import {getMerchantPointHandler} from "../handlers/merchantHandler";
import {useParams} from "react-router-dom";
import {MerchantUserDetails} from "../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../redux/selectors/authSelectors";
import {resetMerchantPointAction, setIsMerchantSelectedAction} from "../redux/merchant";
import {getCurrentMerchantPoint} from "../redux/selectors/merchantSelector";


interface Props {
    isMerchantPointAdded?: boolean;
}

const MerchantPointContainer = ({isMerchantPointAdded = false}: Props) => {
    const {merchantPointId} = useParams();
    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);
    const dispatch = useDispatch();
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    useEffect(() => {
        const formattedMerchantPointId = merchantPointId ? Number(merchantPointId) : null;
        formattedMerchantPointId && getMerchantPointHandler(formattedMerchantPointId);
    }, [userDetails, merchantPointId]);

    useEffect(() => {
        if (isMerchantPointAdded) {
            dispatch(setIsMerchantSelectedAction(true));
            merchantPoint.id && dispatch(resetMerchantPointAction())
        }
    }, [merchantPoint, isMerchantPointAdded, dispatch]);

    return <MerchantPointForm isMerchantPointAdded={isMerchantPointAdded}/>
};

export default MerchantPointContainer;