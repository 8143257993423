import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {CupAction, CupActionFilterOptions, CupDashboardData} from "../../services/interfaces";


export const getCupState = (state: RootState) => state.cup;


export const getCupDashboardData: Selector<RootState, CupDashboardData> = createSelector(
    getCupState,
    (cupState) => cupState.dashboard
);

export const getCupTableData: Selector<RootState, CupAction[]> = createSelector(
    getCupState,
    (cupState) => {
        return cupState.table.cupActions
    }
);

export const getCupActionFilters: Selector<RootState, CupActionFilterOptions | null> = createSelector(
    getCupState,
    (cupState) => cupState.filterOptions
);

export const getCupTableMaxPage: Selector<RootState, number> = createSelector(
    getCupState,
    (cupState) => cupState.table.maxPage
);

export const getCupTableCount: Selector<RootState, number> = createSelector(
    getCupState,
    (cupState) => cupState.table.cupActionsCount
);