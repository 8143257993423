const errorDict: { [key: string]: string } = {
    "already_exists": "Konto z podanym adresem e-mail już istnieje",
    "could_not_validate_credentials": "Login lub hasło są nieprawidłowe",
    "email_already_verified": "Email już został zweryfikowany",
    "email_error": "Adres e-mail jest nieprawidłowy",
    "email_not_verified": "Email nie został jeszcze weryfikowany",
    "expired_token": "Przedawniony kod weryfikacyjny",
    "incorrect_token": "Nieprawidłowy kod weryfikacyjny",
    "invalid_payment_status": "Nieprawidłowy status płatności",
    "invalid_session_id": "Nieprawidłowy identyfikator sesji",
    "invalid_user": "Nieprawidłowy użytkownik",
    "password_error": "Hasło musi być dłuższe niż 10 znaków",
    "password_incorrect": "Stare hasło jest nieprawidłowe",
    "password_same": "Nowe hasło musi być inne niż stare",
    "reset_password_expired_token": "Kod resetujący hasło jest nieaktywny lub przedawniony",
    "unknown_error": "Wystąpił nieznany błąd",
    "user_not_exist": "Użytkownik nie istnieje",
    "verification": "Wysłano email weryfikacyjny",
    "verification_token_expired": "Kod weryfikacyjny wygasł",
    "invalid_verification_step": "Nieprawidłowy krok weryfikacji",
    "invalid_verification_token": "Nieprawidłowy kod weryfikacyjny",
    "nip_not_found": "Nie znaleziono podanego numeru",
    "send_message_error": "Nie udało się wysłać wiadomości",
    "category_already_exists": "Kategoria z podaną nazwą już istnieje",
    "product_already_exists": "Produkt z podaną nazwą już istnieje",
    "service_already_exists": "Serwis z podaną nazwą już istnieje",
};

const getErrorMessage = (error: string): string => {
    if (errorDict[error]) {
        return errorDict[error];
    } else {
        return error;
    }
};

export default getErrorMessage;