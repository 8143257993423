import Button from "../common/Button";
import DepositModal from "../modals/deposit/DepositModal";
import {useState} from "react";
import {icons, sidebarIcons} from "../../services/images";
import HistoryDepositModal from "../modals/deposit/HistoryDepositModal";
import {useDispatch} from "react-redux";
import {modalOpenAction} from "../../redux/navigation";
import {formatNumber} from "../../services/numbers";
import {Merchant} from "../../services/interfaces";
import {Tooltip} from 'react-tooltip';

interface Props {
    merchant: Merchant | null
}

const DepositCashbackBox = ({merchant}: Props) => {
    const isDisabled = false;
    const [historyDepositModalOpen, setHistoryDepositModalOpen] = useState<boolean>(false);

    const [isGearRotating, setIsGearRotating] = useState<boolean>(false);

    const deposit = merchant ? merchant.deposit : null;
    const blockedFunds = merchant ? merchant.blocked_funds : null;
    const depositRequirement = merchant ? merchant.deposit_requirement : null;

    const dispatch = useDispatch();

    return (
        <>
            <div className={`nav-card ${isDisabled ? 'disabled' : ''}`}>
                <div>
                    <p>Depozyt cashback</p>
                    <img
                        src={sidebarIcons.settings}
                        alt="history"
                        className={`settings-icon ${isGearRotating ? 'rotating' : ''}`}
                        onClick={() => {
                            setHistoryDepositModalOpen(true);
                            setIsGearRotating(true);
                        }}
                    />
                </div>
                <div style={{alignItems: 'baseline'}}>
                    <h2>{formatNumber(deposit)}</h2>
                    {depositRequirement && depositRequirement > 0 ?
                        <img
                            data-tooltip-id="deposit-requirement"
                            style={{marginLeft: '0.5rem'}} src={icons.redWarningIcon} alt=""/> : <div/>}
                </div>
                <Button
                    onClick={() => dispatch(modalOpenAction('deposit'))}
                    disabled={isDisabled}
                    label="Zasil konto"
                />
            </div>
            <DepositModal merchant={merchant}/>
            <HistoryDepositModal
                deposit={deposit}
                blockedFunds={blockedFunds}
                isOpen={historyDepositModalOpen}
                setIsOpen={setHistoryDepositModalOpen}
                setIsLoaded={setIsGearRotating}
            />
            <Tooltip
                id="deposit-requirement"
                place="bottom"
                className="tooltip"
            >
                <div className="deposit-requirement-tooltip">
                    <span className="title">Nieuregulowane zobowiązania</span>
                    <span className="amount">{formatNumber(depositRequirement)}</span>
                    <span className="desc">Zasil jak najszybciej swoje konto aby klienci otrzymali swój cashback.</span>
                </div>
            </Tooltip>
        </>
    )
};

export default DepositCashbackBox;