import React from "react";
import {OrderStatus} from "../../../services/interfaces";
import {DEFAULT_ORDER_STATUS_STATE, OrderStatusState} from "./OrderFiltersModal";


interface Props {
    status: OrderStatusState
    setStatus: React.Dispatch<React.SetStateAction<OrderStatusState>>
}


const OrderStatusSelect = ({status, setStatus}: Props) => {

    const areAllStatusActive = Object.values(status).every((value) => value);

    const orderStatuses: { label: string, value: OrderStatus }[] = [
        {label: 'Złożone', value: 'placed'},
        {label: 'Opłacone', value: 'paid'},
        {label: 'Anulowane', value: 'canceled'},
        {label: 'Odebrane', value: 'completed'},
        {label: 'Gotowe', value: 'ready'},
    ];

    const toggleStatus = (status: OrderStatus) => {
        setStatus((prevState: OrderStatusState) => ({
            ...prevState,
            [status]: !prevState[status],
        }));
    };

    return (
        <div className="section">
            <div className="header">
                <h6>Status cashbacku</h6>
                <span onClick={() =>
                    setStatus(() => {
                            if (areAllStatusActive) {
                                return DEFAULT_ORDER_STATUS_STATE
                            } else {
                                return {
                                    placed: true,
                                    paid: true,
                                    completed: true,
                                    canceled: true,
                                    ready: true,
                                }
                            }
                        }
                    )}>
                    {areAllStatusActive ? 'Odznacz wszystko' : 'Zaznacz wszystko'}
                </span>
            </div>
            <div className="status">
                {orderStatuses.map((o, index) =>
                    <button onClick={() => toggleStatus(o.value)} className={`${status[o.value] && 'active'}`}
                            key={`OrderStatus${index}`}>
                        <span>{o.label}</span>
                    </button>
                )}
            </div>
        </div>
    )
};

export default OrderStatusSelect;