import React, {useEffect, useMemo, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import {TargetGroup} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getTargetGroups} from "../../../redux/selectors/merchantSelector";
import TargetGroupList from "./TargetGroupList";
import TargetGroupForm from "./TargetGroupForm";
import {TARGET_GROUP_DEFAULT_STATE} from "../../../redux/merchant";
import {isEmpty} from "lodash";
import {Tooltip} from "react-tooltip";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    showList: boolean;
    setShowList: React.Dispatch<React.SetStateAction<boolean>>;
    editCurrent: boolean;
    editData: TargetGroup;
}


const TargetGroupModal = ({isOpen, setIsOpen, showList, setShowList, editCurrent, editData}: Props) => {
    const [editedTargetGroup, setEditedTargetGroup] = useState<TargetGroup>(TARGET_GROUP_DEFAULT_STATE);
    const targetGroups = useSelector(getTargetGroups);

    const modalTitle = useMemo(() => {
        if (showList)
            return "Wybierz grupę docelową";
        else
            return editedTargetGroup.id ? "Edytuj grupę docelową" : "Utwórz grupę docelową"
    }, [showList, editedTargetGroup]);

    useEffect(() => {
        setEditedTargetGroup(editData)
    }, [editData, setEditedTargetGroup, isOpen]);

    const toggleModal = () => {
        setIsOpen(false);
        if (!(isEmpty(targetGroups) || editCurrent)) {
            setEditedTargetGroup(TARGET_GROUP_DEFAULT_STATE);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content target-group-modal"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <Tooltip id="TargetGroupTitleTooltip" className="tooltip" noArrow={true}>
                    <p className="tooltip-title">{modalTitle}</p>
                    <p className="tooltip-body">Wybierz z listy utworzoną wcześniej grupę docelową lub utwórz nową grupę, do której chcesz skierować wyjątkową ofertę. Pamiętaj wybór grupy docelowej wpłynie na liczbę użytkowników, do których zostanie skierowana promocja.</p>
                </Tooltip>
                <h5 className="modal-title">
                    {modalTitle}
                    {showList &&
                        <img src={icons.infoGrayIcon}
                         alt="info" className="tooltip-icon"
                         data-tooltip-id="TargetGroupTitleTooltip"
                         data-tooltip-place="bottom-end"/>
                    }
                </h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {
                showList ?
                    <TargetGroupList targetGroups={targetGroups} setEditedTargetGroup={setEditedTargetGroup}
                                     setShowList={setShowList} setIsModalOpen={setIsOpen}/> :
                    <TargetGroupForm targetGroups={targetGroups} editedTargetGroup={editedTargetGroup}
                                     setEditedTargetGroup={setEditedTargetGroup} setShowList={setShowList}
                                     setIsOpen={setIsOpen} editCurrent={editCurrent}/>
            }
        </Modal>
    )
};

export default TargetGroupModal
