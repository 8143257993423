import {TargetGroup} from "../../../services/interfaces";
import React, {useEffect, useState} from "react";
import {some} from "lodash";
import TargetGroupRangesSelect from "./TargetGroupRangesSelect";

interface Props {
    editedTargetGroup: TargetGroup
    setEditedTargetGroup: React.Dispatch<React.SetStateAction<TargetGroup>>
}

const TargetGroupAmountRanges = ({editedTargetGroup, setEditedTargetGroup}: Props) => {
    const [selectedAmountRangeKey, setSelectedAmountRangeKey] = useState<'sum' | 'single'>('single');
    const onAmountRangeKeyChange = (key: 'sum' | 'single') => {
        let newTargetGroup = {...editedTargetGroup};
        setSelectedAmountRangeKey(key);
        if (key === 'single')
            newTargetGroup.amount_sum_ranges = newTargetGroup.amount_sum_ranges.map(range => ({
                ...range,
                selected: false
            }));
        else if (key === 'sum')
            newTargetGroup.amount_ranges = newTargetGroup.amount_ranges.map(range => ({
                ...range,
                selected: false
            }));
        setEditedTargetGroup(newTargetGroup);
    };

    useEffect( () => {
        if (some(editedTargetGroup.amount_sum_ranges, 'selected'))
            setSelectedAmountRangeKey('sum');
        else if (some(editedTargetGroup.amount_ranges, 'selected'))
            setSelectedAmountRangeKey('single')
    }, [editedTargetGroup]);

    return (
        <div className="range-container amount">
            <p className="label">Kwota zakupów (zł)</p>
            <div className="amount-range-buttons">
                {['single', 'sum'].map((key) => (
                    <div className="amount-range-radio-container"
                         onClick={() => onAmountRangeKeyChange(key as 'single' | 'sum')}
                         key={`RadioButtonContainer${key}`}>
                        <input type="radio" checked={selectedAmountRangeKey === key}
                               onChange={() => null}/>
                        <span className="radio-label">{key === 'single' ? 'pojedynczych' : 'łączna'}</span>
                    </div>
                ))}
            </div>
            <TargetGroupRangesSelect rangeKey={selectedAmountRangeKey === 'sum' ? 'amount_sum_ranges' : 'amount_ranges'}
                                     placeholder={selectedAmountRangeKey === 'sum' ? 'Wybierz łączną kwotę zakupów...' : 'Wybierz kwotę pojedynczych zakupów...'}
                                     title={selectedAmountRangeKey === 'sum' ? 'Łączna kwota zakupów' : 'Kwota pojedynczych zakupów'}
                                     targetGroup={editedTargetGroup}
                                     showTitle={false}
                                     setTargetGroup={setEditedTargetGroup}/>
        </div>
    )
};

export default TargetGroupAmountRanges;