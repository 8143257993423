import request, {GET} from "./request";
import {handleError} from "./handleError";


export const getMerchantTransactions = async (urlParams: string) => {
    try {
        const {data} = await request(GET, `transactions/?${urlParams}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointTransactions = async (merchantPointId: number, urlParams: string) => {
    try {
        const {data} = await request(GET, `transactions/${merchantPointId}?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadMerchantTransactions = async (urlParam: string) => {
    try {
        return await request(GET, `transactions/download/?${urlParam}`)
    } catch (error) {
        throw handleError(error);
    }
};

export const downloadMerchantPointTransactions = async (merchantPointId: number, urlParam: string) => {
    try {
        return await request(GET, `transactions/${merchantPointId}/download/?${urlParam}`)
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantTransactionDashboard = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `transactions/dashboard/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointTransactionDashboard = async (merchantPointId: number, urlParam: string) => {
    try {
        const {data} = await request(GET, `transactions/${merchantPointId}/dashboard/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantHistoryFilterOptions = async () => {
    try {
        const {data} = await request(GET, 'transactions/filter_options/');
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointHistoryFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `transactions/${merchantPointId}/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getMerchantFirstTransaction = async () => {
    try {
        const {data} = await request(GET, `transactions/first/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointFirstTransaction = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `transactions/${merchantPointId}/first/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getCashbackLevels = async () => {
    try {
        const {data} = await request(GET, `transactions/cashback_levels/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getMerchantReturns = async () => {
    try {
        const {data} = await request(GET, `transactions/returns/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getMerchantReturnMatches = async (returnId: number) => {
    try {
        const {data} = await request(GET, `transactions/returns/${returnId}/matches/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const confirmReturn = async (returnId: number, matchId: number) => {
    try {
        return await request(GET, `transactions/returns/${returnId}/matches/${matchId}/confirm/`);
    } catch (error) {
        throw handleError(error);
    }
}