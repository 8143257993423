import Modal from "react-modal";
import {icons} from "../../../services/images";
import React, {useState} from "react";
import ButtonFilters, {ButtonFilterOptions, ButtonFilterState} from "../../../components/common/ButtonFilters";
import Button from "../../../components/common/Button";
import {PayoutStatus} from "../financial/Payouts";
import {FilterModalProps} from "../../../services/interfaces";

const AdminPayoutsModal = ({open, setOpen, setFilters}: FilterModalProps) => {

    const [status, setStatus] = useState<ButtonFilterState<PayoutStatus>>({
        queue: true,
        imported: false,
        completed: false,
    });

    const statuses: ButtonFilterOptions<PayoutStatus>[] = [
        {value: 'queue', icon: icons.coinGreenIcon, color: '#00F6A1', label: 'Do wypłaty'},
        {value: 'imported', icon: icons.coinBluePrimaryIcon, color: '#213DFC', label: 'Zaimportowany do pliku'},
        {value: 'completed', icon: icons.coinGrayIcon, color: '#989898', label: 'Zakończony'},
    ];

    const clearFilters = () => {
        setStatus({
            queue: false,
            imported: false,
            completed: false,
        });
    }

    const applyFilters = () => {
        const statusString = Object.keys(status)
            .filter(key => status[key as keyof ButtonFilterState<PayoutStatus>])
            .join(',');
        const urlFilters = 'status=' + statusString;
        setFilters(urlFilters);
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ButtonFilters<PayoutStatus>
                    options={statuses}
                    selectedOption={status}
                    setSelectedOption={setStatus}
                    title="Status wypłaty"
                />
            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>
            </div>
        </Modal>
    )
};

export default AdminPayoutsModal;