import {store} from "../../redux/store";
import {
    getAdminDashboardMapFilterOptions,
    getAdminFirstTransaction,
    getAdminMerchants,
    getAdminMerchantTransactionsFilterOptions, getBanksFilterOptions,
} from "../http/filterOptions";
import {
    setAdminBanksFilterOptions,
    setAdminDashboardMapFilterOptionsAction,
    setAdminFirstTransactionDateAction,
    setAdminMerchantsFilterOptionsAction,
    setAdminMerchantTransactionsFilterOptionsAction,
} from "../redux/adminViewSettings";
import {Transaction} from "../../services/interfaces";
import {AdminDashboardMapFilterOptions, AdminMerchantTransactionsFilterOptions,} from "../services/interfaces";


export const getAdminMerchantsHandler = () => {
    getAdminMerchants().then(data => {
        store.dispatch(setAdminMerchantsFilterOptionsAction(data));
    }).catch(error => {
        console.error(error);
    });
};


export const getAdminFirstTransactionHandler = () => {
    getAdminFirstTransaction().then((data: Transaction) => {
        store.dispatch(setAdminFirstTransactionDateAction(data.trade_date));
    }).catch(error => {
        console.error(error);
    });
};


export const getAdminDashboardMapFilterOptionsHandler = () => {
    getAdminDashboardMapFilterOptions().then((data: AdminDashboardMapFilterOptions) => {
        store.dispatch(setAdminDashboardMapFilterOptionsAction(data));
    }).catch(error => {
        console.error(error);
    });
};

export const getAdminMerchantTransactionsFilterOptionsHandler = () => {
    getAdminMerchantTransactionsFilterOptions().then((data: AdminMerchantTransactionsFilterOptions) => {
        store.dispatch(setAdminMerchantTransactionsFilterOptionsAction(data));
    }).catch(error => {
        console.error(error);
    });
};

export const getBanksFilterOptionsHandler = () => {
    getBanksFilterOptions().then(response => {
        store.dispatch(setAdminBanksFilterOptions(response));
    }).catch(error => {
        throw error;
    })
};
