import {icons, images} from "../../services/images";
import React, {useState} from "react";
import {SignUpCredentials} from "../../services/interfaces";
import Input from "../common/Input";
import {createPasswordValidation, emailValidation, validatePasswordDecomposed} from "../../services/validators";
import ErrorComponent from "../common/ErrorComponent";
import OnboardingSidebar from "./OnboardingSidebar";
import {useSelector} from "react-redux";
import Button from "../common/Button";
import Switch from "../common/Switch";
import {Link} from "react-router-dom";
import {LOGIN_PAGE} from "../../services/dictionaries/paths";
import {getAccountErrorStatus} from "../../redux/selectors/authSelectors";

interface Props {
    data: SignUpCredentials
    setData: React.Dispatch<React.SetStateAction<SignUpCredentials>>
    setStagePhoneNumber: React.Dispatch<React.SetStateAction<boolean>>
}

const SignUp = ({data, setData, setStagePhoneNumber}: Props) => {
    const [toggleRules, setToggleRules] = useState<boolean>(false);
    const error = useSelector(getAccountErrorStatus);

    interface SignUpErrorKey {
        emailError: string
        passwordError: string
    }

    const errorMessage: Record<keyof SignUpErrorKey, string> = {
        emailError: "Niepoprawny adres e-mail",
        passwordError: "Hasło musi zawierać co najmniej 10 znaków, w tym jedną cyfrę, jedną małą literę, jedną wielką literę i znak specjalny."
    };

    const buttonDisabled = !!emailValidation(data.email) || !!createPasswordValidation(data.password) || !toggleRules;


    const decomposedPasswordValidation = validatePasswordDecomposed(data.password);

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="PayProfit dla biznesu"
                image={images.onboardingBusiness}
                body="Skutecznie zarządzaj swoim biznesem i wstrzelaj się w aktualne trendy!"
                hint="Dowiedz się więcej"
                hintLink="/" //TODO Change to valid link
            />
            <div className="onboarding-content">
                <img src={images.payProfitCRMLogoBlack} alt="PayProfitCRM" className="brand-logo"/>
                <h2>Zarejestruj się</h2>
                <ErrorComponent error={error}/>
                <form>
                    <Input<SignUpCredentials, 'fullName'>
                        value={data}
                        onChange={setData}
                        name="fullName"
                        icon={icons.fullNameIcon}
                        placeholder="Imię i Nazwisko"
                    />
                    <Input<SignUpCredentials, 'email'>
                        value={data}
                        onChange={setData}
                        name="email"
                        type="email"
                        icon={icons.emailIcon}
                        showError
                        placeholder="E-mail"
                        validate={emailValidation}
                        errorMessage={errorMessage}
                    />
                    <Input<SignUpCredentials, 'password'>
                        value={data}
                        onChange={setData}
                        name="password"
                        type="password"
                        icon={icons.passwordIcon}
                        placeholder="Hasło"
                    />
                    {data.password.length > 0 &&
                        <div className="password-validation-container">
                            <p className={`${decomposedPasswordValidation.length ? 'active' : ''}`}>Przynajmniej 10
                                znaków</p>
                            <p className={`${decomposedPasswordValidation.lowerCase && decomposedPasswordValidation.upperCase ? 'active' : ''}`}>Przynajmniej
                                jedna mała i duża litera</p>
                            <p className={`${decomposedPasswordValidation.digit ? 'active' : ''}`}>Przynajmniej jedna
                                cyfra</p>
                            <p className={`${decomposedPasswordValidation.specialCharacter ? 'active' : ''}`}>Przynajmniej
                                jeden znak
                                specjalny</p>
                        </div>
                    }
                    <div className="accept-rules-container">
                        <Switch checked={toggleRules} setChecked={setToggleRules}/>
                        <span className="accept-rules-text">Zgadzam się z <Link to="/">Regulaminem oraz polityką prywatności PayProfit</Link>
                            {/*TODO change to redirect policy privacy*/}
                        </span>
                    </div>
                    <Button
                        label="Zarejestruj się"
                        onClick={() => setStagePhoneNumber(true)}
                        disabled={buttonDisabled}
                    />
                    <p className="sign-up-hint">Masz już konto biznesowe?<Link to={LOGIN_PAGE}>Zaloguj się</Link></p>
                </form>
            </div>
        </div>
    )
};

export default SignUp;