import {Aggregate} from "../../services/interfaces";
import React from "react";
import {Select} from "antd";


interface Props {
    aggregate: Aggregate
    setAggregate: (aggregate: Aggregate) => void
}

const AggregateSelect = ({aggregate, setAggregate}: Props) => {
    return (
        <div className="aggregate-select">
            <span className="label-2">Wyświetlaj dane wg: </span>
            <Select
                value={aggregate}
                onChange={setAggregate}
                options={[
                    {value: "amount" as Aggregate, label: 'Przychodu'},
                    {value: "transactions" as Aggregate, label: 'Liczby transakcji'},
                    {value: "clients" as Aggregate, label: 'Liczby klientów'},
                ]}
            />
        </div>
    )
};

export default AggregateSelect;