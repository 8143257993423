import NotificationPopup from "../../common/NotificationPopup";
import React from "react";
import ReservationTable from "./ReservationTable";
import {getHeaderTitle} from "../../../services/helpers";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentMerchantPoint, getIsMerchantSelected, getMerchant} from "../../../redux/selectors/merchantSelector";
import Button from "../../common/Button";
import {icons} from "../../../services/images";
import {setBookingEditModeAction} from "../../../redux/booking";
import OrderTable from "./OrderTable";
import {Order, Reservation} from "../../../services/interfaces";
import {getOrderTableData, getReservationTableData} from "../../../redux/selectors/bookingSelector";
import {isEmpty} from "lodash";
import NoBookings from "./NoBookings";


const BookingDashboard = () => {
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const dispatch = useDispatch();

    const orders: Order[] = useSelector(getOrderTableData);
    const reservations: Reservation[] = useSelector(getReservationTableData);
    if (!merchant)
        return <div/>;
    return (
        <div className="dashboard-content">
            <NotificationPopup/>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Rezerwacje i zamówienia - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
                <div className="header-section">
                    <Button label="Zarządzaj ofertą" icon={icons.cogWhiteIcon}
                            onClick={() => dispatch(setBookingEditModeAction(true))}/>
                </div>
            </div>
            {isEmpty(orders) && isEmpty(reservations) ?
                <NoBookings/> :
                <>
                    <OrderTable/>
                    <ReservationTable/>
                </>
            }
        </div>
    )
};

export default BookingDashboard;