import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminDashboardCashbackBalance, AdminDashboardData, AdminDashboardMerchantPoint} from "../services/interfaces";

export interface AdminDashboardState {
    current: AdminDashboardData,
    previous: AdminDashboardData,
    cashback_balance: AdminDashboardCashbackBalance,
    merchantPoints: AdminDashboardMerchantPoint[]
}


const DEFAULT_STATE: AdminDashboardState = {
    current: {
        income: 0,
        income_data: [],
        user_count: 0,
        merchant_point_count: 0,
        transaction_count: 0,
        active_offers: 0
    },
    previous: {
        income: 0,
        income_data: [],
        user_count: 0,
        merchant_point_count: 0,
        transaction_count: 0,
        active_offers: 0
    },
    cashback_balance: {
        cashback: 0,
        partner_cashback: 0,
        forest: 0,
        payprofit_fee: 0
    },
    merchantPoints: []
};

const adminDashboardSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminDashboard',
    reducers: {
        resetAdminDashboardState: (state) => {
            return {...state, ...DEFAULT_STATE}
        },
        setData: (state, action: PayloadAction<{
            previous: AdminDashboardData,
            current: AdminDashboardData,
            cashback_balance: AdminDashboardCashbackBalance
        }>) => {
            return {
                ...state,
                current: action.payload.current,
                previous: action.payload.previous,
                cashback_balance: action.payload.cashback_balance
            }
        },

        setMerchantPoints: (state, action: PayloadAction<AdminDashboardMerchantPoint[]>) => {
            return {
                ...state,
                merchantPoints: action.payload
            }
        }
    }
});

export default adminDashboardSlice.reducer;


export const setAdminDashboardDataAction = adminDashboardSlice.actions.setData;
export const setAdminDashboardMerchantPointsAction = adminDashboardSlice.actions.setMerchantPoints;

