import OnboardingSidebar from "./OnboardingSidebar";
import {icons, images} from "../../services/images";
import Input from "../common/Input";
import React, {useEffect, useState} from "react";
import Button from "../common/Button";
import {Link, useLocation} from "react-router-dom";
import {ADMIN_LOGIN_PAGE, FORGOT_PASSWORD_PAGE, LOGIN_PAGE} from "../../services/dictionaries/paths";
import {
    resetPasswordHandler,
    sendResetPasswordEmailHandler,
    verifyResetPasswordTokenHandler
} from "../../handlers/authHandlers";
import {createPasswordValidation} from "../../services/validators";


const ForgotPassword = () => {
    const queryParams = new URLSearchParams(useLocation().search);
    const token: string | null = queryParams.get('token');
    const admin: string | null = queryParams.get('admin');

    const [emailData, setEmailData] = useState<{ email: string }>({email: ''});
    const [passwordData, setPasswordData] = useState<{ password: string }>({password: ''});

    const [sendClicked, setSendClicked] = useState<boolean>(false);
    const [sendResetPasswordEmailSuccess, setSendResetPasswordEmailSuccess] = useState<boolean>(false);
    const [loadingVerifyToken, setLoadingVerifyToken] = useState<boolean>(true);

    const [status, setStatus] = useState<string | null>(null);

    useEffect(() => {
        if (token) {
            verifyResetPasswordTokenHandler(token, setStatus, setLoadingVerifyToken, admin==="true");
        }
    }, [token, admin]);


    const sendResetPasswordEmailHandlerCallback = ({success}: { success: boolean }) => {
        setSendClicked(false);
        if (success)
            setSendResetPasswordEmailSuccess(true);
    };

    interface PasswordErrorKey {
        passwordError: string
    }

    const errorMessage: Record<keyof PasswordErrorKey, string> = {
        passwordError: "Hasło musi zawierać co najmniej 10 znaków, w tym jedną cyfrę, jedną małą i jedną wielką literę"
    };

    const renderTokenComponent = (tokenStatus: string | null) => {
        if (loadingVerifyToken) return (
            <h2>Weryfikacja linku...</h2>
        );

        switch (tokenStatus) {
            case 'token_expired':
                return (
                    <>
                        <h2>Twój link stracił ważność</h2>
                        <p className="sign-up-hint"><Link to={FORGOT_PASSWORD_PAGE}>Spróbuj jeszcze raz</Link></p>
                    </>
                );
            case 'verified':
                return (
                    <>
                        <h2>Resetuj hasło</h2>
                        <p>Podaj nowe hasło</p>
                        <form>
                            <Input<{ password: string }, 'password'>
                                value={passwordData}
                                onChange={setPasswordData}
                                name="password"
                                type="password"
                                icon={icons.passwordIcon}
                                placeholder="Nowe hasło"
                                showError
                                validate={createPasswordValidation}
                                errorMessage={errorMessage}
                            />
                            <Button
                                disabled={!passwordData.password || sendClicked || !!createPasswordValidation(passwordData.password)}
                                onClick={() => {
                                    setSendClicked(true);
                                    resetPasswordHandler(token!, passwordData.password, setStatus, admin==="true");
                                }}
                                label="Zresetuj hasło"
                            />
                        </form>
                    </>
                );
            case 'password_changed':
                return (
                    <>
                        <h2>Hasło zostało zmienione</h2>
                        <p>Możesz zalogować się na swoje konto</p>
                        <p className="sign-up-hint"><Link to={admin==="true"? ADMIN_LOGIN_PAGE: LOGIN_PAGE}>Zaloguj się</Link></p>
                    </>
                );
            default:
                return (
                    <>
                        <h2>Wystąpił błąd</h2>
                        <p>Link do resetowania hasła jest błędny lub nieaktywny</p>
                        <p className="sign-up-hint"><Link to={FORGOT_PASSWORD_PAGE}>Spróbuj jeszcze raz</Link></p>
                    </>
                );
        }
    };

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="PayProfit dla biznesu"
                image={images.onboardingBusiness}
                body={admin ? "" : "Skutecznie zarządzaj swoim biznesem i wstrzelaj się w aktualne trendy!"}
                imgSrc={admin ? images.payProfitAdminLogoWhite: ""}
                hint="Dowiedz się więcej"
                hintLink="/" //TODO Change to valid link
            />
            <div className="onboarding-content">
                <img src={admin ? images.payProfitAdminLogoBlack : images.payProfitCRMLogoBlack} alt="PayProfitCRM" className="brand-logo"/>
                {token ? renderTokenComponent(status) :
                    (sendResetPasswordEmailSuccess ?
                            <>
                                <h2>Link do resetowania hasła został wysłany na podany adres e-mail</h2>
                                <p>Jeśli nie widzisz wiadomości w skrzynce odbiorczej, sprawdź folder spam.</p>
                            </> :
                            <>
                                <h2>Przypomnij hasło</h2>
                                <p>Podaj adres e-mail, na który zarejestrowane jest Twoje konto. <br/> Wyślemy na niego
                                    link
                                    do
                                    zresetowania hasła.</p>
                                <form>
                                    <Input<{ email: string }, 'email'>
                                        value={emailData}
                                        onChange={setEmailData}
                                        name="email"
                                        type="email"
                                        icon={icons.emailIcon}
                                        placeholder="E-mail"
                                    />
                                    <Button
                                        disabled={!emailData.email || sendClicked}
                                        onClick={() => {
                                            setSendClicked(true);
                                            sendResetPasswordEmailHandler(emailData.email, sendResetPasswordEmailHandlerCallback, admin ==='true');
                                        }}
                                        label="Wyślij"
                                    />
                                    <p className="sign-up-hint"><Link to={LOGIN_PAGE}>Wróć do ekranu logowania</Link>
                                    </p>
                                </form>
                            </>
                    )
                }
            </div>
        </div>
    )
};

export default ForgotPassword;