import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Router from "./Router";
import './styles/input.scss';
import './styles/fonts.css'
import {authenticateAction, setAuthenticatedAction} from "./redux/auth";
import {Provider, useDispatch} from "react-redux";
import {store} from "./redux/store";
import {storageActionTypes} from "./services/storage/basicStorage";
import {authStorageManager, secureStorageKeys} from "./services/storage/secureStorage";

const Root = () => {

    const dispatch = useDispatch();
    const router = createBrowserRouter(Router());

    useEffect(() => {
        const fetchToken = async () => {
            const storedToken = await authStorageManager(storageActionTypes.GET, secureStorageKeys.ACCESS_TOKEN);
            const expDate = await authStorageManager(storageActionTypes.GET, secureStorageKeys.EXP_DATE);
            const isAdmin = await authStorageManager(storageActionTypes.GET, secureStorageKeys.ADMIN);
            if (storedToken && expDate) {
                dispatch(authenticateAction({
                    accessToken: storedToken,
                    expDate: expDate,
                    adminPanel: isAdmin === 'true'
                }))
            } else {
                dispatch(setAuthenticatedAction(false))
            }
        };

        fetchToken();
        //TODO Fetch Introduced User
    }, []);

    return <RouterProvider router={router} />
};


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <Root/>
    </Provider>
);
