import { useEffect, useRef } from "react";

interface Props {
    value: string | undefined;
    onChange: (value: any) => void;
    placeholder?: string;
    className?: string;
    isDisabled?: boolean;
    maxLength?: number;
}

const InputDescription = ({placeholder="", value, onChange, className="", isDisabled=false, maxLength}: Props) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (textareaRef.current != null) {
                textareaRef.current.style.height = "0px";
                const scrollHeight = textareaRef.current.scrollHeight;
                textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [value]);

    return (
        <textarea
            ref={textareaRef}
            value={value}
            className={className}
            placeholder={placeholder}
            onChange={event => onChange(event.target.value)}
            disabled={isDisabled}
            maxLength={maxLength}
        />
    );
};

export default InputDescription;