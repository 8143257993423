import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {
    Category, ImportExcelError,
    Order,
    OrderFilterOptions,
    ProductCategory,
    Reservation,
    ReservationFilterOptions,
    ServiceCategory
} from "../../services/interfaces";


export const getBookingState = (state: RootState) => state.booking;

export const getBookingEditMode: Selector<RootState, boolean> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.editMode
    }
);

export const getOrderTableData: Selector<RootState, Order[]> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.orderTable.orders
    }
);

export const getOrderTableCount: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderTable.ordersCount
);

export const getOrderTableMaxPage: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderTable.maxPage
);

export const getOrderFilters: Selector<RootState, OrderFilterOptions | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderFilterOptions
);

export const getReservationTableData: Selector<RootState, Reservation[]> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.reservationTable.reservations;
    }
);

export const getReservationTableCount: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationTable.reservationsCount
);

export const getReservationTableMaxPage: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationTable.maxPage
);

export const getReservationFilters: Selector<RootState, ReservationFilterOptions | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationFilterOptions
);


export const getReservationDetails: Selector<RootState, Reservation | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationDetails
);


export const getOrderDetails: Selector<RootState, Order | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderDetails
);


export const getProductCategories: Selector<RootState, ProductCategory[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.productCategories
);

export const getServiceCategories: Selector<RootState, ServiceCategory[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.serviceCategories
);


export const getCategoryOptions: Selector<RootState, Category[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.categoryOptions
);


export const getCategoryImportErrors: Selector<RootState, ImportExcelError[] | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.importErrors
);
