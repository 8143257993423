import numeral from 'numeral';

numeral.register('locale', 'crm', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    currency: {
        symbol: 'zł'
    },
    ordinal: number => '',
});

numeral.locale('crm');

export const DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY = '0,0.00 $';
export const DOUBLE_DECIMAL_NUMBER_FORMAT = '0,0.00';
export const WHOLE_NUMBER_FORMAT = '0,0';
export const DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE = '0.00%';
export const WHOLE_NUMBER_FORMAT_PERCENTAGE = '0%';

export const formatNumber = (value: any, format: string = DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY, divider: number = 1) => {
    return value === null || isNaN(value) ? '--' : numeral(value / divider).format(format);
};

export const calculateChange = (value?: number, prevValue?: number) => {
    let change: number;
    if (prevValue === 0 && value === 0) {
        change = 0;
    } else if (prevValue === 0) {
        change = 1;
    } else if (typeof value === 'number' && typeof prevValue === 'number') {
        change = (value - prevValue) / prevValue;
    } else {
        change = 0;
    }
    return change
};

export const isFloat = (value: number): boolean => {
    return Number(value) === value && value % 1 !== 0;
};