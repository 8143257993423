import {downloadAliorTransferFile, fetchPayoutAvailability, fetchPayouts} from "../http/adminFinance";
import {prepareB64FileToText} from "../../services/utils";
import {saveAs} from "file-saver";
import {setAvailabilityAction, setPayoutsAction} from "../redux/adminFinance";
import {store} from "../../redux/store";
import {PayoutDetails} from "../services/interfaces";

export const fetchPayoutsHandler = (query: string) => {
    fetchPayouts(query).then((response: { data: PayoutDetails[] | { message: string } }) => {
        if ('message' in response.data) {
            console.log(response.data.message);
            return
        }
        store.dispatch(setPayoutsAction(response.data))
    }).catch(error => {
        console.log(error)
    })
}

export const fetchPayoutAvailabilityHandler = () => {
    fetchPayoutAvailability().then((response) => {
        store.dispatch(setAvailabilityAction(response.data.available))
    }).catch(error => {
        console.log(error)
    })
}

export const downloadAliorTransferFileHandler = (callbackMessage: (message: string) => void, callbackSave: () => void) => {
    downloadAliorTransferFile().then((response) => {
        if ('message' in response.data) {
            callbackMessage(response.data.message);
        }
        const blob = prepareB64FileToText(response.data.content);
        const filename = response.data.filename;
        saveAs(blob, filename)
        callbackSave();
    }).catch(error => {
        console.log(error)
    })
};