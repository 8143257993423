import {icons} from "../images";
import {CashbackMerchantLevel} from "../interfaces";



export const cashbackMerchantDictionary: CashbackMerchantLevel[] = [
    {
        name: "LOW",
        min: 0,
        max: 7,
        iconUri: icons.coinBlueLowIcon,
        label: "Niski"
    },
    {
        name: "MEDIUM",
        min: 7.01,
        max: 13,
        iconUri: icons.coinBluePrimaryIcon,
        label: "Średni"
    },
    {
        name: "HIGH",
        min: 13.01,
        max: 20,
        iconUri: icons.coinGreenIcon,
        label: "Wysoki"
    }
];