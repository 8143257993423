import React, {useEffect, useRef, useState} from 'react';
import Button from "../../common/Button";
import {
    Coordinates, Gender, OfferLocation, TargetGroup
} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getTargetGroups} from "../../../redux/selectors/merchantSelector";
import MapModal, {DEFAULT_OFFER_LOCATION} from "../MapModal";
import {icons} from "../../../services/images";
import { PlusOutlined } from '@ant-design/icons';
import { Button as ButtonAntd, Divider, Input as InputAntd, Select, Space } from 'antd';
import type { InputRef } from 'antd';
import {TARGET_GROUP_DEFAULT_STATE} from "../../../redux/merchant";
import TargetGroupDatePicker from "../target_group/TargetGroupDatePicker";
import OfferRadioSelect from "./OfferRadioSelect";
import {
    getMerchantTargetGroupUsers,
    getTargetGroupUsersExceptMerchant
} from "../../../http/targetGroup";
import {FormErrors} from "../Stepper";
import {formatNumber} from "../../../services/numbers";
import {showCustomersNumber} from "../../../services/utils";
import TargetGroupRangesSelect from "../target_group/TargetGroupRangesSelect";
import TargetGroupAmountRanges from "../target_group/TargetGroupAmountRanges";
import {createTargetGroupHandler, updateTargetGroupHandler} from "../../../handlers/targetGroupHandlers";
import {isEqual} from "lodash";

interface StepProps {
    formData: {
        clients: string;
        targetGroup: TargetGroup;
        localization: OfferLocation;
    };
    handleChangeSubField: (field: string, subfield: string, value: any) => void;
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
    setEstimateCost: (value: number) => void;
    setNumberOfCustomers: (value: number) => void;
    errors: FormErrors;
}

const Step1: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        handleChangeSubField,
                                        nextStep,
                                        prevStep,
                                        setEstimateCost,
                                        setNumberOfCustomers,
                                        errors
                                    }) => {
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const [myLocation, setMyLocation] = useState<Coordinates | null>(null);

    const targetGroups = useSelector(getTargetGroups);
    const [options, setOptions] = useState<TargetGroup[]>(targetGroups);
    const [error, setError] = useState(false);
    const [newTargetGroup, setNewTargetGroup] = useState(TARGET_GROUP_DEFAULT_STATE);
    const [temporaryTargetGroup, setTemporaryTargetGroup] = useState<TargetGroup>(TARGET_GROUP_DEFAULT_STATE);
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [currentTargetGroup, setCurrentTargetGroup] = useState<string>('needToKeep');

    const selectRef = useRef<any>(null);
    const inputRef = useRef<InputRef>(null);

    const [exceptedNumberOfCustomers, setExceptedNumberOfCustomers] = useState<number>(0);

    const estimateCost = (clients: string, numberOfCustomers: number): number => {
        if (clients === "current") {
            return numberOfCustomers * (0.05)
        } else if (clients === "new") {
            return numberOfCustomers * (0.1)
        } else {
            return 0
        }
    }

    const validation = () => {
        if (formData.targetGroup.name === "") {
            setError(true);
        }
    }

    const addItem = () => {
        if (newTargetGroup.name) {
            setOptions([...options, newTargetGroup]);
            handleChange("targetGroup", newTargetGroup);
            if (selectRef.current) {
                selectRef.current.blur();
            }
            handleSelectNameChange(newTargetGroup.name)
            setCurrentTargetGroup("needToSave");
            setDropdownIsOpen(false);
            setNewTargetGroup(TARGET_GROUP_DEFAULT_STATE);
        }
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        setNewTargetGroup({
            ...newTargetGroup,
            name: event.target.value
        });
    };

    const handleSelectNameChange = (value: string) => {
        setError(false);
        const selected = options.find(option => option.name === value);
        if (selected) {
            setCurrentTargetGroup("needToKeep");
            setTemporaryTargetGroup(selected);
            handleChange("targetGroup", selected);
        }
    }

    const handleMapSave = (location: OfferLocation) => {
        handleChange("localization", location);
        setMapIsOpen(false);
    };

    const handleMapClose = () => {
        setMapIsOpen(false);
    }

    const resetLocalization = () => {
        handleChange("localization", DEFAULT_OFFER_LOCATION)
    }

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setMyLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        getLocation();
    }, []);

    useEffect(() => {
        if (formData.targetGroup !== TARGET_GROUP_DEFAULT_STATE) {
            const urlParam = new URLSearchParams();
            const targetGroupJson = JSON.stringify(formData.targetGroup);
            urlParam.append('target_group_data', targetGroupJson);
            if(formData.localization && formData.localization.range ){
                     urlParam.append('longitude', String(formData.localization.coordinates.lng));
                     urlParam.append('latitude',String(formData.localization.coordinates.lat));
                     urlParam.append('range_km',String(formData.localization.range));
            }
            if (formData.clients === "current") {
                getMerchantTargetGroupUsers(urlParam.toString()).then((numberOfClients: number) => {
                    setExceptedNumberOfCustomers(numberOfClients);
                })
            }
            if (formData.clients === "new") {
                getTargetGroupUsersExceptMerchant(urlParam.toString()).then((numberOfClients: number) => {
                    setExceptedNumberOfCustomers(numberOfClients);
                })
            }
        }
    }, [formData.targetGroup, formData.clients, formData.localization]);

    useEffect(() => {
         if (formData.targetGroup && temporaryTargetGroup && temporaryTargetGroup.id && !isEqual(formData.targetGroup, temporaryTargetGroup)) {
            setCurrentTargetGroup("needToUpdate");
        }
    }, [formData.targetGroup, temporaryTargetGroup]);

    const offerRange = formData.localization && formData.localization.range ? formData.localization.range : 0;

    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <OfferRadioSelect selectedOption={formData.clients}
                                  handleOptionChange={e => handleChange("clients", e.target.value)}/>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Utwórz lub wybierz grupę docelową<span
                            className="required-mark"> *</span></p>
                        <Select
                            className="input"
                            variant="borderless"
                            placeholder="Grupa docelowa..."
                            open={dropdownIsOpen}
                            onDropdownVisibleChange={setDropdownIsOpen}
                            onChange={handleSelectNameChange}
                            value={formData.targetGroup.name === "" ? undefined : formData.targetGroup.name}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '0 8px 4px'}}>
                                        <InputAntd
                                            placeholder="Wprowadź nazwę nowej grupy docelowej"
                                            ref={inputRef}
                                            value={newTargetGroup.name}
                                            onChange={onNameChange}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <ButtonAntd type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                            Dodaj grupę
                                        </ButtonAntd>
                                    </Space>
                                </>
                            )}
                            options={options.map((item: TargetGroup) => ({value: item.name}))}
                            ref={selectRef}
                        />
                    </div>
                </div>
                {error &&
                    <div className="section">
                        <div className="error-message">Grupa docelowa jest wymagana</div>
                    </div>
                }
                <div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='age_ranges' title='Wiek' placeholder="Wybierz wiek..."
                                                     targetGroup={formData.targetGroup}
                                                     setTargetGroup={value => handleChange("targetGroup", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="select-wrapper">
                            <p className="label label-margin">Płeć</p>
                            <Select
                                mode="multiple"
                                value={formData.targetGroup.gender ? formData.targetGroup.gender : undefined}
                                onChange={(value) => handleChangeSubField("targetGroup", "gender", value)}
                                allowClear={true}
                                placeholder="Wybierz płeć..."
                                showSearch={false}
                                options={[
                                    {value: "male" as Gender, label: 'Mężczyzna'},
                                    {value: "female" as Gender, label: 'Kobieta'},
                                    {value: "unknown" as Gender, label: 'Inna'},
                                ]}
                                className="dropdown-select"
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='transactions_ranges'
                                                     title={formData.clients === "current" ? 'Liczba transakcji w sklepie' : 'Liczba transakcji'}
                                                     placeholder="Wybierz liczbę transakcji..."
                                                     targetGroup={formData.targetGroup}
                                                     setTargetGroup={value => handleChange("targetGroup", value)}/>
                            <TargetGroupDatePicker editedTargetGroup={formData.targetGroup}
                                                   setEditedTargetGroup={value => handleChange("targetGroup", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupAmountRanges
                                editedTargetGroup={formData.targetGroup}
                                setEditedTargetGroup={value => handleChange("targetGroup", value)}/>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper">
                        <p className="label label-margin">Lokalizacja</p>
                        <p className="label required-mark" style={{cursor: "pointer"}}
                           onClick={resetLocalization}>Wyczyść</p>
                    </div>
                    <div className="input">
                        <img className="pin"
                             src={icons.greyPinIcon} alt={'Pin'}/>
                        <input type="text"
                               className="input-place"
                               style={{marginLeft: 20}}
                               value={formData.localization.placeName}
                               onClick={() => setMapIsOpen(true)}
                               onChange={() => handleMapSave}
                               placeholder="Wybierz lokalizację..."
                        />
                        <input type="text"
                               className="input-radius"
                               placeholder="+ 0 km"
                               value={`+ ${offerRange} km`}
                               readOnly
                               onChange={() => handleMapSave}
                        />
                    </div>
                    <MapModal
                        isOpen={mapIsOpen}
                        onClose={handleMapClose}
                        userLocation={myLocation}
                        savedLocation={formData.localization}
                        onSaveRange={handleMapSave}
                    />
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label"
                            style={{fontWeight: 700}}>{showCustomersNumber(exceptedNumberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span className="label"
                              style={{fontWeight: 700}}>{formatNumber(estimateCost(formData.clients, exceptedNumberOfCustomers))}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Anuluj"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            disabled={error}
                            onClick={() => {
                                if (exceptedNumberOfCustomers) {
                                    setEstimateCost(estimateCost(formData.clients, exceptedNumberOfCustomers))
                                    setNumberOfCustomers(exceptedNumberOfCustomers)
                                }
                                if(currentTargetGroup === "needToSave"){
                                    createTargetGroupHandler(formData.targetGroup);
                                }
                                 if(currentTargetGroup === "needToUpdate"){
                                    updateTargetGroupHandler(temporaryTargetGroup, formData.targetGroup);
                                }
                                validation()
                                nextStep()
                            }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step1;