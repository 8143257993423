import {StatisticsUserSummary} from "../../../services/interfaces";
import {parseQueryString} from "../../../services/utils";
import {GenderEnum} from "../../../services/dictionaries/enums";
import {formatNumber} from "../../../services/numbers";
import {useDispatch, useSelector} from "react-redux";
import {getStatisticsSelectedUserIds, getStatisticsTableMode} from "../../../redux/selectors/statisticsSelectors";
import React from "react";
import {selectStatisticsUserAction} from "../../../redux/statistics";

interface Props {
    user: StatisticsUserSummary,
    filters: string,
    onClick: () => void
}

const StatisticsTableRow = ({user, filters, onClick}:  Props) => {
    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];
    const tableMode = useSelector(getStatisticsTableMode);
    const selectedUserIds = useSelector(getStatisticsSelectedUserIds);
    const dispatch = useDispatch();

    let gender = 'Inna';
    if (user.gender in GenderEnum) {
        gender = GenderEnum[user.gender];
    }
    return (
        <tr>
            {tableMode &&
                <td>
                    <input
                        type="checkbox"
                        checked={selectedUserIds.includes(user.id)}
                        onClick={() => dispatch(selectStatisticsUserAction(user.id))}
                        onChange={() => null}
                    />
                </td>
            }
            <td className="clickable-cell" >
                <span onClick={onClick}>#{user.id}</span>
            </td>
            {!excludedColumns.includes('name') && <td>{user.name ? user.name : "--"}</td>}
            {!excludedColumns.includes('email') && <td>{user.email}</td>}
            {!excludedColumns.includes('age') && <td>{user.age ? user.age : "--"}</td>}
            {!excludedColumns.includes('gender') && <td>{gender}</td>}
            {!excludedColumns.includes('amount') &&
                <td className="cell-digit">{formatNumber(user.amount)}</td>}
        </tr>
    )
};

export default StatisticsTableRow;