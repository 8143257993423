import {
    RangeBase,
    RangeBaseCreateData,
    TargetGroup,
    TargetGroupBase,
    TargetGroupCreateData,
    TargetGroupUpdateData
} from "../services/interfaces";
import {store} from "../redux/store";
import {setTargetGroupsAction, TARGET_GROUP_RANGE_KEYS} from "../redux/merchant";
import {
    createTargetGroup,
    deleteTargetGroup,
    duplicateTargetGroup,
    getTargetGroups,
    updateTargetGroup
} from "../http/targetGroup";
import {getCurrentTargetGroup} from "../redux/selectors/merchantSelector";
import {setResponseNotificationErrorAction, setResponseNotificationSavedAction} from "../redux/navigation";
import {setCurrentTargetGroupAndStatisticsFilterOrder} from "../services/statisticsHelpers";

export const getTargetGroupsHandler = () => {
    getTargetGroups().then((data: TargetGroup[]) => {
        store.dispatch(setTargetGroupsAction(data))
    }).catch(error => {
        console.error(error);
    })
};

export const createTargetGroupHandler = (targetGroup: TargetGroup, callback?: () => void, errorCallback?: () => void) => {
    const prepareCreateData = (targetGroup: TargetGroup): TargetGroupCreateData => {
        const removeIdFromRange = (range: RangeBase): RangeBaseCreateData => {
            const {id, ...rest} = range;
            return rest;
        };
        return {
            ...targetGroup,
            age_ranges: targetGroup.age_ranges.map(removeIdFromRange),
            transactions_ranges: targetGroup.transactions_ranges.map(removeIdFromRange),
            amount_ranges: targetGroup.amount_ranges.map(removeIdFromRange),
            amount_sum_ranges: targetGroup.amount_sum_ranges.map(removeIdFromRange),
        };
    };

    createTargetGroup(prepareCreateData(targetGroup)).then((data: TargetGroup) => {
        getTargetGroupsHandler();
        if(callback)callback();
        setCurrentTargetGroupAndStatisticsFilterOrder(data);
        store.dispatch(setResponseNotificationSavedAction())
    }).catch(error => {
        if(errorCallback)errorCallback();
        console.error(error);
        store.dispatch(setResponseNotificationErrorAction())
    })
};

export const updateTargetGroupHandler = (originalTargetGroup: TargetGroup,
                                         editedTargetGroup: TargetGroup,
                                         callback?: () => void,
                                         errorCallback?: () => void) => {

    const addDataFromBasicFields = (updateData: TargetGroupUpdateData) => {
        const basicFields: (keyof TargetGroupBase)[] = ['name', 'gender', 'start_date', 'end_date'];
        basicFields.forEach(field => {
            if (editedTargetGroup[field] !== originalTargetGroup[field]) {
                updateData[field] = editedTargetGroup[field] as any;
            }
        });
    };

    const addDataFromRangeFields = (updateData: TargetGroupUpdateData) => {
        TARGET_GROUP_RANGE_KEYS.forEach(key => {
            const originalRanges = originalTargetGroup[key];
            const editedRanges = editedTargetGroup[key];

            if (JSON.stringify(originalRanges) !== JSON.stringify(editedRanges)) {
                updateData[key] = editedRanges;
            }
        });
    };
    if (editedTargetGroup.id) {
        const updateData: TargetGroupUpdateData = {};
        addDataFromBasicFields(updateData);
        addDataFromRangeFields(updateData);
        updateTargetGroup(editedTargetGroup.id, updateData).then((updatedGroup: TargetGroup) => {
            getTargetGroupsHandler();
            const currentTargetGroup = getCurrentTargetGroup(store.getState());
            if (currentTargetGroup && currentTargetGroup.id === updatedGroup.id)
                setCurrentTargetGroupAndStatisticsFilterOrder(updatedGroup);
            if(callback)callback();
            store.dispatch(setResponseNotificationSavedAction())
        }).catch(error => {
            if(errorCallback)errorCallback();
            console.error(error);
            store.dispatch(setResponseNotificationErrorAction())
        })
    }

};

export const deleteTargetGroupHandler = (targetGroupId: number,
                                         callback: () => void,
                                         errorCallback: () => void) => {
    deleteTargetGroup(targetGroupId).then(() => {
        getTargetGroupsHandler();
        callback();
    }).catch(error => {
        errorCallback();
        console.error(error);
    })
};

export const duplicateTargetGroupHandler = (targetGroupId: number,
                                            callback: () => void,
                                            errorCallback: () => void) => {
    duplicateTargetGroup(targetGroupId).then(() => {
        getTargetGroupsHandler();
        callback();
    }).catch(error => {
        errorCallback();
        console.error(error);
    })
};