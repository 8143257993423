import React from "react";
import {icons} from "../../../../services/images";
import {useDispatch} from "react-redux";
import {setContactPageAction} from "../../../../redux/chat";


const AdminContactMenu = () => {
    const dispatch = useDispatch();

    return (
        <ul className="list-bars">
            <li onClick={() => dispatch(setContactPageAction("user_message"))}>
                <span className="img-wrapper"><img src={icons.userActive} alt=""/></span>
                <span>Wyślij wiadomość użytkowników PayProfit</span>
            </li>
            <li onClick={() => dispatch(setContactPageAction("merchant_message"))}>
                <span className="img-wrapper"><img src={icons.messagesIcon} alt=""/>
                </span>
                <span>Wyślij wiadomość do sieci</span>
            </li>
        </ul>
    )
};

export default AdminContactMenu