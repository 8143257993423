import {useSelector} from "react-redux";
import {getCurrentChat, getMessages} from "../../../redux/selectors/chatSelectors";
import React, {useEffect, useRef, useState} from "react";
import {Message} from "../../../services/interfaces";
import MessageBubble from "./MessageBubble";
import {
    createChatHandler,
    getMoreMessagesHandler,
    pollMessagesHandler,
    sendMessageHandler
} from "../../../handlers/chatHandlers";
import ChatFooter from "./ChatFooter";
import ErrorComponent from "../../common/ErrorComponent";

const POLLING_INTERVAL = 15000;
const PAGE_SIZE = 10;


const ContactChat = () => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [sendDisabled, setSendDisabled] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMoreMessages, setHasMoreMessages] = useState(false);
    const messages = useSelector(getMessages);
    const chat = useSelector(getCurrentChat);
    const chatWindowRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        if (page === 1 && messages.length === PAGE_SIZE)
            setHasMoreMessages(true);
    }, [messages]);

    useEffect(() => {
        !loading && scrollToBottom();
    }, [messages]);


    useEffect(() => {
        let pollInterval: NodeJS.Timeout;

        if (chat && chat.id && chat.last_message) {
            pollInterval = setInterval(() => {
                chat.id && chat.last_message && pollMessagesHandler(chat.id, chat.last_message.id);
            }, POLLING_INTERVAL);
        }
        return () => {
            if (pollInterval) {
                clearInterval(pollInterval);
            }
        };
    }, [chat]);

    const loadMore = () => {
        if (hasMoreMessages && chat?.id) {
            const successCallback = (newMessages: Message[]) => {
                if (newMessages.length < PAGE_SIZE) {
                    setHasMoreMessages(false);
                }
                setPage(page + 1);
            };
            setLoading(true);
            getMoreMessagesHandler(
                chat.id,
                page + 1,
                setLoading,
                successCallback
            );
        }
    };

    const handleSend = () => {

        const callback = () => {
            setInput('');
            setSendDisabled(false);
        };
        const errorCallback = () => {
            setError('send_message_error');
            setSendDisabled(false);
        };
        if (input && chat) {
            setSendDisabled(true);
            chat.id ?
                sendMessageHandler(chat.id, input, callback, errorCallback) :
                createChatHandler(chat.user.id, input, callback, errorCallback);
        }
    };

    const scrollToBottom = () => {
        const chatWindow = chatWindowRef.current;
        if (chatWindow) {
            chatWindow.scrollTop = chatWindow.scrollHeight;
        }
    };

    return (
        <div className="chat">
            <div className="chat-window" ref={chatWindowRef}>
                {!loading && hasMoreMessages &&
                    <div onClick={loadMore} className="load-more">
                        <span className="chevron up"/>
                    </div>
                }
                {loading && <div className="messages-loading"/>}
                {messages.map(message =>
                    <MessageBubble message={message}
                                   user={chat?.user}
                                   key={`Message${message.id}`}/>
                )}

            </div>
            <ErrorComponent error={error}/>
            <ChatFooter input={input} setInput={setInput} onSend={handleSend} sendDisabled={sendDisabled}/>
        </div>
    )
};

export default ContactChat;

