import React from "react";
import Button from "../../../../components/common/Button";
import {icons} from "../../../../services/images";


const AdminMessageSuccess = ({onClose}: { onClose: () => void }) => {
    return (
        <div className="success-screen">
            <img src={icons.successIcon} alt=""/>
            <span className="title">Pomyślnie wysłano wiadomość</span>
            <span className="desc">Kopię wiadomości oraz odpowiedź dostaniesz niebawem na swój adres e-mail</span>
            <Button label="Kontynuuj" onClick={onClose}/>
        </div>
    )
};

export default AdminMessageSuccess