import {AdminSidebarItem, SidebarItem} from "../interfaces";
import {
    ADMIN_ACCOUNTING_PAGE,
    ADMIN_CUPS_PAGE,
    ADMIN_FOREST_PAGE,
    ADMIN_HOME_PAGE,
    ADMIN_MERCHANTS_PAGE, ADMIN_PAYOUTS_PAGE,
    ADMIN_USERS_PAGE, BOOKING_PAGE,
    CUPS_PAGE,
    FOREST_PAGE,
    HISTORY_PAGE,
    HOME_PAGE,
    OFFERS_PAGE,
    STATISTICS_PAGE
} from "./paths";


export const sidebarItems: SidebarItem[] = [
    {
        name: 'home',
        title: 'Kokpit',
        path: HOME_PAGE
    },
    {
        name: 'statistics',
        title: 'Statystyki',
        path: STATISTICS_PAGE
    },
    {
        name: 'offers',
        title: 'Promocje',
        path: OFFERS_PAGE
    },
    {
        name: 'forest',
        title: 'Mój las',
        path: FOREST_PAGE
    },
    {
        name: 'history',
        title: 'Historia transakcji',
        path: HISTORY_PAGE
    },
    {
        name: 'cups',
        title: 'Ekokubki',
        path: CUPS_PAGE
    },
    {
        name: 'booking',
        title: 'Rezerwacje i zamówienia',
        path: BOOKING_PAGE
    },
    {
        name: 'contact',
        title: 'Kontakt',
        modal: true
    },
    {
        name: 'settings',
        title: 'Ustawienia',
        modal: true
    }
];

export const adminSidebarItems: AdminSidebarItem[] = [
    {
        name: 'home',
        title: 'Kokpit',
        path: ADMIN_HOME_PAGE
    },
    {
        name: 'merchants',
        title: 'Lista Sieci',
        path: ADMIN_MERCHANTS_PAGE
    },
    {
        name: 'users',
        title: 'Lista Użytkowników',
        path: ADMIN_USERS_PAGE
    },
    {
        name: 'accounting',
        title: 'Dokumenty Księgowe',
        path: ADMIN_ACCOUNTING_PAGE
    },
    {
        name: 'payouts',
        title: 'Wypłaty',
        path: ADMIN_PAYOUTS_PAGE
    },
    {
        name: 'forest',
        title: 'Mój las',
        path: ADMIN_FOREST_PAGE
    },

    {
        name: 'cups',
        title: 'Ekokubki',
        path: ADMIN_CUPS_PAGE
    },
    {
        name: 'contact',
        title: 'Kontakt',
        modal: true
    },
    {
        name: 'settings',
        title: 'Ustawienia',
        modal: true
    }
];