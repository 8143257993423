import Modal from 'react-modal';
import {SetSettingsPageActionType} from "../../../services/interfaces";
import React from "react";
import {icons} from "../../../services/images";
import SettingsMenu from "./SettingsMenu";
import SettingsManagement from "./SettingsManagement";
import SettingsPassword from "./SettingsPassword";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../../../redux/selectors/authSelectors";
import {getModalOpen} from "../../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../../redux/navigation";


const SettingsModal = () => {
    const [settingsPage, setSettingsPage] = React.useState<SetSettingsPageActionType>(null);
    const isOpen = useSelector(getModalOpen('settings'));
    const dispatch = useDispatch();

    const title = settingsPage === "management" ? "Zarządzaj dostępem" :
        settingsPage === "password" ? "Zmień hasło" :
            settingsPage === "delete" ? "Usuń konto" : "Ustawienia";

    const userDetails = useSelector(getUserDetails);

    const userEmail = userDetails ? userDetails.email : '';

    const closeModal = () => {
        dispatch(modalCloseAction('settings'));
        setSettingsPage(null);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            className="modal-content settings"

        >
            <div className="modal-header">
                <div/>
                <div>
                    <h5 className="modal-title">{title}</h5>
                    <span className="email">{userEmail}</span>
                </div>
                <div className="modal-close" onClick={() => closeModal()}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                {settingsPage === "management" ? <SettingsManagement/> :
                    settingsPage === "password" ? <SettingsPassword setSettingsPage={setSettingsPage}/> :
                        settingsPage === "delete" ? <h1>Delete</h1> :
                            <SettingsMenu setSettingsPage={setSettingsPage}/>
                }
            </div>
        </Modal>
    )
};

export default SettingsModal;