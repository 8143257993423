import {
    Category, ImportExcelError,
    OrderDetails,
    OrderFilterOptions,
    OrderTableData,
    OrderTableResponse,
    ProductCategory,
    ReservationDetails,
    ReservationFilterOptions,
    ReservationTableData,
    ReservationTableResponse,
    ServiceCategory,
} from "../services/interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface BookingState {
    orderFilterOptions: OrderFilterOptions | null,
    reservationFilterOptions: ReservationFilterOptions | null,
    orderTable: OrderTableData,
    reservationTable: ReservationTableData
    editMode: boolean,
    reservationDetails: ReservationDetails | null
    orderDetails: OrderDetails | null
    productCategories: ProductCategory[]
    serviceCategories: ServiceCategory[]
    categoryOptions: Category[]
    importErrors: null | ImportExcelError[]
}

const DEFAULT_STATE: BookingState = {
    orderFilterOptions: null,
    reservationFilterOptions: null,
    orderTable: {
        orders: [],
        ordersCount: 0,
        maxPage: 0,
        pageSize: 0
    },
    reservationTable: {
        reservations: [],
        reservationsCount: 0,
        maxPage: 0,
        pageSize: 0
    },
    editMode: false,
    reservationDetails: null,
    orderDetails: null,
    productCategories: [],
    serviceCategories: [],
    categoryOptions: [],
    importErrors: null
};

const bookingSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'booking',
    reducers: {
        setOrderTableData: (state, action: PayloadAction<OrderTableResponse>) => {
            return {
                ...state,
                orderTable: {
                    orders: action.payload.orders,
                    ordersCount: action.payload.orders_count,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size
                },
            }
        },
        setReservationTableData: (state, action: PayloadAction<ReservationTableResponse>) => {
            return {
                ...state,
                reservationTable: {
                    reservations: action.payload.reservations,
                    reservationsCount: action.payload.reservations_count,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size
                },
            }
        },
        setOrderFilterOptions: (state, action: PayloadAction<OrderFilterOptions>) => {
            return {
                ...state,
                orderFilterOptions: action.payload
            }
        },
        setReservationFilterOptions: (state, action: PayloadAction<ReservationFilterOptions>) => {
            return {
                ...state,
                reservationFilterOptions: action.payload
            }
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                editMode: action.payload
            }
        },
        setReservationDetails: (state, action: PayloadAction<ReservationDetails | null>) => {
            return {
                ...state,
                reservationDetails: action.payload
            }
        },
        setOrderDetails: (state, action: PayloadAction<OrderDetails | null>) => {
            return {
                ...state,
                orderDetails: action.payload
            }
        },
        setProductCategories: (state, action: PayloadAction<ProductCategory[]>) => {
            return {
                ...state,
                productCategories: action.payload
            }
        },
        setServiceCategories: (state, action: PayloadAction<ServiceCategory[]>) => {
            return {
                ...state,
                serviceCategories: action.payload
            }
        },
        setCategoryOptions: (state, action: PayloadAction<Category[]>) => {
            return {
                ...state,
                categoryOptions: action.payload
            }
        },
        setImportErrors: (state, action: PayloadAction<ImportExcelError[] | null>) => {
            return {
                ...state,
                importErrors: action.payload
            }
        },
    }
});

export default bookingSlice.reducer

export const setOrderTableDataAction = bookingSlice.actions.setOrderTableData;
export const setReservationTableDataAction = bookingSlice.actions.setReservationTableData;
export const setOrderFilterOptionsAction = bookingSlice.actions.setOrderFilterOptions;
export const setReservationFilterOptionsAction = bookingSlice.actions.setReservationFilterOptions;
export const setBookingEditModeAction = bookingSlice.actions.setEditMode;
export const setReservationDetailsAction = bookingSlice.actions.setReservationDetails;
export const setOrderDetailsAction = bookingSlice.actions.setOrderDetails;
export const setProductCategoriesAction = bookingSlice.actions.setProductCategories;
export const setServiceCategoriesAction = bookingSlice.actions.setServiceCategories;
export const setCategoryOptionsAction = bookingSlice.actions.setCategoryOptions;
export const setCategoryImportErrorsAction = bookingSlice.actions.setImportErrors;