import React from "react";
import Modal from "react-modal";
import Button from "../common/Button";
import {icons} from "../../services/images";
import {Blocker} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setShouldBlockNavigation} from "../../redux/navigation";


interface Props {
    blocker: Blocker
    onProceed: (() => void) | null
}

const UnsavedChangesModal = ({blocker, onProceed}: Props) => {
    const toggleModal = () => {
        blocker.reset && blocker.reset();
    };
    const dispatch = useDispatch();
    return (
        <Modal
            isOpen={blocker.state === "blocked"}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content popup"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <h5 className="modal-title">Masz niezapisane zmiany. Czy na pewno chcesz opuścić strone?</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-footer">
                <Button label="Anuluj"
                        onClick={toggleModal}
                        type="Default"
                />
                <Button label="Porzuć zmiany"
                        type="Red"
                        onClick={() => {
                            if (blocker.proceed) {
                                blocker.proceed();
                                dispatch(setShouldBlockNavigation(false));
                                onProceed && onProceed()
                            }
                        }}
                />
            </div>
        </Modal>
    )
};


export default UnsavedChangesModal;