import {icons, images} from "../../services/images";
import OnboardingSidebar from "./OnboardingSidebar";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {LOGIN_PAGE} from "../../services/dictionaries/paths";
import {activateAccountHandler, verifyInvitationTokenHandler} from "../../handlers/managementHandlers";
import Input from "../common/Input";
import {ActivateCredentials} from "../../services/interfaces";
import {createPasswordValidation} from "../../services/validators";
import Button from "../common/Button";

const ActivateAccount = () => {
    const queryParams = new URLSearchParams(useLocation().search);
    const [status, setStatus] = React.useState<Object | string | null>(null);
    const token: string | null = queryParams.get('token');
    const navigation = useNavigate();
    const [data, setData] = React.useState<ActivateCredentials>({name: '', password: ''});
    const [activateButtonClicked, setActivateButtonClicked] = React.useState<boolean>(false);

    if (!token) {
        navigation(LOGIN_PAGE);
    }

    useEffect(() => {
        if (!token) return;
        verifyInvitationTokenHandler(token, setStatus);
    }, [token]);


    if (['unknown_error', 'invalid_token'].includes(status as string)) {
        navigation(LOGIN_PAGE);
    }

    if (status === 'registered') {
        setTimeout(() => {
            navigation(LOGIN_PAGE);
        }, 5000);
    }

    const verificationSuccess: boolean = status !== null && typeof status === 'object' && ['email', 'merchant', 'inviter'].every(key => key in status);

    interface ActivateErrorKey {
        passwordError: string
    }

    const errorMessage: Record<keyof ActivateErrorKey, string> = {
        passwordError: "Hasło musi zawierać co najmniej 10 znaków, w tym jedną cyfrę, jedną małą literę, jedną wielką literę i znak specjalny."
    };

    const buttonDisabled = !!createPasswordValidation(data.password) || data.name.length < 1;

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="PayProfit dla biznesu"
                image={images.onboardingBusiness}
                body="Skutecznie zarządzaj swoim biznesem i wstrzelaj się w aktualne trendy!"
                hint="Dowiedz się więcej"
                hintLink="/" //TODO Change to valid link
            />
            <div className="onboarding-content">
                <img src={images.payProfitCRMLogoBlack} alt="PayProfitCRM" className="brand-logo"/>
                {token ? (status === 'token_expired' ?
                    <>
                        <h2>Twój link wygasł</h2>
                        <p>Poproś administratora o wygenerowanie nowego zaproszenia.</p>
                    </> : status === 'registered' ?
                        <>
                            <h2>Twoje konto zostało aktywowane.</h2>
                            <p>Za chwilę nastąpi przekierowanie na stronę logowania</p>
                            <div className="loader small"/>

                            <p>Jeżeli nie chcesz czekać <a href={LOGIN_PAGE}>kliknij tu</a></p>
                        </> : verificationSuccess ?
                            <>
                                <h2>Dokończ rejestrację</h2>
                                <form>
                                    <Input<ActivateCredentials, 'name'>
                                        value={data}
                                        onChange={setData}
                                        name="name"
                                        icon={icons.fullNameIcon}
                                        placeholder="Imię i Nazwisko"
                                    />
                                    <Input<ActivateCredentials, 'password'>
                                        value={data}
                                        onChange={setData}
                                        name="password"
                                        type="password"
                                        icon={icons.passwordIcon}
                                        showError
                                        placeholder="Hasło"
                                        validate={createPasswordValidation}
                                        errorMessage={errorMessage}
                                    />
                                    <Button
                                        label="Aktywuj konto"
                                        onClick={() => activateAccountHandler(data, token, setStatus, setActivateButtonClicked)}
                                        disabled={buttonDisabled || activateButtonClicked}
                                    />
                                </form>
                            </> : null) : null
                }
            </div>
        </div>
    )
};

export default ActivateAccount;