import {createSelector, Selector} from "reselect";
import {RootState} from "../../../redux/selectors/rootSelectors";
import {AppUserDetails, ChartData, PieChartData, StatisticsData, User} from "../../../services/interfaces";
import {AdminAppUserSummary, AdminUsersCount} from "../../services/interfaces";


export const getAdminUsersState = (state: RootState) => state.admin.users;


export const getAdminUsersStatisticsData: Selector<RootState, StatisticsData> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.statisticsData
);

export const getAdminUsersStatisticsDetailsCurrentData: Selector<RootState, PieChartData[] | ChartData[]> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.statisticsDetails.current
);

export const getAdminUsersStatisticsDetailsPreviousData: Selector<RootState, PieChartData[] | ChartData[]> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.statisticsDetails.previous
);

export const getAdminUsersCount: Selector<RootState, AdminUsersCount> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.count
);

export const getAdminUsersTableList: Selector<RootState, AdminAppUserSummary[]> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.table.users
);

export const getAdminUsersTableMaxPage: Selector<RootState, number> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.table.maxPage
);

export const getAdminUsersTableCount: Selector<RootState, number> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.table.usersCount
);

export const getAdminAppUserDetails: Selector<RootState, AppUserDetails | null> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.appUserDetails
);


export const getAdminUsersWithoutTransactionsTableList: Selector<RootState, AdminAppUserSummary[]> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.withoutTransactionsTable.users
);

export const getAdminUsersWithoutTransactionsTableMaxPage: Selector<RootState, number> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.withoutTransactionsTable.maxPage
);

export const getAdminUsersWithoutTransactionsTableCount: Selector<RootState, number> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.withoutTransactionsTable.usersCount
);

export const getAllAppUsers: Selector<RootState, User[]> = createSelector(
    getAdminUsersState,
    (adminUsersState) => adminUsersState.all
);