import {createSlice} from "@reduxjs/toolkit";

export interface AdminFinanceState {
    payouts: any[];
    available: boolean;
}

const DEFAULT_STATE: AdminFinanceState = {
    payouts: [],
    available: false
}

const adminFinanceSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminFinance',
    reducers: {
        setPayouts: (state, action) => {
            return {
                ...state,
                payouts: action.payload
            }
        },
        setAvailability: (state, action) => {
            return {
                ...state,
                available: action.payload
            }
        }
    }
});

export default adminFinanceSlice.reducer;

export const setPayoutsAction = adminFinanceSlice.actions.setPayouts;
export const setAvailabilityAction = adminFinanceSlice.actions.setAvailability;
