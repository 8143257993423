import getErrorMessage from "../../services/dictionaries/errorDict";

interface Props {
    error: string | null
}

const ErrorComponent = ({error=null}: Props) => {
    return (
        error ? <p className="error-message">{getErrorMessage(error)}</p> : <div/>
    )
};

export default ErrorComponent