import React, {useEffect, useMemo, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import HistorySlider from "../../modals/filters/HistorySlider";
import {Category, CategoryFilters, Product, Service} from "../../../services/interfaces";
import {Select} from "antd";
import {maxBy, minBy} from "lodash";


interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    allCategories: Category[];
    filters: CategoryFilters;
    setFilters: ({query, hiddenCategories, priceSlider}: CategoryFilters) => void
}

type PricedItem = Service | Product;

const CategoryFiltersModal = ({open, setOpen, allCategories, filters, setFilters}: Props) => {

    const [query, setQuery] = useState<string>('');
    const [priceSlider, setPriceSlider] = useState<[number, number] | null>(null);
    const [hiddenCategories, setHiddenCategories] = useState<number[]>([]);

    const clearFilters = () => {
        setHiddenCategories([]);
        setQuery('');
        setPriceSlider([minPrice, maxPrice]);
    };
    const applyFilters = () => {
        setFilters({query, hiddenCategories, priceSlider});
        setOpen(false);
    };
    const categoryOptions = allCategories.map((c) => ({label: c.name, value: c.id}));
    const {minPrice, maxPrice} = useMemo(() => {
        const allItems = allCategories.flatMap(category =>
            // Assert the type of items to be PricedItem[]
            (category.items as PricedItem[])
        );
        const minPrice = minBy(allItems, 'price')?.price ?? 0;
        const maxPrice = maxBy(allItems, 'price')?.price ?? 0;
        return {minPrice, maxPrice};
    }, [allCategories]);

    useEffect(() => {
        setPriceSlider([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    useEffect(() => {
        setQuery(filters.query);
        filters.priceSlider && setPriceSlider(filters.priceSlider);
        setHiddenCategories(filters.hiddenCategories);
    }, [filters, open]);

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="searchbar" style={{maxWidth: 'unset'}}>
                    <img src={icons.search} alt="Search"/>
                    <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                           value={query}
                           onChange={event => setQuery(event.target.value)}/>
                </div>
                <div className="section">
                    <h6>Ukryj kategorie</h6>
                    <Select
                        mode="multiple"
                        showSearch={false}
                        placeholder="Zaznacz kategorie które chcesz ukryć..."
                        options={categoryOptions}
                        value={hiddenCategories}
                        className="dropdown-select"
                        onChange={setHiddenCategories}/>
                </div>
                <HistorySlider
                    label="Cena"
                    min={minPrice}
                    max={maxPrice}
                    value={priceSlider}
                    onChange={setPriceSlider}
                />

            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>
            </div>
        </Modal>
    )
};

export default CategoryFiltersModal