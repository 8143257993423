import React, {useEffect} from "react";
import {
    getUserListHandler,
    removeStandardUserHandler, resendActivationCodeHandler,
    sendInvitationHandler
} from "../../../handlers/managementHandlers";
import {getUserList} from "../../../redux/selectors/managementSelectors";
import {useSelector} from "react-redux";
import Input from "../../common/Input";
import {icons} from "../../../services/images";
import {emailValidation} from "../../../services/validators";
import Button from "../../common/Button";
import ActionModal from "../ActionModal";
import PopUpModal from "../PopUpModal";
import {MerchantUserDetails} from "../../../services/interfaces";
import ConfirmChangeModal from "../ConfirmChangeModal";

interface UserTileProps {
    index: number;
    setActionDetails: React.Dispatch<React.SetStateAction<{ index: number, id: number } | null>>;
    setShowActionModal: React.Dispatch<React.SetStateAction<boolean>>;
    showActionButton: boolean;
    user: {
        id: number,
        email: string,
        name: string,
        is_superuser: boolean,
        is_verified: boolean,
        activation_token_datetime: string | null,
        activation_token_expired: boolean | null
    };
}

const UserTile = ({index, user, setShowActionModal, showActionButton, setActionDetails}: UserTileProps) => {
    const label = user.is_verified ? user.name + " (" + user.email + ")" : user.email + ` (niepotwierdzony)`;
    return (
        <div key={user.id} className="tile">
            <img src={icons.userActive} alt=""/>
            <div>
                <p className={`${user.is_superuser && 'superuser'} ${!user.is_verified && 'not-verified'}`}>{label}</p>
                {!user.is_verified &&
                    <sub style={user.activation_token_expired ? {color: 'rgba(245,0,0,0.9)'} : {}}>Ostatni kod
                        wysłano {user.activation_token_datetime}</sub>}
            </div>
            {showActionButton &&
                <img className="dots" src={icons.actionsButtonIcon} alt=""
                     onClick={() => {
                         setShowActionModal(prevState => !prevState);
                         setActionDetails(prevState => prevState === null ? {
                             index,
                             id: user.id
                         } : null);
                     }}
                />
            }
        </div>
    )
};

const SettingsManagement = () => {

    useEffect(() => {
        getUserListHandler();
    }, []);

    const userList = useSelector(getUserList);
    const [data, setData] = React.useState<{ email: string }>({email: ""});

    const [emailSentCallback, setEmailSentCallback] = React.useState<boolean>(false);
    const [userDeletedCallback, setUserDeletedCallback] = React.useState<boolean>(false);
    const [resentCodeCallback, setResentCodeCallback] = React.useState<boolean>(false);

    const [showActionModal, setShowActionModal] = React.useState<boolean>(false);

    const [actionDetails, setActionDetails] = React.useState<{
        index: number,
        id: number
    } | null>(null);

    const [showRemoveUserConfirmationModal, setShowRemoveUserConfirmationModal] = React.useState<boolean>(false);

    const userTileRefs = React.useRef<(HTMLDivElement | null)[]>([]);
    const [actionModalPosition, setActionModalPosition] = React.useState({x: 0, y: 0});
    const [invitationSent, setInvitationSent] = React.useState<boolean>(false);

    useEffect(() => {
        if (showActionModal && actionDetails !== null) {
            const userTile = userTileRefs.current[actionDetails?.index as number];
            if (userTile) {
                const {right, bottom} = userTile.getBoundingClientRect();
                setActionModalPosition({
                    x: right,
                    y: bottom,
                });
            }
        }
    }, [showActionModal, actionDetails]);

    interface LoginErrorKey {
        emailError: string
    }

    const errorMessage: Record<keyof LoginErrorKey, string> = {
        emailError: "Niepoprawny adres e-mail",
    };

    const buttonDisabled = !!emailValidation(data.email);

    const sendInvitationCallback = () => {
        setEmailSentCallback(true);
        setData({email: ""});
        getUserListHandler();
        setInvitationSent(false);
    };

    const removeUserCallback = () => {
        setShowActionModal(false);
        setActionDetails(null);
        setUserDeletedCallback(true);
        getUserListHandler();
    };

    const resendCallback = () => {
        setResentCodeCallback(true);
        getUserListHandler();
    };

    const emailAlreadyExists: boolean = userList.map(user => user.email).includes(data.email);
    const selectedUser: MerchantUserDetails | undefined = userList.find(user => user.id === actionDetails?.id);

    let actionModalActions = [];
    if (selectedUser !== undefined) {
        if (!selectedUser.is_superuser) {
            actionModalActions.push({
                title: 'Usuń pracownika',
                onClick: () => {
                    setShowRemoveUserConfirmationModal(true);
                    setShowActionModal(false);
                },
                className: 'delete'
            });
        }
        if (showActionModal && !selectedUser.is_verified) {
            actionModalActions.unshift({
                title: 'Wyślij ponownie kod',
                onClick: () => resendActivationCodeHandler(selectedUser.id, resendCallback),
                className: 'blue-primary'
            });
        }

    }

    return (
        <div className="management-modal">
            <div className="invitation">
                <p className="title">Wpisz adres e-mail pracownika:</p>
                <Input value={data} onChange={setData} name="email" type="email" showError
                       placeholder="E-mail pracownika" validate={emailValidation} errorMessage={errorMessage}/>
                {emailAlreadyExists && <p className="error-message">Pracownik o podanym adresie e-mail już istnieje</p>}
                <Button label="Wyślij zaproszenie do rejestracji"
                        disabled={buttonDisabled || emailAlreadyExists || invitationSent}
                        onClick={() => sendInvitationHandler(data.email, sendInvitationCallback, setInvitationSent)}
                        fullWidth={true}/>
                <p className="hint">Dodaj pracownika do swojej sieci za pośrednictwem unikalnego adresu rejestracyjnego.
                    Po zarejestrowaniu nowego użytkownika będzie miał on dostęp do Skanera QR.</p>
            </div>
            <div className="list">
                <p>Pracownicy:</p>
                {userList.length > 0 ? userList.map((user, index) =>
                    <div ref={(ref) => userTileRefs.current[index] = ref}>
                        <UserTile index={index}
                                  user={user}
                                  setShowActionModal={setShowActionModal}
                                  setActionDetails={setActionDetails}
                                  showActionButton={!user.is_superuser || !user.is_verified}
                        />
                    </div>) : "Brak pracowników"
                }
            </div>
            <ActionModal isOpen={showActionModal}
                         onClose={() => {
                             setShowActionModal(false);
                             setActionDetails(null);
                         }}
                         actions={actionModalActions}
                         x={actionModalPosition.x} y={actionModalPosition.y}
            />
            <PopUpModal
                show={emailSentCallback}
                setShow={setEmailSentCallback}
                title="Zaproszenie zostało wysłane"
                buttonCloseLabel="Zamknij"
            />
            <PopUpModal
                show={userDeletedCallback}
                setShow={setUserDeletedCallback}
                title="Konto pracownika zostało usunięte"
                buttonCloseLabel="Zamknij"
            />
            <PopUpModal
                show={resentCodeCallback}
                setShow={setResentCodeCallback}
                title="Kod aktywacyjny został wysłany ponownie"
                buttonCloseLabel="Zamknij"
            />
            <ConfirmChangeModal
                isOpen={showRemoveUserConfirmationModal}
                setIsOpen={setShowRemoveUserConfirmationModal}
                onConfirm={() => {
                    if (selectedUser) removeStandardUserHandler(selectedUser.id, removeUserCallback);
                }}
                title="Czy na pewno chcesz usunąć tego pracownika?"
                confirmLabel="Usuń"
                onCancel={() => setActionDetails(null)}
            />
        </div>
    )
};

export default SettingsManagement;