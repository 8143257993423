import {formatNumber, WHOLE_NUMBER_FORMAT} from "../../../services/numbers";
import ChangePill from "../../../components/common/ChangePill";


interface Props {
    title: string;
    value: number;
    prevValue: number;
}

const AdminDashboardTile = ({title, value, prevValue}: Props) => {
    return (
        <div className="block block-2-1">
            <div className="value-section" >
                <span className="small-block-title">{title}</span>
                <div className="value-wrapper no-timerange">
                    <span className="standard-value">{formatNumber(value, WHOLE_NUMBER_FORMAT)}</span>
                    <ChangePill value={value} prevValue={prevValue}/>
                </div>
            </div>
        </div>
    )
};

export default AdminDashboardTile