import {icons} from "../../../../services/images";
import React from "react";
import {BaseMapItem, MapPanelProps} from "./types";


const MapPanel = <T extends BaseMapItem>({
                                             title,
                                             items,
                                             ItemOverviewComponent,
                                             setFiltersModalOpen,
                                             setQuery
                                         }: MapPanelProps<T>) => {
    return (
        <div className="map-panel">
            <div className="header">
                <h5>{title}</h5>
            </div>
            <div className="map-controls">
                <div className="searchbar">
                    <img src={icons.search} alt="Search"/>
                    <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                           onChange={event => setQuery(event.target.value)}/>
                </div>
                <div className="filters-button" onClick={() => setFiltersModalOpen(true)}>
                    <img src={icons.filterGrey} alt="Filters"/>
                    <p className="label-small">Filtry</p>
                </div>
            </div>
            <div className="map-list">
                {items.map((item: any, index) => (
                    <ItemOverviewComponent {...item} key={`MapItemOverview${index}`}/>
                ))}
            </div>
        </div>
    )
};

export default MapPanel;