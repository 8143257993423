import {useDispatch, useSelector} from "react-redux";
import {getCategoryImportErrors} from "../../../redux/selectors/bookingSelector";
import {setCategoryImportErrorsAction} from "../../../redux/booking";
import {icons} from "../../../services/images";

const importErrorDict: { [key: string]: string } = {
    "Value required.": "Pole wymagane",
    'Not a valid number.': 'Niepoprawna liczba',
    'Not a valid boolean.': "Niepoprawna wartość. Wpisz 'TAK' lub 'NIE'"
};

const getImportErrorMessage = (error: string): string => {
    if (importErrorDict[error]) {
        return importErrorDict[error];
    } else {
        return error;
    }
};

const columnsDict: { [key: string]: string } = {
    'category_name': 'Kategoria',
    'name': 'Nazwa',
    'description': 'Opis',
    'price': 'Cena',
    'active': 'Czy aktywny/a',
    'duration': 'Czas trwania',
    'quantity_in_stock': 'Ilość na stanie',
    'unit': 'Jednostka'
};

const getColumnName = (column: string): string => {
    if (columnsDict[column]) {
        return columnsDict[column];
    } else {
        return column;
    }
};



const ImportNotificationPopup = () => {
    const errors = useSelector(getCategoryImportErrors);
    const dispatch = useDispatch();


    return (
        errors ?
            <div className="notification-popup import-errors">
                <div className="header">
                    <span className="message error">Błądy w imporcie pliku:</span>
                    <div onClick={() => dispatch(setCategoryImportErrorsAction(null))}
                         className="close">
                        <img src={icons.closeIcon} alt="close"/>
                    </div>
                </div>
                <div className="errors">
                    {errors.map(error =>
                        <span>Rząd: {error.row}, Kolumna: {getColumnName(error.column)}, Błąd: {getImportErrorMessage(error.detail)} </span>
                    )}
                </div>
            </div> :
            <></>

    )
};

export default ImportNotificationPopup;