import {parseQueryString} from "../../../../services/utils";
import {MerchantSummary} from "../../../services/interfaces";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import {translateServiceType} from "../../../../services/dictionaries/serviceTypesDict";
import {useState} from "react";
import AdminMerchantPointRow from "./AdminMerchantPointRow";


const AdminMerchantRow = ({merchant, filters}: { merchant: MerchantSummary, filters: string }) => {
    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <tr>
                {!excludedColumns.includes('name') &&
                    <td className="merchant-name">
                        {merchant.merchant_points.length > 1 &&
                            <span className={`chevron ${isOpen ? 'up' : 'down'}`}
                                  onClick={() => setIsOpen(!isOpen)}></span>}
                        {merchant.name}
                    </td>}
                {!excludedColumns.includes('service_type') &&
                    <td>{merchant.service_type.map(s => translateServiceType(s)).join(', ')}</td>}
                {!excludedColumns.includes('points_count') &&
                    <td>{merchant.merchant_points.length}</td>}
                {!excludedColumns.includes('cashback') &&
                    <td className="cell-digit">
                        {formatNumber(merchant.cashback)}
                        <span
                            className="gray"> ({formatNumber(merchant.cashback / merchant.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                    </td>}
                {!excludedColumns.includes('cashback_share') &&
                    <td className="cell-digit">{formatNumber(merchant.cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
                {!excludedColumns.includes('payprofit_fee') &&
                    <td className="cell-digit">
                        {formatNumber(merchant.payprofit_fee)}
                        <span
                            className="gray"> ({formatNumber(merchant.payprofit_fee / merchant.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                    </td>}
                {!excludedColumns.includes('payprofit_fee_share') &&
                    <td className="cell-digit">{formatNumber(merchant.payprofit_fee_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
                {!excludedColumns.includes('partner_cashback') &&
                    <td className="cell-digit">
                        {formatNumber(merchant.partner_cashback)}
                        <span
                            className="gray"> ({formatNumber(merchant.partner_cashback / merchant.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                    </td>}
                {!excludedColumns.includes('partner_cashback_share') &&
                    <td className="cell-digit">{formatNumber(merchant.partner_cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
                {!excludedColumns.includes('forest_cashback') &&
                    <td className="cell-digit">
                        {formatNumber(merchant.forest_cashback)}
                        <span
                            className="gray"> ({formatNumber(merchant.forest_cashback / merchant.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                    </td>}
                {!excludedColumns.includes('forest_cashback_share') &&
                    <td className="cell-digit">{formatNumber(merchant.forest_cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
                {!excludedColumns.includes('due_payment') &&
                    <td className="cell-digit">{formatNumber(merchant.due_payment)}</td>}
                {!excludedColumns.includes('transactions_count') &&
                    <td className="cell-digit">{formatNumber(merchant.transactions_count, WHOLE_NUMBER_FORMAT)}</td>}
            </tr>
            {isOpen && merchant.merchant_points.map(point => <AdminMerchantPointRow merchantPoint={point}
                                                                                    excludedColumns={excludedColumns}/>)}
        </>

    )
};

export default AdminMerchantRow;