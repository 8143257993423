import {
    ChartData,
    ChartInterval,
    ChartType,
    ChartValueKey,
    DatePreset,
    DateRange,
    PieChartData,
    PreviousPeriod,
    RangeBase,
    StatisticsData,
    StatisticsDataKey,
    TargetGroupRangeKey,
} from "../../services/interfaces";
import {icons} from "../../services/images";
import React, {useRef, useState} from "react";
import ActionModal from "../modals/ActionModal";
import ChartDetailsModal from "./ChartDetailsModal";
import {useDispatch, useSelector} from "react-redux";
import {
    getStatisticsActiveClientInterval,
    getStatisticsAggregate,
    getStatisticsCurrentTargetGroup
} from "../../redux/selectors/statisticsSelectors";
import DateIntervalBarChart from "../common/chart/DateIntervalBarChart";
import {Tooltip} from "react-tooltip";
import TargetGroupRangesModal from "../modals/target_group/TargetGroupRangesModal";
import {getStatisticTitle, getStatisticTooltip} from "../../services/dictionaries/statisticsInfo";
import RangePieChartContainer from "./chart/RangePieChartContainer";
import {changeCurrentTargetGroupAction, TARGET_GROUP_RANGE_KEYS} from "../../redux/merchant";
import GenderPieChartContainer from "./chart/GenderPieChartContainer";
import NoChart from "../common/chart/NoChart";
import {TargetGroupRangeKeyEnum} from "../../services/dictionaries/enums";
import ActiveClientIntervalSelect from "./ActiveClientIntervalSelect";
import {setActiveClientIntervalAction} from "../../redux/statistics";
import {clientsTooltipFormat, transactionTooltipFormat} from "../../services/chartUtils";
import {DOUBLE_DECIMAL_NUMBER_FORMAT} from "../../services/numbers";

interface Props {
    dataKey: StatisticsDataKey
    statisticsData: StatisticsData
    previousPeriod: PreviousPeriod
    chartInterval: ChartInterval
    barChartSelected: boolean
    dateRange: DateRange
    datePreset: DatePreset | null
    admin?: boolean
}


const StatisticsChartTile = ({
                                 dataKey,
                                 statisticsData,
                                 previousPeriod,
                                 chartInterval,
                                 barChartSelected,
                                 dateRange,
                                 datePreset,
                                 admin = false,
                             }: Props) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [isRangesModalOpen, setIsRangesModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const actionRef = useRef<(HTMLDivElement | null)>(null);
    const currentTargetGroup = useSelector(getStatisticsCurrentTargetGroup);
    const activeClientInterval = useSelector(getStatisticsActiveClientInterval);
    const aggregate = useSelector(getStatisticsAggregate);
    const dispatch = useDispatch();

    let targetGroupRangeKey = dataKey + '_ranges' as TargetGroupRangeKey;
    if (aggregate === 'clients' && dataKey === 'amount') {
        targetGroupRangeKey = 'amount_sum_ranges';
    }
    const title = getStatisticTitle(dataKey, aggregate);
    const tooltip = getStatisticTooltip(dataKey, aggregate);

    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const toggleDetailsModal = () => {
        setIsDetailsModalOpen(!isDetailsModalOpen);
        setIsActionModalOpen(false);
    };

    const toggleRangesModal = () => {
        setIsRangesModalOpen(!isRangesModalOpen);
        setIsActionModalOpen(false);
    };

    let actions = [
        {
            title: 'Pokaż szczegóły',
            onClick: toggleDetailsModal,
            className: ''
        },
    ];

    if (TARGET_GROUP_RANGE_KEYS.includes(targetGroupRangeKey)) {
        actions.push({
            title: 'Ustal zakresy',
            onClick: toggleRangesModal,
            className: ''
        })
    }

    const getChart = () => {
        if (dataKey === "gender")
            return <GenderPieChartContainer data={statisticsData.gender} genderData={currentTargetGroup.gender}
                                            aggregate={aggregate}/>;
        else if (TARGET_GROUP_RANGE_KEYS.includes(targetGroupRangeKey))
            return <RangePieChartContainer
                data={statisticsData[dataKey] as PieChartData[]}
                targetGroupRangeKey={targetGroupRangeKey as TargetGroupRangeKey}
                targetGroupRanges={currentTargetGroup[targetGroupRangeKey] as RangeBase[]} aggregate={aggregate}/>;
        else {
            let valueKey: ChartValueKey = "value";
            let chartType: ChartType = "absolute";
            let chartData: ChartData[] = statisticsData.current.aggregate_data;
            let prevChartData: ChartData[] | undefined = undefined;
            let tooltipFormat = undefined;
            if (dataKey === "dynamics") {
                valueKey = "change";
                chartType = "percent";
                chartData = statisticsData.current.aggregate_data;
                prevChartData = statisticsData.previous.aggregate_data;
                if (previousPeriod === 'hide')
                    return <NoChart/>
            } else if (dataKey === "average") {
                valueKey = "average";
                if (aggregate === 'amount') {
                    chartType = "currency";
                    chartData = statisticsData.average_amount;
                } else if (aggregate === 'transactions') {
                    tooltipFormat = (value: number) => transactionTooltipFormat(value, DOUBLE_DECIMAL_NUMBER_FORMAT);
                    chartData = statisticsData.current.aggregate_data;
                } else if (aggregate === 'clients') {
                    tooltipFormat = (value: number) => clientsTooltipFormat(value, DOUBLE_DECIMAL_NUMBER_FORMAT);
                    chartData = statisticsData.current.aggregate_data;
                }

            } else {
                chartData = statisticsData[dataKey] as ChartData[];
                tooltipFormat = clientsTooltipFormat;
            }
            return <DateIntervalBarChart chartData={chartData}
                                         prevChartData={prevChartData}
                                         interval={chartInterval}
                                         valueKey={valueKey}
                                         chartType={chartType}
                                         height={160}
                                         tooltipFormat={tooltipFormat}/>;
        }
    };

    return (
        <div
            className={`block block-1-3 chart-block chart-block-vertical ${dataKey === "active_clients" ? 'active-clients' : ''}`}>
            <div className="title-section" ref={actionRef}>
                <Tooltip id={`${dataKey}Tooltip`} className="tooltip" noArrow={true}>
                    <p className="tooltip-title">{title}</p>
                    <p className="tooltip-body">{tooltip}</p>
                </Tooltip>
                <div className="title">
                    <span>{title}</span>
                    <img src={icons.infoGrayIcon}
                         alt="info"
                         data-tooltip-id={`${dataKey}Tooltip`}
                         data-tooltip-place="bottom-end"
                         width={16} height={16}/>
                </div>
                <div className="actions-button" onClick={toggleActionModal}>
                    <img src={icons.actionsButtonIcon}
                         alt="actionsButtonIcon"
                    />
                </div>
            </div>
            {dataKey === "active_clients" &&
                <ActiveClientIntervalSelect activeClientInterval={activeClientInterval}
                                            onIntervalChange={(interval) => dispatch(setActiveClientIntervalAction(interval))}/>
            }
            {getChart()}
            <ActionModal isOpen={isActionModalOpen}
                         onClose={toggleActionModal}
                         actions={actions}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
            {isDetailsModalOpen &&
                <ChartDetailsModal isOpen={isDetailsModalOpen}
                                   setIsOpen={setIsDetailsModalOpen}
                                   title={title}
                                   dataKey={dataKey}
                                   parentAggregate={aggregate}
                                   parentDateRange={dateRange}
                                   parentDatePreset={datePreset}
                                   parentChartInterval={chartInterval}
                                   parentActiveClientInterval={dataKey === "active_clients" ? activeClientInterval : undefined}
                                   parentPreviousPeriod={previousPeriod}
                                   parentBarChartSelected={barChartSelected}
                                   admin={admin}
                />}
            {TARGET_GROUP_RANGE_KEYS.includes(targetGroupRangeKey) &&
                <TargetGroupRangesModal isOpen={isRangesModalOpen}
                                        setIsOpen={setIsRangesModalOpen}
                                        savedRanges={currentTargetGroup[targetGroupRangeKey] as RangeBase[]}
                                        title={TargetGroupRangeKeyEnum[targetGroupRangeKey] as string}
                                        onUpdateRanges={(ranges) => dispatch(changeCurrentTargetGroupAction({
                                            field: targetGroupRangeKey,
                                            value: ranges
                                        }))}
                                        rangeKey={targetGroupRangeKey}
                />
            }
        </div>
    )
};

export default StatisticsChartTile;