import {PreparedPieChartData} from "../../../services/interfaces";
import {getPieChartColorById} from "../../../services/chartUtils";
import {formatNumber, WHOLE_NUMBER_FORMAT_PERCENTAGE} from "../../../services/numbers";

interface Props {
    entry: PreparedPieChartData,
    value: number,
    checked: boolean,
    selectable: boolean,
    hovered: boolean,
    handleSelect: (entry: PreparedPieChartData) => void;
    setHoveredEntry: (entry: PreparedPieChartData | null) => void;
}


const PieChartLegendPill = ({entry, value, checked, selectable, hovered, setHoveredEntry, handleSelect}: Props) => {
    const className = `
        pie-chart-legend-pill 
        pie-chart-filling
        ${checked ? '' : 'unchecked'} 
        ${hovered ? 'hovered' : ''}
        ${selectable ? "selectable" : "not-selectable"}
    `;

    return (
        <div className={className}
             style={{backgroundColor: getPieChartColorById(entry.id)}}
             onClick={() => handleSelect(entry)}
             onMouseOver={() => setHoveredEntry(entry)}
             onMouseLeave={() => setHoveredEntry(null)}
        >
            <span className="pie-chart-legend-value">
              {formatNumber(value, WHOLE_NUMBER_FORMAT_PERCENTAGE)}
            </span>
        </div>
    )
};
export default PieChartLegendPill