import {ChangeEvent} from "react";

interface Props {
    checked: boolean
    setChecked: (value: boolean) => void
}

const Switch = ({checked, setChecked}: Props) => {

    const toggleSwitch = (event: ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked);

    return (
        <div className="switch-wrapper">
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={toggleSwitch}/>
                <span className="slider round"></span>
            </label>
        </div>
    )
};

export default Switch;
