import React, {useState} from "react";
import Button from "./Button";
import {icons} from "../../services/images";
import Modal from "react-modal";
import Stepper from "../modals/Stepper";


const CreateOfferButton = () => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <Button label="Utwórz nową promocję" onClick={() => setIsModalOpen(true)} icon={icons.plusIcon}/>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={toggleModal}
                overlayClassName="modal-wrapper"
                ariaHideApp={false}
                className="stepper-modal-content filters-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Dodaj promocję</h5>
                    <div className="modal-close" onClick={toggleModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <Stepper closeModal={closeModal}/>
            </Modal>
        </>
    )
};

export default CreateOfferButton;