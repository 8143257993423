import {icons} from "../../services/images";
import React from "react";
import {useSelector} from "react-redux";
import {getCurrentMerchantPoint, getIsMerchantSelected, getMerchant} from "../../redux/selectors/merchantSelector";
import {getMerchantCupCount} from "../../services/helpers";


const SidebarCupsCount = () => {
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const isMerchantSelected = useSelector(getIsMerchantSelected);

    if (!merchant)
        return <div/>;

    const cupsCount = isMerchantSelected ? getMerchantCupCount(merchant) : merchantPoint.cup_count;

    return (
        <div className="sidebar-cups-count">
            <span>{cupsCount}</span>
            <img src={icons.cupUsedIcon} alt="cup"/>
        </div>
    )
};

export default SidebarCupsCount;