import {
    calculateChange,
    DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE,
    formatNumber,
    WHOLE_NUMBER_FORMAT_PERCENTAGE,
} from "../../services/numbers";
import {useSelector} from "react-redux";
import {getPreviousPeriod} from "../../redux/selectors/merchantSelector";

interface Props {
    value?: number
    prevValue?: number
}

const ChangePill = ({value, prevValue}: Props) => {
    const previousPeriod = useSelector(getPreviousPeriod);

    if (previousPeriod === 'hide')
        return <></>;

    const change = calculateChange(value, prevValue);

    let extraClassName = "";
    if (change > 0)
        extraClassName = "positive";
    else if (change < 0)
        extraClassName = "negative";

    return (
        <div className={`change-pill ${extraClassName}`}>
            <div className="arrow"/>
            <span className="label-2 change-value">
                {formatNumber(change, change ? DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE : WHOLE_NUMBER_FORMAT_PERCENTAGE)}
            </span>
        </div>
    )
};

export default ChangePill;

