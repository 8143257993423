import request, {DELETE, GET, POST, PUT} from "./request";
import {handleError} from "./handleError";
import {TargetGroupCreateData, TargetGroupUpdateData} from "../services/interfaces";

export const getTargetGroups = async () => {
    try {
        const {data} = await request(GET, `target_group/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const createTargetGroup = async (targetGroupCreateData: TargetGroupCreateData) => {
    try {
        const {data} = await request(POST, `target_group/`, {...targetGroupCreateData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateTargetGroup = async (targetGroupId: number, updateData: TargetGroupUpdateData) => {
    try {
        const {data} = await request(PUT, `target_group/${targetGroupId}`, {...updateData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteTargetGroup = async (targetGroupId: number) => {
    try {
        const {data} = await request(DELETE, `target_group/${targetGroupId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const duplicateTargetGroup = async (targetGroupId: number) => {
    try {
        const {data} = await request(POST, `target_group/${targetGroupId}/duplicate`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantTargetGroupUsers = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `target_group/current_users_count/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getTargetGroupUsersExceptMerchant = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `target_group/new_users_count/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};