import AdminUsers from "../components/users/AdminUsers";
import {useSelector} from "react-redux";
import {getCurrentTargetGroup} from "../../redux/selectors/merchantSelector";
import {DateRange} from "../../services/interfaces";
import {useEffect} from "react";
import {
    getAdminChartInterval,
    getAdminDateRange,
    getAdminGlobalFilters,
    getAdminPreviousPeriod
} from "../redux/selectors/adminViewSettingsSelectors";
import {getUsersCountHandler, getUsersStatisticsHandler} from "../handlers/adminUsersHandlers";
import {getStatisticsAggregate, getStatisticsFilterOrder} from "../../redux/selectors/statisticsSelectors";


const AdminUsersContainer = () => {
    const currentTargetGroup = useSelector(getCurrentTargetGroup);
    const aggregate = useSelector(getStatisticsAggregate);
    const filterOrder = useSelector(getStatisticsFilterOrder);
    const previousPeriod = useSelector(getAdminPreviousPeriod);
    const chartInterval = useSelector(getAdminChartInterval);
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const globalFilters = useSelector(getAdminGlobalFilters);


    useEffect(() => {
        getUsersStatisticsHandler(aggregate, currentTargetGroup, filterOrder, previousPeriod, chartInterval, dateRange, globalFilters);
    }, [currentTargetGroup, aggregate, filterOrder, previousPeriod, chartInterval, dateRange, globalFilters]);


    useEffect(() => {
        getUsersCountHandler(dateRange)
    }, [dateRange]);

    return <AdminUsers/>
};

export default AdminUsersContainer;