import {Select} from "antd";
import React from "react";

interface Props {
    title: string;
    placeholder: string;
    options: { children: string, value: string }[] | undefined;
    values: string[];
    setValues: React.Dispatch<React.SetStateAction<string[]>>;
    setChildren?: boolean;
}

const HistoryDropdown = ({title, placeholder, options, values, setValues, setChildren = false}: Props) => {

    const showValues = setChildren ? values.map((v: string) => options?.find(option => option.children === v)?.value || '') : values;

    return (
        <div className="section">
            <h6>{title}</h6>
            <Select
                mode="multiple"
                showSearch
                value={showValues}
                onChange={(value: string[]) => {
                    if (setChildren) {
                        setValues(value.map((v: string) => options?.find(o => o.value === v)?.children || ''));
                    } else {
                        setValues(value);
                    }
                }}
                placeholder={placeholder}
                className="dropdown-select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option ? (
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    ) : false
                }
                options={options}
            />
        </div>
    )
};

export default HistoryDropdown;