import {useSelector} from "react-redux";
import {getChartInterval, getPreviousPeriod} from "../../redux/selectors/merchantSelector";
import {useMemo} from "react";


const TimeRangeLabel = () => {
    const previousPeriod = useSelector(getPreviousPeriod);
    const chartInterval = useSelector(getChartInterval);

    const label = useMemo(() => {
        const baseLabel = 'Zmiana względem ';

        if (previousPeriod === 'year')
            return baseLabel + 'analogicznego okresu z poprzedniego roku';

        switch (chartInterval) {
            case "DAY":
                return baseLabel + 'poprzedniego dnia';
            case "LAST_7_DAYS":
                return baseLabel + 'poprzednich 7 dni';
            case "LAST_30_DAYS":
                return baseLabel + 'poprzednich 30 dni';
            case "LAST_90_DAYS":
                return baseLabel + 'poprzednich 90 dni';
            case "LAST_365_DAYS":
                return baseLabel + 'poprzednich 365 dni';
            case "WEEK":
                return baseLabel + 'poprzedniego tygodnia';
            case "MONTH":
                return baseLabel + 'poprzedniego miesiąca';
            case "QUARTER":
                return baseLabel + 'poprzedniego kwartału';
            case "YEAR":
                return baseLabel + 'poprzedniego roku';
        }
    }, [chartInterval, previousPeriod]);

    if (previousPeriod === 'hide')
        return <></>;

    return (
        <p className="label-2 change-timeframe">{label}</p>
    )
};

export default TimeRangeLabel;

