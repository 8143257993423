import React, {Dispatch, SetStateAction} from "react";
import {CategoryFilters, CategoryType} from "../../../services/interfaces";
import {CATEGORY_FILTERS_DEFAULT_STATE} from "./BookingEdit";

interface TabManagerProps {
    tab: CategoryType
    setTab: Dispatch<SetStateAction<CategoryType>>
    setFilters: (filters: CategoryFilters) => void
}

const TabManager = ({tab, setTab, setFilters}: TabManagerProps) => {

    const onChange = (categoryType: CategoryType) => {
        setTab(categoryType);
        setFilters(CATEGORY_FILTERS_DEFAULT_STATE)
    };

    return (
        <div className="tab-manager">
            <div onClick={() => tab === 'service' && onChange('product')}
                 className={`tab-pill ${tab === 'product' ? 'selected' : ''}`}><span>Zamówienia</span></div>
            <div onClick={() => tab === 'product' && onChange('service')}
                 className={`tab-pill ${tab === 'service' ? 'selected' : ''}`}><span>Rezerwacje</span></div>
        </div>
    )
};


export default TabManager;