import React from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import Input from "../../common/Input";
import Button from "../../common/Button";
import {addMerchantDepositHandler} from "../../../handlers/merchantHandler";
import {formatNumber} from "../../../services/numbers";
import {useDispatch, useSelector} from "react-redux";
import {setMerchantDepositAction} from "../../../redux/merchant";
import {getModalOpen} from "../../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../../redux/navigation";
import {Merchant} from "../../../services/interfaces";

interface Props {
    merchant: Merchant | null
}

const DepositModal = ({merchant}: Props) => {

    const isOpen = useSelector(getModalOpen('deposit'));

    const [data, setData] = React.useState<{ depositAmount: number | null }>({depositAmount: null});
    const [successScreen, setSuccessScreen] = React.useState<boolean>(false);
    const [sendClicked, setSendClicked] = React.useState<boolean>(false);

    const minimumAmount = merchant ? Math.ceil(merchant.deposit_requirement) : 0;

    const dispatch = useDispatch();

    const addDepositSuccessCallback = ({deposit, deposit_requirement}: {
        deposit: number,
        deposit_requirement: number
    }) => {
        setSuccessScreen(true);
        dispatch(setMerchantDepositAction({deposit, deposit_requirement}));
    };

    const closeDepositModal = () => {
        dispatch(modalCloseAction('deposit'));
        setSuccessScreen(false);
        setSendClicked(false);
        setData({depositAmount: null});
    }

    const minimumAmountValidation = (value: number | null, minimumAmount: number | null): string | null => {
        if (value && minimumAmount && value < minimumAmount) {
            return 'minimumAmount';
        }
        return null;
    }

    const buttonDisabled = !data.depositAmount || sendClicked || !!minimumAmountValidation(data.depositAmount, minimumAmount);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeDepositModal}
            overlayClassName="modal-wrapper"
            className="modal-content popup deposit"
            ariaHideApp={false}
        >
            {!successScreen &&
                <div className="modal-header">
                    <h5 className="modal-title">Zasil konto PayProfit</h5>
                    <div className="modal-close" onClick={() => closeDepositModal()}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
            }
            <div className="modal-body">
                {successScreen ?
                    <>
                        <div className="success-screen">
                            <img src={icons.successIcon} alt="success"/>
                            <h2>Pomyślnie doładowano saldo</h2>
                            <p className="message">Doładowano {formatNumber(data.depositAmount)} na twoje konto
                                PayProfit.</p>
                            <Button
                                label="Powrót"
                                onClick={closeDepositModal}
                                fullWidth={true}
                            />
                        </div>
                    </> :
                    <>
                        <Input<{ depositAmount: number | null }, 'depositAmount'>
                            label="Wpisz kwotę (w PLN)"
                            placeholder="Podaj kwotę którą chcesz przelać na swoje konto PayProfit..."
                            type="number"
                            min={0}
                            name="depositAmount"
                            value={data}
                            onChange={setData}
                            appendix="zł"
                            showError
                            errorMessage={{minimumAmount: `Minimalna kwota to ${formatNumber(minimumAmount)}`}}
                            validate={(value) => minimumAmountValidation(value, minimumAmount)}
                        />
                        <Button
                            label="Przejdź do płatności"
                            onClick={() => {
                                setSendClicked(true);
                                data.depositAmount && addMerchantDepositHandler(data.depositAmount, addDepositSuccessCallback);
                            }}
                            disabled={buttonDisabled}
                            loading={sendClicked}
                            fullWidth={true}
                        />
                    </>
                }
            </div>
        </Modal>
    )
}

export default DepositModal;