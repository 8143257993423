import React, {Dispatch, SetStateAction, useEffect, useRef} from "react";
import {icons} from "../../../services/images";

interface Props {
    onSend: () => void;
    input: string;
    setInput: Dispatch<SetStateAction<string>>;
    sendDisabled: boolean
}

const ChatFooter = ({input, setInput, onSend, sendDisabled}: Props) => {

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            // Reset height to auto to get the correct scrollHeight
            textarea.style.height = '20px';
            // Set the height to scrollHeight to fit the content
            textarea.style.height = `${Math.max(20, textarea.scrollHeight)}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [input]);


    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent default to avoid new line
            if (!sendDisabled) {
                onSend();
            }
        }
    };
    return (
        <div className="chat-footer">
            <div className="message-input">
                     <textarea placeholder="Wiadomość..." maxLength={256}
                               ref={textareaRef}
                               value={input}
                               onKeyDown={handleKeyPress}
                               onChange={event => setInput(event.target.value)}/>
            </div>
            <div onClick={() => !sendDisabled && onSend()} className={`send-button ${sendDisabled ? 'disabled' : ''}`}>
                <img src={icons.sendIcon} alt=""/>
            </div>
        </div>
    )
};

export default ChatFooter