import {Select} from "antd";
import {ActiveClientInterval} from "../../services/interfaces";


interface Props {
    activeClientInterval: ActiveClientInterval ;
    onIntervalChange: (value: ActiveClientInterval) => void;
}


const ActiveClientIntervalSelect = ({activeClientInterval, onIntervalChange}: Props) => {
    return (
        <div className="active-client-interval-select">
            <span className="label-2">Okres: </span>
            <Select
                value={activeClientInterval}
                onChange={onIntervalChange}
                options={[
                    {value: "week" as ActiveClientInterval, label: 'Tydzień'},
                    {value: "two_weeks" as ActiveClientInterval, label: 'Dwa tygodnie'},
                    {value: "month" as ActiveClientInterval, label: 'Miesiąc'},
                ]}
            />
        </div>
    )
};

export default ActiveClientIntervalSelect;