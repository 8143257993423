import Modal from "react-modal";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import {useCallback, useEffect, useMemo, useState} from "react";
import Input from "../../common/Input";
import {Category, CategoryType} from "../../../services/interfaces";
import {createCategoryHandler, getCategoriesHandler, updateCategoryHandler} from "../../../handlers/bookingHandlers";
import ErrorComponent from "../../common/ErrorComponent";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    category: Category | null;
    categoryType: CategoryType;
}

interface CategoryDataInterface {
    name: string;
}

const CategoryModal = ({isOpen, onClose, category, categoryType}: Props) => {
    const [data, setData] = useState<CategoryDataInterface>({
        name: "",
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        category && setData({name: category.name});
    }, [isOpen]);

    const closeModal = useCallback(() => {
        onClose();
        setData({name: ''});
        setError(null);
    }, [setData, setError, onClose]);

    const title = useMemo(() => {
        return category ? 'Edytuj kategorię' : 'Dodaj kategorię'
    }, [category]);

    const saveButtonTitle = useMemo(() => {
        return category ? 'Zapisz kategorię' : 'Utwórz kategorię'
    }, [category]);

    const saveCallback = useCallback(() => {
        setIsLoading(false);
        closeModal();
        getCategoriesHandler(categoryType)
    }, [categoryType, setIsLoading, closeModal]);

    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onSave = useCallback(() => {
        setIsLoading(true);
        if (category)
            updateCategoryHandler(categoryType, data.name, category.id, saveCallback, errorCallback);
        else {
            createCategoryHandler(categoryType, data.name, saveCallback, errorCallback);
        }

    }, [categoryType, category, data, saveCallback, errorCallback]);

    const isDisabled = useMemo(() => {
        if (category && data.name === category.name)
            return true;
        return !data.name || isLoading
    }, [isLoading, data, category]);


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content category-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">{title}</h5>
                <div/>
            </div>
            <div className="modal-body">
                <Input<CategoryDataInterface, 'name'>
                    value={data}
                    onChange={setData}
                    name="name"
                    label="Nazwa kategorii"
                    placeholder="Podaj nazwę kategorii..."
                    maxLength={128}
                />
                {error && <ErrorComponent error={error}/>}
            </div>

            <div className="modal-footer">
                <Button label={saveButtonTitle} onClick={onSave} fullWidth={true} loading={isLoading}
                        disabled={isDisabled}/>
            </div>
        </Modal>
    )
};


export default CategoryModal;