import Modal from "react-modal";
import {OfferData} from "./Stepper";
import Button from "../common/Button";
import React from "react";
import {FacebookShareButton, TwitterShareButton} from "react-share";

interface OfferSuccessModalProps {
    onClose: ()=>void;
    formData: OfferData;
}

const OfferSuccessModal = ({formData, onClose}: OfferSuccessModalProps) => {
    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="stepper-modal-content"
            ariaHideApp={false}
        >
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "wrap", marginTop: 96, marginBottom: 24}}>
                <div className="badge badge-success"/>
                <h3>Pomyślnie utworzono promocję</h3>
                <p style={{fontFamily: "Roboto", fontWeight: 400, fontSize: 16, color: "black", opacity: 0.7, marginLeft: 68, marginRight: 68}}>Twoja płatność przebiegła pomyślnie.
                    Ciesz się większym zainteresowaniem klientów dzięki nowo utworzonej promocji!</p>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: 88, justifyContent: "space-between"}}>
                {/*<Button label="Kontynuuj"*/}
                {/*            onClick={onClose}*/}
                {/*            />*/}
                <FacebookShareButton hashtag={"#payprofit"} url={"https://payprofit.pl/"}>
                    <Button label="Udostępnij post na facebook"
                            onClick={onClose}
                            type="Default"
                    />
                </FacebookShareButton>
                <TwitterShareButton url={"https://payprofit.pl/"} title={"Polecam tę aplikację"} hashtags={["payprofit","cashback"]} related={["@groszala"]}>
                    <Button label="Udostępnij post na X"
                            onClick={onClose}
                            type="Default"
                    />
                </TwitterShareButton>
            </div>
        </Modal>
    );
};

export default OfferSuccessModal;