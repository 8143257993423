import {images} from "../../../services/images";
import {useState} from "react";
import {adminSidebarItems} from "../../../services/dictionaries/sidebarItems";
import AdminNavigationSidebarItem from "./AdminNavigationSideBarItem";
import AdminSettingsModal from "../modals/AdminSettingsModal";
import AdminContactModal from "../modals/contact/AdminContactModal";


const AdminNavigationSidebar = () => {
    const [open, setOpen] = useState(false);
    const topSidebarItems = adminSidebarItems.filter(item => !['contact', 'settings'].includes(item.name));
    const bottomSidebarItems = adminSidebarItems.filter(item => ['contact', 'settings'].includes(item.name));


    return (
        <nav className="sidebar">
            <div className="nav-header">
                <img src={images.payProfitAdminLogoBlack} alt="logo-black" className="logo"/>
                <div className="nav-mobile" onClick={() => setOpen(!open)}>
                    <div className={`nav-toggle ${open ? 'active' : ''}`}>
                        <span/>
                    </div>
                </div>
            </div>
            <div className="nav-content">
                <div className="nav-top">
                    <ul className="nav-dropdown list-nav">
                        {topSidebarItems.map(item => <AdminNavigationSidebarItem {...item}
                                                                                 key={`SidebarMenuItem${item.name}`}
                        />)}
                    </ul>
                </div>
                <div className="nav-dropdown nav-bottom">
                    <ul className="list-nav">
                        {bottomSidebarItems.map(item => <AdminNavigationSidebarItem {...item}
                                                                                    key={`SidebarMenuItem${item.name}`}/>)}
                    </ul>
                </div>
            </div>
            <AdminSettingsModal/>
            <AdminContactModal/>
        </nav>
    )
};

export default AdminNavigationSidebar
