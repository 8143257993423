import {
    changePassword,
    getUserDetails,
    logIn,
    logOut,
    resendVerificationCode, resetPassword, sendResetPasswordEmail,
    sendVerificationCode,
    signUp,
} from "../http/auth";
import {AuthResponse, LoginCredentials, SignUpCredentials} from "../services/interfaces";
import {store} from "../redux/store";
import {
    authenticateAction,
    logoutAction,
    setAccountError,
    setAdminUserDetailsAction,
    setUserDetailsAction
} from "../redux/auth";
import React from "react";
import {resetMerchantStateAction} from "../redux/merchant";
import {clearAuthItems, storeAuthItems} from "../services/storage/secureStorage";

export const loginHandler = (data: LoginCredentials, setSendClicked: React.Dispatch<React.SetStateAction<boolean>>, admin: boolean = false) => {
    setSendClicked(true);
    logIn(data, admin).then(response => {
        const {data}: { data: AuthResponse } = response;

        storeAuthItems(data.access_token, data.refresh_token, data.expire, admin);
        store.dispatch(authenticateAction({
            accessToken: data.access_token,
            expDate: String(data.expire),
            adminPanel:data.admin_panel
        }));
        setSendClicked(false);

    }).catch(error => {
        setSendClicked(false);
        if ([400, 401, 409, 421, 422].includes(error.status))
            store.dispatch(setAccountError(error.detail));
        if (error.status === 500) {
            store.dispatch(setAccountError(error.error))
        }
    })
};

export const signUpHandler = (data: SignUpCredentials, callback: ({success}: { success: boolean }) => void) => {

    signUp(data).then(response => {
        const {data}: { data: AuthResponse } = response;
        storeAuthItems(data.access_token, data.refresh_token, data.expire);
        store.dispatch(authenticateAction({
            accessToken: data.access_token,
            expDate: String(data.expire),
        }));
        callback({success: true});
    }).catch(error => {
        if ([400, 401, 409, 421, 422].includes(error.status))
            store.dispatch(setAccountError(error.detail));
        if (error.status === 500)
            store.dispatch(setAccountError(error.error));
        callback({success: false});
    })
};

export const logOutHandler = (admin: boolean = false) => {
    logOut(admin).then(() => {
        store.dispatch(logoutAction());
        if (!admin) {
            store.dispatch(resetMerchantStateAction());
        }
        clearAuthItems();
    }).catch(error => {
        console.log(error)
    })
};

export const getUserDetailsHandler = async (admin?: boolean) => {
    getUserDetails(admin).then(response => {
        if (admin) {
            store.dispatch(setAdminUserDetailsAction(response.data));
        }
        else {
            store.dispatch(setUserDetailsAction(response.data));
        }

    }).catch(error => {
        console.log(error)
    })
};

export const sendVerificationCodeHandler = (code: number, afterSendCallback: ({success}: {
    success: boolean
}) => void) => {
    sendVerificationCode(code).then(response => {
        afterSendCallback({success: true})
    }).catch(error => {
        afterSendCallback({success: false});
        store.dispatch(setAccountError(error.detail));
    })
};

export const resendVerificationCodeHandler = (resendCodeCallback: () => void) => {
    resendVerificationCode().then(() => {
        resendCodeCallback();
    }).catch(error => {
        console.log(error)
    })
};

export const changePasswordHandler = (data: { oldPassword: string, newPassword: string }, callback: ({success}: {
    success: boolean
}) => void, admin?: boolean) => {
    changePassword(data, admin).then(() => {
        callback({success: true})
    }).catch(error => {
        store.dispatch(setAccountError(error.detail));
        callback({success: false})
    })
};

export const sendResetPasswordEmailHandler = (email: string, callback: ({success}: { success: boolean }) => void, admin?: boolean) => {
    sendResetPasswordEmail(email, admin).then(() => {
        callback({success: true})
    }).catch(error => {
        store.dispatch(setAccountError(error.detail));
        callback({success: false})
    })
};

export const verifyResetPasswordTokenHandler = (token: string, callback: (status: string) => void, loadingCallback: React.Dispatch<React.SetStateAction<boolean>>, admin?: boolean) => {
    resetPassword(token, null, admin).then(({data}) => {
        callback(data.detail);
        loadingCallback(false);
    }).catch(error => {
        callback(error.detail);
        loadingCallback(false);
    })
};

export const resetPasswordHandler = (token: string, password: string, callback: (status: string) => void, admin?: boolean) => {
    resetPassword(token, password, admin).then(({data}) => {
        callback(data.detail)
    }).catch(error => {
        callback(error.detail)
    })
};