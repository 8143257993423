import {Select} from "antd";
import {serviceTypeTiles} from "../../../services/dictionaries/serviceTypesDict";
import React from "react";
import {ServiceTypeName} from "../../../services/interfaces";

interface Props {
    serviceTypes: ServiceTypeName[];
    setServiceTypes: React.Dispatch<React.SetStateAction<ServiceTypeName[]>>;
}

const AdminServiceTypeFilter = ({serviceTypes, setServiceTypes}: Props) => {
    return (
        <div className="section">
            <h6>Wybierz branże</h6>
            <Select
                mode="multiple"
                value={serviceTypes}
                onChange={(value) => setServiceTypes(value)}
                allowClear={true}
                placeholder="Zaznacz branże które chcesz wyświetlać..."
                showSearch={false}
                options={serviceTypeTiles.map(service => ({
                    value: service.name,
                    label: service.label
                }))}
                className="dropdown-select"
            />
        </div>
    )
};

export default AdminServiceTypeFilter