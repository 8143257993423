import React, {useEffect, useState} from "react";
import {TERMINAL_SUCCESS_PAGE} from "../../../services/dictionaries/paths";
import {useNavigate} from "react-router-dom";
import {verifyTerminalHandler} from "../../../handlers/bankHandlers";
import {icons} from "../../../services/images";


const ConfigureTerminalVerify = () => {
    const navigate = useNavigate();
    const [verified, setVerified] = useState<boolean>(false);


    useEffect(() => {
        let isComponentMounted = true;

        const checkTerminal = () => {
            verifyTerminalHandler(
                (isVerified: boolean) => {
                    if (isComponentMounted) {
                        setVerified(isVerified);
                    }
                },
                () => {
                    clearInterval(intervalId);
                });
        };

        checkTerminal();
        const intervalId = setInterval(checkTerminal, 10000);

        return () => {
            isComponentMounted = false;
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (verified) {
            navigate(TERMINAL_SUCCESS_PAGE);
        }
    }, [verified, navigate]);

    return (
        <>
            <img src={icons.verificationTerminalIllustration} alt="terminal-illustration"/>
            <h2>Weryfikacja terminala</h2>
            <p className="note">
                Weryfikujemy nazwę Twojego terminala.&nbsp;
                Weryfikacja może potrwać do 24 godzin.<br/>
                O jej wyniku powiadomimy Cię mailowo.
            </p>
        </>
    )
};

export default ConfigureTerminalVerify;