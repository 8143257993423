import React from "react";
import {ServiceType, ServiceTypeName, ServiceTypeTile} from "../../services/interfaces";
import Modal from "react-modal";
import {getServiceTypeIcon, icons} from "../../services/images";
import {serviceTypeTiles} from "../../services/dictionaries/serviceTypesDict";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeServiceType: ServiceType;
    onServiceTypeSelect: (serviceTypeName: ServiceTypeName) => void;
    selectedServiceTypeNames: ServiceTypeName[];
}


const ServiceTypesModal = ({isOpen, setIsOpen, activeServiceType, onServiceTypeSelect, selectedServiceTypeNames}: Props) => {

    const toggleServiceTypesModal = () => setIsOpen(!isOpen);

    const handleTileClick = (tile: ServiceTypeTile) =>
        tile.name !== activeServiceType.name &&
        !selectedServiceTypeNames.includes(tile.name) &&
        onServiceTypeSelect(tile.name);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleServiceTypesModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content service-types-modal"
        >
            <div className="modal-header">
                <h5>Wybierz {activeServiceType.is_main ? 'głowną kategorię' : 'kategorię poboczną'}</h5>
                <div className="modal-close" onClick={toggleServiceTypesModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="service-types-grid">
                    {serviceTypeTiles.map((tile, index) =>
                        <div
                            onClick={() => handleTileClick(tile)}
                            key={`ServiceTypeTile${index}`}
                            className={`service-tile ${tile.name === activeServiceType.name ? 
                                'active' : selectedServiceTypeNames.includes(tile.name) ? 'selected' : ''}`}
                        >
                            <img
                                height={tile.iconHeight}
                                width={tile.iconWidth}
                                src={getServiceTypeIcon(tile.name, true)} alt=""/>
                            <span className={`category-label ${tile.name}`}>{tile.label}</span>
                        </div>
                    )}
                </div>

            </div>
            <div className="modal-footer"/>
        </Modal>
    )
};


export default ServiceTypesModal;