import {icons} from "../../../services/images";
import {getUserDetailsHandler, logOutHandler} from "../../../handlers/authHandlers";
import React, {useEffect} from "react";
import {SettingsPage} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../../../redux/selectors/authSelectors";
import {modalOpenAction} from "../../../redux/navigation";
import {getMerchantReturnsCount} from "../../../redux/selectors/merchantSelector";

interface Props {
    setSettingsPage: React.Dispatch<React.SetStateAction<SettingsPage | null>>;
}

const SettingsMenu = ({setSettingsPage}: Props) => {

    useEffect(() => {
        getUserDetailsHandler();
    }, []);

    const userDetails = useSelector(getUserDetails);

    const dispatch = useDispatch();

    const returnsCount = useSelector(getMerchantReturnsCount)

    return (
        <ul className="list-bars">
            {userDetails && userDetails.is_superuser &&
                <li onClick={() => setSettingsPage("management")}>
                    <span className="img-wrapper"><img src={icons.userActive} alt=""/></span>
                    <span>Zarządzaj dostępem</span>
                </li>
            }
            <li onClick={() => setSettingsPage("password")}>
                <span className="img-wrapper"><img src={icons.lockActive} alt=""/></span>
                <span>Twoje hasło</span>
            </li>
            <li onClick={() => dispatch(modalOpenAction('returns'))}>
                <span className="img-wrapper"><img src={icons.returnsSmallIcon} alt=""/></span>
                <span className="returns">Zwroty {returnsCount && returnsCount > 0 ?
                    <span className="badge-attention small">{returnsCount}</span> : <div/>}</span>
            </li>
            <li className="disabled" style={{cursor: 'not-allowed'}}>
                <span className="img-wrapper"><img src={icons.removeActive} alt=""/></span>
                <span>Usuń konto</span>
                <a href="#" className="link-arrow"></a>
            </li>
            <li onClick={() => logOutHandler()}>
                <span className="img-wrapper"><img src={icons.logoutActive} alt=""/></span>
                <span>Wyloguj się</span>
            </li>
        </ul>
    )

};

export default SettingsMenu;