import React, {useState} from "react";
import {isEmpty, uniqueId, sortBy} from "lodash";
import {ServiceType, ServiceTypeName} from "../../services/interfaces";
import ServiceTypesModal from "./ServiceTypesModal";
import {getServiceTypeIcon, icons} from "../../services/images";
import {serviceTypeTiles} from "../../services/dictionaries/serviceTypesDict";


interface Props {
    serviceTypes: ServiceType[];
    setServiceTypes: (serviceTypes: ServiceType[]) => void;
}

const ServiceTypesSelect = ({serviceTypes, setServiceTypes}: Props) => {
    const sortedServiceTypes = sortBy(serviceTypes, s => s.is_main ? 0 : 1);
    const [isServiceTypesModalOpen, setIsServiceTypesModalOpen] = useState(false);
    const [activeServiceType, setActiveServiceType] = useState<ServiceType>({name: "", is_main: false, id: ''});


    const addServiceType = (serviceType: ServiceType) => {
        setServiceTypes([...serviceTypes, serviceType])
    };

    const editServiceType = (serviceType: ServiceType) => {
        const editedServiceTypes = serviceTypes.map(s => (
            s.id === serviceType.id ? serviceType : s
        ));
        setServiceTypes(editedServiceTypes)
    };

    const removeServiceType = (serviceType: ServiceType) => {
        const editedServiceTypes = serviceTypes.filter(s => s.id !== serviceType.id);
        setServiceTypes(editedServiceTypes);
    };

    const handleServiceTypeSelection = (serviceTypeName: ServiceTypeName) => {
        const editedServiceType: ServiceType = {...activeServiceType, name: serviceTypeName};
        !activeServiceType.name ?
            addServiceType(editedServiceType) :
            editServiceType(editedServiceType);
        setIsServiceTypesModalOpen(false);
    };

    const onCategoryClick = (serviceType: ServiceType) => {
        setActiveServiceType(serviceType);
        setIsServiceTypesModalOpen(true);
    };

    const getServiceTileButton = (serviceType: ServiceType) => {
        const tile = serviceTypeTiles.find(tile => tile.name === serviceType.name);
        return tile ?
            <div className="remove-wrapper">
                <div className="service-tile" onClick={() => onCategoryClick(serviceType)}>
                    <img
                        height={tile.iconHeight}
                        width={tile.iconWidth}
                        src={getServiceTypeIcon(tile.name, true)} alt=""
                    />
                    <span className={`category-label ${tile.name}`}>{tile.label}</span>

                </div>
                {!serviceType.is_main &&
                    <div className="remove" onClick={() => removeServiceType(serviceType)}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                }
            </div>
            :
            <div className="category-pick">+</div>
    };

    const getCategoryLabel = (is_main: boolean) => is_main ? 'Główna kategoria ' : 'Kategoria poboczna';

    const renderAddedCategory = (serviceType: ServiceType, index: number) => (
        <div className="file-input-inner-wrapper category-inner-wrapper"
             key={`Category${index}`}
        >
            <span
                className="sub-label">{getCategoryLabel(serviceType.is_main)}
                {serviceType.is_main && <span className="required-mark">*</span>}
            </span>
            {getServiceTileButton(serviceType)}
        </div>
    );

    const renderNewCategory = (is_main: boolean) => {
        const canAddCategory = is_main || !!sortedServiceTypes.find(s => s.is_main)?.name;

        return (
            <div className={`file-input-inner-wrapper category-inner-wrapper ${canAddCategory ? '' : 'disabled'}`}
                 onClick={() => canAddCategory &&
                     onCategoryClick({name: '', is_main, id: uniqueId('new_')})}>
                <span className="sub-label">{getCategoryLabel(is_main)}
                    {is_main && <span className="required-mark">*</span>}
                </span>
                <div className="category-pick">{canAddCategory ? '+' : ''}</div>
            </div>
        )
    };

    return (
        <div className="form-section-wrapper">
            <label>Rodzaj usługi <span className="required-mark">*</span></label>
            <div className="input-wrapper category-wrapper">
                {!isEmpty(sortedServiceTypes) ?
                    sortedServiceTypes.map((serviceType, index) =>
                        renderAddedCategory(serviceType, index)
                    ) :
                    renderNewCategory(true)
                }
                {sortedServiceTypes.length < 4 &&
                    renderNewCategory(false)
                }
            </div>
            <ServiceTypesModal
                isOpen={isServiceTypesModalOpen}
                setIsOpen={setIsServiceTypesModalOpen}
                activeServiceType={activeServiceType}
                onServiceTypeSelect={handleServiceTypeSelection}
                selectedServiceTypeNames={sortedServiceTypes.map(s => s.name)}
            />
        </div>
    )
};

export default ServiceTypesSelect;