import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {getOrderFilterOptionsHandler} from "../../../handlers/bookingHandlers";
import {FilterModalProps, MultiSelectOption, OrderFilterOptions} from "../../../services/interfaces";
import {getOrderFilters} from "../../../redux/selectors/bookingSelector";
import {getFiltersClientOptions} from "../../../services/helpers";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import {toLocalISOString} from "../../../services/dates";
import {icons} from "../../../services/images";
import HistoryDropdown from "../../modals/filters/HistoryDropdown";
import HistoryDatePicker from "../../modals/filters/HistoryDatePicker";
import HistorySlider from "../../modals/filters/HistorySlider";
import Button from "../../common/Button";
import OrderStatusSelect from "./OrderStatusSelect";

export const DEFAULT_ORDER_STATUS_STATE = {
    placed: false,
    paid: false,
    canceled: false,
    completed: false,
    ready: false,
};

export interface OrderStatusState {
    placed: boolean,
    paid: boolean,
    canceled: boolean,
    completed: boolean,
    ready: boolean
}


const OrderFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: OrderFilterOptions | null = useSelector(getOrderFilters);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    useEffect(() => {
        getOrderFilterOptionsHandler()
    }, [merchantPoint]);


    const [clients, setClients] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const [status, setStatus] = useState<OrderStatusState>(DEFAULT_ORDER_STATUS_STATE);

    const [amountSlider, setAmountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_amount, filterOptions.max_amount] : null);

    const [quantitySlider, setQuantitySlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_quantity, filterOptions.max_quantity] : null);

    if (filterOptions && (amountSlider === null || quantitySlider === null)) {
        setAmountSlider([filterOptions.min_amount, filterOptions.max_amount]);
        setQuantitySlider([filterOptions.min_quantity, filterOptions.max_quantity]);
    }

    const columnsOptions: MultiSelectOption[] = [
        {value: 'ID zamówienia', children: 'id'},
        {value: 'ID klienta', children: 'client_id'},
        {value: 'Data zamówienia', children: 'created_at'},
        {value: 'Status', children: 'status'},
        {value: 'Liczba produktów', children: 'number_of_products'},
        {value: 'Kwota zakupów (zł)', children: 'total_price'}
    ];

    const clientOptions = getFiltersClientOptions(filterOptions);

    const clearFilters = () => {
        setClients([]);
        setColumns([]);
        setFromDate(null);
        setToDate(null);
        setStatus(DEFAULT_ORDER_STATUS_STATE);
        setAmountSlider([filterOptions?.min_amount || 0, filterOptions?.max_amount || 100]);
        setQuantitySlider([filterOptions?.min_quantity || 0, filterOptions?.max_quantity || 100]);
    };

    const applyFilters = () => {

        if (!amountSlider || !quantitySlider) {
            return;
        }

        const statusString = Object.keys(status)
            .filter(key => status[key as keyof OrderStatusState])
            .join(',');
        const urlFilters = '' +
            'clients=' + clients.join(',') +
            '&columns=' + columns.join(',') +
            '&fromDate=' + (fromDate ? toLocalISOString(fromDate) : '') +
            '&toDate=' + (toDate ? toLocalISOString(toDate) : '') +
            '&status=' + statusString +
            '&amount=' + amountSlider.join(',') +
            '&quantity=' + quantitySlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <HistoryDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                <HistoryDropdown
                    title="Wybierz klienta"
                    placeholder="Wybierz klienta po user ID..."
                    options={clientOptions}
                    values={clients}
                    setValues={setClients}
                />
                <HistoryDatePicker
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />
                <OrderStatusSelect status={status} setStatus={setStatus}/>
                <HistorySlider
                    label="Kwota zakupów (zł)"
                    min={filterOptions?.min_amount || 0}
                    max={filterOptions?.max_amount || 100}
                    value={amountSlider}
                    onChange={setAmountSlider}
                />
                <HistorySlider
                    label="Liczba produktów"
                    min={filterOptions?.min_quantity || 0}
                    max={filterOptions?.max_quantity || 100}
                    value={quantitySlider}
                    onChange={setQuantitySlider}
                    wholeNumber
                />
            </div>
            <div className="modal-footer">
                {filterOptions && <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default OrderFiltersModal;