import {formatNumber, WHOLE_NUMBER_FORMAT_PERCENTAGE} from "../../../services/numbers";
import {PreparedPieChartData} from "../../../services/interfaces";
import {Cell, Label, Pie, PieChart} from "recharts";
import {BALANCE_PIE_CHART_COLORS} from "../../../services/chartUtils";
import NoChart from "../../../components/common/chart/NoChart";
import {isEmpty} from "lodash";


interface Props {
    chartData: PreparedPieChartData[];
    sum: number;
}

const AdminPieChartWithLabels = ({
                                     chartData,
                                     sum
                                 }: Props) => {
    const SIZE = {WIDTH: 184, HEIGHT: 184};
    const OUTER_RADIUS = 184 / 2;
    const INNER_RADIUS = OUTER_RADIUS * 0.63;
    const CORNER_RADIUS = 6;
    const SUM_FONT_SIZE = "18px";

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}: any) => {
        const radius = (innerRadius + outerRadius) / 2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central" fontSize={14}>
                {formatNumber(percent, WHOLE_NUMBER_FORMAT_PERCENTAGE)}
            </text>
        );
    };

    const filteredData = chartData.filter(d => d.value > 0);
    return (
        isEmpty(filteredData) ?
            <NoChart/> :
            <PieChart width={SIZE.WIDTH} height={SIZE.HEIGHT}>
                <Pie
                    dataKey="value"
                    data={filteredData}
                    cx="50%"
                    cy="50%"
                    innerRadius={INNER_RADIUS}
                    outerRadius={OUTER_RADIUS}
                    startAngle={90}
                    endAngle={450}
                    fill="#82ca9d"
                    cornerRadius={CORNER_RADIUS}
                    paddingAngle={4}
                    isAnimationActive={true}
                    label={renderCustomizedLabel}
                    labelLine={false}
                >
                    {filteredData.map((entry) => (
                        <Cell
                            key={`cell-${entry.id}`}
                            fill={BALANCE_PIE_CHART_COLORS[entry.id as number]}
                            className="pie-chart-filling"
                        />
                    ))}
                    <Label
                        value={formatNumber(sum)}
                        position="center"
                        fill="black"
                        style={{
                            fontSize: SUM_FONT_SIZE,
                            fontWeight: "500",
                            fontFamily: "Roboto"
                        }}
                    />
                </Pie>
            </PieChart>
    );
};

export default AdminPieChartWithLabels;
