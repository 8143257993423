import {
    CupActionFilterOptions,
    CupDashboardData,
    CupDashboardResponse,
    CupTableData,
    CupTableResponse,
} from "../services/interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface CupState {
    filterOptions: CupActionFilterOptions | null,
    dashboard: CupDashboardData,
    table: CupTableData
}

const DEFAULT_STATE: CupState = {
    filterOptions: null,
    dashboard: {
        current: [],
        previous: [],
        count: 0,
        prevCount: 0
    },
    table: {
        cupActions: [],
        cupActionsCount: 0,
        maxPage: 0,
        pageSize: 0
    }
};

const cupSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'cup',
    reducers: {
        setCupDashboardData: (state, action: PayloadAction<CupDashboardResponse>) => {
            return {
                ...state,
                dashboard: {
                    current: action.payload.current,
                    previous: action.payload.previous,
                    count: action.payload.cup_actions_count,
                    prevCount: action.payload.cup_actions_prev_count,
                }
            }
        },
        setCupTableData: (state, action: PayloadAction<CupTableResponse>) => {
            return {
                ...state,
                table: {
                    cupActions: action.payload.cup_actions,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    cupActionsCount: action.payload.cup_actions_count
                },
            }
        },
        setCupFilterOptions: (state, action: PayloadAction<CupActionFilterOptions>) => {
            return {
                ...state,
                filterOptions: action.payload
            }
        },
    }
});

export default cupSlice.reducer

export const setCupDashboardDataAction = cupSlice.actions.setCupDashboardData;
export const setCupTableDataAction = cupSlice.actions.setCupTableData;
export const setCupFilterOptionsAction = cupSlice.actions.setCupFilterOptions;