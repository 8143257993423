
const ChartLegend = () => {
    return (
        <div className="chart-legend">
            <p className="chart-legend-label">
                <span className="dot previous"/>
                <span>Poprzedni okres</span>
            </p>
            <p className="chart-legend-label">
                <span className="dot current"/>
                <span>Bieżący okres</span>
            </p>
        </div>
    )
};

export default ChartLegend