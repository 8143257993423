export const LOGIN_PAGE = '/';
export const SIGNUP_PAGE = '/sign-up';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const VERIFY_PHONE_PAGE = '/verify-phone';
export const CONFIGURE_TERMINAL_PAGE = '/configure-terminal';
export const TERMINAL_SUCCESS_PAGE = '/terminal-success';
export const TERMINAL_ERROR_PAGE = '/terminal-error';
export const ACTIVATE_ACCOUNT_PAGE = '/activate';
export const HOME_PAGE = '/home';
export const STATISTICS_PAGE = '/statistics';
export const OFFERS_PAGE = '/offers';
export const FOREST_PAGE = '/forest';
export const HISTORY_PAGE = '/history';
export const CUPS_PAGE = '/cups';
export const BOOKING_PAGE = '/booking';
export const CONFIGURE_MERCHANT_PAGE = '/configure-merchant';
export const MERCHANT_POINT_ADD_PAGE = '/merchant-point/add';
export const MERCHANT_POINT_EDIT_PAGE = (merchantPointId: number | string) => `/merchant-point/${merchantPointId}`;

export const ADMIN_LOGIN_PAGE = '/admin';

export const ADMIN_HOME_PAGE = '/admin/home';
export const ADMIN_MERCHANTS_PAGE = '/admin/merchants';
export const ADMIN_USERS_PAGE = '/admin/users';
export const ADMIN_ACCOUNTING_PAGE = '/admin/accounting';
export const ADMIN_PAYOUTS_PAGE = '/admin/payouts';
export const ADMIN_FOREST_PAGE = '/admin/forest';
export const ADMIN_CUPS_PAGE = '/admin/cups';