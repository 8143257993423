import request, {GET} from "./request";
import {handleError} from "./handleError";

export const getMerchantStatistics = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointStatistics = async (merchantPointId: number, urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/${merchantPointId}?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantStatisticsDetail = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/detail/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointStatisticsDetail = async (merchantPointId: number, urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/${merchantPointId}/detail/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getMerchantStatisticsTable = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/table/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointStatisticsTable = async (merchantPointId: number, urlParam: string) => {
    try {
        const {data} = await request(GET, `statistics/${merchantPointId}/table/?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadMerchantStatisticsTable = async (urlParam: string) => {
    try {
        return await request(GET, `statistics/download/?${urlParam}`);
    } catch (error) {
        throw handleError(error);
    }
};

export const downloadMerchantPointStatisticsTable = async (merchantPointId: number, urlParam: string) => {
    try {
        return await request(GET, `statistics/${merchantPointId}/download/?${urlParam}`);
    } catch (error) {
        throw handleError(error);
    }
};


export const getMerchantStatisticsUserDetails = async (userId: number) => {
    try {
        const {data} = await request(GET, `statistics/table/${userId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointStatisticsUserDetails = async (merchantPointId: number, userId: number) => {
    try {
        const {data} = await request(GET, `statistics/${merchantPointId}/table/${userId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};