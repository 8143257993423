import {Message, User} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import React from "react";
import {getAppUserName} from "../../../services/helpers";
import moment from "moment";


const formatChatTime = (timestamp: string): string => {
    moment.locale('pl'); // Set locale once
    return moment(timestamp).format('ddd, D MMMM HH:mm')
        .replace(/^\w/, c => c.toUpperCase());
};

const MessageBubble = ({message, user}: { message: Message, user?: User }) => {
    return (
        message.sender_type === "user" && user ?
            <UserMessage message={message} user={user}/> :
            <MerchantMessage message={message}/>
    )
};

export default MessageBubble


const UserMessage = ({message, user}: { message: Message, user: User }) => {
    return (
        <div className="user-message">
            <div className="user-info">
                <div className="user-profile-icon"><img src={icons.userActive} alt=""/></div>
                <span>{getAppUserName(user)}</span>
            </div>

            <div className="message-container">
                <span className="message-content">
                    {message.content}
                </span>
            </div>
            <span className="timestamp">{formatChatTime(message.timestamp)}</span>
        </div>
    )
};

const MerchantMessage = ({message}: { message: Message }) => {
    return (
        <div className="merchant-message">
            <div className="message-container">
                <span className="message-content">
                    {message.content}
                </span>
            </div>
            <span className="timestamp">{formatChatTime(message.timestamp)}</span>
        </div>
    )
};
