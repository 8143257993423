import {cashbackMerchantDictionary} from "../../services/dictionaries/cashbackDict";
import {CashbackMerchantLevel, MasterCashbackLevel, MerchantPoint} from "../../services/interfaces";
import {icons} from "../../services/images";
import React from "react";
import {Slider} from "antd";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, formatNumber} from "../../services/numbers";
import Input from "../common/Input";
import {Tooltip} from "react-tooltip";
import {useNavigate} from "react-router-dom";
import {OFFERS_PAGE} from "../../services/dictionaries/paths";
import {useSelector} from "react-redux";
import {getMasterCashbackLevels} from "../../redux/selectors/merchantSelector";


interface Props {
    merchantPointFormData: MerchantPoint;
    setMerchantPointFormData: React.Dispatch<React.SetStateAction<MerchantPoint>>
}

const CashbackLevelSelect = ({merchantPointFormData, setMerchantPointFormData}: Props) => {

    const cashbackMerchant = merchantPointFormData.cashback;

    const cashbackLevels: MasterCashbackLevel[] = useSelector(getMasterCashbackLevels);

    const isPillSelected = (level: CashbackMerchantLevel) =>
        level.min <= cashbackMerchant &&
        level.max >= cashbackMerchant;

    const setMerchantCashback = (level: number) => setMerchantPointFormData(prevState => ({
        ...prevState,
        cashback: level
    }));

    const navigate = useNavigate();
    const cashbackLevelByRange = cashbackLevels.find(level => cashbackMerchant / 100 <= level.range_end && cashbackMerchant / 100 >= level.range_start);

    const partnerCashback = cashbackLevelByRange ? cashbackLevelByRange.partner_cashback : 0;
    const payProfitCashback = cashbackLevelByRange ? cashbackLevelByRange.payprofit_cashback : 0;
    const forestCashback = cashbackLevelByRange ? cashbackLevelByRange.forest_cashback : 0;
    const additionalCashback = payProfitCashback + forestCashback;

    const totalCashback = partnerCashback + additionalCashback + cashbackMerchant / 100;


    return (
        <div className="form-section-wrapper">
            <div className="input-wrapper">
                <div className="label-wrapper">
                    <label>
                        Wysokość cashbacku <span className="required-mark">*</span>
                    </label>
                    <Tooltip id="CashbackTooltip" className="tooltip" noArrow={true} clickable={true}>
                        <p className="tooltip-title">Wysokość cashbacku</p>
                        <p className="tooltip-body">Wybierzesz wysokość Cashbacku, jakim chcesz premiować zakupy swoich
                            klientów. Pamiętaj: wg badań Cashback to najlepsza zachęta dla kupujących do wydawania
                            większych kwot. To do Ciebie należy decyzja, jaki będzie to % wydawanej przez klientów
                            kwoty. Wysokość Cashbacku można zmienić w dowolnym momencie, można także podwyższyć go np. w
                            ramach promocji w danym okresie i godzinach tak, aby zachęcać klientów do zakupów. Aby
                            podwyższyć Cashback na określony czas <span className="link"
                                                                        onClick={() => navigate(OFFERS_PAGE)}>
                                kliknij tutaj
                            </span>
                        </p>

                    </Tooltip>
                    <img src={icons.infoIcon} alt="info" data-tooltip-id="CashbackTooltip"
                         data-tooltip-place="top-end"/>
                </div>
                <div className="cashback-size-select">
                    {cashbackMerchantDictionary.map((size, index) =>
                        <div
                            key={`CashbackPill${index}`}
                            className={`cashback-size-pill ${size.name.toLowerCase()} ${isPillSelected(size) ? 'selected' : ''}`}
                        >
                            <img src={size.iconUri} alt=""/>
                            <span>{size.label}</span>
                        </div>
                    )}
                </div>
                <div className="slider-value-container">
                    <span
                        className="label">{formatNumber(cashbackMerchantDictionary[0].min, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                    <div className="slider-input-wrapper">
                        <Input<MerchantPoint, 'cashback'>
                            value={merchantPointFormData}
                            onChange={setMerchantPointFormData}
                            min={cashbackMerchantDictionary[0].min}
                            max={cashbackMerchantDictionary[2].max}
                            name="cashback"
                            type="number"
                            decimalPlaces={2}
                        />
                        <span className="label">%</span>
                    </div>
                    <span
                        className="label">{formatNumber(cashbackMerchantDictionary[2].max, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                </div>
                <Slider
                    min={cashbackMerchantDictionary[0].min}
                    max={cashbackMerchantDictionary[2].max}
                    onChange={setMerchantCashback}
                    value={cashbackMerchant}
                    step={0.01}
                    tooltip={{formatter: (value) => formatNumber(value, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}}
                />
                <div className="cashback-expenditure-summary">
                    <div className="cashback-expenditure">
                        <p>Cashback od partnera:</p>
                        <p>{formatNumber(partnerCashback, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</p>
                    </div>
                    <div className="cashback-expenditure">
                        <p>Dodatkowe opłaty:</p>
                        <p>{formatNumber(additionalCashback, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</p>
                    </div>
                    <div className="cashback-expenditure total-expenditure">
                        <p>Łączny koszt:</p>
                        <p>{formatNumber(totalCashback, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CashbackLevelSelect;