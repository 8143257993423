import {RootState} from "../../../redux/selectors/rootSelectors";
import {createSelector, Selector} from "reselect";
import {AdminMerchantPointSimpleSummary, AdminServiceTypeSummary, MerchantSummary} from "../../services/interfaces";

export const getAdminMerchantsState = (state: RootState) => state.admin.merchants;


export const getAdminMerchantsTableList: Selector<RootState, MerchantSummary[]> = createSelector(
    getAdminMerchantsState,
    (adminMerchantsState) => adminMerchantsState.table.merchants
);

export const getAdminMerchantsTableMaxPage: Selector<RootState, number> = createSelector(
    getAdminMerchantsState,
    (adminMerchantsState) => adminMerchantsState.table.maxPage
);

export const getAdminMerchantsTableCount: Selector<RootState, number> = createSelector(
    getAdminMerchantsState,
    (adminMerchantsState) => adminMerchantsState.table.merchantsCount
);


export const getAdminMerchantsPieChartMerchantPoints: Selector<RootState, AdminMerchantPointSimpleSummary[]> = createSelector(
    getAdminMerchantsState,
    (adminMerchantsState) => adminMerchantsState.pieChart.merchantPoints
);

export const getAdminMerchantsPieChartServiceTypes: Selector<RootState, AdminServiceTypeSummary[]> = createSelector(
    getAdminMerchantsState,
    (adminMerchantsState) => adminMerchantsState.pieChart.serviceTypes
);