import {DateRange, OrderType, TableHeader} from "../../../../services/interfaces";
import React, {useEffect, useState} from "react";
import Table from "../../../../components/common/Table";
import {AdminAppUserSummary, UsersTableSort} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminDateRange, getAdminGlobalFilters} from "../../../redux/selectors/adminViewSettingsSelectors";
import {
    getAdminUsersTableCount,
    getAdminUsersTableList,
    getAdminUsersTableMaxPage
} from "../../../redux/selectors/adminUsersSelectors";
import {downloadUsersTableHandler, getUsersTableHandler} from "../../../handlers/adminUsersHandlers";
import {getCurrentTargetGroup} from "../../../../redux/selectors/merchantSelector";
import AdminUsersTableFiltersModal from "./AdminUsersTableFiltersModal";
import AdminUsersTableRow from "./AdminUsersTableRow";
import AppUserDetailsModal from "../../../../components/modals/AppUserDetailsModal";


export const USERS_TABLE_HEADERS: TableHeader<UsersTableSort>[] = [
    {name: 'ID klienta', sort: 'id', digit: false},
    {name: 'Transakcje', sort: 'transactions_count', digit: true},
    {name: 'Akcje kubkowe', sort: 'cup_actions_count', digit: true},
    {name: 'Uzyskany Cashback (zł)', sort: 'cashback', digit: true},
    {name: 'Płeć', sort: 'gender', digit: false},
    {name: 'Wiek', sort: 'age', digit: false},
    {name: 'Kontakt', sort: 'email', digit: false},
    {name: 'Bank', sort: 'bank', digit: false},
];

const AdminUsersTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const data: AdminAppUserSummary[] = useSelector(getAdminUsersTableList);
    const maxPage: number = useSelector(getAdminUsersTableMaxPage);
    const count: number = useSelector(getAdminUsersTableCount);
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const globalFilters = useSelector(getAdminGlobalFilters);
    const targetGroup = useSelector(getCurrentTargetGroup);

    useEffect(() => {
        getUsersTableHandler({
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize,
            dateRange,
            globalFilters,
            targetGroup
        })
    }, [page, query, sort, order, filterString, pageSize, dateRange, globalFilters, targetGroup]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);


    return (
        <>
            <Table page={page}
                   setPage={setPage}
                   pageSize={pageSize}
                   setPageSize={setPageSize}
                   query={query}
                   setQuery={setQuery}
                   sort={sort}
                   setSort={setSort}
                   order={order}
                   setOrder={setOrder}
                   count={count}
                   filters={filterString}
                   setFilters={setFilterString}
                   maxPage={maxPage}
                   headers={USERS_TABLE_HEADERS}
                   renderRows={() => data.map((user, index) =>
                       <AdminUsersTableRow user={user}
                                           filters={filterString}
                                           key={`UserRow${index}`}
                                           onClick={() => setSelectedId(user.id)}
                       />
                   )}
                   downloadHandler={downloadUsersTableHandler}
                   Modal={AdminUsersTableFiltersModal}/>
            <AppUserDetailsModal admin
                                 userId={selectedId}
                                 onClose={() => setSelectedId(null)}/>
        </>
    )
};


export default AdminUsersTable;