import React from "react";
import {ChartInterval, DatePreset, DateRange} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {
    getAdminChartInterval,
    getAdminDatePreset,
    getAdminDateRange,
    getAdminPreviousPeriod
} from "../../redux/selectors/adminViewSettingsSelectors";
import Modal from "react-modal";
import ChartIntervalSelect from "../../../components/common/chart/ChartIntervalSelect";
import {
    setAdminChartIntervalAction,
    setAdminDatePresetAction,
    setAdminDateRangeAction,
    setAdminPreviousPeriodAction
} from "../../redux/adminViewSettings";
import PreviousPeriodSelect from "../../../components/common/PreviousPeriodSelect";
import DateRangePicker from "../../../components/common/DateRangePicker";
import {icons} from "../../../services/images";
import AdminMerchantPointServiceButton from "../common/AdminMerchantPointServiceButton";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminControlsModal = ({isOpen, setIsOpen}: Props) => {

    const toggleModal = () => setIsOpen(false);
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const datePreset: DatePreset | null = useSelector(getAdminDatePreset);
    const chartInterval: ChartInterval = useSelector(getAdminChartInterval);
    const previousPeriod = useSelector(getAdminPreviousPeriod);
    const dispatch = useDispatch();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content controls-modal"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <h5 className="modal-title">Ustawienia ekranu</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <AdminMerchantPointServiceButton/>
                <ChartIntervalSelect chartInterval={chartInterval}
                                     onIntervalChange={(interval) => dispatch(setAdminChartIntervalAction(interval))}/>
                <PreviousPeriodSelect previousPeriod={previousPeriod}
                                      onChange={(period) => dispatch(setAdminPreviousPeriodAction(period))}/>
                <div className="date-picker-label-wrapper">
                    <span className="label-2">Zakres czasu: </span>
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setAdminDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setAdminDatePresetAction(datePreset))}
                                     admin={true}
                    />
                </div>
            </div>
            <div className="modal-footer"/>
        </Modal>
    )
};

export default AdminControlsModal