import request, {GET, POST} from "../../http/request";
import {handleError} from "../../http/handleError";
import {AdminMessageToUser} from "../services/interfaces";


export const getUsersStatistics = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `users/statistics/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getUsersStatisticsDetail = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `users/statistics/detail/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getUsersCount = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `users/count/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getUsersTable = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `users/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const downloadUsersTable = async (urlParam: string) => {
    try {
        return await request(GET, `users/download/?${urlParam}`, {}, {}, true);
    } catch (error) {
        throw handleError(error);
    }
};

export const getUsersWithoutTransactionsTable = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `users/without_transactions/?${urlParam}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const downloadUsersWithoutTransactionsTable = async (urlParam: string) => {
    try {
        return await request(GET, `users/without_transactions/download/?${urlParam}`, {}, {}, true);
    } catch (error) {
        throw handleError(error);
    }
};

export const getAppUserDetails = async (userId: number) => {
    try {
        const {data} = await request(GET, `users/${userId}`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getAllAppUsers = async () => {
    try {
        const {data} = await request(GET, `users/all/`, {}, {}, true);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const sendMessageToUsers = async (
    data: AdminMessageToUser
) => {
    try {
        await request(POST, `users/message/`, data, {}, true);
    } catch (error) {
        throw handleError(error);
    }
};
