import {images} from "../../services/images";

const LoadingScreen = ({text}: { text?: string }) => {
    return (
        <div className="loading-screen">
            <img src={images.payProfitCRMLogoBlack} alt="PayProfitCRM" className="brand-logo"/>
            <div className="loader"/>
            {text &&
                <div className="text-container">
                    <span>{text}</span>
                </div>
            }
        </div>
    );
};

export default LoadingScreen;