import request, {DELETE, GET, POST} from "./request";
import {handleError} from "./handleError";
import {ActivateCredentials, MerchantUserDetails} from "../services/interfaces";


export const getUserList = async () => {
    try {
        const {data, status}: { data: MerchantUserDetails[], status: number } = await request(GET, 'management/list');
        return {data, status}
    } catch (error) {
        throw handleError(error);
    }
};

export const sendInvitation = async (email: string) => {
    try {
        const {status}: { status: number } = await request(POST, `management/create/`, {email});
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const verifyInvitationToken = async (token: string, request_body: ActivateCredentials | null) => {
    try {
        const {data, status}: {
            data: string,
            status: number
        } = await request(POST, `management/verify?token=${token}`, request_body);
        return {data, status}
    } catch (error) {
        throw handleError(error);
    }
};

export const removeStandardUser = async (id: number) => {
    try {
        const {status}: { status: number } = await request(DELETE, `management/remove/${id}`);
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};

export const resendActivationCode = async (id: number) => {
    try {
        const {status}: { status: number } = await request(POST, `management/resend/${id}`);
        return {status}
    } catch (error) {
        throw handleError(error);
    }
};