import {icons} from "../../../services/images";


interface Props {
    barChartSelected: boolean;
    onChartChange: (value: boolean) => void;
}

const ChartSwitch = ({barChartSelected, onChartChange}: Props) => {
    return (
        <div className="chart-switch" onClick={() => onChartChange(!barChartSelected)}>
            <div className={`image-wrapper ${barChartSelected ? "active" : ""}`}>
                <img src={barChartSelected ? icons.barChartActiveIcon : icons.barChartIcon} alt="bar-chart-icon" className="bar-chart-icon"/>
            </div>
            <div className={`image-wrapper ${!barChartSelected ? "active" : ""}`}>
                <img src={!barChartSelected ? icons.lineChartActiveIcon : icons.lineChartIcon} alt="line-chart-icon" className="line-chart-icon"/>
            </div>
        </div>
    )
};

export default ChartSwitch