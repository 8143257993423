import SuccessScreen from "../common/SuccessScreen";
import {useNavigate} from "react-router-dom";
import {CONFIGURE_MERCHANT_PAGE} from "../../services/dictionaries/paths";

const TerminalSuccess = () => {
    const navigate = useNavigate();
    return <SuccessScreen title="Pomyślnie połączono terminal"
                          body="Łączenie Twojego terminala przebiegło pomyślnie. Zanim jeszcze zaczniesz w pełni korzystać z usług PayProfit musisz skonfigurować profil swojego biznesu. "
                          onContinue={() => navigate(CONFIGURE_MERCHANT_PAGE)}/>
};

export default TerminalSuccess;