import CreateOfferButton from "../common/CreateOfferButton";
import DateRangePicker from "../common/DateRangePicker";
import React, {useEffect, useState} from "react";
import {ChartInterval, DatePreset, DateRange} from "../../services/interfaces";
import {setDatePresetAction, setDateRangeAction} from "../../redux/merchant";
import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentMerchantPoint,
    getDatePreset,
    getDateRange,
    getIsMerchantSelected,
    getMerchant
} from "../../redux/selectors/merchantSelector";
import StatisticsTopTile from "../statistics/StatisticsTopTile";
import NoChart from "../common/chart/NoChart";
import {getTargetGroupsHandler} from "../../handlers/targetGroupHandlers";
import ImgGeneratorModal from "../modals/ImgGeneratorModal";

const Offers = () => {
    const dateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const merchant = useSelector(getMerchant);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const dispatch = useDispatch();
    const [imgGeneratorIsOpen, setImgGeneratorIsOpen] = useState<boolean>(false);

    useEffect(() => {
        getTargetGroupsHandler();
    }, []);

    if (!merchant)
        return <div/>;

    return (
        <div className="dashboard-content">
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Promocje
                        - {isMerchantSelected ? "Cała sieć " : ""}{isMerchantSelected ? merchant.name : merchantPoint.name}</h2>
                </div>
                <div className="header-section offer-button-wrapper">
                    <CreateOfferButton/>
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                    />
                </div>
            </div>
            <div className="block-wrapper block-wrapper-statistics">
                <StatisticsTopTile title="Aktywne promocje" value={0}
                                   highlight/>
                <StatisticsTopTile title="Liczba użyć promocji" value={0}
                                   prevValue={0}/>
                <StatisticsTopTile title="Nowi klienci" value={0}
                                   prevValue={0}/>
                <StatisticsTopTile title="Powracający klienci" value={0}
                                   prevValue={0}/>
            </div>
            <div className="block-wrapper discount-section-wrapper">
                <div className="discount-block block-1-4 big-chart-block">
                    <div className="title-section">
                        <div className="value-section">
                            <h4>Wybierz promocję</h4>
                        </div>
                    </div>
                    <div className="discount-center">
                        <div className="offer-button-wrapper">
                            <span className="small-block-title" style={{color: "#CCCCCC"}}>Brak promocji</span>
                            <div style={{marginTop: 16}}>
                                <CreateOfferButton/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="discount-block block-1-2">
                    <div className="title-section">
                    </div>
                    <div className="discount-center" onClick={() => setImgGeneratorIsOpen(true)}>
                        <NoChart/>
                    </div>
                </div>
                <div className="discount-block block-1-4">
                    <div className="title-section">
                        <h4>Grupa docelowa</h4>
                    </div>
                    <div className="discount-center">
                        <NoChart/>
                    </div>
                </div>
            </div>
            <ImgGeneratorModal isOpen={imgGeneratorIsOpen} onClose={() => setImgGeneratorIsOpen(false)}/>
        </div>
    )
};

export default Offers;