import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";
import {icons} from "../../services/images";

interface Props {
    value?: number
    prevValue?: number
}

const CupCollectedTile = ({value, prevValue}: Props) => {
    return (
          <div className="block block-1-4">
            <div className="value-section">
                <h4>Kupione kubki</h4>
                <div className="value-wrapper">
                    <div className="value-icon-wrapper">
                        <span className="standard-value">{value || 0}</span>
                        <img src={icons.cupCollectedIcon} alt="" className="cup-icon"/>
                    </div>
                    <ChangePill value={value} prevValue={prevValue}/>
                    <TimeRangeLabel/>
                </div>
            </div>
        </div>
    )
};

export default CupCollectedTile;

