import Modal from "react-modal";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Input from "../../common/Input";
import {
    Category,
    CategoryType,
    CreateCategoryItem,
    Product,
    ProductUnit,
    Service,
    UpdateCategoryItem
} from "../../../services/interfaces";
import {
    activateCategoryItemHandler,
    createCategoryItemHandler,
    deleteCategoryItemHandler,
    getCategoriesHandler,
    updateCategoryItemHandler
} from "../../../handlers/bookingHandlers";
import ErrorComponent from "../../common/ErrorComponent";
import {Select, TimePicker, TimePickerProps} from "antd";
import {ProductUnitEnum} from "../../../services/dictionaries/enums";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pl_PL";
import {formatDateToDuration, formatDurationToDate,} from "../../../services/dates";
import {pick} from "lodash";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";
import {getCategoryName, isProduct, isService} from "../../../services/helpers";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    item: Product | Service | null;
    categories: Category[];
    categoryType: CategoryType;
    selectedCategoryId?: number;
}

interface CategoryItemDataInterface {
    name: string
    description: string
    price?: number
    quantity_in_stock?: number
}

const CategoryItemModal = ({isOpen, onClose, item, categories, categoryType, selectedCategoryId}: Props) => {
    const [data, setData] = useState<CategoryItemDataInterface>({
        name: "",
        description: "",
    });
    const [duration, setDuration] = useState<Date | null>(null);
    const [unit, setUnit] = useState<ProductUnit | null>(null);
    const [categoryId, setCategoryId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [showActivateConfirmation, setShowActivateConfirmation] = useState<boolean>(false);

    useEffect(() => {
        if (item) {
            setCategoryId(item.category_id);
            const basicData = {...pick(item, ['name', 'description', 'price'])};

            if (isProduct(item))
                setData({...basicData, quantity_in_stock: item.quantity_in_stock});
            else {
                setData(basicData)
            }
            if (isProduct(item))
                setUnit(item.unit);
            if (isService(item))
                setDuration(formatDurationToDate(item.duration));
        }

        if (selectedCategoryId)
            setCategoryId(selectedCategoryId);
    }, [isOpen]);

    const title = useMemo(() => {
        if (categoryType === 'product')
            return item ? 'Szczegóły produktu' : 'Dodaj produkt';
        return item ? 'Szczegóły usługi' : 'Dodaj usługę';
    }, [categoryType, item]);
    const saveButtonTitle = useMemo(() => {
        return item ? 'Zapisz zmiany' :
            categoryType === 'product' ? 'Dodaj produkt' : 'Dodaj usługę';
    }, [categoryType, item]);

    const updateData: UpdateCategoryItem = useMemo(() => {
        if (!item) return {};
        return {
            name: item.name !== data.name ? data.name : undefined,
            description: item.description !== data.description ? data.description : undefined,
            price: item.price !== data.price ? data.price : undefined,
            category_id: categoryId && item.category_id !== categoryId ? categoryId : undefined,
            unit: isProduct(item) && unit && item.unit !== unit ? unit : undefined,
            quantity_in_stock: isProduct(item) && item.quantity_in_stock !== data.quantity_in_stock ? data.quantity_in_stock : undefined,
            duration: isService(item) && duration && item.duration !== formatDateToDuration(duration) ? formatDateToDuration(duration) : undefined,
            active: undefined
        };
    }, [item, data, categoryId, unit, duration]);
    const isDisabled = useMemo(() => {
        if (item && Object.values(updateData).every(value => value === undefined)) return true;
        if (categoryType === 'product' && (!unit || data.quantity_in_stock === undefined)) return true;
        if (categoryType === 'service' && !duration) return true;
        return !data.name || !categoryId || isLoading || !data.price
    }, [item, updateData, categoryType, unit, data.quantity_in_stock, data.name, data.price, duration, categoryId, isLoading]);


    const closeModal = useCallback(() => {
        onClose();
        setData({name: '', description: ''});
        setUnit(null);
        setDuration(null);
        setCategoryId(null);
        setError(null);
    }, [setData, setError, onClose]);
    const saveCallback = useCallback(() => {
        setIsLoading(false);
        closeModal();
        getCategoriesHandler(categoryType)
    }, [categoryType, setIsLoading, closeModal]);
    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onSave = useCallback(() => {
        setIsLoading(true);
        if (item) {
            updateCategoryItemHandler(categoryType, updateData, item.id, saveCallback, errorCallback);
        } else if (data.name && data.price && categoryId) {
            const createData: CreateCategoryItem = {
                name: data.name,
                description: data.description,
                price: data.price,
                category_id: categoryId,
                quantity_in_stock: data.quantity_in_stock,
                duration: duration ? formatDateToDuration(duration) : undefined,
                unit: unit ? unit : undefined
            };
            createCategoryItemHandler(categoryType, createData, saveCallback, errorCallback);
        }
    }, [item, data.name, data.price, data.description, data.quantity_in_stock, categoryId, duration, unit, categoryType, saveCallback, errorCallback, updateData]);

    const onActiveButtonClick = useCallback(() => {
        if (item) {
            item.active ?
                setShowActivateConfirmation(true) :
                activateCategoryItemHandler(categoryType, item, saveCallback)
        }
    }, [categoryType, item, saveCallback]);

    const onChangeDuration: TimePickerProps['onChange'] = (date, dateString) => {
        setDuration(date ? new Date(date.toString()) : null);
    };

    const categoryItemLabel = useMemo(() => {
        return categoryType === 'product' ? 'produkt' : 'usługę'
    }, [categoryType]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content category-item-modal"
        >
            <div className="modal-header">
                <div>
                    <div className="modal-close" onClick={closeModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                    <h5 className="modal-title">{title}</h5>
                    <div/>
                </div>
            {item &&
                <div className="status-header">
                    <span>Status:</span>
                    {isProduct(item) && (item.quantity_in_stock === 0) ?
                        <span className="status empty-stock">brak produktów na stanie </span> :
                        <span className={`status ${item.active ? "active" : 'not-active'}`}>{item.active ? "w sprzedaży" : 'nieaktywny'}</span>
                         }
                </div>
            }
            </div>
            <div className="modal-body">
                <Input<CategoryItemDataInterface, 'name'>
                    value={data}
                    onChange={setData}
                    isRequired={true}
                    maxLength={64}
                    name="name"
                    label={`Nazwa ${categoryType === 'product' ? 'produktu' : 'usługi'}`}
                    placeholder={`Podaj nazwę ${categoryType === 'product' ? 'produktu' : 'usługi'}...`}
                />
                <Input<CategoryItemDataInterface, 'description'>
                    value={data}
                    onChange={setData}
                    maxLength={256}
                    name="description"
                    label={`Opis ${categoryType === 'product' ? 'produktu' : 'usługi'}`}
                    placeholder={`Opisz krótko ${categoryItemLabel}...`}
                />
                <Input<CategoryItemDataInterface, 'price'>
                    value={data}
                    onChange={setData}
                    isRequired={true}
                    min={0}
                    name="price"
                    type="number"
                    label="Cena"
                    placeholder={`Podaj cenę ${categoryType === 'product' ? 'produktu' : 'usługi'}...`}
                />
                {categoryType === 'service' &&
                    <div className="select-wrapper">
                        <p className="label">Czas trwania<span className="required-mark"> *</span></p>
                        <TimePicker
                            value={duration ? dayjs(duration) : null}
                            onChange={onChangeDuration}
                            className="date-picker-range-input booking"
                            suffixIcon={<img src={icons.calendarIcon} alt=""/>}
                            locale={locale}
                            placeholder="Podaj czas wykonania usługi..."
                            format={"HH:mm"}
                            minuteStep={5}
                            showNow={false}
                            allowClear={false}
                        />
                    </div>
                }
                {categoryType === 'product' &&
                    <div className="double-input-wrapper">
                        <div className="select-wrapper">
                            <p className="label">Jednostka<span className="required-mark"> *</span></p>
                            <Select
                                value={unit}
                                showSearch={false}
                                placeholder="Np. sztuki, kilogramy..."
                                className="dropdown-select"
                                onChange={setUnit}
                                options={[
                                    {value: 'piece', label: ProductUnitEnum['piece']},
                                    {value: 'kilogram', label: ProductUnitEnum['kilogram']},
                                ]}
                            />
                        </div>
                        <Input<CategoryItemDataInterface, 'quantity_in_stock'>
                            value={data}
                            onChange={setData}
                            isRequired={true}
                            name="quantity_in_stock"
                            type="number"
                            label="Ilość na stanie"
                            min={0}
                            tooltipTitle="Ilość na stanie"
                            tooltipBody="Wprowadź stan początkowy produktów. Wskaż ilość dostępną do sprzedaży w
                             aplikacji PayProfit, dzięki temu klienci będą mogli je zakupić w aplikacji,
                             a w momencie dokonywania zakupów przez klientów PayProfit stan magazynowy
                             zostanie automatycznie skorygowany. Pamiętaj, że jeżeli stan magazynowy zmienił się na
                             skutek akcji niedokonanych w aplikacji PayProfit należy go ręczenie skorygować."
                            placeholder="Ilośc produktu na stanie..."
                        />
                    </div>
                }
                <div className="select-wrapper">
                    <p className="label">Kategoria<span className="required-mark"> *</span></p>
                    <Select
                        value={categoryId}
                        onChange={setCategoryId}
                        showSearch={false}
                        placeholder={`Przypisz ${categoryItemLabel} do wybranej kategorii...`}
                        className="dropdown-select"
                        options={categories.map(c => ({label: getCategoryName(c), value: c.id}))}
                    />
                </div>
            </div>
            <div className="modal-footer">
                {error && <ErrorComponent error={error}/>}
                <Button label={saveButtonTitle} onClick={onSave} fullWidth={true} loading={isLoading}
                        disabled={isDisabled}/>
                {item &&
                    <div>
                        <Button label={`Usuń ${categoryItemLabel}`} onClick={() => setShowDeleteConfirmation(true)}
                                type={'Cancel'}/>
                        <Button label={`${item.active ? 'Dezaktywuj' : 'Aktywuj'} ${categoryItemLabel}`}
                                onClick={onActiveButtonClick} type={'Default'}
                        />
                    </div>
                }
            </div>
            <ConfirmChangeModal isOpen={showDeleteConfirmation}
                                setIsOpen={setShowDeleteConfirmation}
                                title="Jesteś pewien?"
                                onConfirm={() => item && deleteCategoryItemHandler(categoryType, item.id, saveCallback)}
                                body={`Usuniętych ${categoryType === 'product' ? 'produktów' : 'usług'} nie da się w żaden sposób odzyskać`}
                                confirmLabel="Tak, usuń"

            />
            <ConfirmChangeModal isOpen={showActivateConfirmation}
                                setIsOpen={setShowActivateConfirmation}
                                title="Jesteś pewien?"
                                type="question"
                                onConfirm={() => item && activateCategoryItemHandler(categoryType, item, saveCallback)}
                                body={categoryType === 'product' ?
                                    "Dezaktywowany produkt nie będzie wyświetlany i dostępny dla klientów ale będzie dalej zapisany w twojej ofercie. W każdej chwili produkt może być aktywowany z powrotem." :
                                    "Dezaktywowana usługa nie będzie wyświetlana i dostępna dla klientów ale będzie dalej zapisana w twojej ofercie. W każdej chwili usługa może być aktywowana z powrotem."
                                }
                                confirmLabel="Tak, dezaktywuj"
            />
        </Modal>
    )
};


export default CategoryItemModal;