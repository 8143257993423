import {DatePicker, DatePickerProps} from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pl_PL";
import React from "react";
import {TargetGroup} from "../../../services/interfaces";
import {icons} from "../../../services/images";

dayjs.locale('pl')

interface Props {
    editedTargetGroup: TargetGroup
    setEditedTargetGroup: React.Dispatch<React.SetStateAction<TargetGroup>>
}

const TargetGroupDatePicker = ({editedTargetGroup, setEditedTargetGroup}: Props) => {
    const onChangeFromDate: DatePickerProps['onChange'] = (date, dateString) => {
        setEditedTargetGroup({...editedTargetGroup, start_date:  date.format("YYYY-MM-DD").toString()});
    };

    const onChangeToDate: DatePickerProps['onChange'] = (date, dateString) => {
        setEditedTargetGroup({...editedTargetGroup, end_date: date.format("YYYY-MM-DD").toString()});
    };

    return (
        <div>
            <DatePicker
                value={editedTargetGroup.start_date ? dayjs(editedTargetGroup.start_date) : null}
                onChange={onChangeFromDate}
                className="date-picker-range-input history"
                suffixIcon={<img src={icons.calendarIcon} alt=""/>}
                locale={locale}
                placeholder="Od: "
                maxDate={editedTargetGroup.end_date ? dayjs(editedTargetGroup.end_date) : undefined}
                allowClear={false}
                format={"DD.MM.YYYY"}

            />
            <DatePicker
                value={editedTargetGroup.end_date ? dayjs(editedTargetGroup.end_date) : null}
                onChange={onChangeToDate}
                className="date-picker-range-input history"
                suffixIcon={<img src={icons.calendarIcon} alt=""/>}
                locale={locale}
                placeholder="Do: "
                minDate={editedTargetGroup.start_date ? dayjs(editedTargetGroup.start_date) : undefined}
                maxDate={dayjs()}
                allowClear={false}
                format={"DD.MM.YYYY"}
            />
        </div>
    )
};

export default TargetGroupDatePicker