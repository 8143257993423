import {ChartInterval, DateRange, PreviousPeriod} from "../../services/interfaces";
import {store} from "../../redux/store";
import {setScreenLoadingAction} from "../../redux/navigation";
import {prepareURLParamForAdminChartData} from "../services/utils";
import {getAdminDashboard, getAdminDashboardMerchantPoints} from "../http/dashboard";
import {setAdminDashboardDataAction, setAdminDashboardMerchantPointsAction} from "../redux/adminDashboard";
import {AdminGlobalFilters} from "../services/interfaces";
import {isEmpty} from "lodash";


export const getAdminDashboardDataHandler = (dateRange: DateRange,
                                             previousPeriod: PreviousPeriod,
                                             chartInterval: ChartInterval,
                                             globalFilters: AdminGlobalFilters) => {
    store.dispatch(setScreenLoadingAction(true));

    const urlParam = prepareURLParamForAdminChartData(dateRange, previousPeriod, chartInterval, globalFilters);

    getAdminDashboard(urlParam).then(data => {
        store.dispatch(setAdminDashboardDataAction({...data}));
        store.dispatch(setScreenLoadingAction(false));
    }).catch(error => {
        console.error(error);
        store.dispatch(setScreenLoadingAction(false));
    });
};

export const getAdminDashboardMerchantPointsHandler = (dateRange: DateRange,
                                                       globalFilters: AdminGlobalFilters,
                                                       query: string,
                                                       filters: string) => {
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    if (query !== '') {
        urlParam.append('q', query);
    }

    let newFilters = filters;
    if (!isEmpty(globalFilters.merchantPoints))
        newFilters += '&merchant_points_ids=' + globalFilters.merchantPoints.join(',');
    if (!isEmpty(globalFilters.serviceTypes))
        newFilters += '&service_types=' + globalFilters.serviceTypes.join(',');
    if (newFilters !== '')
        urlParam.append('filters', newFilters);
    getAdminDashboardMerchantPoints(urlParam.toString()).then(data => {
        store.dispatch(setAdminDashboardMerchantPointsAction(data));
        store.dispatch(setScreenLoadingAction(false));
    }).catch(error => {
        console.error(error);
        store.dispatch(setScreenLoadingAction(false));
    });
};