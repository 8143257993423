import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {getUserDetailsHandler} from "../../handlers/authHandlers";
import {getAdminUserDetails} from "../../redux/selectors/authSelectors";
import {useDispatch, useSelector} from "react-redux";
import {AdminUserDetails} from "../../services/interfaces";
import LoadingScreen from "../../components/common/LoadingScreen";
import {getScreenLoading} from "../../redux/selectors/navigationSelectors";
import {setScreenLoadingAction} from "../../redux/navigation";
import AdminNavigationSidebar from "../components/navigation/AdminNavigationSidebar";
import {getAdminFirstTransactionHandler, getAdminMerchantsHandler} from "../handlers/filterOptionsHandlers";


const NavigationContainer = () => {
    const userDetails: AdminUserDetails | null = useSelector(getAdminUserDetails);
    const screenLoading = useSelector(getScreenLoading);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setScreenLoadingAction(false))
    }, [location]);

    useEffect(() => {
        !userDetails && getUserDetailsHandler(true);
        if (userDetails) {
            getAdminMerchantsHandler();
            getAdminFirstTransactionHandler();
        }
    }, [userDetails]);


    if (!userDetails)
        return <LoadingScreen/>;

    return (
        <div className="main-wrapper">
            <AdminNavigationSidebar/>
            {screenLoading && <div className="loading-wrapper">
                <LoadingScreen/>
            </div>}
            <div className="site-content">
                <Outlet/>
            </div>
        </div>
    )
};

export default NavigationContainer;