import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth';
import bankReducer from './bank';
import managementReducer from './management';
import merchantReducer from './merchant';
import navigationReducer from './navigation';
import statisticsReducer from './statistics';
import cupReducer from './cup';
import chatReducer from './chat';
import bookingReducer from './booking';
import adminRootReducer from './../admin/redux/adminRootReducer'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        bank: bankReducer,
        management: managementReducer,
        merchant: merchantReducer,
        navigation: navigationReducer,
        statistics: statisticsReducer,
        cup: cupReducer,
        chat: chatReducer,
        booking: bookingReducer,
        admin: adminRootReducer
    }
});