import React, {useEffect, useState} from 'react';
import Input from "../common/Input";
import {Merchant} from "../../services/interfaces";
import {searchCompanyByNIPHandler} from "../../handlers/merchantHandler";
import {
    NIPValidation,
    validateNipLength,
    validateNipNumber,
    validateZipCode
} from "../../services/validators";
import ErrorComponent from "../common/ErrorComponent";


interface NIP {
    number: string
}

interface Props {
    merchant: Merchant
    setMerchant: React.Dispatch<React.SetStateAction<Merchant>>
    isMerchantConfigured: boolean
}

const NIPSearch = ({merchant, setMerchant, isMerchantConfigured}: Props) => {
    const [nip, setNip] = useState<NIP>({number: ''});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (validateNipNumber(nip.number) && validateNipLength(nip.number)) {
                searchCompanyByNIPHandler(nip.number, setMerchant, setLoading, setError)
            } else if (!isMerchantConfigured) {
                setMerchant(prevState => ({...prevState, nip: '', city: '', street: '', zip_code: ''}));
                setError('');
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [nip, setMerchant, setLoading, setError, isMerchantConfigured]);


    interface NIPErrorKey {
        NIPLengthError: string;
        NIPNumberError: string;
    }

    const errorMessage: Record<keyof NIPErrorKey, string> = {
        NIPLengthError: "Niepoprawna długość numeru NIP",
        NIPNumberError: "Numer NIP musi składać się tylko z cyfr"
    };

    const addressInputDisabled = !merchant.nip || !validateNipLength(merchant.nip) || !validateNipNumber(merchant.nip);

    return (
        <>
            <div className="nip-input">
                {!isMerchantConfigured ?
                    <>
                        <Input<NIP, 'number'>
                            value={nip}
                            onChange={setNip}
                            name='number'
                            errorMessage={errorMessage}
                            label="NIP"
                            validate={NIPValidation}
                            isLoading={loading}
                            isRequired
                            showError
                            isDisabledDuringLoading
                            tooltipTitle="Numer NIP"
                            tooltipBody="Wpisz swój numer NIP w formacie samych liczb. System automatycznie pobierze dane Twojej firmy z rejestru GUS. Jeżeli pobrane dane nie odpowiadają stanowi faktycznemu, możesz je edytować."
                        />
                        {error && <ErrorComponent error={error}/>}
                    </>
                    :
                    <Input value={merchant} onChange={setMerchant} name='nip' label="NIP" isDisabled isRequired/>
                }
            </div>
            <div>
                <Input<Merchant, 'name'>
                    value={merchant}
                    onChange={setMerchant}
                    name="name"
                    isDisabled={addressInputDisabled || isMerchantConfigured}
                    label="Nazwa firmy"
                    isRequired
                />
                {error === 'nip_not_found' && !merchant.name &&
                    <p className="error-message"> Wprowadź dane ręcznie </p>
                }
            </div>
            <div>
                <label>Adres Firmy<span className="required-mark"> *</span></label>
                <div className="merchant-address">
                    <Input<Merchant, 'street'>
                        value={merchant}
                        onChange={setMerchant}
                        name='street'
                        placeholder="Ulica i nr budynku"
                        isDisabled={addressInputDisabled || isMerchantConfigured}
                    />
                    <span className="vertical-divider"/>
                    <Input<Merchant, 'zip_code'>
                        value={merchant}
                        onChange={setMerchant}
                        name='zip_code'
                        placeholder="Kod pocztowy"
                        isDisabled={addressInputDisabled || isMerchantConfigured}
                    />
                    <span className="vertical-divider"/>
                    <Input<Merchant, 'city'>
                        value={merchant}
                        onChange={setMerchant}
                        name='city'
                        placeholder="Miasto"
                        isDisabled={addressInputDisabled || isMerchantConfigured}
                    />
                </div>
                {error === 'nip_not_found' && (!merchant.street || !merchant.city || !merchant.zip_code) &&
                    <p className="error-message"> Wprowadź dane ręcznie </p>
                }
                {merchant.street && merchant.city && merchant.zip_code && !validateZipCode(merchant.zip_code) &&
                    <p className="error-message">Niepoprawny kod pocztowy </p>
                }
            </div>
        </>
    );
};

export default NIPSearch;