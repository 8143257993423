import {RangeBase, TargetGroup, TargetGroupRangeKey} from "../../../services/interfaces";
import React, {useState} from "react";
import {icons} from "../../../services/images";
import {Select} from "antd";
import TargetGroupRangesModal from "./TargetGroupRangesModal";
import {getRangeLabel} from "../../../services/statisticsHelpers";


interface Props {
    rangeKey: TargetGroupRangeKey,
    title: string
    placeholder: string
    targetGroup: TargetGroup
    setTargetGroup: React.Dispatch<React.SetStateAction<TargetGroup>>
    showTitle?: boolean
}

const TargetGroupRangesSelect = ({targetGroup, setTargetGroup, rangeKey, title, placeholder, showTitle=true}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ranges = targetGroup[rangeKey] as RangeBase[];
    const updateTargetGroupRanges = (newRanges: RangeBase[]) =>
        setTargetGroup({...targetGroup, [rangeKey]: newRanges});

    const selectRange = (selectedIds: (string | number)[]) => {
        const newRanges = ranges.map((range) => ({
            ...range,
            selected: selectedIds.includes(range.id)
        }));
        updateTargetGroupRanges(newRanges);
    };

    const mapRangesToOptions = (ranges: RangeBase[]) =>
        ranges.map((range: RangeBase) => ({
            value: range.id,
            label: getRangeLabel(range, rangeKey)
        }));


    return (
        <div className="range-section">
            {showTitle && <p className="label">{title}</p>}
            <div className="range-select">
                <Select
                    mode="multiple"
                    showSearch={false}
                    value={ranges.filter(r => r.selected).map(r => r.id)}
                    onChange={selectRange}
                    placeholder={placeholder}
                    className="dropdown-select"
                    options={mapRangesToOptions(ranges)}
                />
                <img src={icons.penActiveIcon} alt="" onClick={() => setIsOpen(true)}/>
            </div>
            <TargetGroupRangesModal isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    title={title}
                                    savedRanges={ranges}
                                    onUpdateRanges={updateTargetGroupRanges}
                                    rangeKey={rangeKey}
            />
        </div>
    )
};


export default TargetGroupRangesSelect;
