import {Aggregate, StatisticsDataKey} from "../interfaces";


interface StatisticsInfo {
    title: string;
    tooltip: string;
}

const statisticsInfo: Record<StatisticsDataKey, Record<Aggregate, StatisticsInfo>> = {
    amount: {
        amount: {
            title: 'Przychody wg kwot zakupów',
            tooltip: 'Sprawdź jaką kwotę najczęściej zostawiają u Ciebie klienci w wybranym okresie.'
        },
        transactions: {
            title: 'Liczba transakcji wg kwot zakupów',
            tooltip: 'Sprawdzaj wartości transakcji, które najczęściej pojawiają się w Twoim sklepie.'
        },
        clients: {
            title: 'Liczba klientów wg sum kwot zakupów',
            tooltip: 'Sprawdź ilu klientów generuje przychody w wybranych zakresach. Zakres możesz edytować klikając wstawić symbol.'
        }
    },
    transactions: {
        amount: {
            title: 'Przychody wg liczby transakcji',
            tooltip: 'Zobacz, jaka część Twoich przychodów została wygenerowana przez klientów, którzy odwiedzają Ciebie rzadko, a jak przez klientów, którzy pojawiają się u Ciebie częściej.'
        },
        transactions: {
            title: 'Liczba transakcji wg liczby transakcji klienta',
            tooltip: 'Zobacz, jaka część transakcji jest dokonywana przez klientów, którzy odwiedzają Ciebie rzadko, a jak przez klientów, którzy pojawiają się u Ciebie częściej.'
        },
        clients: {
            title: 'Liczba klientów wg liczby transakcji',
            tooltip: 'Zobacz, ilu klientów dokonuje u Ciebie transakcje w wybranych zakresach.'
        }
    },
    gender: {
        amount: {
            title: 'Przychody wg płci',
            tooltip: 'Sprawdź w jakiej części Twój przychód jest generowany przez kobiety, a w jakiej przez mężczyzn.'
        },
        transactions: {
            title: 'Liczba transakcji wg płci',
            tooltip: 'Sprawdź ile transakcji dokonywanych jest przez kobiety, a ile przez mężczyzn.'
        },
        clients: {
            title: 'Liczba klientów wg płci',
            tooltip: 'Sprawdzaj jaka część Twoich klientów to kobiety, a jaka mężczyźni.'
        }
    },
    age: {
        amount: {
            title: 'Przychody wg wieku',
            tooltip: 'Zobacz, kto zostawia u Ciebie najwięcej pieniędzy: starsi, czy młodsi.'
        },
        transactions: {
            title: 'Liczba transakcji wg wieku',
            tooltip: 'Zobacz, czy transakcje dokonywane są częściej przez młodsze, czy starsze osoby.'
        },
        clients: {
            title: 'Liczba klientów wg wieku',
            tooltip: 'Zobacz, ilu klientów z danej grupy wiekowej dokonuje transakcji'
        }
    },
    average: {
        amount: {
            title: 'Średnia kwota transakcji',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się średnia kwota zakupów.'
        },
        transactions: {
            title: 'Średnia liczba transakcji',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się średnia liczba transakcji w Twoim sklepie.'
        },
        clients: {
            title: 'Średnia liczba klientów',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się średnia liczba klientów'
        }
    },
    dynamics: {
        amount: {
            title: 'Dynamika przychodów',
            tooltip: 'Zobacz o ile zwiększała się Twoja sprzedaż względem poprzednich okresów.'
        },
        transactions: {
            title: 'Dynamika liczby transakcji',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się średnia liczba transakcji w Twoim sklepie.'
        },
        clients: {
            title: 'Dynamika liczby klientów',
            tooltip: 'Zobacz jak zmienia się liczba Twoich klientów.'
        }
    },
    new_clients: {
        amount: {
            title: 'Nowi klienci',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się sprzedaż u nowych klientów.'
        },
        transactions: {
            title: 'Nowi klienci',
            tooltip: 'Zobacz ile transakcji było dokonanych przez nowych klientów.'
        },
        clients: {
            title: 'Nowi klienci',
            tooltip: 'Zobacz ilu nowych klientów odwiedziło Ciebie w wybranym okresie'
        }
    },
    returning_clients: {
        amount: {
            title: 'Powracający klienci',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się sprzedaż u klientów którzy odwiedzili twój sklep więcej niż 1 raz.'
        },
        transactions: {
            title: 'Powracający klienci',
            tooltip: 'Zobacz ile transakcji było dokonanych przez klientów którzy odwiedzili twój sklep więcej niż 1 raz.'
        },
        clients: {
            title: 'Powracający klienci',
            tooltip: 'Sprawdź, ilu klientów odwiedziło Twój sklep więcej niż 1 raz.'
        }
    },
    active_clients: {
        amount: {
            title: 'Aktywni klienci',
            tooltip: 'Sprawdź jak na przestrzeni czasu kształtowała się sprzedaż u klientów regularnie odwiedzających Twój sklep.'
        },
        transactions: {
            title: 'Aktywni klienci',
            tooltip: 'Zobacz ile transakcji było dokonanych przez klientów regularnie odwiedzających Twój sklep.'
        },
        clients: {
            title: 'Aktywni klienci',
            tooltip: 'Obserwuj, ilu klientów regularnie odwiedza Twój sklep.'
        }
    },
};

const getStatisticInfo = (dataKey: StatisticsDataKey, aggregate: Aggregate): StatisticsInfo => {
    const info = statisticsInfo[dataKey]?.[aggregate];
    if (info) {
        return info;
    }
    return {
        title: `${aggregate.charAt(0).toUpperCase() + aggregate.slice(1)} by ${dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}`,
        tooltip: `Default tooltip for ${aggregate} by ${dataKey}`
    };
};

export const getStatisticTitle = (dataKey: StatisticsDataKey, aggregate: Aggregate): string => {
    return getStatisticInfo(dataKey, aggregate).title;
};

export const getStatisticTooltip = (dataKey: StatisticsDataKey, aggregate: Aggregate): string => {
    return getStatisticInfo(dataKey, aggregate).tooltip;
};

export default getStatisticInfo;