import {Aggregate, Gender, PieChartData, PreparedPieChartData} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo, useState} from "react";
import {getStatisticsFilterOrder, getStatisticsTableMode} from "../../../redux/selectors/statisticsSelectors";
import {preparePieChartData} from "../../../services/chartUtils";
import {isEmpty, some, sumBy} from "lodash";
import {updateFilterOrder} from "../../../services/statisticsHelpers";
import {changeCurrentTargetGroupAction} from "../../../redux/merchant";
import {setStatisticsFilterOrderAction} from "../../../redux/statistics";
import NoChart from "../../common/chart/NoChart";
import PieChartGraph from "./PieChartGraph";
import PieChartLegend from "./PieChartLegend";

interface Props {
    data: PieChartData[];
    genderData: Gender[]
    prevData?: PieChartData[];
    aggregate: Aggregate
}

const GenderPieChartContainer = ({data, genderData, prevData, aggregate}: Props) => {
    const [hoveredEntry, setHoveredEntry] = useState<PreparedPieChartData | null>(null);
    const dispatch = useDispatch();
    const filterOrder = useSelector(getStatisticsFilterOrder);
    const tableMode = useSelector(getStatisticsTableMode);

    const isGenderSelected = useCallback((entryId: string | number): boolean => {

        const allSelected = isEmpty(genderData);
        return allSelected || genderData.includes(entryId as Gender);
    }, [genderData]);
    const chartData = useMemo<PreparedPieChartData[]>(
        () => preparePieChartData(data, isGenderSelected, prevData),
        [data, isGenderSelected, prevData]
    );


    const legendData = useMemo<PreparedPieChartData[]>(
        () => data.map((entry, index) => ({
            ...entry,
            checked: isGenderSelected(entry.id),
            prevValue: undefined // prevValue is not needed in the legend
        })),
        [data, isGenderSelected]
    );

    const filteredData = useMemo(() =>
            some(data, entry => entry.filtered_value)
                ? data.map(entry => ({...entry, value: entry.filtered_value}))
                : data,
        [data]
    );

    const selectedSum = useMemo(
        () => sumBy(filteredData, entry => isGenderSelected(entry.id) && entry.value ? entry.value : 0),
        [filteredData, isGenderSelected]
    );

    const sum = useMemo(() => sumBy(data, 'value'), [data]);

    const handleSelect = (entry: PreparedPieChartData) => {
        if (!isSelectable(entry)) return;

        const newGenderData = genderData.includes(entry.id as Gender) ?
            genderData.filter(gender => gender !== entry.id) :
            [...genderData, entry.id as Gender];
        dispatch(changeCurrentTargetGroupAction({
            field: 'gender',
            value: newGenderData
        }));
        const isFilterSelected = !isEmpty(newGenderData);
        const newFilterOrder = updateFilterOrder(filterOrder, 'gender', isFilterSelected);

        dispatch(setStatisticsFilterOrderAction(newFilterOrder));
    };
    const isSelectable = (entry: PreparedPieChartData) => !(!!prevData || (entry.value === 0 && !entry.checked) || tableMode);
    const isHovered = (id: number | string) => hoveredEntry?.id === id;

    if (isEmpty(chartData)) {
        return <NoChart/>;
    }

    return (
        <div className="pie-chart-section">
            <PieChartGraph
                chartData={chartData}
                prevData={prevData}
                selectedSum={selectedSum}
                aggregate={aggregate}
                handleSelect={!prevData ? handleSelect : undefined}
                setHoveredEntry={!prevData ? setHoveredEntry : undefined}
                isEntryHovered={!prevData ? isHovered : undefined}
                isEntrySelectable={isSelectable}
            />
            <PieChartLegend
                legendData={legendData}
                sum={sum}
                handleSelect={handleSelect}
                setHoveredEntry={setHoveredEntry}
                isHovered={isHovered}
                isSelectable={isSelectable}
            />
        </div>
    );
};

export default GenderPieChartContainer;