import {
    ActiveClientInterval,
    Aggregate,
    AppUserDetails,
    ChartInterval,
    DateRange,
    DownloadTableProps,
    GetTableProps,
    PreviousPeriod,
    StatisticsData,
    StatisticsDataKey,
    StatisticsPieChartKey,
    StatisticsTableResponse,
    TargetGroup,
} from "../services/interfaces";
import {store} from "../redux/store";
import {
    downloadMerchantPointStatisticsTable,
    downloadMerchantStatisticsTable,
    getMerchantPointStatistics,
    getMerchantPointStatisticsDetail,
    getMerchantPointStatisticsTable,
    getMerchantPointStatisticsUserDetails,
    getMerchantStatistics,
    getMerchantStatisticsDetail,
    getMerchantStatisticsTable,
    getMerchantStatisticsUserDetails,
} from "../http/statistics";
import {
    setStatisticsDataAction,
    setStatisticsDetailsDataAction,
    setStatisticsTableAction,
    setStatisticsUserDetailsAction,
} from "../redux/statistics";
import {
    getCurrentMerchantPoint,
    getDateRange,
    getIsMerchantSelected
} from "../redux/selectors/merchantSelector";
import {setScreenLoadingAction} from "../redux/navigation";
import {prepareB64FileToXLSX, prepareURLParamForDownloadTable, prepareURLParamForTable} from "../services/utils";
import {saveAs} from "file-saver";
import {
    getStatisticsCurrentTargetGroup,
    getStatisticsSelectedUserIds,
    getStatisticsTableMode
} from "../redux/selectors/statisticsSelectors";

export const getStatisticsHandler = (aggregate: Aggregate,
                                     targetGroup: TargetGroup,
                                     filterOrder: StatisticsPieChartKey[],
                                     activeClientInterval: ActiveClientInterval,
                                     previousPeriod: PreviousPeriod,
                                     chartInterval: ChartInterval,
                                     dateRange: DateRange,
                                     userIds: number[]) => {
    store.dispatch(setScreenLoadingAction(true));

    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const tableMode = getStatisticsTableMode(store.getState());
    const urlParam = new URLSearchParams();

    urlParam.append('aggregate', aggregate);

    urlParam.append('active_client_interval', activeClientInterval);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);

    if (tableMode)
        urlParam.append('user_ids', userIds.join(','));
    else
        urlParam.append('filter_order', filterOrder.join(','));

    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('target_group_json', targetGroupJson);
    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        getMerchantPointStatistics(merchantPoint.id, urlParam.toString()).then((data: StatisticsData) => {
            store.dispatch(setStatisticsDataAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            store.dispatch(setScreenLoadingAction(false));
            console.error(error);
        });

    else
        getMerchantStatistics(urlParam.toString()).then((data: StatisticsData) => {
            store.dispatch(setStatisticsDataAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            store.dispatch(setScreenLoadingAction(false));
            console.error(error);
        });
};


export const getMerchantStatisticsDetailHandler = (targetGroup: TargetGroup,
                                                   aggregate: Aggregate,
                                                   dataKey: StatisticsDataKey,
                                                   dateRange: DateRange,
                                                   previousPeriod: PreviousPeriod,
                                                   chartInterval: ChartInterval,
                                                   activeClientInterval?: ActiveClientInterval) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const tableMode = getStatisticsTableMode(store.getState());
    const urlParam = new URLSearchParams();

    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('detail_key', dataKey);
    urlParam.append('aggregate', aggregate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    if (activeClientInterval)
        urlParam.append('active_client_interval', activeClientInterval);

    if (tableMode) {
        const userIds = getStatisticsSelectedUserIds(store.getState());
        urlParam.append('user_ids', userIds.join(','));
    }

    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    urlParam.append('target_group_json', targetGroupJson);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        getMerchantPointStatisticsDetail(merchantPoint.id, urlParam.toString()).then((data) => {
            store.dispatch(setStatisticsDetailsDataAction(data));
        }).catch(error => {
            console.error(error);
        });
    else
        getMerchantStatisticsDetail(urlParam.toString()).then((data) => {
            store.dispatch(setStatisticsDetailsDataAction(data));
        }).catch(error => {
            console.error(error);
        })
};


export const getStatisticsTableHandler = (tableProps: GetTableProps,
                                          targetGroup: TargetGroup,
                                          dateRange: DateRange) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForTable(tableProps);
    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    urlParam.append('target_group_json', targetGroupJson);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        getMerchantPointStatisticsTable(merchantPoint.id, urlParam.toString()).then((data: StatisticsTableResponse) => {
            store.dispatch(setStatisticsTableAction(data));
        }).catch(error => {
            console.error(error);
        });

    else
        getMerchantStatisticsTable(urlParam.toString()).then((data: StatisticsTableResponse) => {
            store.dispatch(setStatisticsTableAction(data));
        }).catch(error => {
            console.error(error);
        });
};


export const downloadStatisticsTableHandler = (props: DownloadTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const dateRange = getDateRange(store.getState());
    const targetGroup = getStatisticsCurrentTargetGroup(store.getState());

    const urlParam = prepareURLParamForDownloadTable(props);
    const targetGroupJson = JSON.stringify(targetGroup);
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    urlParam.append('target_group_json', targetGroupJson);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        downloadMerchantPointStatisticsTable(merchantPoint.id, urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, `users-${new Date().toISOString()}.xlsx`)
        }).catch(error => {
            console.error(error);
        });

    else
        downloadMerchantStatisticsTable(urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, `users-${new Date().toISOString()}.xlsx`)
        }).catch(error => {
            console.error(error);
        });
};


export const getStatisticsUserDetailsHandler = (userId: number) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id)
        getMerchantPointStatisticsUserDetails(merchantPoint.id, userId).then((data: AppUserDetails) => {
            store.dispatch(setStatisticsUserDetailsAction(data));
        }).catch(error => {
            console.error(error);
        });
    else
        getMerchantStatisticsUserDetails(userId).then((data: AppUserDetails) => {
            store.dispatch(setStatisticsUserDetailsAction(data));
        }).catch(error => {
            console.error(error);
        });
};