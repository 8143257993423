import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AdminMerchantPointSimpleSummary,
    AdminMerchantsPieChartResponse,
    AdminServiceTypeSummary,
    MerchantsTableResponse,
    MerchantSummary
} from "../services/interfaces";

export interface AdminMerchantsState {
    pieChart: {
        merchantPoints: AdminMerchantPointSimpleSummary[],
        serviceTypes: AdminServiceTypeSummary[]
    }
    table: {
        merchants: MerchantSummary[],
        maxPage: number,
        merchantsCount: number,
        pageSize: number
    }
}


const DEFAULT_STATE: AdminMerchantsState = {
    pieChart: {
        merchantPoints: [],
        serviceTypes: []
    },
    table: {
        merchants: [],
        maxPage: 0,
        pageSize: 0,
        merchantsCount: 0
    }
};

const adminMerchantsSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'adminMerchants',
    reducers: {
        resetAdminMerchantsState: (state) => {
            return {...state, ...DEFAULT_STATE}
        },
        setMerchantsTable: (state, action: PayloadAction<MerchantsTableResponse>) => {
            return {
                ...state,
                table: {
                    merchants: action.payload.merchants,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size,
                    merchantsCount: action.payload.merchants_count
                },
            }
        },
        setMerchantsPieChart: (state, action: PayloadAction<AdminMerchantsPieChartResponse>) => {
            return {
                ...state,
                pieChart: {
                    merchantPoints: action.payload.merchant_points,
                    serviceTypes: action.payload.service_types
                }
            }
        },
    }
});

export default adminMerchantsSlice.reducer;


export const setAdminMerchantsTableAction = adminMerchantsSlice.actions.setMerchantsTable;
export const setAdminMerchantsPieChartAction = adminMerchantsSlice.actions.setMerchantsPieChart;
