import React, {useEffect, useState} from "react";
import {Chat} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getChats} from "../../../redux/selectors/chatSelectors";
import {icons} from "../../../services/images";
import {setContactPageAction} from "../../../redux/chat";
import Button from "../../common/Button";
import {getChatDetailsHandler, getChatsHandler} from "../../../handlers/chatHandlers";
import moment from "moment";
import {getAppUserName, getIsChatUnread} from "../../../services/helpers";

const ContactChatList = () => {
    const [query, setQuery] = useState<string>('');
    const chats = useSelector(getChats);
    const dispatch = useDispatch();
    const filteredChats: Chat[] = chats.filter((chat: Chat) =>
        chat.user.email.toLowerCase().includes(query.toLowerCase()) ||
        (chat.user.name && chat.user.name.toLowerCase().includes(query.toLowerCase()))
    );

    const orderedChats = filteredChats.sort((a, b) =>
        a.last_message && b.last_message ?
            new Date(b.last_message.timestamp).getTime() - new Date(a.last_message.timestamp).getTime() :
            0
    );

    useEffect(() => {
        getChatsHandler()
    }, []);

    return (
        <div className="chat-list">
            <div className="searchbar">
                <img src={icons.search} alt="Search"/>
                <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                       value={query}
                       onChange={event => setQuery(event.target.value)}/>
            </div>
            <div className="chats">
                {orderedChats.map(chat =>
                    <ChatOverview chat={chat} key={`ChatOverview${chat.id}`}/>
                )}
            </div>
            <Button label="Napisz wiadomość" onClick={() => dispatch(setContactPageAction('new_chat'))} fullWidth/>
        </div>
    )
};

export default ContactChatList


const ChatOverview = ({chat}: {
    chat: Chat,
}) => {
    const dispatch = useDispatch();

    if (!chat.last_message)
        return <></>;
    const unRead = getIsChatUnread(chat);

    const formatTimestamp = (date: string) => {
        let newDate = moment(date);
        return newDate.calendar(null, {
            sameDay: 'HH:mm',
            sameElse: "DD/MM/YYYY",
            sameWeek: "DD/MM/YYYY",
            lastWeek: "DD/MM/YYYY"
        });
    };

    return (
        <div className="chat-overview"
             onClick={() => {
                 dispatch(setContactPageAction('chat'));
                 getChatDetailsHandler(chat.user.id)
             }}>
            <div>
                <div className="user-profile-icon">
                    <img src={icons.userActive} alt=""/>
                </div>
                <div>
                    <span className="user-name">{getAppUserName(chat.user)}</span>
                    <span className={`message-content ${unRead ? 'unread' : ''}`}>
                        {chat.last_message.sender_type === 'merchant' ? 'Ty: ' : ''}
                        {chat.last_message.content}
                    </span>
                </div>
            </div>
            <span
                className={`timestamp ${unRead ? 'unread' : ''}`}>{formatTimestamp(chat.last_message.timestamp)}</span>
        </div>
    )
};