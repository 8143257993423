import request, {GET} from "../../http/request";
import {handleError} from "../../http/handleError";


export const fetchPayouts = async (query: string) => {
    try {
        return await request(GET, `finance/payouts${query}`, {}, {}, true);
    } catch (error) {
        throw handleError(error);
    }
}

export const fetchPayoutAvailability = async () => {
    try {
        return await request(GET, `finance/payouts/available`, {}, {}, true);
    } catch (error) {
        throw handleError(error);
    }
}

export const downloadAliorTransferFile = async () => {
    try {
        return await request(GET, `finance/payouts/file`, {}, {}, true);
    } catch (error) {
        throw handleError(error);
    }
}