import {PreparedPieChartData} from "../../../services/interfaces";
import PieChartLegendPill from "./PieChartLegendPill";

interface Props {
    legendData: PreparedPieChartData[];
    sum: number;
    handleSelect: (entry: PreparedPieChartData) => void;
    setHoveredEntry: (entry: PreparedPieChartData | null) => void;
    isHovered: (id: number | string) => boolean;
    isSelectable: (entry: PreparedPieChartData) => boolean;
}

const PieChartLegend = ({
                            legendData,
                            sum,
                            handleSelect,
                            setHoveredEntry,
                            isHovered,
                            isSelectable,
                        }: Props) => {

    return (
        <div className="pie-chart-legend">
            {legendData.map((entry, index) =>
                <div key={`PieChartLegendRow${entry.name}`} className="pie-chart-legend-row">
                    <span className="pie-chart-legend-label">{entry.name === "No Data" ? "Brak wieku" : entry.name}</span>
                    <div className="pie-chart-legend-pill-container">
                        <PieChartLegendPill entry={entry}
                                            value={(entry.checked && entry.filtered_value !== null ? (entry.value - entry.filtered_value) : entry.value) / sum}
                                            checked={entry.checked && entry.filtered_value === null}
                                            selectable={isSelectable(entry)}
                                            hovered={isHovered(entry.id)}
                                            handleSelect={handleSelect}
                                            setHoveredEntry={setHoveredEntry}
                        />

                        {entry.checked && entry.filtered_value !== null &&
                            <PieChartLegendPill entry={entry}
                                                checked={true}
                                                value={entry.filtered_value / sum}
                                                selectable={isSelectable(entry)}
                                                hovered={isHovered(entry.id)}
                                                handleSelect={handleSelect}
                                                setHoveredEntry={setHoveredEntry}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default PieChartLegend;