import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {
    ChartInterval,
    DashboardData, DatePreset,
    DateRange,
    Merchant,
    MerchantPoint,
    MerchantPointSimple, PreviousPeriod, TargetGroup,
    Transaction
} from "../../services/interfaces";
import {MerchantState} from "../merchant";

export const getMerchantState = (state: RootState) => state.merchant;

export const getMerchant: Selector<RootState, Merchant | null> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.merchant
);

export const getMerchantPoints: Selector<RootState, MerchantPointSimple[]> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.merchant?.merchant_points || []
);

export const getCurrentMerchantPoint: Selector<RootState, MerchantPoint> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.merchantPoint
);

export const getDateRange: Selector<RootState, DateRange> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.dateRange
);

export const getDatePreset: Selector<RootState, DatePreset | null> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.datePreset
);


export const getChartInterval: Selector<RootState, ChartInterval> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.chartInterval
);

export const getCurrentDashboardData: Selector<RootState, DashboardData> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.dashboard.current
);

export const getPreviousDashboardData: Selector<RootState, DashboardData> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.dashboard.previous
);

export const getIsMerchantSelected: Selector<RootState, boolean> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.isMerchantSelected
);

export const getHistoryTransactions: Selector<RootState, MerchantState['transactionsHistory']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.transactionsHistory
);

export const getHistoryTransactionsList: Selector<RootState, Transaction[]> = createSelector(
    getHistoryTransactions,
    (transactions) => transactions.transactions
);

export const getHistoryTransactionsMaxPage: Selector<RootState, number> = createSelector(
    getHistoryTransactions,
    (transactions) => transactions.maxPage
);


export const getHistoryTransactionsCount: Selector<RootState, number> = createSelector(
    getHistoryTransactions,
    (transactions) => transactions.transactionsCount
);

export const getHistoryTransactionsDashboard: Selector<RootState, MerchantState['transactionsHistoryDashboard']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.transactionsHistoryDashboard
);

export const getHistoryTransactionsFilterOptions: Selector<RootState, MerchantState['historyFilterOptions']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.historyFilterOptions
);

export const getMerchantPointTerminals: Selector<RootState, MerchantState['merchantPoint']['terminals']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.merchantPoint.terminals
);

export const getTargetGroups: Selector<RootState, TargetGroup[]> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.targetGroups
);

export const getCurrentTargetGroup: Selector<RootState, TargetGroup> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.currentTargetGroup
);

export const getFirstTransactionDate: Selector<RootState, string | null> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.firstTransactionDate
);

export const getPreviousPeriod: Selector<RootState, PreviousPeriod> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.previousPeriod
);

export const getMasterCashbackLevels: Selector<RootState, MerchantState['masterCashbackLevels']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.masterCashbackLevels
);

export const getMerchantReturns: Selector<RootState, MerchantState['returns']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.returns
);

export const getMerchantReturnsCount: Selector<RootState, number> = createSelector(
    getMerchantReturns,
    (returns) => returns.length
);

export const getMerchantReturnMatches: Selector<RootState, MerchantState['returnMatches']> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.returnMatches
);

export const getBarChartSelected: Selector<RootState, boolean> = createSelector(
    getMerchantState,
    (merchantState) => merchantState.barChartSelected
);