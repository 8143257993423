import axios from 'axios';
import {store} from "../redux/store";
import {isTokenExpired} from "../services/utils";
import {API_URL} from "../config/config";
import {authenticateAction} from "../redux/auth";
import {handleError} from "./handleError";
import {storageActionTypes} from "../services/storage/basicStorage";
import {authStorageManager, secureStorageKeys, storeAuthItems} from "../services/storage/secureStorage";

export type requestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';


export const service = async (requestType: requestMethod, route: string, data: any = {}, config = {}, admin = false) => {
    const state = store.getState();
    const expDate: string | null = state.auth.expDate;
    let accessToken: string | null = state.auth.accessToken;
    let URL = API_URL;
    if (admin) URL = API_URL + 'admin/';
    if (accessToken && expDate && isTokenExpired(expDate)) {
        const refreshToken: string | null = await authStorageManager(storageActionTypes.GET, secureStorageKeys.REFRESH_TOKEN);
        return await axios.post(URL + 'auth/refresh', {refresh_token: refreshToken}, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
        }).then(
            refreshTokenResponse => {
                const {data: refreshTokenData} = refreshTokenResponse;
                storeAuthItems(refreshTokenData.access_token, refreshTokenData.refresh_token, refreshTokenData.expire);
                store.dispatch(authenticateAction({
                    accessToken: refreshTokenData.access_token,
                    expDate: String(refreshTokenData.expire),
                }));

                accessToken = refreshTokenData.access_token;
                if (data.hasOwnProperty('refresh_token')) {
                    data.refresh_token = refreshTokenData.refresh_token;
                }
                return make(requestType, route, data, config, accessToken, admin)
            }).catch(error => {
            throw handleError(error);
        });
    } else {
        return await make(requestType, route, data, config, accessToken, admin)
    }

};

const make = (requestType: requestMethod, route: string, data = {}, config: {
    headers?: {}
} = {}, accessToken: string | null, admin: boolean) => {
    if (accessToken) config.headers = {Authorization: `Bearer ${accessToken}`};
    config.headers = {...config.headers, 'Content-Type': 'application/json'};
    let URL = API_URL;
    if (admin) URL = API_URL + 'admin/';

    switch (requestType) {
        case GET:
            return axios.get(URL + route, config);
        case POST:
            return axios.post(URL + route, data, config);
        case PUT:
            return axios.put(URL + route, data, config);
        case DELETE:
            return axios.delete(URL + route, config);
        default:
            throw new TypeError('No valid request type provided');
    }
};

export default service;
