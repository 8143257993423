import Cookies from "js-cookie";

export enum storageActionTypes {
    GET = 'GET',
    SET = 'SET',
    DELETE = 'DELETE'
}

export const cookieSave = (key: string, object: object | string, expires: number = 30) => {
    if (typeof object === "string") {
        Cookies.set(key, object, {expires: expires});
    }
    return object;
};
export const cookieGet = (key: string) => Cookies.get(key);
export const cookieRemove = (key: string) => Cookies.remove(key);