import {useState} from "react";
import SignUp from "../components/auth/SignUp";
import {SignUpCredentials} from "../services/interfaces";
import SendPhoneNumber from "../components/auth/SendPhoneNumber";


const SignUpContainer = () => {

    const [stagePhoneNumber, setStagePhoneNumber] = useState<boolean>(false);
    const [data, setData] = useState<SignUpCredentials>({
        fullName: '',
        email: '',
        password: '',
        phoneNumber: '',
        countryCode: 'PL'
    });
    return (
        <>
            {!stagePhoneNumber ? <SignUp data={data} setData={setData} setStagePhoneNumber={setStagePhoneNumber}/> :
                <SendPhoneNumber data={data} setData={setData} setStagePhoneNumber={setStagePhoneNumber}/>}
        </>
    );
};

export default SignUpContainer;