import React, {useState} from "react";
import {
    Aggregate,
    ChartInterval,
    DatePreset,
    DateRange,
    PreviousPeriod,
    StatisticsData
} from "../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {
    getBarChartSelected,
    getChartInterval,
    getCurrentMerchantPoint,
    getDatePreset,
    getDateRange,
    getIsMerchantSelected,
    getMerchant,
    getPreviousPeriod
} from "../../redux/selectors/merchantSelector";
import {
    getStatisticsAggregate,
    getStatisticsData,
    getStatisticsTableMode
} from "../../redux/selectors/statisticsSelectors";
import DateRangePicker from "../common/DateRangePicker";
import StatisticsTopTile from "./StatisticsTopTile";
import TargetGroupButton from "../common/TargetGroupButton";
import StatisticsAggregateChartTile from "./StatisticsAggregateChartTile";
import StatisticsChartTile from "./StatisticsChartTile";
import CreateOfferButton from "../common/CreateOfferButton";
import {getHeaderTitle} from "../../services/helpers";
import {WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import AggregateSelect from "./AggregateSelect";
import {
    setBarChartSelectedAction,
    setChartIntervalAction,
    setDatePresetAction,
    setDateRangeAction,
    setPreviousPeriodAction
} from "../../redux/merchant";
import {setStatisticsAggregateAction} from "../../redux/statistics";
import TargetGroupFloatingButtons from "./TargetGroupFloatingButtons";
import NotificationPopup from "../common/NotificationPopup";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import {icons} from "../../services/images";
import ControlsModal from "../modals/ControlsModal";
import ChartIntervalSelect from "../common/chart/ChartIntervalSelect";
import StatisticsTable from "./table/StatisticsTable";
import TableModeSwitch from "./table/TableModeSwitch";

const Statistics = () => {
    const [isControlsModalOpen, setIsControlsModalOpen] = useState<boolean>(false);
    const merchant = useSelector(getMerchant);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const dateRange: DateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const chartInterval: ChartInterval = useSelector(getChartInterval);
    const statisticsData: StatisticsData = useSelector(getStatisticsData);
    const aggregate = useSelector(getStatisticsAggregate);
    const previousPeriod = useSelector(getPreviousPeriod);
    const barChartSelected = useSelector(getBarChartSelected);
    const tableMode = useSelector(getStatisticsTableMode);
    const currentData = statisticsData.current;
    const previousData = statisticsData.previous;

    const dispatch = useDispatch();

    const chartTileProps: {
        statisticsData: StatisticsData
        previousPeriod: PreviousPeriod
        chartInterval: ChartInterval
        aggregate: Aggregate
        barChartSelected: boolean
        datePreset: DatePreset | null
        dateRange: DateRange
    } = {
        statisticsData, previousPeriod, chartInterval, aggregate, barChartSelected, dateRange, datePreset
    };

    if (!merchant)
        return <div/>;

    return (
        <div className="dashboard-content statistics with-sticky-bar">
            <div className="controls-sticky-bar">
                <div>
                    {!tableMode && <TargetGroupButton showTargetGroupChanges/>}
                </div>
                <div className="selectors-with-labels">
                    <TableModeSwitch/>
                    <AggregateSelect aggregate={aggregate}
                                     setAggregate={(aggregate) => dispatch(setStatisticsAggregateAction(aggregate))}/>
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={(interval) => dispatch(setChartIntervalAction(interval))}/>
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setPreviousPeriodAction(period))}/>
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange}
                                         setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                         datePreset={datePreset}
                                         setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                        />
                    </div>
                </div>
            </div>
            <div className="controls-mobile-button" onClick={() => setIsControlsModalOpen(true)}>
                <img src={icons.cogIcon} alt=""/>
            </div>
            <NotificationPopup/>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Statystyki - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
                <div className="header-section offer-button-wrapper">
                    <CreateOfferButton/>
                </div>
            </div>
            <div className="block-wrapper block-wrapper-statistics">
                <StatisticsTopTile title="Twoja sprzedaż (zł)" value={currentData.income_sum}
                                   prevValue={previousData.income_sum}/>
                <StatisticsTopTile title="Wizyty w sklepie" value={currentData.transactions_count}
                                   format={WHOLE_NUMBER_FORMAT}
                                   prevValue={previousData.transactions_count}/>
                <StatisticsTopTile title="Udostępnione promocje" value={currentData.shared_offers}
                                   format={WHOLE_NUMBER_FORMAT}
                                   prevValue={previousData.shared_offers}/>
                <StatisticsTopTile title="Wypłacony Cashback (zł)" value={currentData.cashback_withdrawn}
                                   prevValue={previousData.cashback_withdrawn}/>
            </div>
            <StatisticsAggregateChartTile aggregate={aggregate}
                                          chartData={currentData.aggregate_data}
                                          prevChartData={previousData.aggregate_data}
                                          value={currentData.aggregate_sum} prevValue={previousData.aggregate_sum}
                                          chartInterval={chartInterval}
                                          barChartSelected={barChartSelected}
                                          setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
            />

            <div className="block-wrapper statistics-chart-block-wrapper">
                <StatisticsChartTile dataKey="amount" {...chartTileProps}/>
                <StatisticsChartTile dataKey="transactions" {...chartTileProps}/>
                <StatisticsChartTile dataKey="gender" {...chartTileProps}/>
            </div>
            <div className="block-wrapper statistics-chart-block-wrapper">
                <StatisticsChartTile dataKey="age" {...chartTileProps}/>
                <StatisticsChartTile dataKey="dynamics" {...chartTileProps}/>
                <StatisticsChartTile dataKey="average" {...chartTileProps}/>
            </div>
            <div className="block-wrapper statistics-chart-block-wrapper">
                <StatisticsChartTile dataKey="new_clients" {...chartTileProps}/>
                <StatisticsChartTile dataKey="returning_clients" {...chartTileProps}/>
                <StatisticsChartTile dataKey="active_clients" {...chartTileProps}/>
            </div>
            <div className="block-wrapper">
                <StatisticsTable/>
            </div>
            <TargetGroupFloatingButtons/>
            <ControlsModal isOpen={isControlsModalOpen} setIsOpen={setIsControlsModalOpen} showStatisticsControls/>
        </div>
    )
};

export default Statistics;