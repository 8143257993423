import {CroppedAreaPixels, ServiceType} from "./interfaces";
import {cloudFrontUrl, logoCategoryIcons} from "./images";

export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url
    });



export const getCroppedImg = async (imageSrc: string, pixelCrop: CroppedAreaPixels) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null
    }
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.translate(image.width / 2, image.height / 2);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
        return null
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;
    croppedCtx.fillStyle = 'white';
    croppedCtx.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);

    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    return croppedCanvas.toDataURL('image/jpeg');
};

export const isBase64Image = (imageData: string): boolean => {
  const base64Regex = /^data:image\/[a-zA-Z]+;base64,/;
  return base64Regex.test(imageData);
};

export const isLogo = ( logo: string, serviceTypes: ServiceType[]): string => {
    if(logo === ""){
        const serviceName: ServiceType|undefined = serviceTypes.find(obj => obj.is_main)
        if(serviceName) return logoCategoryIcons[serviceName.name];
    } else {
        if(isBase64Image(logo)){
            return logo;
        } else {
            return `${cloudFrontUrl}${logo}`;
        }
    }
    return "";
}