import React from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import ContactAdminMessage from "./contact/ContactAdminMessage";


interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    predefinedTitle?: string;
    predefinedBody?: string
}

const AdminMessageModal = ({isOpen, setIsOpen, predefinedTitle, predefinedBody}: Props) => {

    const toggleModal = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content admin-message-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">Wyślij wiadomość do administratora PayProfit</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ContactAdminMessage onClose={toggleModal}
                                     isOpen={isOpen}
                                     predefinedTitle={predefinedTitle}
                                     predefinedBody={predefinedBody}
                />
            </div>
        </Modal>
    )
};


export default AdminMessageModal;