import {formatNumber} from "../../../services/numbers";
import React from "react";
import {icons} from "../../../services/images";
import {Product, Service} from "../../../services/interfaces";
import {ProductUnitShortEnum} from "../../../services/dictionaries/enums";
import {formatDurationToString} from "../../../services/dates";
import {isProduct, isService} from "../../../services/helpers";


interface Props {
    onClick: () => void;
    item: Service | Product
}


const CategoryItemComponent = ({onClick, item}: Props) => {

    return (
        <li className={`item ${item.active ? '' : 'not-active'} ${"quantity_in_stock" in item && item.quantity_in_stock === 0 ? 'empty-stock' : ''}`}
            onClick={onClick}>
            <div>
                <span className="item-name">{item.name}</span>
                {item.description ?
                    <span className="item-description">{item.description}</span> :
                    <span className="item-no-description">Dodaj opis</span>
                }
                <div className="item-details">
                    {isProduct(item) &&
                        <>
                            {item.quantity_in_stock === 0 && <img src={icons.redWarningIcon} alt="Warning"/>}
                            <span className="stock">Stan:</span>
                            <span className={`stock-quantity ${item.quantity_in_stock === 0 ? 'empty' : ''}`}>
                                {item.quantity_in_stock} {ProductUnitShortEnum[item.unit]}
                            </span>
                        </>
                    }
                    {isService(item) &&
                        <>
                            <span>Czas Trwania:</span>
                            <span>{formatDurationToString(item.duration)}</span>
                        </>
                    }
                </div>
            </div>
            <div>
                <span className="item-price">{formatNumber(item.price)}</span>
                <span className="chevron right"/>
            </div>
        </li>
    )
};
export default CategoryItemComponent;