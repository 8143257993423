import React, {useEffect} from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {getReservationDetails} from "../../../redux/selectors/bookingSelector";
import {setReservationDetailsAction} from "../../../redux/booking";


interface Props {
    reservationId: number | null,
    onClose: () => void;
}

const ReservationDetailsModal = ({onClose, reservationId}: Props) => {
    const dispatch = useDispatch();

    const reservation = useSelector(getReservationDetails);

    useEffect(() => {
        if (reservationId !== null)
            console.log(reservationId) // TODO Add getDetailsHandler
    }, [reservationId]);

    const closeModal = () => {
        onClose();
        dispatch(setReservationDetailsAction(null)
        )
    };

    return (
        <Modal
            isOpen={!!reservationId}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content booking-details-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">
                    Szczegóły rezerwacji <span className="blue-primary">#{reservation ? reservation.id : ""}</span>
                </h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {reservation ?
                <div className="modal-body">
                </div> :
                <LoadingScreen/>
            }
        </Modal>
    )
};

export default ReservationDetailsModal;