import AdminMerchants from "../components/merchants/AdminMerchants";
import {useEffect} from "react";
import {getMerchantsPieChartDataHandler} from "../handlers/adminMerchantsHandlers";
import {DateRange} from "../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminDateRange} from "../redux/selectors/adminViewSettingsSelectors";


const AdminMerchantsContainer = () => {
    const dateRange: DateRange = useSelector(getAdminDateRange);
    useEffect(() => {
        getMerchantsPieChartDataHandler(dateRange)
    }, [dateRange]);

    return <AdminMerchants/>
};

export default AdminMerchantsContainer;