import {Aggregate, PieChartData, PreparedPieChartData} from "../../../services/interfaces";
import {
    calculateChange,
    DOUBLE_DECIMAL_NUMBER_FORMAT,
    formatNumber,
    isFloat,
    WHOLE_NUMBER_FORMAT,
    WHOLE_NUMBER_FORMAT_PERCENTAGE
} from "../../../services/numbers";
import {Cell, Label, Pie, PieChart} from "recharts";
import {getPieChartColorById} from "../../../services/chartUtils";

interface Props {
    chartData: PreparedPieChartData[];
    selectedSum: number;
    prevData?: PieChartData[];
    aggregate?: Aggregate
    handleSelect?: (entry: PreparedPieChartData) => void;
    setHoveredEntry?: (entry: PreparedPieChartData | null) => void;
    isEntryHovered?: (id: number | string) => boolean;
    isEntrySelectable: (entry: PreparedPieChartData) => boolean;
}

const CHART_SIZES = {
    WITH_PREV_DATA: {
        HEIGHT: 320,
        WIDTH: 400
    },
    WITHOUT_PREV_DATA: {
        HEIGHT: 140,
        WIDTH: 140
    }
};

const OUTER_RADIUS_SIZES = {
    WITH_PREV_DATA: 106,
    WITHOUT_PREV_DATA: 70
};

const CORNER_RADIUS_SIZES = {
    WITH_PREV_DATA: 6,
    WITHOUT_PREV_DATA: 4
};

const SUM_FONT_SIZES = {
    WITH_PREV_DATA: "18px",
    WITHOUT_PREV_DATA: "12px"
};

const INNER_RADIUS_RATIO = 0.67;

const PieChartGraph = ({
                           chartData,
                           selectedSum,
                           prevData,
                           aggregate="amount",
                           handleSelect,
                           setHoveredEntry,
                           isEntryHovered,
                           isEntrySelectable,
                       }: Props) => {
    const SIZE = prevData ? CHART_SIZES.WITH_PREV_DATA : CHART_SIZES.WITHOUT_PREV_DATA;
    const OUTER_RADIUS = prevData ? OUTER_RADIUS_SIZES.WITH_PREV_DATA : OUTER_RADIUS_SIZES.WITHOUT_PREV_DATA;
    const INNER_RADIUS = OUTER_RADIUS * INNER_RADIUS_RATIO;
    const CORNER_RADIUS = prevData ? CORNER_RADIUS_SIZES.WITH_PREV_DATA : CORNER_RADIUS_SIZES.WITHOUT_PREV_DATA;
    const SUM_FONT_SIZE = prevData ? SUM_FONT_SIZES.WITH_PREV_DATA : SUM_FONT_SIZES.WITHOUT_PREV_DATA;

    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, value, prevValue}: any) => {
        const RADIAN = Math.PI / 180;
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const change = calculateChange(value, prevValue);
        const changeColor = change ? (change > 0 ? "#11DB95" : "#D13735") : "#989898";

        return (
            <>
                <text x={x} y={y} fill="black" style={{fontSize: 14, fontWeight: "bold", fontFamily: "Roboto"}}
                      textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                    {formatNumber(value, aggregate === "amount" ? DOUBLE_DECIMAL_NUMBER_FORMAT : WHOLE_NUMBER_FORMAT)}
                </text>
                <text x={x} y={y + 20} fill={changeColor}
                      style={{fontSize: 12, fontWeight: "bold", fontFamily: "Roboto"}}
                      textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
                    {change > 0 && "+"}{formatNumber(change, WHOLE_NUMBER_FORMAT_PERCENTAGE)}
                </text>
            </>
        );
    };

    const getCellClassName = (entry: PreparedPieChartData) => `
        pie-chart-filling
        ${entry.checked ? '' : 'unchecked'}
        ${isEntryHovered && isEntryHovered(entry.id) ? 'hovered' : ''}
        ${isEntrySelectable(entry) ? "selectable" : "not-selectable"}  
    `;

    return (
        <PieChart width={SIZE.WIDTH} height={SIZE.HEIGHT}>
            <Pie
                dataKey="value"
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={INNER_RADIUS}
                outerRadius={OUTER_RADIUS}
                startAngle={90}
                endAngle={450}
                fill="#82ca9d"
                cornerRadius={CORNER_RADIUS}
                paddingAngle={4}
                isAnimationActive={false}
                label={prevData ? renderCustomizedLabel : false}
            >
                {chartData.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={getPieChartColorById(entry.id)}
                        onClick={() => handleSelect && handleSelect(entry)}
                        onMouseOver={() => setHoveredEntry && setHoveredEntry(entry)}
                        onMouseLeave={() => setHoveredEntry && setHoveredEntry(null)}
                        className={getCellClassName(entry)}
                    />
                ))}
                <Label
                    value={formatNumber(selectedSum, isFloat(selectedSum) ? DOUBLE_DECIMAL_NUMBER_FORMAT : WHOLE_NUMBER_FORMAT)}
                    position="center"
                    fill="black"
                    style={{
                        fontSize: SUM_FONT_SIZE,
                        fontWeight: "500",
                        fontFamily: "Roboto"
                    }}
                />
            </Pie>
        </PieChart>
    );
};

export default PieChartGraph;