import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {omit} from "lodash";
import {AdminUserDetails, MerchantUserDetails} from "../services/interfaces";

interface AuthenticateActionInterface {
    accessToken: string | null;
    expDate: string | null;
    adminPanel?: boolean | null;
}

interface AuthState {
    accessToken: string | null;
    error: string | null;
    expDate: string | null;
    isAuthenticated: boolean | null;
    isAdminPanel: boolean | null;
    userDetails: MerchantUserDetails | null;
    adminUserDetails: AdminUserDetails | null;

}

const DEFAULT_STATE: AuthState = {
    accessToken: null,
    error: null,
    expDate: null,
    isAuthenticated: null,
    userDetails: null,
    adminUserDetails: null,
    isAdminPanel: null
};

const authSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'auth',
    reducers: {
        authenticate: (state, action: PayloadAction<AuthenticateActionInterface>) => {
            return {
                ...state,
                accessToken: action.payload.accessToken,
                error: DEFAULT_STATE.error,
                expDate: action.payload.expDate,
                isAuthenticated: true,
                isAdminPanel: action.payload.adminPanel || state.isAdminPanel
            }
        },
        logout: (state) => {
            return {...state, ...omit(DEFAULT_STATE, 'introducedUser'), isAuthenticated: false}
        },
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAuthenticated: action.payload
            }
        },
        setError: (state, action: PayloadAction<string | null>) => {
            return {...state, error: action.payload}
        },
        setUserDetails: (state, action: PayloadAction<MerchantUserDetails>) => {
            return {
                ...state,
                userDetails: action.payload
            }
        },
        setAdminUserDetails: (state, action: PayloadAction<AdminUserDetails>) => {
            return {
                ...state,
                adminUserDetails: action.payload
            }
        }
    }
});

export default authSlice.reducer;

export const authenticateAction = authSlice.actions.authenticate;
export const logoutAction = authSlice.actions.logout;
export const setAuthenticatedAction = authSlice.actions.setAuthenticated;
export const setAccountError = authSlice.actions.setError;
export const setUserDetailsAction = authSlice.actions.setUserDetails;

export const setAdminUserDetailsAction = authSlice.actions.setAdminUserDetails;

