import {MerchantPointSummary} from "../../../services/interfaces";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, formatNumber, WHOLE_NUMBER_FORMAT} from "../../../../services/numbers";
import {translateServiceType} from "../../../../services/dictionaries/serviceTypesDict";


const AdminMerchantPointRow = ({merchantPoint, excludedColumns}: {
    merchantPoint: MerchantPointSummary,
    excludedColumns: string[]
}) => {
    return (
        <tr className="inner-row">
            {!excludedColumns.includes('name') &&
                <td className="client-id-cell">{merchantPoint.name}</td>}
            {!excludedColumns.includes('service_type') && <td>{translateServiceType(merchantPoint.service_type)}</td>}
            {!excludedColumns.includes('points_count') && <td>--</td>}
            {!excludedColumns.includes('cashback') &&
                <td className="cell-digit">
                    {formatNumber(merchantPoint.cashback)}
                    <span
                        className="gray"> ({formatNumber(merchantPoint.cashback / merchantPoint.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                </td>}
            {!excludedColumns.includes('cashback_share') &&
                <td className="cell-digit">{formatNumber(merchantPoint.cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
            {!excludedColumns.includes('payprofit_fee') &&
                <td className="cell-digit">
                    {formatNumber(merchantPoint.payprofit_fee)}
                    <span
                        className="gray"> ({formatNumber(merchantPoint.payprofit_fee / merchantPoint.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                </td>}
            {!excludedColumns.includes('payprofit_fee_share') &&
                <td className="cell-digit">{formatNumber(merchantPoint.payprofit_fee_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
            {!excludedColumns.includes('partner_cashback') &&
                <td className="cell-digit">
                    {formatNumber(merchantPoint.partner_cashback)}
                    <span
                        className="gray"> ({formatNumber(merchantPoint.partner_cashback / merchantPoint.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                </td>}
            {!excludedColumns.includes('partner_cashback_share') &&
                <td className="cell-digit">{formatNumber(merchantPoint.partner_cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
            {!excludedColumns.includes('forest_cashback') &&
                <td className="cell-digit">
                    {formatNumber(merchantPoint.forest_cashback)}
                    <span
                        className="gray"> ({formatNumber(merchantPoint.forest_cashback / merchantPoint.amount_sum, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)})</span>
                </td>}
            {!excludedColumns.includes('forest_cashback_share') &&
                <td className="cell-digit">{formatNumber(merchantPoint.forest_cashback_share, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE)}</td>}
            {!excludedColumns.includes('due_payment') &&
                <td className="cell-digit">{formatNumber(merchantPoint.due_payment)}</td>}
            {!excludedColumns.includes('transactions_count') &&
                <td className="cell-digit">{formatNumber(merchantPoint.transactions_count, WHOLE_NUMBER_FORMAT)}</td>}
        </tr>
    )
};

export default AdminMerchantPointRow;