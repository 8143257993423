import {
    ChartInterval,
    DateRange,
    DownloadTableProps,
    GetTableProps,
    PreviousPeriod,
    TargetGroup
} from "../services/interfaces";
import {getCurrentMerchantPoint, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {store} from "../redux/store";
import {
    confirmReturn,
    downloadMerchantPointTransactions,
    downloadMerchantTransactions,
    getMerchantHistoryFilterOptions,
    getMerchantPointHistoryFilterOptions,
    getMerchantPointTransactionDashboard,
    getMerchantPointTransactions,
    getMerchantReturnMatches,
    getMerchantReturns,
    getMerchantTransactionDashboard,
    getMerchantTransactions
} from "../http/transactions";
import {
    setHistoryFilterOptionsAction,
    setHistoryTransactionDashboardAction,
    setHistoryTransactionsAction,
    setMerchantReturnsAction,
    setReturnMatchesAction
} from "../redux/merchant";
import {saveAs} from "file-saver";
import {
    prepareB64FileToXLSX,
    prepareURLParamForChartDataWithTargetGroup,
    prepareURLParamForDownloadTable,
    prepareURLParamForTable
} from "../services/utils";
import {setScreenLoadingAction} from "../redux/navigation";

export const getMerchantTransactionsDashboardHandler = (dateRange: DateRange,
                                                        previousPeriod: PreviousPeriod,
                                                        chartInterval: ChartInterval,
                                                        targetGroup: TargetGroup) => {
    store.dispatch(setScreenLoadingAction(true));
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    const urlParam = prepareURLParamForChartDataWithTargetGroup(dateRange, previousPeriod, chartInterval, targetGroup);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointTransactionDashboard(merchantPoint.id, urlParam).then(data => {
            store.dispatch(setHistoryTransactionDashboardAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        })
    } else {
        getMerchantTransactionDashboard(urlParam).then(data => {
            store.dispatch(setHistoryTransactionDashboardAction(data));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        });
    }
};

export const getMerchantTransactionsHandler = (props: GetTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointTransactions(merchantPoint.id, urlParam.toString()).then((data) => {
            store.dispatch(setHistoryTransactionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    } else {
        getMerchantTransactions(urlParam.toString()).then((data) => {
            store.dispatch(setHistoryTransactionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const downloadTransactionTableHandler = (props: DownloadTableProps) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const urlParam = prepareURLParamForDownloadTable(props);

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        downloadMerchantPointTransactions(merchantPoint.id, urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, `transactions-${new Date().toISOString()}.xlsx`)
        }).catch(error => {
            console.error(error);
        })
    } else {
        downloadMerchantTransactions(urlParam.toString()).then((response) => {
            const blob = prepareB64FileToXLSX(response.data.file);
            saveAs(blob, `transactions-${new Date().toISOString()}.xlsx`)
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getHistoryFilterOptionsHandler = () => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());

    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointHistoryFilterOptions(merchantPoint.id).then(data => {
            store.dispatch(setHistoryFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    } else {
        getMerchantHistoryFilterOptions().then(data => {
            store.dispatch(setHistoryFilterOptionsAction(data))
        }).catch(error => {
            console.error(error);
        })
    }
};

export const getMerchantReturnsHandler = () => {
    getMerchantReturns().then(data => {
        store.dispatch(setMerchantReturnsAction(data));
    }).catch(error => {
        console.error(error);
    })
};

export const getMerchantReturnMatchesHandler = (returnId: number) => {
    getMerchantReturnMatches(returnId).then(data => {
        store.dispatch(setReturnMatchesAction(data));
    }).catch(error => {
        console.error(error);
    })
};

export const confirmReturnHandler = (returnId: number, matchId: number, callback: () => void) => {
    confirmReturn(returnId, matchId).then(() => {
        callback();
    }).catch(error => {
        console.error(error);
    })
};