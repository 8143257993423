import React, {useMemo, useState} from "react";
import AdminMerchantPointServiceModal from "../modals/AdminMerchantPointServiceModal";
import Button from "../../../components/common/Button";
import {useSelector} from "react-redux";
import {getAdminGlobalFilters} from "../../redux/selectors/adminViewSettingsSelectors";
import {isEmpty} from "lodash";


const AdminMerchantPointServiceButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const globalFilters = useSelector(getAdminGlobalFilters);

    const label =  useMemo(() => {
        if (isEmpty(globalFilters.merchantPoints) && isEmpty(globalFilters.serviceTypes))
            return "Wybierz sklepy/branże";
        else
            return `Sklepy: ${globalFilters.merchantPoints.length} | Branże: ${globalFilters.serviceTypes.length}`
    }, [globalFilters]);

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)} label={label} extraStyle={{
                width: "unset", gap: "unset", fontWeight: "unset"}}/>
            <AdminMerchantPointServiceModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>
        </>
    )
};

export default AdminMerchantPointServiceButton;