import {SignUpCredentials} from "../../services/interfaces";
import React, {useState} from "react";
import {icons, images} from "../../services/images";
import OnboardingSidebar from "./OnboardingSidebar";
import Button from "../common/Button";
import {signUpHandler} from "../../handlers/authHandlers";
import {Navigate} from "react-router-dom";
import {VERIFY_PHONE_PAGE} from "../../services/dictionaries/paths";
import Input from "../common/Input";
import CountrySelect from "../common/CountrySelect";
import {AsYouType, CountryCode, isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js'

interface Props {
    data: SignUpCredentials
    setData: React.Dispatch<React.SetStateAction<SignUpCredentials>>
    setStagePhoneNumber: React.Dispatch<React.SetStateAction<boolean>>
}

const SendPhoneNumber = ({data, setData, setStagePhoneNumber}: Props) => {

    const [sendPhoneNumberClicked, setSendPhoneNumberClicked] = useState<boolean>(false);


    interface TelephoneErrorKey {
        telephoneError: string
    }

    const errorMessage: Record<keyof TelephoneErrorKey, string> = {
        telephoneError: "Niepoprawny numer telefonu",
    };

    const buttonSendCallback = ({success}: { success: boolean }) => {
        if (success) {
            return <Navigate to={VERIFY_PHONE_PAGE}/>
        }
        setStagePhoneNumber(false);
        setSendPhoneNumberClicked(false);
    };

    const validPhoneNumber: boolean = isValidPhoneNumber(data.phoneNumber, data.countryCode as CountryCode || 'PL');
    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="Numer telefonu"
                image={images.onboardingTelephone}
                body="W trosce o Twoje bezpieczeństwo podchodzimy poważnie do weryfikacji konta przed jego założeniem.
                Numer telefonu jest nam potrzebny wyłącznie do rejestracji, nie będziemy Ci nic wysyłać ani przekazywać go dalej."
            />
            <div className="onboarding-content verify-telephone">
                <h2>Wpisz numer telefonu</h2>
                <p className="note">Podaj nam swój numer telefonu, na który dostaniesz smsem kod weryfikacyjny.</p>
                <form>
                    <div className="phone-wrapper">
                        <CountrySelect setData={setData}/>
                        <Input<SignUpCredentials, 'phoneNumber'>
                            value={data}
                            displayedValue={new AsYouType(data.countryCode as CountryCode).input(data.phoneNumber)}
                            onChange={setData}
                            name="phoneNumber"
                            type="tel"
                            icon={icons.telephoneIcon}
                            showError
                            placeholder="Numer telefonu"
                            validate={() => validPhoneNumber ? null : 'telephoneError'}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <Button
                        label="Wyślij kod na mój telefon"
                        onClick={() => {
                            data.phoneNumber = parsePhoneNumber(data.phoneNumber, data.countryCode as CountryCode).number;
                            setSendPhoneNumberClicked(true);
                            signUpHandler(data, buttonSendCallback);
                        }}
                        loading={sendPhoneNumberClicked}
                        disabled={!validPhoneNumber}
                    />
                </form>
            </div>
        </div>
    )
};

export default SendPhoneNumber;