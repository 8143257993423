import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {
    ActiveClientInterval,
    Aggregate,
    AppUserDetails,
    ChartData,
    PieChartData,
    StatisticsData,
    StatisticsPieChartKey,
    StatisticsUserSummary,
    TargetGroup
} from "../../services/interfaces";
import {getMerchantState} from "./merchantSelector";
import {TARGET_GROUP_RANGE_KEYS} from "../merchant";


export const getStatisticsState = (state: RootState) => state.statistics;


export const getStatisticsData: Selector<RootState, StatisticsData> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.data
);

export const getStatisticsDetailsCurrentData: Selector<RootState, PieChartData[] | ChartData[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.details.current
);

export const getStatisticsDetailsPreviousData: Selector<RootState, PieChartData[] | ChartData[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.details.previous
);

export const getStatisticsAggregate: Selector<RootState, Aggregate> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.aggregate
);

export const getStatisticsFilterOrder: Selector<RootState, StatisticsPieChartKey[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.filterOrder
);

export const getStatisticsActiveClientInterval: Selector<RootState, ActiveClientInterval> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.activeClientInterval
);

export const getStatisticsTableList: Selector<RootState, StatisticsUserSummary[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.table.users
);

export const getStatisticsTableMaxPage: Selector<RootState, number> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.table.maxPage
);

export const getStatisticsTableCount: Selector<RootState, number> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.table.usersCount
);

export const getStatisticsUserDetails: Selector<RootState, AppUserDetails | null> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.userDetails
);

export const getStatisticsTableMode: Selector<RootState, boolean> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.tableMode
);

export const getStatisticsCurrentTargetGroup: Selector<RootState, TargetGroup> = createSelector(
    getMerchantState,
    getStatisticsState,
    (merchantState, statisticsState) => {
        if (!statisticsState.tableMode)
            return merchantState.currentTargetGroup;

        let emptyTargetGroup = {...merchantState.currentTargetGroup};
        emptyTargetGroup.gender = [];
        TARGET_GROUP_RANGE_KEYS.forEach(key => {
            emptyTargetGroup[key] = emptyTargetGroup[key].map(range => ({
                ...range,
                selected: false
            }));
        });
        return emptyTargetGroup;
    }
);

export const getStatisticsSelectedUserIds: Selector<RootState, number[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.selectedUserIds
);


export const getStatisticsAllUserIds: Selector<RootState, number[]> = createSelector(
    getStatisticsState,
    (statisticsState) => statisticsState.table.allUserIds
);