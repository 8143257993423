import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";
import DateIntervalComposedChart from "../common/chart/DateIntervalComposedChart";
import {Aggregate, ChartData, ChartInterval} from "../../services/interfaces";
import {DOUBLE_DECIMAL_NUMBER_FORMAT, formatNumber, WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import {useSelector} from "react-redux";
import {getPreviousPeriod} from "../../redux/selectors/merchantSelector";
import {clientsTooltipFormat, transactionTooltipFormat} from "../../services/chartUtils";

interface Props {
    chartData: ChartData[]
    prevChartData: ChartData[]
    value: number
    prevValue: number
    aggregate: Aggregate
    chartInterval: ChartInterval
    barChartSelected: boolean
    setBarChartSelected: (barChartSelected: boolean) => void
}

const StatisticsAggregateChartTile = ({
                                          aggregate,
                                          chartData,
                                          value,
                                          prevValue,
                                          prevChartData,
                                          chartInterval,
                                          barChartSelected,
                                          setBarChartSelected,
                                      }: Props) => {
    const previousPeriod = useSelector(getPreviousPeriod);
    let label = '';
    let format = WHOLE_NUMBER_FORMAT;
    let chartType: 'absolute' | 'currency' = 'absolute';
    let tooltipFormat = undefined;
    switch (aggregate) {
        case "amount":
            label = "Przychody (zł)";
            format = DOUBLE_DECIMAL_NUMBER_FORMAT;
            chartType = 'currency';
            break;
        case "transactions":
            label = "Liczba transakcji";
            tooltipFormat = transactionTooltipFormat;
            break;
        case "clients":
            label = "Liczba klientów";
            tooltipFormat = clientsTooltipFormat;
            break;
    }


    return (
        <div className="block-wrapper chart-block-wrapper">
            <div className="block chart-block big-chart-block">
                <div className="value-section">
                    <h3>{label}</h3>
                    <div className="value-wrapper">
                        <span className="standard-value">{formatNumber(value, format)}</span>
                        <ChangePill value={value}
                                    prevValue={prevValue}
                        />
                        <TimeRangeLabel/>
                    </div>
                </div>
                <DateIntervalComposedChart chartData={chartData}
                                           prevChartData={prevChartData}
                                           chartType={chartType}
                                           chartInterval={chartInterval}
                                           barChartSelected={barChartSelected}
                                           setBarChartSelected={setBarChartSelected}
                                           previousPeriod={previousPeriod}
                                           tooltipFormat={tooltipFormat}
                />
            </div>
        </div>
    )
};


export default StatisticsAggregateChartTile;