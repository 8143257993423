import Table from "../../../components/common/Table";
import {separateAccountNumber} from "../../../services/utils";
import {
    downloadAliorTransferFileHandler,
    fetchPayoutAvailabilityHandler,
    fetchPayoutsHandler
} from "../../handlers/adminFinanceHandlers";
import {useEffect, useState} from "react";
import {OrderType} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getDownloadAvailability, getPayouts} from "../../redux/selectors/adminFinanceSelectors";
import {PayoutDetails} from "../../services/interfaces";
import {formatNumber} from "../../../services/numbers";
import AdminPayoutsModal from "../modals/AdminPayoutsModal";

export type PayoutStatus = 'queue' | 'imported' | 'completed';

const Payouts = () => {

    const [filterString, setFilterString] = useState<string>('status=queue');
    const [order, setOrder] = useState<OrderType>(null);
    const [message, setMessage] = useState<string | null>(null);

    const callbackMessage = (message: string) => {
        setMessage(message);
    }

    const fetchPayoutData = () => {
        fetchPayoutsHandler(filterString === '' ? '' : `?${filterString}`);
        fetchPayoutAvailabilityHandler();
    }

    useEffect(() => {
        fetchPayoutData();
    }, [filterString]);

    const payouts: PayoutDetails[] = useSelector(getPayouts);
    const downloadAvailable: boolean = useSelector(getDownloadAvailability);

    const formatStatus = (status: string) => {
        switch (status) {
            case 'queue':
                return 'Do wypłaty';
            case 'imported':
                return 'Zaimportowany do pliku';
            case 'completed':
                return 'Zakończony';
            default:
                return 'Nieznany';
        }
    }

    return <div>
        {message && <span className="error-message">{message}</span>}
        <Table
            page={1}
            setPage={() => {
            }}
            query={''}
            setQuery={() => {
            }}
            sort={''}
            setSort={() => {
            }}
            order={order}
            setOrder={() => {
            }}
            filters={filterString}
            count={0}
            pageSize={10}
            setPageSize={() => {
            }}
            setFilters={setFilterString}
            maxPage={1}
            renderRows={() => payouts ?
                payouts.map(payout => <tr key={payout.id}>
                    <td>{payout.user.email} (ID: {payout.user.id})</td>
                    <td>{payout.account.bank.bank_name}</td>
                    <td>{separateAccountNumber(payout.account.account_no)}</td>
                    <td>{payout.trigger_date ?? '--'}</td>
                    <td>{payout.payout_date ?? 'oczekuje'}</td>
                    <td>{formatNumber(payout.amount)}</td>
                    <td>{formatStatus(payout.status)}</td>
                </tr>) : null
            }
            downloadHandler={() => {
                downloadAliorTransferFileHandler(callbackMessage, () => fetchPayoutData())
            }}
            downloadDisabled={!downloadAvailable}
            headers={[
                {name: 'Użytkownik', sort: ''},
                {name: 'Bank', sort: ''},
                {name: 'Numer konta', sort: ''},
                {name: 'Data polecenia wypłaty', sort: ''},
                {name: 'Data wpłaty', sort: ''},
                {name: 'Kwota', sort: ''},
                {name: 'Status', sort: ''},
            ]}
            Modal={AdminPayoutsModal}
            extraButtons={<></>}
            hasActionButton={true}
            searchable={false}
            pagination={false}
        />
    </div>
}

export default Payouts;