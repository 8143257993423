import {TreeSelect, TreeSelectProps} from "antd";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {getAdminMerchants} from "../../redux/selectors/adminViewSettingsSelectors";

interface Props {
    merchantPointIds: number[]
    setMerchantPointsIds: React.Dispatch<React.SetStateAction<number[]>>
}

interface TreeNode {
    title: string;
    value: string;
    children?: TreeNode[];
    id: number
}

const AdminMerchantPointFilter = ({merchantPointIds, setMerchantPointsIds}: Props) => {
    const merchants = useSelector(getAdminMerchants);
    const treeData: TreeNode[] = useMemo(() => {
        return merchants.map(merchant => ({
            title: `${merchant.name} (${merchant.merchant_points.length})`,
            id: merchant.id,
            value: `merchant-${merchant.id}`,
            children: merchant.merchant_points.map(point => ({
                title: point.name,
                value: `point-${point.id}`,
                id: point.id
            }))
        }));
    }, [merchants]);
    const treeSelectValues = treeData
        .flatMap(merchant => merchant.children || [])
        .filter(point => merchantPointIds.includes(point.id))
        .map(point => point.value);

    const onChange: TreeSelectProps['onChange'] = (newValue) => {
        const selectedPointIds = new Set<number>();
        newValue.forEach((value: string) => {
            if (value.startsWith('point-')) {
                selectedPointIds.add(parseInt(value.split('-')[1]));
            } else if (value.startsWith('merchant-')) {
                const merchantId = parseInt(value.split('-')[1]);
                const merchant = merchants.find(m => m.id === merchantId);
                if (merchant) {
                    merchant.merchant_points.forEach(point => {
                        selectedPointIds.add(point.id);
                    });
                }
            }
        });
        setMerchantPointsIds(Array.from(selectedPointIds))
    };

    return (
        <div className="section">
            <h6>Wybierz sklepy</h6>
            <TreeSelect
                treeData={treeData}
                value={treeSelectValues}
                onChange={onChange}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                placeholder="Zaznacz sklepy które chcesz wyświetlać..."
                className="dropdown-select"
            />
        </div>
    )
};

export default AdminMerchantPointFilter