import {
    ackTerminalDraft,
    addBankAccount, cancelVerifyTerminal,
    getBankList, getConfigureAccountNumbers,
    getConfigureBankUrl,
    getDraftTerminal, skipTerminalVerification,
    verifyTerminal
} from "../http/bank";
import {store} from "../redux/store";
import {setBankList} from "../redux/bank";
import {DraftTerminalResponse} from "../services/interfaces";
import React from "react";


export const bankListHandler = () => {
    getBankList().then(response => {
        store.dispatch(setBankList(response));
    }).catch(error => {
        throw error;
    })
};

export const configureBankHandler = (bankCode: number, callbackError: () => void, accountNumber?: string | null, merchantPointId?: number | null) => {
    getConfigureBankUrl(bankCode, accountNumber, merchantPointId).then(response => {
        window.location.href = response.aspspRedirectUri;
    }).catch(() => {
        callbackError()
    })
};

export const addBankAccountHandler = (bankCode: number, accountNumber: string, callback: (response: DraftTerminalResponse) => void, callbackError: () => void, merchantPointId?: number) => {
    addBankAccount(bankCode, accountNumber, merchantPointId).then(data => {
        callback(data as DraftTerminalResponse);
    }).catch(() => {
        callbackError()
    });
};

export const getDraftTerminalHandler = (draftTerminalCallback: (response: DraftTerminalResponse) => void) => {
    getDraftTerminal().then(data => {
        draftTerminalCallback(data as DraftTerminalResponse)
    }).catch((error) => {
        if (error.response.status === 404) {
            draftTerminalCallback({} as DraftTerminalResponse)
        } else {
            console.log(error)
        }
    });
};

export const ackTerminalDraftHandler = (callback: () => void, callbackError: () => void) => {
    ackTerminalDraft().then(() => {
        callback();
    }).catch((error) => {
        console.log(error);
        callbackError();
    });
};

export const verifyTerminalHandler = (callback: (verified: boolean) => void, callbackError: () => void) => {
    verifyTerminal().then((response: { verified: boolean }) => {
        if (response.verified) {
            callback(true)
        } else {
            callback(false)
        }
    }).catch((error) => {
        if (error.response.status === 404) {
            callbackError()
        }
        console.log(error)
    })
};

export const cancelVerificationHandler = (callback: () => void) => {
    cancelVerifyTerminal().then(() => {
        callback()
    }).catch((error) => {
        console.log(error)
    })
};

export const skipTerminalVerificationHandler = (callback: () => void) => {
    skipTerminalVerification().then(() => {
        callback()
    }).catch((error) => {
        console.log(error)
    })
};

export const getConfigureAccountNumbersHandler = (setAccountNumbers: React.Dispatch<React.SetStateAction<string[]>>, setBankId: React.Dispatch<React.SetStateAction<number | null>>) => {
    getConfigureAccountNumbers().then(data => {
        setAccountNumbers(data.account_numbers)
        setBankId(data.bank_id)
    }).catch((error) => {
        console.log(error)
    })
}