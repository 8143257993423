import React, {ChangeEvent, useRef, useState} from "react";
import {icons} from "../../../services/images";

interface Props {
    video: string;
    setVideoUrl: (video: string) => void;
    setVideoFile: (file: File) => void;
}

const AddVideo = ({video, setVideoUrl, setVideoFile}: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(video);
    const [error, setError] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && selectedFile.type === 'video/mp4') {
            if (selectedFile.size <= 50 * 1024 * 1024) {
                setFile(selectedFile);
                setError('');
                setVideoFile(selectedFile);

                const fileUrl = URL.createObjectURL(selectedFile);
                setPreviewUrl(fileUrl);
                setVideoUrl(fileUrl);
            } else {
                setError('Plik jest za duży. Maksymalny rozmiar to 50MB.');
                resetForm();
            }
        } else {
            setError('Proszę wybrać plik MP4.');
            resetForm();
        }
    };

    const resetForm = () => {
        setFile(null);
        setPreviewUrl(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    return (
        <>
            <div className="file-input-inner-wrapper">
                <div className="remove-wrapper">
                    <input
                        type="file"
                        accept="video/mp4"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        id={"video"}
                        hidden
                    />
                    {error && <p className="note" style={{color: 'red', marginBottom: 16}}>{error}</p>}
                    {!previewUrl ?
                        <label
                            htmlFor={"video"}
                            className={"input-video"}
                        >Dodaj film</label>
                        :
                        <>
                            <div>
                                <video className="media selected-video" controls>
                                    <source src={previewUrl} type="video/mp4"/>
                                    Twoja przeglądarka nie obsługuje tagu wideo.
                                </video>
                            </div>
                            <div className="remove" onClick={resetForm}>
                                <img src={icons.closeIcon} alt=""/>
                            </div>
                        </>
                    }
                </div>
                <p className="note">Rozszerzenia: MP4
                    Waga filmu nie może przekroczyć 50 MB. </p>
            </div>
        </>
    );
}

export default AddVideo;