import {cookieGet, cookieRemove, cookieSave, storageActionTypes} from "./basicStorage";

export enum warningBoxStorageKeys {
    DEPOSIT = 'deposit',
    RETURNS = 'returns'
}

export const warningBoxStorageManager = (action: storageActionTypes, storageKey: warningBoxStorageKeys, storageValue?: string): string | Promise<string | null> => {
    if (action === storageActionTypes.GET)
        return cookieGet(storageKey) as string;

    if (action === storageActionTypes.SET && storageValue)
        cookieSave(storageKey, storageValue);

    if (action === storageActionTypes.DELETE)
        cookieRemove(storageKey);

    return Promise.resolve(null);
}