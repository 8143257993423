import React, {useEffect, useState} from "react";
import {images} from "../../../services/images";
import OnboardingSidebar from "../OnboardingSidebar";
import {Navigate, useLocation} from "react-router-dom";
import {DraftTerminalResponse, MerchantUserDetails, QueryParams} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getUserDetails} from "../../../redux/selectors/authSelectors";
import {CONFIGURE_MERCHANT_PAGE} from "../../../services/dictionaries/paths";
import ConfigureTerminalBank from "./ConfigureTerminalBank";
import ConfigureTerminalPayment from "./ConfigureTerminalPayment";
import ConfigureTerminalVerify from "./ConfigureTerminalVerify";
import {getDraftTerminalHandler} from "../../../handlers/bankHandlers";
import LoadingScreen from "../../common/LoadingScreen";
import _ from "lodash";
import {getUserDetailsHandler} from "../../../handlers/authHandlers";


export enum Step {
    BANK = 0,
    PAYMENT = 1,
    VERIFY = 2,
}

const ConfigureTerminal = () => {
    const [currentStep, setCurrentStep] = useState<Step>(Step.BANK);
    const [terminalDraftAmount, setTerminalDraftAmount] = useState<number>(0.00);
    const [loading, setLoading] = useState<boolean>(true);
    const [bankLoading, setBankLoading] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState<QueryParams>({});
    const [queryParamsSet, setQueryParamsSet] = useState<boolean>(false);

    const location = useLocation();
    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);

    const draftTerminalCallback = (data: DraftTerminalResponse) => {
        if (!_.isEmpty(data)) {
            setTerminalDraftAmount(data.amount);
            setCurrentStep(data.acknowledged ? Step.VERIFY : Step.PAYMENT);
        }
        if (_.isEmpty(data) && location.search === '') {
            setBankLoading(true);
            setLoading(false);
            const timer = setTimeout(() => {
                setBankLoading(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
        setLoading(false);
    };

    const addBankAccountCallback = (response: DraftTerminalResponse) => {
        setTerminalDraftAmount(response.amount);
        setCurrentStep(Step.PAYMENT);
        window.history.pushState({}, '', window.location.pathname);
    };

    useEffect(() => {
        getDraftTerminalHandler(draftTerminalCallback);
        getUserDetailsHandler();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params: QueryParams = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
        });
        setQueryParams(params);
        setQueryParamsSet(true);

    }, [location.search]);

    if (userDetails && userDetails.verification_step !== 'terminal' &&
        queryParamsSet && queryParams.mp === undefined && !queryParams.hasOwnProperty('merchantPointId')
    ) {
        return <Navigate to={CONFIGURE_MERCHANT_PAGE}/>
    }

    if (loading)
        return <LoadingScreen/>;

    if (bankLoading)
        return <LoadingScreen
            text="Za chwilę przekierujemy Cię na stronę wyboru banku."/>;

    return (
        <div className="onboarding">
            <OnboardingSidebar
                title="Określenie nazwy terminala"
                image={images.onboardingTerminal}
                body="Aby określić nazwę terminala należy udzielić jednorazowej zgody na dostęp do Twojego rachunku bankowego. Następnie należy wykonać płatność testową na terminalu sklepowym w celu weryfikacji terminala."
                hint="Potrzebujesz pomocy?"
                hintLink="/" //TODO Change to valid link
            />
            <div className="onboarding-content configure-terminal">
                {
                    currentStep === Step.BANK ?
                        <ConfigureTerminalBank
                            queryParams={queryParams}
                            addBankAccountCallback={addBankAccountCallback}
                        />
                        : currentStep === Step.PAYMENT ?
                            <ConfigureTerminalPayment terminalDraftAmount={terminalDraftAmount}
                                                      setCurrentStep={setCurrentStep}/> :
                            currentStep === Step.VERIFY ?
                                <ConfigureTerminalVerify/> : null
                }
            </div>
        </div>
    );
};

export default ConfigureTerminal;