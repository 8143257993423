import Modal from "react-modal";
import {icons, images} from "../../services/images";
import React from "react";
import {OfferData} from "./Stepper";
import dayjs from "dayjs";
import {OfferTypeMapping} from "../../services/dictionaries/enums";
import {mapOfferDaysToString} from "../../services/utils";

interface PreviewModalProps {
    isOpen: boolean,
    onClose: () => void;
    formData: OfferData;
}

const PreviewModal = ({formData, isOpen, onClose}: PreviewModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="stepper-modal-content filters-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: 32}}>
                        <h5 className="modal-title" style={{margin: 0}}>Podgląd Promocji</h5>
                        <p className="label required-mark">Podgląd dotyczy widoku promocji z perspektywy klienta.</p>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="step-form-wrapper step-form">
                        <div>
                            <img
                                className="media"
                                src={formData.step3.imagePath ? formData.step3.imagePath : images.offerImagePlaceHolder}
                                alt={"Offer"}/>
                            <div style={{width: 350}}>
                                <h2 className="preview-h2 wrap">{formData.step2.name}</h2>
                                <p className="preview-p wrap">{formData.step2.description}</p>
                                <div className="one-line">
                                    <p className="preview-p primary">Promocja dostępna w okresie:&nbsp;</p>
                                    <span
                                        className="preview-span primary">{`${dayjs(formData.step2.startDate).format('DD.MM.YYYY')} - ${dayjs(formData.step2.endDate).format('DD.MM.YYYY')}`}</span>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
                                    <p className="preview-p primary">w&nbsp;</p>
                                    <p className="preview-p primary"
                                       style={{fontWeight: 700}}>
                                        {mapOfferDaysToString(formData.step2.daysOffer)}.
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    marginBottom: 32
                                }}>
                                    <div className="tag">Pozostałe użycia: {formData.step2.usage}</div>
                                    <div
                                        className="tag">{formData.step2.type && OfferTypeMapping[formData.step2.type]}</div>
                                </div>
                            </div>
                            {formData.step3.filmPath &&
                                <>
                                    <h3 className="preview-h3 wrap">{formData.step3.filmTitle !== "" && formData.step3.filmTitle}</h3>
                                    <video className="media" controls>
                                        <source src={formData.step3.filmPath}
                                                type="video/mp4"/>
                                        <source src={formData.step3.filmPath} type="video/ogg"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </Modal>
    );
};

export default PreviewModal;