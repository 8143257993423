import {createRoutesFromChildren, Navigate, Route} from "react-router-dom";
import Login from "./components/auth/Login";
import VerifyPhoneNumber from "./components/auth/VerifyPhoneNumber";
import ConfigureTerminal from "./components/auth/bank/ConfigureTerminal";
import TerminalSuccess from "./components/auth/TerminalSuccess";
import TerminalError from "./components/auth/TerminalError";
import NavigationContainer from "./containers/NavigationContainer";
import AdminNavigationContainer from "./admin/containers/AdminNavigationContainer";
import {
    ACTIVATE_ACCOUNT_PAGE,
    ADMIN_ACCOUNTING_PAGE,
    ADMIN_CUPS_PAGE,
    ADMIN_FOREST_PAGE,
    ADMIN_HOME_PAGE,
    ADMIN_LOGIN_PAGE,
    ADMIN_MERCHANTS_PAGE,
    ADMIN_USERS_PAGE,
    CONFIGURE_MERCHANT_PAGE,
    CONFIGURE_TERMINAL_PAGE,
    CUPS_PAGE,
    FOREST_PAGE,
    FORGOT_PASSWORD_PAGE,
    HISTORY_PAGE,
    HOME_PAGE,
    LOGIN_PAGE,
    MERCHANT_POINT_ADD_PAGE,
    MERCHANT_POINT_EDIT_PAGE,
    OFFERS_PAGE,
    SIGNUP_PAGE,
    STATISTICS_PAGE,
    TERMINAL_ERROR_PAGE,
    TERMINAL_SUCCESS_PAGE,
    VERIFY_PHONE_PAGE, ADMIN_PAYOUTS_PAGE, BOOKING_PAGE
} from "./services/dictionaries/paths";
import DashboardContainer from "./containers/DashboardContainer";
import AdminDashboardContainer from "./admin/containers/AdminDashboardContainer";
import StatisticsContainer from "./containers/StatisticsContainer";
import OffersContainer from "./containers/OffersContainer";
import ForestContainer from "./containers/ForestContainer";
import HistoryContainer from "./containers/HistoryContainer";
import CupsContainer from "./containers/CupsContainer";
import MerchantPointContainer from "./containers/MerchantPointContainer";
import SignUpContainer from "./containers/SignUpContainer";
import {useSelector} from "react-redux";

import {getIsAdminPanel, getIsAuthenticated} from "./redux/selectors/authSelectors";
import ActivateAccountContainer from "./containers/ActivateAccountContainer";
import ForgotPassword from "./components/auth/ForgotPassword";
import LoadingScreen from "./components/common/LoadingScreen";
import AdminLogin from "./admin/components/AdminLogin";
import AdminMerchantsContainer from "./admin/containers/AdminMerchantsContainer";
import AdminUsersContainer from "./admin/containers/AdminUsersContainer";
import AdminAccountingContainer from "./admin/containers/AdminAccountingContainer";
import AdminForestContainer from "./admin/containers/AdminForestContainer";
import AdminCupsContainer from "./admin/containers/AdminCupsContainer";
import AdminPayoutsContainer from "./admin/containers/AdminPayoutsContainer";
import BookingContainer from "./containers/BookingContainer";

const NonAuthenticatedRoutes = () => {
    return createRoutesFromChildren(
        <>
            <Route path="*" element={<Navigate to="/" replace={true}/>}/>
            <Route path={LOGIN_PAGE} element={<Login/>}/>
            <Route path={SIGNUP_PAGE} element={<SignUpContainer/>}/>
            <Route path={FORGOT_PASSWORD_PAGE} element={<ForgotPassword/>}/>
            <Route path={ACTIVATE_ACCOUNT_PAGE} element={<ActivateAccountContainer/>}/>
            <Route path={ADMIN_LOGIN_PAGE} element={<AdminLogin/>}/>
        </>
    );
};


const AuthenticatedRoutes = () => {
    return createRoutesFromChildren(
        <>
            <Route path={VERIFY_PHONE_PAGE} element={<VerifyPhoneNumber/>}/>
            <Route path={CONFIGURE_TERMINAL_PAGE} element={<ConfigureTerminal/>}/>
            <Route path={TERMINAL_SUCCESS_PAGE} element={<TerminalSuccess/>}/>
            <Route path={TERMINAL_ERROR_PAGE} element={<TerminalError/>}/>
            <Route element={<NavigationContainer/>}>
                <Route path={HOME_PAGE} element={<DashboardContainer/>}/>
                <Route path={CONFIGURE_MERCHANT_PAGE} element={<MerchantPointContainer/>}/>
                <Route path={MERCHANT_POINT_ADD_PAGE} element={<MerchantPointContainer isMerchantPointAdded={true}/>}/>
                <Route path={MERCHANT_POINT_EDIT_PAGE(':merchantPointId')} element={<MerchantPointContainer/>}/>
                <Route path={STATISTICS_PAGE} element={<StatisticsContainer/>}/>
                <Route path={OFFERS_PAGE} element={<OffersContainer/>}/>
                <Route path={FOREST_PAGE} element={<ForestContainer/>}/>
                <Route path={HISTORY_PAGE} element={<HistoryContainer/>}/>
                <Route path={CUPS_PAGE} element={<CupsContainer/>}/>
                <Route path={BOOKING_PAGE} element={<BookingContainer/>}/>
            </Route>
            <Route path="*" element={<Navigate to={HOME_PAGE} replace={true}/>}/>
        </>
    )
};

const AuthenticatedAdminRoutes = () => {
    return createRoutesFromChildren(
        <>
            <Route element={<AdminNavigationContainer/>}>
                <Route path={ADMIN_HOME_PAGE} element={<AdminDashboardContainer/>}/>
                <Route path={ADMIN_MERCHANTS_PAGE} element={<AdminMerchantsContainer/>}/>
                <Route path={ADMIN_USERS_PAGE} element={<AdminUsersContainer/>}/>
                <Route path={ADMIN_ACCOUNTING_PAGE} element={<AdminAccountingContainer/>}/>
                <Route path={ADMIN_PAYOUTS_PAGE} element={<AdminPayoutsContainer/>}/>
                <Route path={ADMIN_FOREST_PAGE} element={<AdminForestContainer/>}/>
                <Route path={ADMIN_CUPS_PAGE} element={<AdminCupsContainer/>}/>
            </Route>
            <Route path="*" element={<Navigate to={ADMIN_HOME_PAGE} replace={true}/>}/>
        </>
    )
};

const Router = () => {

    const isAuthenticated: boolean | null = useSelector(getIsAuthenticated);
    const isAdminPanel: boolean | null = useSelector(getIsAdminPanel);
    const loadingRoutes = createRoutesFromChildren(<Route path="*" element={<LoadingScreen/>}/>);
    return (
        isAuthenticated === null ? loadingRoutes :
            isAuthenticated ? isAdminPanel ? AuthenticatedAdminRoutes() : AuthenticatedRoutes() : NonAuthenticatedRoutes()
    )
};

export default Router;