import {createSelector, Selector} from "reselect";
import {RootState} from "./rootSelectors";
import {Chat, ContactPage, Message, User} from "../../services/interfaces";
import {MerchantState} from "../merchant";
import {getMerchantState} from "./merchantSelector";
import {getIsChatUnread} from "../../services/helpers";


export const getChatState = (state: RootState) => state.chat;

export const getChats: Selector<RootState, Chat[]> = createSelector(
    getChatState,
    (chat) => chat.chats
);


export const getMessages: Selector<RootState, Message[]> = createSelector(
    getChatState,
    (chat) => chat.messages
);


export const getChatAvailableUsers: Selector<RootState, User[]> = createSelector(
    getChatState,
    (chat) => chat.users
);


export const getCurrentChat: Selector<RootState, Chat | null> = createSelector(
    getChatState,
    (chat) => chat.currentChat
);

export const getContactPage: Selector<RootState, ContactPage | null> = createSelector(
    getChatState,
    (chat) => chat.contactPage
);


export const getUnreadChatsCount: Selector<RootState, number> = createSelector(
    getChats,
    (chats) => chats.filter(chat => getIsChatUnread(chat)).length
);
