import {AdminSidebarItem} from "../../../services/interfaces";
import {getAdminSidebarIcon} from "../../../services/images";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";
import {modalOpenAction, NavigationModal} from "../../../redux/navigation";


const AdminNavigationSidebarItem = ({path, title, name, modal}: AdminSidebarItem) => {
    const location = useLocation();
    const isActive = path ? location.pathname.includes(path) : false;
    const dispatch = useDispatch();
    return (
        <li className={isActive ? "active" : ''}>
            {modal ?
                <a href="#"
                   onClick={() => dispatch(modalOpenAction(name as NavigationModal))}>
                    <img className="list-icon" alt={`${name}icon`}
                         src={getAdminSidebarIcon(name, isActive)}/>{title}
                </a> :
                <Link to={path || '/'}>
                    <img className="list-icon" alt={`${name}icon`} src={getAdminSidebarIcon(name, isActive)}/>
                    {title}
                </Link>
            }
        </li>
    )
};

export default AdminNavigationSidebarItem;
