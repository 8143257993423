import {isInteger} from "lodash";

export const validateEmail = (email: string) => {
    const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailExpression.test(email);
};


export const emailValidation = (value: string): string | null => {
    if (!validateEmail(value))
        return 'emailError';
    return null;
};

export const createPasswordValidation = (value: string): string | null => {
    if (!(value.length >= 10 && /\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[!@#$%^&*(),.?":{}|<>]/.test(value)))
        return 'passwordError';
    return null;
};

export const passwordEqualityValidation = (password: string, repeatedPassword: string): string | null => {
    if (password !== repeatedPassword)
        return 'passwordsNotEqual';
    return null;
};


export const validateNipLength = (value: string) => value.length === 10;
export const validateNipNumber = (value: string) => isInteger(Number(value));

export const NIPValidation = (value: string): string | null => {
    if (!validateNipLength(value))
        return 'NIPLengthError';
    if (!validateNipNumber(value))
        return 'NIPNumberError';
    return null;
};

export const googleQueryValidation = (value: string): string | null => {
    if (value.length < 2)
        return 'QueryError';
    return null;
};


export const validateZipCode = (zipCode: string) => {
    const polishZipCodeExpression: RegExp = /^\d{5}$|^\d{2}-\d{3}$/;
    return polishZipCodeExpression.test(zipCode);
};

export const validatePasswordDecomposed = (password: string): {
    length: boolean,
    digit: boolean,
    lowerCase: boolean,
    upperCase: boolean,
    specialCharacter: boolean
} => ({
    length: password.length >= 10,
    digit: /\d/.test(password),
    lowerCase: /[a-z]/.test(password),
    upperCase: /[A-Z]/.test(password),
    specialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password)
});