import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import AdminMessageSuccess from "./AdminMessageSuccess";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import Button from "../../../../components/common/Button";
import Switch from "../../../../components/common/Switch";
import Input from "../../../../components/common/Input";
import TextArea from "../../../../components/common/TextArea";
import {Select} from "antd";
import {getAllAppUsersHandler, sendMessageToUsersHandler} from "../../../handlers/adminUsersHandlers";
import {useSelector} from "react-redux";
import {getAdminAppUserDetails, getAllAppUsers} from "../../../redux/selectors/adminUsersSelectors";
import {Gender, TargetGroup, User} from "../../../../services/interfaces";
import {getAppUserName} from "../../../../services/helpers";
import TargetGroupRangesSelect from "../../../../components/modals/target_group/TargetGroupRangesSelect";
import {TARGET_GROUP_DEFAULT_STATE} from "../../../../redux/merchant";
import {AdminMessageToUser, BankIdentification} from "../../../services/interfaces";
import {every, isEmpty} from "lodash";
import {getBanksFilterOptionsHandler} from "../../../handlers/filterOptionsHandlers";
import {getAdminBanksFilterOptions} from "../../../redux/selectors/adminViewSettingsSelectors";


interface Props {
    onClose: () => void
}

const AdminContactUserMessage = ({onClose}: Props) => {
    const [messageData, setMessageData] = useState({title: '', content: ''});
    const [userIds, setUserIds] = useState<number[]>([]);
    const [bankIds, setBankIds] = useState<number[]>([]);
    const [targetGroup, setTargetGroup] = useState<TargetGroup>(TARGET_GROUP_DEFAULT_STATE);
    const [hasCupActions, setHasCupActions] = useState<boolean | undefined>(undefined);
    const [hasActiveBankAccount, setHasActiveBankAccount] = useState<boolean | undefined>(undefined);
    const [sendNotification, setSendNotification] = useState<boolean>(false);

    const [successScreen, setSuccessScreen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const users: User[] = useSelector(getAllAppUsers);
    const singularUser = useSelector(getAdminAppUserDetails);
    const banks: BankIdentification[] = useSelector(getAdminBanksFilterOptions);

    const successCallback = () => setSuccessScreen(true);
    const errorCallback = () => setError('send_message_error');

    useEffect(() => {
        getAllAppUsersHandler();
        getBanksFilterOptionsHandler()
    }, []);

    useEffect(() => {
        singularUser && setUserIds([singularUser.id])
    }, [singularUser]);

    const data: AdminMessageToUser = {
        title: messageData.title,
        content: messageData.content,
        user_ids: isEmpty(userIds) ? undefined : userIds,
        gender: isEmpty(targetGroup.gender) ? undefined : targetGroup.gender,
        age_ranges: every(targetGroup.age_ranges, range => !range.selected) ? undefined : targetGroup.age_ranges,
        has_active_bank_account: hasActiveBankAccount,
        has_cup_actions: hasCupActions,
        bank_ids: isEmpty(bankIds) ? undefined : bankIds,
        send_notification: sendNotification
    };

    return (
        successScreen ?
            <AdminMessageSuccess onClose={onClose}/> :
            <div className="admin-message">
                <div className="input-wrapper section">
                    <div className="label-wrapper">
                        <label>Wybierz użytkowników</label>
                    </div>
                    <Select
                        mode="multiple"
                        value={userIds}
                        onChange={(value) => setUserIds(value)}
                        allowClear={true}
                        placeholder="Zaznacz użytkowników do których chcesz wysłać wiadomość..."
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={users.map(user => ({
                            value: user.id,
                            label: getAppUserName(user)
                        }))}
                        className="dropdown-select"
                    />
                </div>
                <div className="range-container">
                    <TargetGroupRangesSelect rangeKey='age_ranges' title='Wiek' placeholder="Wybierz wiek..."
                                             targetGroup={targetGroup}
                                             setTargetGroup={setTargetGroup}/>
                </div>
                <div className="input-wrapper section">
                    <div className="label-wrapper">
                        <label>Płeć</label>
                    </div>
                    <Select
                        mode="multiple"
                        value={targetGroup.gender}
                        onChange={(value) => setTargetGroup({...targetGroup, gender: value})}
                        allowClear={true}
                        placeholder="Wybierz płeć..."
                        showSearch={false}
                        options={[
                            {value: "male" as Gender, label: 'Mężczyzna'},
                            {value: "female" as Gender, label: 'Kobieta'},
                            {value: "unknown" as Gender, label: 'Inna'},
                        ]}
                        className="dropdown-select"
                    />
                </div>
                <BooleanDropdown label="Posiada akcje kubkowe"
                                 value={hasCupActions}
                                 setValue={setHasCupActions}/>
                <BooleanDropdown label="Posiada aktywne konto bankowe"
                                 value={hasActiveBankAccount}
                                 setValue={setHasActiveBankAccount}/>
                <div className="input-wrapper section">
                    <div className="label-wrapper">
                        <label>Konto w banku</label>
                    </div>
                    <Select
                        mode="multiple"
                        value={bankIds}
                        onChange={(value) => setBankIds(value)}
                        allowClear={true}
                        placeholder="Wybierz bank..."
                        disabled={!hasActiveBankAccount}
                        showSearch={false}
                        options={banks.map(bank => ({
                            value: bank.id,
                            label: bank.bank_name
                        }))}
                        className="dropdown-select"
                    />
                </div>
                <div className="input-wrapper section">
                    <div className="label-wrapper">
                        <label>Push notyfikacja</label>
                        <Switch checked={sendNotification} setChecked={setSendNotification}/>
                    </div>
                </div>
                <Input name='title' onChange={setMessageData} value={messageData} label="Temat"/>
                <TextArea name='content' onChange={setMessageData} value={messageData} label="Treść wiadomości"/>
                <ErrorComponent error={error}/>
                <Button label="Wyślij wiadomość"
                        disabled={!messageData.title || !messageData.content || loading}
                        onClick={() => sendMessageToUsersHandler(data, successCallback, errorCallback, setLoading)}
                        loading={loading}
                        fullWidth={true}
                />
            </div>
    )
};

export default AdminContactUserMessage;


const BooleanDropdown = ({label, value, setValue}: {
    label: string,
    value: boolean | undefined,
    setValue: Dispatch<SetStateAction<boolean | undefined>>
}) => (
    <div className="input-wrapper section">
        <div className="label-wrapper">
            <label>{label}</label>
        </div>
        <Select
            value={value}
            onChange={(value) => setValue(value)}
            showSearch={false}
            options={[
                {value: true, label: 'Tak'},
                {value: false, label: 'Nie'},
            ]}
            allowClear={true}
            className="dropdown-select"
        />
    </div>
);