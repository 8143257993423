const notificationDict: { [key: string]: string } = {
    "error": "Ups coś poszło nie tak",
    "saved": "Zmiany zapisano pomyślnie",
    "no_file": "Błąd Importu: Nie wybrano pliku",
    "file_too_large": "Błąd Importu: Wybrany plik przekracza limit 5MB",
    "wrong_file_type": "Błąd Importu: Zły format pliku. Należy wybrać plik Excel o rozszczerzeniu (.xlsx lub .xls)",

};

const getNotificationMessage = (key: string): string => {
    if (notificationDict[key]) {
        return notificationDict[key];
    } else {
        return key;
    }
};

export default getNotificationMessage;