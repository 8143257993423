import {Order} from "../../../services/interfaces";
import {OrderStatusEnum, ReservationStatusEnum} from "../../../services/dictionaries/enums";
import {parseQueryString} from "../../../services/utils";
import {icons} from "../../../services/images";
import ActionModal from "../../modals/ActionModal";
import React, {useRef, useState} from "react";

interface Props {
    order: Order,
    filters: string,
    onClick: () => void
}

const OrderTableRow = ({order, filters, onClick}: Props) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const actionRef = useRef<(HTMLTableCellElement | null)>(null);

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    let orderStatus = 'Inna';
    let statusColor = '';

    let actions = [
        {
            title: 'Podgląd',
            onClick,
            className: ''
        }
    ];

    const setAsReady = () => {
        // TODO Add handler
    };

    const setAsCompleted = () => {
        // TODO Add handler
    };

    const setAsCanceled = () => {
        // TODO Add handler
    };

    switch (order.status) {
        case "canceled":
            statusColor = 'red';
            break;
        case "placed":
        case "paid":
            statusColor = 'blue';
            actions.push({
                title: 'Zaznacz jako gotowe',
                onClick: setAsReady,
                className: 'blue-primary'
            });
            actions.push({
                title: 'Anuluj zamówienie',
                onClick: setAsCanceled,
                className: 'delete'
            });
            break;
        case "ready":
            statusColor = 'blue';
            actions.push({
                title: 'Zaznacz jako odebrane',
                onClick: setAsCompleted,
                className: 'blue-primary'
            });
    }
    if (order.status in ReservationStatusEnum) {
        orderStatus = OrderStatusEnum[order.status];
    }

    const formattedDate = order.created_at; // TODO Fix this neeed formatting

    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };



    return (
        <tr>
            <td className="clickable-cell">
                <span onClick={onClick}>#{order.id}</span>
            </td>
            {!excludedColumns.includes('client_id') &&
                <td className="client-id-cell">{order.user_id}</td>}
            {!excludedColumns.includes('appointment_date') && <td>{formattedDate}</td>}
            {!excludedColumns.includes('status') &&
                <td className={`cashback-status-cell ${statusColor}`}>
                    {orderStatus}
                </td>
            }
            {/*TODO Adjust formatting of Status*/}
            {!excludedColumns.includes('number_of_products') && <td>{order.number_of_products}</td>}
            {!excludedColumns.includes('total_price') && <td>{order.total_price}</td>}
            <td className="actions-button-cell" ref={actionRef}>
                <div className="actions-button" onClick={toggleActionModal}>
                    <img src={icons.actionsButtonIcon}
                         alt="actionsButtonIcon"
                    />
                </div>
                <ActionModal isOpen={isActionModalOpen}
                             onClose={() => setIsActionModalOpen(false)}
                             actions={actions}
                             x={actionModalPosition.x} y={actionModalPosition.y}/>
            </td>
        </tr>
    )
};

export default OrderTableRow;