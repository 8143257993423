import {TransactionStatusState} from "../../services/interfaces";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import Button from "../common/Button";
import {DEFAULT_TRANSACTION_STATUS_STATE} from "./filters/TransactionHistoryFiltersModal";
import HistoryDatePicker from "./filters/HistoryDatePicker";
import HistoryCashbackStatus from "./filters/HistoryCashbackStatus";
import HistorySlider from "./filters/HistorySlider";


interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filterOptions: AppUserFilterOptions | null
    setFilters: ({query, fromDate, toDate, amountSlider, cashbackSlider, status}: AppUserFilters) => void
    parentQuery: string
    mode: 'transactions' | 'cups'
}

export interface AppUserFilters {
    query: string
    fromDate: Date | null
    toDate: Date | null
    amountSlider: [number, number] | null
    cashbackSlider: [number, number] | null
    status: TransactionStatusState
}

export interface AppUserFilterOptions {
    min_amount: number
    max_amount: number
    min_cashback: number
    max_cashback: number
}

const AppUserFiltersModal = ({open, setOpen, filterOptions, setFilters, parentQuery, mode}: Props) => {
    const [query, setQuery] = useState<string>('');
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [amountSlider, setAmountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_amount, filterOptions.max_amount] : null);
    const [cashbackSlider, setCashbackSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_cashback, filterOptions.max_cashback] : null);

    const [status, setStatus] = useState<TransactionStatusState>(DEFAULT_TRANSACTION_STATUS_STATE);


    useEffect(() => {
        setQuery(parentQuery)
    }, [parentQuery]);

    const clearFilters = () => {
        setQuery('');
        setFromDate(null);
        setToDate(null);
        setStatus(DEFAULT_TRANSACTION_STATUS_STATE);
        setAmountSlider([filterOptions?.min_amount || 0, filterOptions?.max_amount || 100]);
        setCashbackSlider([filterOptions?.min_cashback || 0, filterOptions?.max_cashback || 100]);
    };

    const applyFilters = () => {
        setFilters({query, fromDate, toDate, status, amountSlider, cashbackSlider});
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="searchbar" style={{maxWidth: 'unset'}}>
                    <img src={icons.search} alt="Search"/>
                    <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                           value={query}
                           onChange={event => setQuery(event.target.value)}/>
                </div>
                <HistoryDatePicker
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />
                {mode === 'transactions' &&
                    <>
                        <HistoryCashbackStatus status={status} setStatus={setStatus}/>
                        <HistorySlider
                            label="Kwota transakcji"
                            min={filterOptions?.min_amount || 0}
                            max={filterOptions?.max_amount || 100}
                            value={amountSlider}
                            onChange={setAmountSlider}
                        />
                        <HistorySlider
                            label="Kwota cashbacku"
                            min={filterOptions?.min_cashback || 0}
                            max={filterOptions?.max_cashback || 100}
                            value={cashbackSlider}
                            onChange={setCashbackSlider}
                        />
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>
            </div>
        </Modal>
    )
};

export default AppUserFiltersModal