import request, {DELETE, GET, POST} from "./request";


export const getBankList = async () => {
    try {
        const {data} = await request(GET, 'bank/list');
        return data;
    } catch (error) {
        throw error;
    }
};

export const getConfigureBankUrl = async (bankCode: number, accountNumber?: string | null, merchantPointId?: number | null) => {

    const payload: { bank_code: number, account_number?: string, merchant_point_id?: number } = {
        bank_code: bankCode,
    };

    if (accountNumber) {
        payload['account_number'] = accountNumber;
    }

    if (merchantPointId) {
        payload['merchant_point_id'] = merchantPointId;
    }

    try {
        const {data}: { data: { aspspRedirectUri: string } } = await request(POST, `bank/configure`, payload);
        return data;
    } catch (error) {
        throw error;
    }
};

export const addBankAccount = async (bankCode: number, accountNumber: string, merchantPointId?: number) => {
    try {
        const payload: { account_number: string, bank_code: number, merchant_point_id?: number } = {
            account_number: accountNumber,
            bank_code: bankCode,
        };

        if (merchantPointId) {
            payload['merchant_point_id'] = merchantPointId;
        }

        const {data} = await request(POST, 'bank/add', payload);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getDraftTerminal = async () => {
    try {
        const {data} = await request(GET, 'bank/draft');
        return data;
    } catch (error) {
        throw error;
    }
};

export const ackTerminalDraft = async () => {
    try {
        const {data} = await request(GET, 'bank/draft/ack');
        return data;
    } catch (error) {
        throw error;
    }
};

export const verifyTerminal = async () => {
    try {
        const {data} = await request(GET, 'bank/verify');
        return data;
    } catch (error) {
        throw error;
    }
};

export const cancelVerifyTerminal = async () => {
    try {
        const {data} = await request(DELETE, 'bank/draft');
        return data;
    } catch (error) {
        throw error;
    }
};

export const skipTerminalVerification = async () => {
    try {
        const {data} = await request(GET, 'bank/verify/skip');
        return data;
    } catch (error) {
        throw error;
    }
};

export const getConfigureAccountNumbers = async () => {
    try {
        const {data} = await request(GET, 'bank/configure/accounts');
        return data;
    } catch (error) {
        throw error;
    }
}