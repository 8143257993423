import React from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import TargetGroupButton from "../common/TargetGroupButton";
import AggregateSelect from "../statistics/AggregateSelect";
import {setStatisticsAggregateAction} from "../../redux/statistics";
import PreviousPeriodSelect from "../common/PreviousPeriodSelect";
import {
    setChartIntervalAction,
    setDatePresetAction,
    setDateRangeAction,
    setPreviousPeriodAction
} from "../../redux/merchant";
import DateRangePicker from "../common/DateRangePicker";
import {ChartInterval, DatePreset, DateRange} from "../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getChartInterval, getDatePreset, getDateRange, getPreviousPeriod} from "../../redux/selectors/merchantSelector";
import {getStatisticsAggregate} from "../../redux/selectors/statisticsSelectors";
import ChartIntervalSelect from "../common/chart/ChartIntervalSelect";
import TableModeSwitch from "../statistics/table/TableModeSwitch";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    showStatisticsControls?: boolean;

}

const ControlsModal = ({isOpen, setIsOpen, showStatisticsControls = false}: Props) => {

    const toggleModal = () => setIsOpen(false);

    const dateRange: DateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const chartInterval: ChartInterval = useSelector(getChartInterval);
    const aggregate = useSelector(getStatisticsAggregate);
    const previousPeriod = useSelector(getPreviousPeriod);
    const dispatch = useDispatch();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            className="modal-content controls-modal"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <h5 className="modal-title">Ustawienia ekranu</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="target-group-container"><TargetGroupButton
                    showTargetGroupChanges={showStatisticsControls}/></div>
                {showStatisticsControls &&
                    <AggregateSelect aggregate={aggregate}
                                     setAggregate={(aggregate) => dispatch(setStatisticsAggregateAction(aggregate))}/>
                }
                <ChartIntervalSelect chartInterval={chartInterval}
                                     onIntervalChange={(interval) => dispatch(setChartIntervalAction(interval))}/>
                <PreviousPeriodSelect previousPeriod={previousPeriod}
                                      onChange={(period) => dispatch(setPreviousPeriodAction(period))}/>
                <div className="date-picker-label-wrapper">
                    <span className="label-2">Zakres czasu: </span>
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                    />
                </div>
                {showStatisticsControls && <TableModeSwitch/>}
            </div>
            <div className="modal-footer"/>
        </Modal>
    )
};

export default ControlsModal