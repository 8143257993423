import Modal from 'react-modal';
import {SetContactPageActionType} from "../../../services/interfaces";
import React, {useMemo} from "react";
import {icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import {getContactPage, getCurrentChat} from "../../../redux/selectors/chatSelectors";
import ContactAdminMessage from "./ContactAdminMessage";
import ContactChatList from "./ContactChatList";
import ContactNewChat from "./ContactNewChat";
import ContactChat from "./ContactChat";
import ContactMenu from "./ContactMenu";
import {getModalOpen} from "../../../redux/selectors/navigationSelectors";
import {modalCloseAction} from "../../../redux/navigation";
import {resetChatAction, setContactPageAction} from "../../../redux/chat";
import {getAppUserName} from "../../../services/helpers";
import {getChatsHandler} from "../../../handlers/chatHandlers";


const ContactModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(getModalOpen('contact'));
    const contactPage = useSelector(getContactPage);
    const currentChat = useSelector(getCurrentChat);
    const title = useMemo(() => {
        switch (contactPage) {
            case 'admin':
                return 'Wyślij wiadomość do administratora PayProfit';
            case 'chat_list':
                return 'Chat z klientami';
            case 'new_chat':
                return 'Rozpocznij czat z:';
            case 'chat':
                return (currentChat && getAppUserName(currentChat.user)) || 'Chat';
            default:
                return 'Kontakt';
        }
    }, [contactPage, currentChat]);

    const BackButton = useMemo(() => () => {
        const Button = ({actionType, shouldResetChat}: { actionType: SetContactPageActionType, shouldResetChat?: boolean }) =>
            (
                <div className="back-button" onClick={() => {
                    dispatch(setContactPageAction(actionType));
                    shouldResetChat && dispatch(resetChatAction())
                }}>
                    <img src={icons.backIcon} alt=""/>
                </div>
            );

        switch (contactPage) {
            case 'admin':
                return <Button actionType={null}/>;
            case 'chat_list':
                return <Button actionType={null}/>;
            case 'new_chat':
                return <Button actionType={'chat_list'}/>;
            case 'chat':
                return <Button actionType={'chat_list'} shouldResetChat/>;
            default:
                return <></>
        }
    }, [contactPage]);

    const closeModal = () => {
        dispatch(modalCloseAction('contact'));
        dispatch(setContactPageAction(null));
        dispatch(resetChatAction());
        getChatsHandler();
    };


    const Page = useMemo(() => () => {
        switch (contactPage) {
            case 'admin':
                return <ContactAdminMessage onClose={closeModal}/>;
            case 'chat_list':
                return <ContactChatList/>;
            case 'new_chat':
                return <ContactNewChat/>;
            case 'chat':
                return <ContactChat/>;
            default:
                return <ContactMenu/>
        }
    }, [contactPage]);


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            className="modal-content contact"
            ariaHideApp={false}
        >
            <div className={`modal-header ${!contactPage ? 'menu-header' : ''}`}>
                {!contactPage && <div/>}
                <div className="modal-title">
                    <BackButton/>
                    <h5>{title}</h5>
                </div>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <Page/>
            </div>
        </Modal>
    )
};

export default ContactModal;