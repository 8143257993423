import React from "react";
import Modal from "react-modal";
import {icons} from "../../services/images";
import Input from "../common/Input";
import Button from "../common/Button";
import {useSelector} from "react-redux";
import {getCurrentMerchantPoint} from "../../redux/selectors/merchantSelector";
import {manageMerchantPointCupCountHandler} from "../../handlers/cupHandlers";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getTableData: () => void;
}


const CupsManagementModal = ({isOpen, setIsOpen, getTableData}: Props) => {
    const [data, setData] = React.useState<{ add: number | null, remove: number | null }>({add: null, remove: null});
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    const toggleModal = () => {
        setIsOpen(false);
        setData({add: null, remove: null});
        setButtonLoading(false);
        setError(false);
    };

    const errorCallback = () => {
        setButtonLoading(false);
        setError(true);
    };

    const callback = () => {
        toggleModal();
        getTableData();
    };
    
    const onAddClick = () => {
        if (merchantPoint.id && data.add) {
            setButtonLoading(true);
            manageMerchantPointCupCountHandler(merchantPoint.id, data.add, callback, errorCallback)
        }
    };
    const onRemoveClick = () => {
        if (merchantPoint.id && data.remove) {
            setButtonLoading(true);
            manageMerchantPointCupCountHandler(merchantPoint.id, data.remove, callback, errorCallback, false)
        }
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content cups-management-modal"
        >
            <div className="modal-header">
                <h5>Zarządzaj stanem kubków</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="input-wrapper">
                    <Input value={data} onChange={setData} name="add" type="number" label="Dodaj kubki"
                           placeholder="Podaj ilośc kubków ktore chcesz dodać..."/>
                </div>
                <Button label="Dodaj kubki"
                        onClick={onAddClick}
                        disabled={!data.add}
                        loading={buttonLoading}
                        fullWidth={true}/>

                <div className="input-wrapper">
                    <Input value={data} onChange={setData} name="remove" type="number" label="Usuń kubki" isDisabled={merchantPoint.cup_count === 0}
                           placeholder="Podaj ilośc kubków ktore chcesz usunąć..." max={merchantPoint.cup_count}/>
                </div>
                <Button label="Usuń kubki"
                        onClick={onRemoveClick}
                        disabled={!data.remove}
                        loading={buttonLoading}
                        type="Red"
                        fullWidth={true}/>
                {error && <div className="error-message">Wystąpił błąd podczas zapisywania zmian stanu kubków</div>}
            </div>
        </Modal>
    )
};

export default CupsManagementModal