import {TargetGroup} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import ActionModal from "../ActionModal";
import React, {useRef, useState} from "react";
import {deleteTargetGroupHandler, duplicateTargetGroupHandler} from "../../../handlers/targetGroupHandlers";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentTargetGroup} from "../../../redux/selectors/merchantSelector";
import {
    resetCurrentTargetGroupAction,
    TARGET_GROUP_DEFAULT_STATE
} from "../../../redux/merchant";
import {
    resetCurrentTargetGroupAndStatisticsFilterOrder,
    setCurrentTargetGroupAndStatisticsFilterOrder
} from "../../../services/statisticsHelpers";


interface Props {
    targetGroups: TargetGroup[]
    setShowList: React.Dispatch<React.SetStateAction<boolean>>
    setEditedTargetGroup: React.Dispatch<React.SetStateAction<TargetGroup>>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const TargetGroupList = ({targetGroups, setShowList, setEditedTargetGroup, setIsModalOpen}: Props) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
    const [activeGroup, setActiveGroup] = useState<TargetGroup | null>(null);
    const [error, setError] = useState<boolean>(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const targetGroupItemRefs = useRef<(HTMLLIElement | null)[]>([]);
    const currentTargetGroup = useSelector(getCurrentTargetGroup);

    const dispatch = useDispatch();

    const toggleActionModal = (group: TargetGroup, index: number) => {
        setActiveGroup(group);
        const targetGroupItem = targetGroupItemRefs.current[index];
        if (targetGroupItem) {
            const {right, bottom} = targetGroupItem.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const closeActionModal = () => {
        setIsActionModalOpen(false);
        setActiveGroup(null);
    };

    const editGroup = () => {
        if (activeGroup) {
            setEditedTargetGroup(activeGroup);
            setShowList(false);
            closeActionModal();
        }
    };

    const createGroup = () => {
        setShowList(false);
        setEditedTargetGroup(TARGET_GROUP_DEFAULT_STATE);
        closeActionModal();
    };

    const duplicateGroup = () => {
        if (activeGroup && activeGroup.id) {
            duplicateTargetGroupHandler(activeGroup.id, closeActionModal, closeActionModal)
        }
    };

    const deleteGroup = () => {
        const callback = () => {
            closeActionModal();
            if (currentTargetGroup?.id === activeGroup?.id)
                dispatch(resetCurrentTargetGroupAction());
        };
        const errorCallback = () => {
            closeActionModal();
            setError(true);
        };
        if (activeGroup && activeGroup.id)
            deleteTargetGroupHandler(
                activeGroup.id,
                callback,
                errorCallback
            );

    };

    const selectGroup = (group: TargetGroup) => {
        if (group.id) {
            if (currentTargetGroup?.id === group.id)
                resetCurrentTargetGroupAndStatisticsFilterOrder();
            else
                setCurrentTargetGroupAndStatisticsFilterOrder(group);
            setIsModalOpen(false);
        }
    };
    return (
        <div className="modal-body">
            <ul className="modal-list">
                {
                    targetGroups.map((group: TargetGroup, index: number) => (
                        <li className={`modal-list-item ${currentTargetGroup?.id === group.id ? 'selected' : ''}`}
                            key={`TargetGroup${index}`}
                            ref={(ref) => (targetGroupItemRefs.current[index] = ref)}
                        >
                            <span className="clickable-name" onClick={() => selectGroup(group)}>{group.name}</span>
                            <div className="actions-button" onClick={() => toggleActionModal(group, index)}>
                                <img src={icons.actionsButtonIcon}
                                     alt="actionsButtonIcon"
                                />
                            </div>
                        </li>
                    ))
                }
            </ul>
            <div className="button-center">
                {error && <div className="error-message">Wystąpił błąd podczas usuwania grupy docelowej</div>}
                <Button label="Utwórz grupę docelową" onClick={createGroup} icon={icons.addWhiteIcon}/>
            </div>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={() => setIsActionModalOpen(false)}
                         actions={[
                             {
                                 title: 'Edytuj',
                                 onClick: editGroup,
                                 className: ''
                             },
                             {
                                 title: 'Duplikuj',
                                 onClick: duplicateGroup,
                                 className: ''
                             },
                             {
                                 title: 'Usuń',
                                 onClick: deleteGroup,
                                 className: 'delete'
                             }]}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
        </div>

    );
};
export default TargetGroupList

