import {icons} from "../../../services/images";
import {useDispatch} from "react-redux";
import {setBookingEditModeAction} from "../../../redux/booking";
import Button from "../../common/Button";
import React from "react";


const NoBookings = () => {
    const dispatch = useDispatch();
    return (
        <div className="block-wrapper">
            <div className="block block-1-1 no-bookings">
                <img src={icons.noBookingsIcon} alt="no-data-graph"/>
            <Button label="Zarządzaj ofertą" icon={icons.cogWhiteIcon}
                    onClick={() => dispatch(setBookingEditModeAction(true))}/>
            </div>

        </div>
    )
};

export default NoBookings;