import Modal from "react-modal";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import React, {useCallback, useMemo, useState} from "react";
import {Category, CategoryType, ImportCategoriesData, MerchantPointSimple} from "../../../services/interfaces";
import {
    getCategoriesHandler,
    getCategoryOptionsHandler,
    importCategoriesHandler
} from "../../../handlers/bookingHandlers";
import {isEmpty} from "lodash";
import {Select} from "antd";
import {useSelector} from "react-redux";
import {getCurrentMerchantPoint, getMerchantPoints} from "../../../redux/selectors/merchantSelector";
import {getCategoryOptions} from "../../../redux/selectors/bookingSelector";
import {getCategoryName} from "../../../services/helpers";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    categoryType: CategoryType
}


const ImportCategoriesModal = ({isOpen, onClose, categoryType}: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [point, setPoint] = useState<number | null>(null);
    const [categories, setCategories] = useState<number[]>([]);

    const merchantPoints = useSelector(getMerchantPoints);
    const currentMerchantPoint = useSelector(getCurrentMerchantPoint);
    const categoryOptions = useSelector(getCategoryOptions);
    const filteredPoints = merchantPoints.filter(m => m.id !== currentMerchantPoint.id);

    const closeModal = useCallback(() => {
        onClose();
        setPoint(null);
        setCategories([])
    }, [onClose]);


    const callback = useCallback(() => {
        setIsLoading(false);
        closeModal();
        getCategoriesHandler(categoryType)
    }, [categoryType, setIsLoading, closeModal]);

    const onImportClick = () => {
        if (point && !isEmpty(categories)) {
            setIsLoading(true);
            const data: ImportCategoriesData = {
                category_ids: categories,
                source_merchant_point_id: point
            };
            importCategoriesHandler(categoryType, data, callback)
        }
    };


    const changePoint = (pointId: number) => {
        getCategoryOptionsHandler(pointId, categoryType, () => {
            setPoint(pointId);
            setCategories([])
        })
    };

    const isDisabled = useMemo(() => {
        return !point || isEmpty(categories) || isLoading
    }, [isLoading, point, categories]);


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content category-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Importuj produkty z innych punktów</h5>
                <div/>
            </div>
            <div className="modal-body">
                <div className="select-wrapper">
                    <p className="label">Wybierz punkt</p>
                    <Select
                        value={point}
                        showSearch={false}
                        placeholder={`Wskaż punkt z którego chcesz zaimportować ${categoryType === 'product' ? 'produkty' : 'usługi'}`}
                        className="dropdown-select"
                        onChange={changePoint}
                        options={filteredPoints.map((merchantPoint: MerchantPointSimple) => (
                            {value: merchantPoint.id, label: merchantPoint.name}
                        ))}
                    />
                </div>
                <div className="select-wrapper">
                    <p className="label">Wybierz kategorie</p>
                    <Select
                        value={categories}
                        mode="multiple"
                        showSearch={false}
                        placeholder={`Wskaż kategorie ${categoryType === 'product' ? 'produktów' : 'usług'}, które chcesz zaimportować produkty`}
                        className="dropdown-select"
                        onChange={setCategories}
                        options={categoryOptions.map((category: Category) => (
                            {value: category.id, label: getCategoryName(category)}
                        ))}
                    />
                </div>
            </div>

            <div className="modal-footer">
                <Button label="Importuj" onClick={onImportClick} fullWidth={true} loading={isLoading}
                        disabled={isDisabled}/>
            </div>
        </Modal>
    )
};


export default ImportCategoriesModal;