import React, {useEffect, useState} from 'react';
import MapView from "../../common/map/MapView";
import AdminDashboardMerchantPointOverview from "./AdminDashboardMerchantPointOverview";
import AdminDashboardMapMarker from "./AdminDashboardMapMarker";
import AdminDashboardMapInfoWindow from "./AdminDashboardMapInfoWindow";
import MapPanel from "../../common/map/MapPanel";
import {AdminDashboardMerchantPoint} from "../../../services/interfaces";
import AdminDashboardMapFiltersModal from "./AdminDashboardMapFiltersModal";
import {getAdminDashboardMerchantPointsHandler,} from "../../../handlers/dashboardHandlers";
import {useSelector} from "react-redux";
import {getAdminDateRange, getAdminGlobalFilters} from "../../../redux/selectors/adminViewSettingsSelectors";
import {getAdminDashboardMerchantPoints} from "../../../redux/selectors/adminDashboardSelectors";


const AdminDashboardMap = () => {
    const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');
    const globalFilters = useSelector(getAdminGlobalFilters);
    const dateRange = useSelector(getAdminDateRange);
    const filteredItems: AdminDashboardMerchantPoint[] = useSelector(getAdminDashboardMerchantPoints);

    useEffect(() => {
        getAdminDashboardMerchantPointsHandler(dateRange, globalFilters, query, filterString)
    }, [dateRange, globalFilters, query, filterString]);

    return (
        <div className="map-container">
            <MapView<AdminDashboardMerchantPoint> items={filteredItems}
                                                  MarkerComponent={AdminDashboardMapMarker}
                                                  InfoWindowContent={AdminDashboardMapInfoWindow}
            />
            <MapPanel<AdminDashboardMerchantPoint> title="Sklepy Payprofit"
                                                   items={filteredItems}
                                                   ItemOverviewComponent={AdminDashboardMerchantPointOverview}
                                                   setQuery={setQuery}
                                                   setFiltersModalOpen={setFiltersModalOpen}/>
            <AdminDashboardMapFiltersModal open={filtersModalOpen} setOpen={setFiltersModalOpen}
                                           setFilters={setFilterString}/>
        </div>
    );
};

export default AdminDashboardMap;
