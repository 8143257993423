import React, {MutableRefObject, useState} from "react";
import {icons} from "../../../services/images";
import CategoryItemComponent from "./CategoryItemComponent";
import {Category, Product, Service} from "../../../services/interfaces";
import {getCategoryName} from "../../../services/helpers";

interface Props {
    toggleActionModal: () => void
    categoryActionRefs: MutableRefObject<(HTMLDivElement | null)[]>
    category: Category
    onItemClick: (item: Service | Product) => void
    onAddClick: () => void
}


const CategoryComponent = ({categoryActionRefs, toggleActionModal, category, onItemClick, onAddClick}: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className="category">
            <div className="category-header">
                <div>
                    <span className="category-title" onClick={() => setIsCollapsed(!isCollapsed)}>
                        <span className={`chevron ${isCollapsed ? 'down' : 'up'}`}
                              onClick={() => setIsCollapsed(!isCollapsed)}/>
                        <span>{getCategoryName(category)}</span>
                    </span>
                    <div className="actions-button"
                         ref={(ref) => (categoryActionRefs.current[category.id] = ref)}
                         onClick={toggleActionModal}
                    >
                        <img src={icons.actionsButtonIcon}
                             alt="actionsButtonIcon"
                        />
                    </div>
                </div>
                <div className="add-item">
                    <img src={icons.addBlueWhiteIcon} alt="" onClick={onAddClick}/>
                </div>
            </div>
            <ul className={`items-list ${isCollapsed ? 'hidden' : ''}`}>
                {category.items.map((item, index) => (
                    <CategoryItemComponent onClick={() => onItemClick(item)} item={item}
                                           key={`Category-${category.id}-Item-${index}`}/>
                ))}
            </ul>
        </div>
    )
};

export default CategoryComponent;