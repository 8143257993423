import {ChartInterval} from "../../../services/interfaces";
import {Select} from "antd";
import {Tooltip} from "react-tooltip";
import {icons} from "../../../services/images";
import React from "react";


interface Props {
    chartInterval: ChartInterval;
    onIntervalChange: (value: ChartInterval) => void;
}


const ChartIntervalSelect = ({chartInterval, onIntervalChange}: Props) => {
    return (
        <div className="chart-interval-select">
            <div className="label-wrapper">
                <span className="label-2">Interwał czasowy: </span>
                <Tooltip id="ChartIntervalTooltip" className="tooltip" noArrow={true}>
                    <p className="tooltip-title">Interwał czasowy</p>
                    <p className="tooltip-body">Wybór interwału czasowego pozwala na analizę danych w różnych
                        podziałach czasowych i umożliwia dostosowanie poziomu szczegółowości wyświetlanych informacji do
                        Twoich potrzeb. Zmiana interwału na wykresie wpływa na sposób grupowania danych, dzięki czemu
                        możliwy jest podgląd ogólnych trendów lub przeprowadzanie szczegółowego badania.
                        Jak zmienić interwał?
                        Aby zmienić interwał na wykresie, skorzystaj z menu rozwijanego lub przycisków dostępnych obok
                        wykresu. Wystarczy wybrać jeden z dostępnych interwałów (dzień, ostatnie 7 dni, miesiąc, kwartał
                        itd.), a wykres automatycznie zaktualizuje się, aby zaprezentować dane zgodnie z wybranym
                        okresem. Poza wyborem aktualnego interwału, można również porównać dane z odpowiadającym poprzednim
                        okresem, co pozwala lepiej zrozumieć zmiany w czasie. Dla każdego wybranego interwału system
                        porówna dane z poprzedniego okresu o takiej samej długości.</p>
                </Tooltip>
                <img src={icons.infoGrayIcon} alt="info" data-tooltip-id="ChartIntervalTooltip"
                     data-tooltip-place="top-end"/>
            </div>
            <Select
                value={chartInterval}
                onChange={onIntervalChange}
                options={[
                    {value: "DAY" as ChartInterval, label: 'Dzień'},
                    {value: "LAST_7_DAYS" as ChartInterval, label: 'Ostatnie 7 dni'},
                    {value: "LAST_30_DAYS" as ChartInterval, label: 'Ostatnie 30 dni'},
                    {value: "LAST_90_DAYS" as ChartInterval, label: 'Ostatnie 90 dni'},
                    {value: "LAST_365_DAYS" as ChartInterval, label: 'Ostatnie 365 dni'},
                    {value: "WEEK" as ChartInterval, label: 'Tydzień'},
                    {value: "MONTH" as ChartInterval, label: 'Miesiąc'},
                    {value: "QUARTER" as ChartInterval, label: 'Kwartał'},
                    {value: "YEAR" as ChartInterval, label: 'Rok'},
                ]}
            />
        </div>
    )
};

export default ChartIntervalSelect;