import ChangePill from "../common/ChangePill";
import TimeRangeLabel from "../common/TimeRangeLabel";
import {icons} from "../../services/images";

interface Props {
    value?: number
    prevValue?: number
}

const CupUseTile = ({value, prevValue}: Props) => {
    return (
         <div className="block block-1-4 icon-block">
             <div className="icon-section">
                <img src={icons.cupUsedIcon} alt="cup-use-icon" className="cup-icon"/>
            </div>
            <div className="value-section">
                <h4>Liczba użyć</h4>
                <div className="value-wrapper">
                    <span className="standard-value">{value || 0}</span>
                    <ChangePill value={value} prevValue={prevValue}/>
                    <TimeRangeLabel/>
                </div>
            </div>
        </div>
    )
};

export default CupUseTile;

