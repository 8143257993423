import React, {useState} from "react";
import {Tooltip} from "react-tooltip";
import {icons} from "../../services/images";


const TextArea = <T extends object, K extends keyof T>({
                                                           value,
                                                           onChange,
                                                           name,
                                                           placeholder,
                                                           label,
                                                           tooltipTitle,
                                                           tooltipBody,
                                                           showError = false,
                                                           errorMessage = {},
                                                           validate = () => null,
                                                           isDisabled = false,
                                                           isRequired = false,
                                                           isLoading = false,
                                                           isDisabledDuringLoading = false,
                                                           maxLength,
                                                           displayedValue
                                                       }: Props<T, K>) => {

    const [error, setError] = useState<string | boolean | null>(false);
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const {value} = event.target;

            if (value !== undefined) {
                onChange((prevState) => ({...prevState, [name]: value}));

                if (showError && !validate(value)) {
                    setError(false);
                }
            }
        };

    return (
        <div className={`input-wrapper ${error ? 'has-error' : ''}`}>
            {label &&
                <div className="label-wrapper">
                    <label>
                        {label}
                        {isRequired && <span className="required-mark"> *</span>}
                    </label>
                    {tooltipTitle && tooltipBody &&
                        <>
                            <Tooltip id={tooltipTitle} className="tooltip" noArrow={true}>
                                <p className="tooltip-title">{tooltipTitle}</p>
                                <p className="tooltip-body">{tooltipBody}</p>
                            </Tooltip>
                            <img src={icons.infoIcon} alt="info" data-tooltip-id={tooltipTitle}
                                 data-tooltip-place="top-end"/>
                        </>
                    }
                </div>
            }
            <textarea
                className="form-control"
                placeholder={placeholder}
                onChange={handleChange}
                value={displayedValue ?? value[name] as string}
                disabled={isDisabled || (isDisabledDuringLoading && isLoading)}
                onBlur={() => showError &&
                    setError((value[name] as string).length !== 0 && validate(value[name]))
                }
                maxLength={maxLength ? maxLength : undefined}

            />
            {error && typeof error == "string" && errorMessage &&
                <p className="error-message">{errorMessage[error]}</p>
            }
        </div>
    )
};


interface Props<T extends object, K extends keyof T> {
    decimalPlaces?: number,
    displayedValue?: string;
    errorMessage?: { [key: string]: string }
    isDisabled?: boolean;
    isDisabledDuringLoading?: boolean;
    isLoading?: boolean;
    isRequired?: boolean;
    label?: string;
    max?: number;
    maxLength?: number;
    min?: number;
    name: K;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    placeholder?: string;
    showError?: boolean;
    tooltipBody?: string;
    tooltipTitle?: string;
    validate?: (value: any) => null | string;
    value: T;
}

export default TextArea;