import React, {useMemo, useState} from "react";
import {DownloadTableProps, FilterModalProps, OrderType, TableHeader} from "../../services/interfaces";
import {icons} from "../../services/images";
import {parseQueryString} from "../../services/utils";
import {Select} from "antd";

const {Option} = Select;


interface Props<T extends string = string> {
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    query: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
    sort: T;
    setSort: React.Dispatch<React.SetStateAction<T>>;
    order: OrderType
    setOrder: React.Dispatch<React.SetStateAction<OrderType>>
    filters: string,
    count: number,
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    setFilters: React.Dispatch<React.SetStateAction<string>>
    maxPage: number
    renderRows: () => React.ReactNode
    downloadHandler: ({query, sort, filters, order}: DownloadTableProps) => void
    downloadDisabled?: boolean
    headers: TableHeader<T>[]
    Modal: React.ComponentType<FilterModalProps>;
    extraButtons?: React.ReactNode;
    searchable?: boolean
    pagination?: boolean
    hasActionButton?: boolean;
    onAllCheckboxClick?: () => void;
    areAllSelected?: boolean;
}

const Table = <T extends string = string>({
                                              page,
                                              setPage,
                                              query,
                                              setQuery,
                                              sort,
                                              setSort,
                                              order,
                                              setOrder,
                                              filters,
                                              count,
                                              pageSize,
                                              setPageSize,
                                              setFilters,
                                              maxPage,
                                              renderRows,
                                              downloadHandler,
                                              downloadDisabled = false,
                                              headers,
                                              Modal,
                                              extraButtons = <></>,
                                              hasActionButton = true,
                                              onAllCheckboxClick,
                                              areAllSelected,
                                              searchable = true,
                                              pagination = true
                                          }: Props<T>) => {

    const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
    const handleSortClick = (name: T) => {
        setOrder((prev: OrderType) => {
            if (prev === 'asc')
                return 'desc';
            if (prev === 'desc')
                return null;
            return 'asc';
        });
        setSort((prev: T) => {
            if (prev !== name)
                setOrder('asc');
            return name;
        });
    };

    const renderHeader = (excludedColumns: string[]) =>
        headers.filter((o) => !excludedColumns.includes(o.sort)).map((header) => {
            const sortClassName = header.sort === sort ? (order === 'asc' ? 'ascending' : (order === 'desc' ? 'descending' : '')) : '';
            return (
                <th
                    key={header.name}
                    className={`${header.digit ? 'cell-digit' : ''}`}
                    onClick={() => header.sort && handleSortClick(header.sort)}
                >
                    {header.name}
                    {header.sort && <div className={`sort-button ${sortClassName}`}/>}
                </th>
            );
        });

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];
    const handlePageSizeChange = (value: number) => {
        setPageSize(value);
        setPage(1);
    };
    const getPageNumbers = () => {
        let pageNumbers = [];

        if (maxPage <= 7) {
            pageNumbers = Array.from({length: maxPage}, (_, i) => i + 1);
        } else {
            if (page <= 6) {
                pageNumbers = [1, 2, 3, 4, 5, 6, 7, '...', maxPage];
            } else if (page > maxPage - 6) {
                pageNumbers = [1, '...', maxPage - 6, maxPage - 5, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
            } else {
                pageNumbers = [1, '...', page - 2, page - 1, page, page + 1, page + 2, '...', maxPage];
            }
        }

        return pageNumbers;
    };

    const rowsCounter = useMemo(() => {
        if (count === 0)
            return '0 z 0 pozycji';
        const start = (page - 1) * pageSize + 1;
        const end = Math.min(page * pageSize, count);

        return `${start}-${end} z ${count} pozycji`
    }, [page, pageSize, count]);

    return (
        <div className="block block-1-1 table-block">
            <div className="table-header">
                {searchable ?
                    <div className="left-header">
                        <div className="searchbar">
                            <img src={icons.search} alt="Search"/>
                            <input type="text" placeholder="Szukaj..." className="searchbar-input" maxLength={50}
                                   onChange={event => setQuery(event.target.value)}/>
                        </div>
                        <h3>{rowsCounter}</h3>
                    </div> : <div/>
                }
                <div className="table-button-wrapper">
                    <div className="filters" onClick={() => setFiltersModalOpen(true)}>
                        <img src={icons.filterGrey} alt="Filters"/>
                        <p className="label-small">Filtry</p>
                    </div>
                    <div className={`download ${downloadDisabled ? 'disabled' : ''}`}
                         onClick={() => !downloadDisabled && downloadHandler({query, sort, order, filters})}>
                        <img src={icons.download} alt="Download"/>
                        <p className="label-small">Pobierz</p>
                    </div>
                    {extraButtons}
                </div>
            </div>
            <div className="table-content-wrapper">
                <div className="table-content">
                    <table>
                        <thead>
                        <tr className="table-content-header">
                            {onAllCheckboxClick &&
                                <th className="checkbox-cell">
                                    <input type="checkbox" checked={areAllSelected}
                                           onClick={onAllCheckboxClick}
                                           onChange={() => null}/>
                                </th>
                            }
                            {renderHeader(excludedColumns)}
                            {hasActionButton && <th/>}
                        </tr>
                        </thead>
                        <tbody>
                        {renderRows()}
                        </tbody>
                    </table>
                </div>
                {pagination ?
                    <div className="pagination-wrapper">
                        <div className="page-size-selector">
                            <label htmlFor="pageSizeSelect">Pokaż:</label>
                            <Select
                                id="pageSizeSelect"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                style={{width: 120}}
                            >
                                <Option value={10}>10 wierszy</Option>
                                <Option value={20}>20 wierszy</Option>
                                <Option value={50}>50 wierszy</Option>
                                <Option value={100}>100 wierszy</Option>
                            </Select>
                        </div>
                        <ul className="pagination">
                            {
                                getPageNumbers().map((pageNumber, index) => (
                                    <li
                                        className={`page-number ${pageNumber === page ? 'active' : ''}`}
                                        key={`PageNumber${index}`}
                                        onClick={() => {
                                            if (typeof pageNumber === 'number') {
                                                setPage(pageNumber);
                                            }
                                        }}
                                    >
                                        {pageNumber}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    : <></>
                }
            </div>
            <Modal
                open={filtersModalOpen}
                setOpen={setFiltersModalOpen}
                setFilters={setFilters}
            />
        </div>
    )
};


export default Table;