import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DatePreset, DateRange} from "../../../services/interfaces";
import {
    getAdminDatePreset,
    getAdminDateRange,
    getAdminMerchants
} from "../../redux/selectors/adminViewSettingsSelectors";
import {setAdminDatePresetAction, setAdminDateRangeAction} from "../../redux/adminViewSettings";
import DateRangePicker from "../../../components/common/DateRangePicker";
import AdminSummaryTile, {SummaryItem} from "./AdminSummaryTile";
import AdminMerchantsTable from "./table/AdminMerchantsTable";
import {
    getAdminMerchantsPieChartMerchantPoints,
    getAdminMerchantsPieChartServiceTypes,
    getAdminMerchantsTableCount
} from "../../redux/selectors/adminMerchantsSelectors";
import {cloudFrontUrl, logoCategoryIcons} from "../../../services/images";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY, WHOLE_NUMBER_FORMAT} from "../../../services/numbers";
import {translateServiceType} from "../../../services/dictionaries/serviceTypesDict";


const AdminMerchants = () => {
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const datePreset: DatePreset | null = useSelector(getAdminDatePreset);
    const merchantsCount = useSelector(getAdminMerchantsTableCount);
    const allMerchants = useSelector(getAdminMerchants);
    const merchantPoints = useSelector(getAdminMerchantsPieChartMerchantPoints);
    const serviceTypes = useSelector(getAdminMerchantsPieChartServiceTypes);
    const dispatch = useDispatch();

    const transactionMappedPoints: SummaryItem[] = useMemo(() => {
        return merchantPoints.map((point) => ({
            logo: point.logo_image ? `${cloudFrontUrl}${point.logo_image}` : logoCategoryIcons[point.service_type],
            name: point.name,
            value: point.transactions_count
        }));
    }, [merchantPoints]);

    const cashbackMappedPoints: SummaryItem[] = useMemo(() => {
        return merchantPoints.map((point) => ({
            logo: point.logo_image ? `${cloudFrontUrl}${point.logo_image}` : logoCategoryIcons[point.service_type],
            name: point.name,
            value: point.cashback
        }));
    }, [merchantPoints]);

    const mappedServiceTypes: SummaryItem[] = useMemo(() => {
        return serviceTypes.map((type) => ({
            logo: logoCategoryIcons[type.name],
            name: translateServiceType(type.name),
            value: type.transactions_count
        }));
    }, [serviceTypes]);


    return (
        <div className="dashboard-content with-sticky-bar admin">
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Lista Sieci ({merchantsCount} / {allMerchants.length})</h2>

                </div>
                <div className="header-section">
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setAdminDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setAdminDatePresetAction(datePreset))}
                                     admin={true}
                    />
                </div>
            </div>
            <div className="block-wrapper">
                <AdminSummaryTile title="sklepy" sortTitle="wg. wizyt" items={transactionMappedPoints}
                                  format={WHOLE_NUMBER_FORMAT}/>
                <AdminSummaryTile title="branże" sortTitle="wg. wizyt" items={mappedServiceTypes}
                                  format={WHOLE_NUMBER_FORMAT}/>
                <AdminSummaryTile title="sklepy" sortTitle="wg. Cashbacku" items={cashbackMappedPoints}
                                  format={DOUBLE_DECIMAL_NUMBER_FORMAT_CURRENCY}/>
            </div>
            <div className="block-wrapper">
                <AdminMerchantsTable/>
            </div>
        </div>
    )
};

export default AdminMerchants;