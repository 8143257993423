import {DatePicker, DatePickerProps} from "antd";
import 'moment/locale/pl'
import locale from 'antd/es/date-picker/locale/pl_PL';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import React from "react";
import {icons} from "../../../services/images";

dayjs.locale('pl');

interface Props {
    fromDate: Date | null;
    setFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    toDate: Date | null;
    setToDate: React.Dispatch<React.SetStateAction<Date | null>>;
    noTitle?: boolean;
    withIcon?: boolean;
    fromToday?: boolean;
}

const HistoryDatePicker = ({fromDate, setFromDate, toDate, setToDate, noTitle = false, withIcon = false, fromToday = false}: Props) => {

    const onChangeFromDate: DatePickerProps['onChange'] = (date, dateString) => {
        setFromDate(date ? new Date(date.toString()) : null);
    };

    const onChangeToDate: DatePickerProps['onChange'] = (date, dateString) => {
        setToDate(date ? new Date(date.toString()) : null);
    };

    return (
        <div className="section datepicker">
            {!noTitle && <h6>Zakres dat</h6>}
            <DatePicker
                value={fromDate ? dayjs(fromDate) : null}
                onChange={onChangeFromDate}
                className="date-picker-range-input history"
                suffixIcon={withIcon ? <img src={icons.calendarIcon} alt=""/> : false}
                locale={locale}
                placeholder="Od: "
                minDate={fromToday ? dayjs() : undefined}
                maxDate={toDate ? dayjs(toDate) : undefined}
                format={"DD.MM.YYYY"}

            />
            <DatePicker
                value={toDate ? dayjs(toDate) : null}
                onChange={onChangeToDate}
                className="date-picker-range-input history"
                suffixIcon={withIcon ? <img src={icons.calendarIcon} alt=""/> : false}
                locale={locale}
                placeholder="Do: "
                minDate={fromDate ? dayjs(fromDate) : undefined}
                maxDate={fromToday ? undefined : dayjs()}
                format={"DD.MM.YYYY"}
            />
        </div>
    )
};

export default HistoryDatePicker;