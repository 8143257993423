import request, {GET, POST} from "./request";
import {handleError} from "./handleError";


export const sendMessageToAdmin = async (title: string, content: string) => {
    try {
        const {data} = await request(POST, `chat/admin_message`, {title, content});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getChats = async () => {
    try {
        const {data} = await request(GET, 'chat/');
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const createChat = async (userId: number, content: string) => {
    try {
        const {data} = await request(POST, `chat/create`, {user_id: userId, content});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const sendMessage = async (chatId: number, content: string) => {
    try {
        const {data} = await request(POST, `chat/${chatId}`, {content});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMessages = async (chatId: number, page: number) => {
    try {
        const {data} = await request(GET, `chat/${chatId}/messages?page=${page}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};

export const pollMessages = async (chatId: number, lastMessageId: number) => {
    try {
        const {data} = await request(GET, `chat/${chatId}/poll?last_message_id=${lastMessageId}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};

export const getChatDetails = async (userId: number) => {
    try {
        const {data} = await request(GET, `chat/user/${userId}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const getChatAvailableUsers = async () => {
    try {
        const {data} = await request(GET, 'chat/users');
        return data;
    } catch (error) {
        throw handleError(error);
    }
};