import moment from "moment";
import _ from "lodash";
import {
    ChartInterval,
    DateRange,
    DownloadTableProps,
    GetTableProps,
    MerchantPointSimple,
    OfferDays,
    PreviousPeriod,
    TargetGroup
} from "./interfaces";
import {DayOfWeek} from "./dictionaries/enums";

export const getUTCTimestamp = (): number => {
    return moment.utc().valueOf() / 1000;
};

export const isTokenExpired = (expirationDate: string): boolean => {
    // Force generating a new pair of tokens 5 seconds before the expiration date
    const expirationOffset: number = 5;
    return +expirationDate - expirationOffset < getUTCTimestamp();
};

// Function to parse the query string into an object
export const parseQueryString = (query: string): { [key: string]: string } => {
    return _.fromPairs(query.split('&').map(param => param.split('=')));
};

export const separateAccountNumber = (accountNumber: string): string => {
    accountNumber = accountNumber.substring(2);

    const chunks = [accountNumber.substring(0, 2)];
    for (let i = 2; i < accountNumber.length; i += 4) {
        chunks.push(accountNumber.substring(i, i + 4));
    }
    return chunks.join(" ");
};

export const getAddressWithoutCountry = (merchantPoint: MerchantPointSimple) => {
    let address = null;
    if (merchantPoint.address) {
        const merchantAddress = merchantPoint.address;
        address = merchantAddress.split(', ').slice(0, -1).join(', ')
    }
    return address;
};

const prepareB64File = (base64String: string): Uint8Array => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
}

export const prepareB64FileToXLSX = (base64String: string): Blob => {
    const byteArray = prepareB64File(base64String);
    return new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
};

export const prepareB64FileToText = (base64String: string): Blob => {
    const byteArray = prepareB64File(base64String);
    return new Blob([byteArray], {type: 'text/plain'})
}

export const arrayIncludesAll = <T>(mainArray: T[], subArray: T[]): boolean => {
    const mainSet = new Set(mainArray);
    return subArray.every((element) => mainSet.has(element));
};

export const mapOfferDaysToString = (days: OfferDays | OfferDays[]): string => {
    if (!days) return "";

    const daysArray = Array.isArray(days) ? days : [days];

    const dayMapping: Record<OfferDays, string> = {
        WEEK: 'Tygodniu',
        WORK_WEEK: 'Dni robocze',
        [DayOfWeek.MONDAY]: 'Poniedziałek',
        [DayOfWeek.TUESDAY]: 'Wtorek',
        [DayOfWeek.WEDNESDAY]: 'Środę',
        [DayOfWeek.THURSDAY]: 'Czwartek',
        [DayOfWeek.FRIDAY]: 'Piątek',
        [DayOfWeek.SATURDAY]: 'Sobotę',
        [DayOfWeek.SUNDAY]: 'Niedzielę'
    };

    return daysArray.map(day => dayMapping[day]).join(', ');
};

export const showCustomersNumber = (value: number): string => {
    if (value === 0 || value >= 5) {
        return `${value} osób`;
    } else if (value === 1) {
        return `${value} osoba`;
    } else if (value > 1 && value < 5) {
        return `${value} osoby`;
    } else {
        return `${value} osób`;
    }
};


export const prepareURLParamForChartData = (dateRange: DateRange, previousPeriod: PreviousPeriod, chartInterval: ChartInterval): string => {
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);

    return urlParam.toString()
};

export const prepareURLParamForChartDataWithTargetGroup = (dateRange: DateRange,
                                                           previousPeriod: PreviousPeriod,
                                                           chartInterval: ChartInterval,
                                                           targetGroup: TargetGroup): string => {
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);

    if (targetGroup.id)
        urlParam.append('target_group_id', targetGroup.id.toString());

    return urlParam.toString()
};


export const prepareURLParamForTable = ({page, query, sort, order, filters, pageSize}: GetTableProps) => {
    const urlParam = new URLSearchParams();
    urlParam.append('page', page.toString());
    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    if (filters !== '')
        urlParam.append('filters', filters);
    if (pageSize !== 10)
        urlParam.append('page_size', pageSize.toString());
    return urlParam
};

export const prepareURLParamForDownloadTable = ({query, sort, order, filters}: DownloadTableProps) => {
    const urlParam = new URLSearchParams();
    if (query !== '')
        urlParam.append('q', query);
    if (sort !== '' && order !== null)
        urlParam.append('sort', `${sort},${order}`);
    if (filters !== '')
        urlParam.append('filters', filters);
    return urlParam
};