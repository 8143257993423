import {createSelector, Selector} from "reselect";
import {RootState} from "./rootSelectors";
import {ResponseNotification} from "../../services/interfaces";
import {NavigationModal} from "../navigation";


export const getNavigationState = (state: RootState) => state.navigation;

export const getShouldBlockNavigation: Selector<RootState, boolean> = createSelector(
    getNavigationState,
    (navigation) => navigation.shouldBlock
);

export const getResponseNotification: Selector<RootState, ResponseNotification | null> = createSelector(
    getNavigationState,
    (navigation) => navigation.responseNotification
);

export const getScreenLoading: Selector<RootState, boolean> = createSelector(
    getNavigationState,
    (navigation) => navigation.screenLoading
);

export const getModalOpen = (modal: NavigationModal) => createSelector(
    getNavigationState,
    (navigation) => navigation.modals[modal]
);