import request, {GET, POST} from "../../http/request";
import {handleError} from "../../http/handleError";
import {DateRange} from "../../services/interfaces";


export const downloadMerchantsTable = async (urlParam: string) => {
    try {
        return await request(GET, `merchants/download/?${urlParam}`, {}, {}, true)
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchants = async (urlParams: string) => {
    try {
        const {data} = await request(GET, `merchants/?${urlParams}`, {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantsPieChartData = async (dateRange: DateRange) => {
    try {
        const {data} = await request(GET, `merchants/pie_chart/?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`, {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const sendMessageToMerchants = async (
    title: string,
    content: string,
    merchantIds: number[]
) => {
    try {
        const {data} = await request(POST, `merchants/message`, {title, content, merchant_ids: merchantIds}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};