import React from "react";

import {createPasswordValidation, passwordEqualityValidation} from "../../../services/validators";
import {changePasswordHandler} from "../../../handlers/authHandlers";
import {useDispatch, useSelector} from "react-redux";
import {getAccountErrorStatus} from "../../../redux/selectors/authSelectors";
import {setAccountError} from "../../../redux/auth";

import {ChangePasswordInterface, SetSettingsPageActionType} from "../../../services/interfaces";
import ErrorComponent from "../../../components/common/ErrorComponent";
import Button from "../../../components/common/Button";
import PopUpModal from "../../../components/modals/PopUpModal";
import Input from "../../../components/common/Input";

const AdminSettingsPassword = ({setSettingsPage}: {
    setSettingsPage: React.Dispatch<React.SetStateAction<SetSettingsPageActionType>>
}) => {
    const [data, setData] = React.useState<ChangePasswordInterface>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);
    const [showSuccessPopUp, setShowSuccessPopUp] = React.useState<boolean>(false);
    const accountError = useSelector(getAccountErrorStatus);
    const dispatch = useDispatch();

    interface ChangePasswordErrorKey {
        passwordError: string;
        passwordsNotEqual: string;
    }

    const errorMessage: Record<keyof ChangePasswordErrorKey, string> = {
        passwordError: "Hasło musi zawierać co najmniej 10 znaków, w tym jedną cyfrę, jedną małą literę, jedną wielką literę i znak specjalny.",
        passwordsNotEqual: "Hasła nie są takie same"
    };

    const buttonDisabled: boolean = !!createPasswordValidation(data.newPassword) || !!passwordEqualityValidation(data.newPassword, data.confirmPassword);

    const changePasswordCallback = ({success}: { success: boolean }) => {
        setButtonClicked(false);
        setData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        });

        if (success) {
            dispatch(setAccountError(null));
            setShowSuccessPopUp(true);
        }
    };

    return (
        <div className="password-modal">
            <form>
                <ErrorComponent error={accountError}/>
                <Input<ChangePasswordInterface, 'oldPassword'>
                    value={data}
                    onChange={setData}
                    name="oldPassword"
                    type="password"
                    placeholder="Wprowadź stare hasło"
                />
                <Input<ChangePasswordInterface, 'newPassword'>
                    value={data}
                    onChange={setData}
                    name="newPassword"
                    type="password"
                    placeholder="Wprowadź nowe hasło"
                    showError
                    validate={createPasswordValidation}
                    errorMessage={errorMessage}
                />
                <Input<ChangePasswordInterface, 'confirmPassword'>
                    value={data}
                    onChange={setData}
                    name="confirmPassword"
                    type="password"
                    placeholder="Potwierdź nowe hasło"
                    showError
                    validate={(value) => passwordEqualityValidation(data.newPassword, value)}
                    errorMessage={errorMessage}
                />
                <Button
                    label="Zmień hasło"
                    disabled={buttonDisabled || buttonClicked}
                    onClick={() => {
                        setButtonClicked(true);
                        dispatch(setAccountError(null));
                        changePasswordHandler(data, changePasswordCallback, true);
                    }}
                    fullWidth={true}
                />
            </form>
            <PopUpModal show={showSuccessPopUp}
                        setShow={setShowSuccessPopUp}
                        title="Twoje hasło zostało zmienione"
                        buttonCloseLabel="Zamknij"
                        popUpCloseCallback={() => setSettingsPage(null)}
            />
        </div>
    )
};

export default AdminSettingsPassword;