import {RootState} from "../../../redux/selectors/rootSelectors";
import {createSelector, Selector} from "reselect";
import {
    AdminDashboardCashbackBalance,
    AdminDashboardData,
    AdminDashboardMerchantPoint
} from "../../services/interfaces";

export const getAdminDashboardState = (state: RootState) => state.admin.dashboard;

export const getAdminCurrentDashboardData: Selector<RootState, AdminDashboardData> = createSelector(
    getAdminDashboardState,
    (adminDashboardState) => adminDashboardState.current
);

export const getAdminPreviousDashboardData: Selector<RootState, AdminDashboardData> = createSelector(
    getAdminDashboardState,
    (adminDashboardState) => adminDashboardState.previous
);

export const getAdminDashboardCashbackBalance: Selector<RootState, AdminDashboardCashbackBalance> = createSelector(
    getAdminDashboardState,
    (adminDashboardState) => adminDashboardState.cashback_balance
);


export const getAdminDashboardMerchantPoints: Selector<RootState, AdminDashboardMerchantPoint[]> = createSelector(
    getAdminDashboardState,
    (adminDashboardState) => adminDashboardState.merchantPoints
);
