import React, {useState} from 'react';
import Button from "../../common/Button";
import {OfferData} from "../Stepper";
import dayjs from "dayjs";
import {icons} from "../../../services/images";
import PreviewModal from "../PreviewModal";
import {OfferTypeMapping} from "../../../services/dictionaries/enums";
import {mapOfferDaysToString, showCustomersNumber} from "../../../services/utils";
import {formatNumber} from "../../../services/numbers";

dayjs.locale('pl');

interface StepProps {
    formData: OfferData;
    handleChange: (field: string, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
    setStep: (value: number) => void;
    numberOfCustomers: number;
    estimateCost: number;
    errors: { password?: string };
}

const Step4: React.FC<StepProps> = ({
                                        formData,
                                        numberOfCustomers,
                                        estimateCost,
                                        handleChange,
                                        prevStep,
                                        errors,
                                        nextStep,
                                        setStep
                                    }) => {
    const [previewIsOpen, setPreviewIsOpen] = useState(false);

    const translateClientValue = (value: string): string =>{
        if(value === "current"){
            return "Obecnych klinetów"
        }
        if( value === "new"){
            return "Nowy klientów"
        }
        return ""
    }

    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <div className="label-margin"
                         style={{position: "relative", cursor: "pointer"}}
                         onClick={() => {
                             setPreviewIsOpen(true)
                         }}
                    >
                        <img className="pin" style={{position: "absolute", left: 0}}
                             src={icons.previewIcon} alt={'Preview'}/>
                        <p className="label required-mark" style={{marginLeft: 26}}>Podgląd widoku</p>
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Promocja przeznaczona dla:</p>
                        <p className="label required-mark" style={{cursor: "pointer"}}
                           onClick={() => setStep(1)}>Edytuj</p>
                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={translateClientValue(formData.step1.clients)}
                               disabled
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Grupa docelowa:</p>
                        <p className="label required-mark" style={{cursor: "pointer"}}
                           onClick={() => setStep(1)}>Edytuj</p>
                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               disabled
                               value={formData.step1.targetGroup.name}
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Szczegóły promocji:</p>
                        <p className="label required-mark" style={{cursor: "pointer"}}
                           onClick={() => setStep(2)}>Edytuj</p>
                    </div>
                    <div className="input" style={{display: "flex", flexDirection: "column", padding: 16}}>
                        <div className="label-space-wrapper" style={{marginBottom: 12}}>
                            <p className="label text-gray"
                               style={{fontWeight: 700}}
                            >
                                Nazwa:
                            </p>
                            <p className="label text-gray"
                               style={{fontWeight: 700}}
                            >
                                {formData.step2.name}
                            </p>
                        </div>
                        <div className="label-space-wrapper" style={{marginBottom: 12}}>
                            <p className="label text-gray" style={{fontWeight: 400}}>Rodzaj:</p>
                            <p className="label text-gray" style={{fontWeight: 400}}>
                                {formData.step2.type && OfferTypeMapping[formData.step2.type]}
                            </p>
                        </div>
                        <div className="label-space-wrapper" style={{marginBottom: 12}}>
                            <>
                                <p className="label text-gray" style={{fontWeight: 400}}>Czas trwania:</p>
                                <p className="label text-gray"
                                   style={{fontWeight: 400}}>{`${dayjs(formData.step2.startDate).format('DD.MM.YYYY')} - ${dayjs(formData.step2.endDate).format('DD.MM.YYYY')}`}</p>
                            </>
                        </div>
                        <div className="label-space-wrapper" style={{marginBottom: 12}}>
                            <p className="label text-gray" style={{fontWeight: 400}}>Obowiązuje w:</p>
                            <p className="label text-gray"
                               style={{fontWeight: 400}}>{mapOfferDaysToString(formData.step2.daysOffer)}</p>
                        </div>
                        <div className="label-space-wrapper" style={{marginBottom: 12}}>
                            <p className="label text-gray" style={{fontWeight: 400}}>Ilość użyć:</p>
                            <p className="label text-gray" style={{fontWeight: 400}}>{formData.step2.usage}</p>
                        </div>
                        <div className="label-space-wrapper">
                            <p className="label text-gray" style={{fontWeight: 400}}>Opis:</p>
                            <p className="label text-gray ellipsis-text"
                               style={{fontWeight: 400}}>{formData.step2.description === "" ? "Brak opisu" : formData.step2.description}</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="label-space-wrapper label-margin">
                        <p className="label">Media:</p>
                        <p className="label required-mark" style={{cursor: "pointer"}}
                           onClick={() => setStep(3)}>Edytuj</p>
                    </div>
                    <div className="input label-margin">
                        <input type="text"
                               className="input-text"
                               value={"Obrazek promocyjny"}
                               disabled
                        />
                        {formData.step3.imagePath !== "" && <img src={icons.checkIcon} alt={'Check'}/>}

                    </div>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={"Film promocyjny"}
                               disabled
                        />
                        {formData.step3.filmPath !== "" && <img src={icons.checkIcon} alt={'Check'}/>}
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label" style={{fontWeight: 700}}>{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span className="label" style={{fontWeight: 700}}>{formatNumber(estimateCost)}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <PreviewModal formData={formData} isOpen={previewIsOpen} onClose={() => {
                    setPreviewIsOpen(false)
                }}/>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Zapłać i utwórz promocję"
                            onClick={nextStep}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step4;