import React, {useState} from 'react';
import Modal from 'react-modal';
import Button from "../common/Button";
import {icons} from "../../services/images";
import {MerchantPointSimple} from "../../services/interfaces";
import {isEqual, remove} from "lodash";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    merchantPoints: MerchantPointSimple[];
    currentMerchantPointId: number;
    onApplyChanges: (merchantPointsToUpdate: number[]) => void;
    onCancel: () => void;
}

const ApplyChangesModal = ({isOpen, setIsOpen, merchantPoints, currentMerchantPointId, onApplyChanges, onCancel}: Props) => {
    const [selectedMerchantPoints, setSelectedMerchantPoints] = useState<MerchantPointSimple[]>([]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        toggleModal();
        deselectAll();
        onCancel();
    };

    const isSelected = (merchantPoint: MerchantPointSimple) => selectedMerchantPoints.includes(merchantPoint);

    const onSelect = (merchantPoint: MerchantPointSimple) => {
        let selectedPoints = [...selectedMerchantPoints];
        isSelected(merchantPoint) ?
            remove(selectedPoints, point => point?.id === merchantPoint?.id) :
            selectedPoints.push(merchantPoint);
        setSelectedMerchantPoints(selectedPoints);
    };

    const selectAll = () => setSelectedMerchantPoints(merchantPoints);

    const deselectAll = () => setSelectedMerchantPoints([]);

    const isAllSelected = isEqual(merchantPoints, selectedMerchantPoints);

    const onAllCheckboxClick = () => {
        isAllSelected ? deselectAll() :  selectAll();
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content merchant-data-modal"
        >
            <div className="modal-header">
                <h5>Zastosuj zmiany do:</h5>
                <div className="modal-close" onClick={handleClose}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ul className="modal-list">
                    <li className="modal-list-item" onClick={onAllCheckboxClick}>
                        <input type="checkbox" checked={isAllSelected} onChange={() => null}/>
                        <span>Wszystkie punkty</span>
                    </li>
                    <span className="horizontal-divider"/>
                    {
                        merchantPoints.map((point, index) => (
                            currentMerchantPointId !== point.id ?
                            <li className="modal-list-item"
                                onClick={() =>  onSelect(point)}
                                key={`MerchantPointAddress${index}`}
                            >
                                <input type="checkbox" checked={isSelected(point)} onChange={() => null}/>
                                <span>{point.address}</span>
                            </li> :
                            <li className="modal-list-item disabled" key={`MerchantPointAddressCurrent`}>
                                <input type="checkbox" checked={true} disabled={true} onChange={() => null}/>
                                <span>{point.address}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="modal-footer">
                <Button
                    label="Anuluj"
                    onClick={handleClose}
                    type="Default"
                />
                <Button label="Zastosuj zmiany"
                        onClick={() => {
                            onApplyChanges(selectedMerchantPoints.map(m => m.id));
                            toggleModal();
                        }}
                />
            </div>
        </Modal>
    );
};

export default ApplyChangesModal;

