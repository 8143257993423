import {icons, images} from "../../../services/images";
import React from "react";
import {PartnerType, PartnerTypeState} from "../../services/interfaces";


interface Props {
    partnerType: PartnerTypeState
    setPartnerType: React.Dispatch<React.SetStateAction<PartnerTypeState>>
}

export const DEFAULT_PARTNER_TYPE_STATE = {
    waycup_point: false,
    waycup_partner: false,
    active_promotion: false,
    with_cashback: false,
    without_cashback: false
};


const AdminPartnerTypeFilter = ({partnerType, setPartnerType}: Props) => {

    interface PartnerTypeInterface {
        value: PartnerType
        icon: string
        label: string
        with_background: boolean
    }

    const areAllPartnerTypesActive = Object.values(partnerType).every((value) => value);

    const partnerTypes: PartnerTypeInterface[] = [
        {value: 'without_cashback', icon: images.payProfitTransparent, label: 'Bez cashbacku', with_background: true},
        {value: 'with_cashback', icon: images.payProfitTransparent, label: 'Z cashbackiem', with_background: true},
        {
            value: 'active_promotion',
            icon: images.payProfitTransparent,
            label: 'Z aktywną promocją',
            with_background: true
        },
        {value: 'waycup_point', icon: icons.waycupIcon, label: 'Punkt WAYCUP', with_background: false},
        {value: 'waycup_partner', icon: icons.waycupIcon, label: 'Sklep partnerski WAYCUP', with_background: false},
    ];

    const togglePartnerType = (partnerType: PartnerType) => {
        setPartnerType((prevState: PartnerTypeState) => ({
            ...prevState,
            [partnerType]: !prevState[partnerType],
        }));
    };

    const toggleAll = () => {
        setPartnerType(() => {
            if (areAllPartnerTypesActive) {
                return DEFAULT_PARTNER_TYPE_STATE
            } else {
                return {
                    waycup_point: true,
                    waycup_partner: true,
                    active_promotion: true,
                    with_cashback: true,
                    without_cashback: true
                }
            }
        })
    };

    return (
        <div className="section">
            <div className="header">
                <h6>Wyświetlaj sklepy</h6>
                <span onClick={toggleAll}>
                    {areAllPartnerTypesActive ? 'Odznacz wszystko' : 'Zaznacz wszystko'}
                </span>
            </div>
            <div className="partner-type">
                {partnerTypes.map(o =>
                    <button onClick={() => togglePartnerType(o.value)} key={`PartnerType-${o.value}`}
                            className={`${partnerType[o.value] && 'active'}`}>
                        {o.with_background ?
                            <div className="icon-container">
                                <img src={o.icon} alt="icon" height={11}/>
                            </div> :
                            <img src={o.icon} alt="icon" width={20} height={20}/>
                        }
                        <span>{o.label}</span>
                    </button>
                )}
            </div>
        </div>
    )
};

export default AdminPartnerTypeFilter;