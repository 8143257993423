import ChartIntervalSelect from "../../../components/common/chart/ChartIntervalSelect";
import PreviousPeriodSelect from "../../../components/common/PreviousPeriodSelect";
import DateRangePicker from "../../../components/common/DateRangePicker";
import DashboardIncomeTile from "../../../components/dashboard/DashboardIncomeTile";
import {ChartInterval, DatePreset, DateRange} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import AdminCashbackBalanceTile from "./AdminCashbackBalanceTile";
import AdminDashboardTile from "./AdminDashboardTile";
import AdminMerchantPointServiceButton from "../common/AdminMerchantPointServiceButton";
import AdminDashboardMap from "./map/AdminDashboardMap";
import {
    getAdminBarChartSelected,
    getAdminChartInterval,
    getAdminDatePreset,
    getAdminDateRange,
    getAdminPreviousPeriod
} from "../../redux/selectors/adminViewSettingsSelectors";
import {
    setAdminBarChartSelectedAction,
    setAdminChartIntervalAction,
    setAdminDatePresetAction,
    setAdminDateRangeAction,
    setAdminPreviousPeriodAction
} from "../../redux/adminViewSettings";
import {
    getAdminCurrentDashboardData,
    getAdminPreviousDashboardData
} from "../../redux/selectors/adminDashboardSelectors";
import AdminControlsModal from "../modals/AdminControlsModal";
import {icons} from "../../../services/images";
import React, {useState} from "react";

const AdminDashboard = () => {
    const [isControlsModalOpen, setIsControlsModalOpen] = useState(false);
    const currentData = useSelector(getAdminCurrentDashboardData);
    const previousData = useSelector(getAdminPreviousDashboardData);
    const dateRange: DateRange = useSelector(getAdminDateRange);
    const datePreset: DatePreset | null = useSelector(getAdminDatePreset);
    const chartInterval: ChartInterval = useSelector(getAdminChartInterval);
    const previousPeriod = useSelector(getAdminPreviousPeriod);
    const barChartSelected = useSelector(getAdminBarChartSelected);

    const dispatch = useDispatch();

    return (
        <div className="dashboard-content with-sticky-bar admin">
            {/*<NotificationPopup/>*/}
            <div className="controls-sticky-bar">
                <AdminMerchantPointServiceButton/>
                <div className="selectors-with-labels">
                    <ChartIntervalSelect chartInterval={chartInterval}
                                         onIntervalChange={(interval) => dispatch(setAdminChartIntervalAction(interval))}/>
                    <PreviousPeriodSelect previousPeriod={previousPeriod}
                                          onChange={(period) => dispatch(setAdminPreviousPeriodAction(period))}/>
                    <div className="date-picker-label-wrapper">
                        <span className="label-2">Zakres czasu: </span>
                        <DateRangePicker dateRange={dateRange}
                                         setDateRange={(dateRange: DateRange) => dispatch(setAdminDateRangeAction(dateRange))}
                                         datePreset={datePreset}
                                         setDatePreset={(datePreset) => dispatch(setAdminDatePresetAction(datePreset))}
                                         admin={true}
                        />
                    </div>
                </div>
            </div>
            <div className="controls-mobile-button" onClick={() => setIsControlsModalOpen(true)}>
                <img src={icons.cogIcon} alt=""/>
            </div>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Kokpit - Payprofit</h2>
                </div>
            </div>
            <div className="block-wrapper">
                <AdminCashbackBalanceTile/>
                <div className="block-1-4 block-wrapper column">
                    <AdminDashboardTile
                        title="Liczba użytkowników"
                        value={currentData.user_count}
                        prevValue={previousData.user_count}
                    />
                    <AdminDashboardTile
                        title="Liczba transakcji"
                        value={currentData.transaction_count}
                        prevValue={previousData.transaction_count}
                    />
                </div>
                <div className="block-1-4 block-wrapper column">
                    <AdminDashboardTile
                        title="Liczba Sklepów"
                        value={currentData.merchant_point_count}
                        prevValue={previousData.merchant_point_count}
                    />
                    <AdminDashboardTile
                        title="Aktywne promocje"
                        value={currentData.active_offers}
                        prevValue={previousData.active_offers}
                    />
                </div>
            </div>
            <div className="block-wrapper chart-block-wrapper">
                <DashboardIncomeTile income={currentData.income}
                                     prevIncome={previousData.income}
                                     incomeData={currentData.income_data}
                                     prevIncomeData={previousData.income_data}
                                     chartInterval={chartInterval}
                                     barChartSelected={barChartSelected}
                                     setBarChartSelected={(selected) => dispatch(setAdminBarChartSelectedAction(selected))}
                                     title="Przychody (zł)"
                />
            </div>
            <AdminDashboardMap/>
            <AdminControlsModal isOpen={isControlsModalOpen} setIsOpen={setIsControlsModalOpen}/>
        </div>
    )
};

export default AdminDashboard;